import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import {
  installIntegration,
  updateIntegration,
} from "../../../apis/workspace/integrations";
import { toast } from "react-hot-toast";
import SharedDataContext from "../../../SharedDataContext";
import { PencilIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "react-tooltip";
import SuccessAlert from "../../Alerts/Timer/SuccessAlert";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
const DynamicForm = ({
  integrationKey,
  integrationFormsMetaData,
  logo,
  closeForm,
  formData,
  edit,
  onboarding,
}) => {
  const integrationData = integrationFormsMetaData[integrationKey];

  if (!integrationData) {
    return <div></div>;
  }

  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const queryClient = useQueryClient();

  const { fields, info, display_name } = integrationData;

  const defaultShownFields = fields.reduce((acc, field) => {
    acc[field.name] = edit ? field.name === "name" : true;
    return acc;
  }, {});

  const [shownFields, setShownFields] = useState(defaultShownFields);

  const toggleField = (fieldName) => {
    setShownFields((prevShown) => ({
      ...prevShown,
      [fieldName]: !prevShown[fieldName],
    }));
    formik.setFieldTouched(fieldName, false); // Reset touch state to prevent unnecessary validation message
    formik.validateForm(); // Trigger a new validation
  };

  const generateValidationSchema = (fields) => {
    let schema = {};

    fields.forEach((field) => {
      if (field.name !== "name") {
        // exclude the name field from toggling
        schema[field.name] = shownFields[field.name]
          ? yup.string().required("This field is required")
          : yup.string();
      } else {
        // name field is always required
        schema[field.name] = yup.string().required("This field is required");
      }
    });

    return yup.object().shape(schema);
  };
  const validationSchema = generateValidationSchema(fields);

  useEffect(() => {
    // formik.setFieldTouched({}, false, false);
    // formik.setErrors({});
    // formik.setValues(formik.values);
    formik.validateForm();
  }, [shownFields]);

  const formik = useFormik({
    initialValues: fields.reduce((acc, field) => {
      if (field.name === "name") {
        acc[field.name] = edit ? formData.name : display_name;
      } else {
        acc[field.name] = "";
      }
      return acc;
    }, {}),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (edit) {
        updateIntegrationFn.mutate({
          data: values,
          configId: formData.configId,
          options: {
            headers: { "X-Workspace-Id": activeWorkspace?.id },
          },
        });
      } else {
        installIntegrationFn.mutate({
          data: values,
          key: integrationKey,
          options: {
            headers: { "X-Workspace-Id": activeWorkspace?.id },
          },
        });
      }

      //console.log("Form Values:", values);
    },
  });
  const setQueryString = useUpdateQuery();

  // handle form submit function
  const installIntegrationFn = useMutation(
    "install_integration",
    installIntegration,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);
        setIsTimerVisible(true);
        queryClient.invalidateQueries("get_integrations");
        formik.setSubmitting(false);
        formik.resetForm();
        setQueryString(`key=${formik.values.name}&tab=installed`);
      },
      onError(error) {
        formik.setSubmitting(false);
      },
    }
  );

  // handle update form

  const updateIntegrationFn = useMutation(
    "install_integration",
    updateIntegration,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);
        closeForm();
        queryClient.invalidateQueries("get_integrations");

        formik.setSubmitting(false);
        formik.resetForm();
      },
      onError(error) {
        formik.setSubmitting(false);
      },
    }
  );

  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const onTimerEnd = () => {
    closeForm();
  };
  const captureEvent = useCaptureEvent();
  return (
    <>
      {isTimerVisible && !edit ? (
        <SuccessAlert
          onTimerEnd={onTimerEnd}
          isVisible={isTimerVisible}
          heading={"Integration Installed Successfully!"}
          // description={"Integration has been done."}
          timer={onboarding ? 3 : 5}
        />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <span
            className="svg__32 inline-block border border-border rounded-[--radius] p-1 bg-white"
            dangerouslySetInnerHTML={{
              __html: logo,
            }}
          />
          <h3 className="text-[18px] block font-semibold mb-5 mt-4">
            {integrationData.display_name}
          </h3>
          {fields.map((field, index) => {
            return (
              <div
                key={index}
                className={`mb-4 ${
                  onboarding && field.name === "name" ? "hidden" : ""
                }`}
              >
                <label
                  className="text-sm font-medium leading-6  mb-2 flex items-center"
                  htmlFor={field.name}
                >
                  {field.label}
                  {edit && field.name !== "name" && (
                    <span
                      onClick={() => toggleField(field.name)}
                      className="ml-2 text-black-500 cursor-pointer inline-flex"
                      data-tooltip-id="in_form_tooltip"
                      data-tooltip-content={`Update ${field.label}`}
                    >
                      <PencilSquareIcon width={12} height={12} />
                      <Tooltip id="in_form_tooltip" />
                    </span>
                  )}
                </label>

                {edit && field.name !== "name" ? (
                  <>
                    {shownFields[field.name] ? (
                      <div>
                        <Input
                          id={field.name}
                          name={field.name}
                          type={field.type}
                          placeholder={`Enter ${field.placeholder}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values[field.name]}
                          onClick={() => {
                            captureEvent("SET_ManageIntegrationReConfigure_UpdateAPIKeyBtn");
                          }}
                        />
                      </div>
                    ) : (
                      <Input
                        type={field.type}
                        disabled
                        placeholder={field.placeholder}
                      />
                    )}
                  </>
                ) : (
                  <div>
                    <Input
                      id={field.name}
                      name={field.name}
                      type={field.type}
                      placeholder={`Enter ${field.placeholder}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values[field.name]}
                      onClick={() => {
                        if(field.name==='apiKey'){ 
                          captureEvent("SET_ManageIntegrationAddConnection_APIKey");
                        }
                        
                      }}
                    />
                  </div>
                )}

                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <div className="text-red-700 text-size-10 mt-2">
                    {formik.errors[field.name]}
                  </div>
                ) : null}
              </div>
            );
          })}

          {info && (
            <div className="border mt-4 border-orange-200 bg-orange-50 text-orange-600 rounded-md py-3 px-5">
              {info}
            </div>
          )}

          <div className="mt-8 flex items-center  space-x-2 absolute p-6 border-t border-border left-0 bottom-0 z-10 bg-background w-full">
            <Button
              onClick={(e) => {
                e.preventDefault();
                closeForm("cancel");
                captureEvent("SET_ManageIntegrationAddConnection_CancelBtn");
              }}
              variant="outline"
              className="flex-1"
            >
              Cancel
            </Button>
            <Button
             onClick={() => {
              if(edit==="Submit"){
                captureEvent("SET_ManageIntegrationAddConnection_SubmitBtn");
              }else{
                captureEvent("SET_ManageIntegrationReConfigure_UpdateBtn");
              }
             
            }}
              type="submit"
              disabled={
                formik.isSubmitting || (formik.dirty && !formik.isValid)
              }
              className="flex-1"

            >
              {edit ? "Update" : "Submit"}{" "}
              {formik.isSubmitting && (
                <div className="loader_circle ml-2"></div>
              )}
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default DynamicForm;
