import React, { useEffect, useState } from "react";
import GenericModal from "../../../GenericModal";
import useModal from "../../../GenericModal/useModal";
import { RadioGroup, RadioGroupItem } from "../../../ui/radio-group";
import Text from "../../../Tags/Text";
import { Button } from "../../../ui/button";

export default function AdvanceMapping({ onConfirm, item, selected, advanced_mapping }) {
  const { isOpen, openModal, closeModal } = useModal(false);

  const [currentValue, setCurrentValue] = useState("default");
  const [currentMapping, setCurrentMapping] = useState({});
  const radios = [
    {
      name: "Use default mapping",
      value: "default",
    },
    {
      name: "Custom mapping",
      value: "custom",
    },
  ];

  useEffect(() => {
    return () => {
      setCurrentValue("default");
      setCurrentMapping({});
    };
  }, []);

  useEffect(() => {
    if (advanced_mapping && advanced_mapping.value_mapping) {
      setCurrentMapping(advanced_mapping.value_mapping);
      setCurrentValue("custom");
    }
  }, [advanced_mapping]);

  return (
    <>
      <span className="text-[#6941C6] font-bold text-sm cursor-pointer" onClick={openModal}>
        Advanced
      </span>
      <GenericModal isOpen={isOpen} closeModal={closeModal} width="w-[500px]">
        <h3 className="text-[18px] block font-semibold mb-1 text-text-900">{item && item?.label}</h3>
        <p className="text-text-600 mb-7">Please choose your mapping preferences for field</p>
        <RadioGroup
          defaultValue={currentValue}
          onValueChange={(value) => {
            setCurrentValue(value);
          }}
        >
          {radios &&
            radios.length > 0 &&
            radios.map((radio) => (
              <div className={`flex items-start space-x-3 mb-2`}>
                <div className="inline-flex items-center justify-center">
                  <RadioGroupItem value={radio.value} id={radio.value} />
                </div>
                <div className="flex-1">
                  <label htmlFor={radio.value}>
                    <span size="sm" className="text-gray-dark-700 font-light leading-tight">
                      {radio.name}
                    </span>
                  </label>
                  {radio.value === "custom" && (
                    <div className="mt-3">
                      {item &&
                        item.advanced_mapping_options &&
                        item.advanced_mapping_options.values &&
                        item.advanced_mapping_options.values.length > 0 &&
                        item.advanced_mapping_options.values.map((el) => (
                          <div className="flex items-center mt-1.5 w-full">
                            <div className={`w-4/12  font-semibold ${el === "-" && "italic"}`}>
                              {el !== "-" ? el : "Empty"}
                            </div>

                            <select
                              value={
                                currentMapping && currentMapping[el]
                                  ? currentMapping[el] || advanced_mapping?.value_mapping[el]
                                  : ""
                              }
                              onChange={(e) => {
                                setCurrentMapping((prevMapping) => ({
                                  ...prevMapping,
                                  [el]: e.target.value,
                                }));
                              }}
                              disabled={currentValue === "custom" ? false : true}
                              className=" h-10 px-4 py-1 text-sm w-6/12 rounded-[--radius] border border-border bg-background text-text-900"
                            >
                              <option>--Select--</option>
                              {selected &&
                                selected.picklist_values &&
                                selected.picklist_values.length > 0 &&
                                selected.picklist_values.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                            </select>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </RadioGroup>
        <div className="flex space-x-3 mt-6">
          <Button variant="outline" className="flex-1" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="flex-1"
            onClick={() => {
              onConfirm(currentValue === "default" ? null : currentMapping);
              closeModal();
            }}
          >
            Confirm
          </Button>
        </div>
      </GenericModal>
    </>
  );
}
