import React, { useState } from "react";
import Image from "../Tags/Image";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";

export default function AlertTag({
  heading,
  description,
  variant = "destructive",
  dismiss = true,
  cta,
}) {
  const [showAlert, setShowAlert] = useState(true);

  return (
    <>
      {showAlert ? (
        <Alert className="mb-6" variant={variant}>
          <div className="flex items-start space-x-3">
            <Image
              src="/images/icons/untitledui/alert-error.svg"
              width="30"
              height="30"
              className="no-invert inline-block -mt-1 -ml-1"
            />
            <div>
              {heading && (
                <AlertTitle className="text-gray-900 font-bold mb-2">
                  {heading}
                </AlertTitle>
              )}
              {description && (
                <AlertDescription>{description}</AlertDescription>
              )}
              <div className="flex items-center space-x-3 mt-3">
                {dismiss && (
                  <Button
                    onClick={() => {
                      setShowAlert(false);
                    }}
                    variant="link"
                    className="p-0 h-min  text-gray-600"
                  >
                    Dismiss
                  </Button>
                )}
                {cta && cta}
              </div>
            </div>
          </div>
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
