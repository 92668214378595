import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect } from "react";

export default function GenericModal({
  isOpen,
  closeModal,
  title,
  children,
  width = "w-[600px]",
  drawer = false,
  dismiss = true,
  drawerWidth = "",
  loading = false,
  overflow = "overflow-hidden",
}) {
  useEffect(() => {
    const docEl = document && document.documentElement;
    if (docEl && isOpen) {
      docEl.classList.add("modal__open");
    } else {
      docEl.classList.remove("modal__open");
    }
    return () => {
      if (docEl) {
        docEl.classList.remove("modal__open");
      }
    };
  }, [isOpen]);

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            dismiss ? closeModal() : void 0;
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#0C111D] bg-opacity-80 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div
              className={`${
                drawer
                  ? "fixed right-0 top-0 h-full"
                  : "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mobile:items-start"
              }`}
            >
              <Transition.Child
                as={Fragment}
                enter={`${drawer ? "ease duration-300" : "ease-out duration-300"}`}
                enterFrom={`${
                  drawer ? "translate-x-9" : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                }`}
                enterTo={`${drawer ? "translate-x-0" : "opacity-100 translate-y-0 sm:scale-100"}`}
                leave={`${drawer ? "transition ease-out duration-200 transform" : "ease-in duration-0"}`}
                leaveFrom={`${drawer ? "-translate-x-0" : "opacity-100 translate-y-0 sm:scale-100"}`}
                leaveTo={`${
                  drawer ? "translate-x-full" : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                }`}
              >
                <Dialog.Panel
                  className={`${
                    drawer
                      ? `drawer__dialog__panel bg-background ml-auto h-full overflow-y-auto ${
                          drawerWidth ? drawerWidth : "max-w-[500px] min-w-[500px]"
                        }`
                      : `relative transform ${overflow} rounded-lg bg-background px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6  ${width}`
                  }`}
                >
                  {dismiss && (
                    <div className={`absolute right-0 top-0  ${drawer ? "pr-6 pt-6" : "pr-4 pt-4"} sm:block`}>
                      <button type="button" className="rounded-md  text-paragraph  " onClick={closeModal}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                  <div className="text-[18px] font-semibold">{title}</div>
                  <div className="h-full">
                    {loading ? (
                      <div className="absolute top-1/2 left-1/2 loader_circle ml-3 !border-l-brand-700 dark:!border-l-brand-400" />
                    ) : (
                      children
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
