import React from "react";
import EnrichLeftNav from "../../containers/Enrich/EnrichLeftNav";
import Layout from "../../Layout";
import { useParams } from "react-router-dom";

import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import ContactCard from "./ContactCard";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export default function ContactPage() {
  const { contactId } = useParams();

  return (
    <Layout leftNav={<EnrichLeftNav />}>
      {contactId && <ContactCard contactId={contactId} />}
    </Layout>
  );
}
