import { getListsAttributes } from "../../../../apis/workspace/lists";
import { ReactComponent as InfoIcon } from "../../../../static/media/icons/info-icon.svg";
import { ReactComponent as ListIcon } from "../../../../static/media/icons/list-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../static/media/icons/trash-detail-icon.svg";

const items = [
  {
    group: "group_1",
    items: [
      {
        action: "info",
        label: "Info",
        icon: <InfoIcon height="16" width="16" />,
      },
      {
        action: "add",
        label: "Add to List",
        icon: <ListIcon height="16" width="16" />,
        props: {
          isItemsLoaded: false,
          queryFn: (workspaceId) =>
            getListsAttributes({ options: { headers: { "X-Workspace-Id": workspaceId } } }),
          onSuccess: (data) => {
            return data.map(({ id, name }) => ({
              action: "add",
              label: name,
              data: { id, name },
            }));
          },
        },
        items: [],
      },
    ],
  },
  {
    group: "group_2",
    items: [
      {
        action: "delete",
        label: "Remove Item",
        icon: <TrashIcon height="16" width="16" className="stroke-red-600" />,
        className: "text-red-600 focus:text-red-600",
      },
    ],
  },
];

export const baseRowActionItems = {
  props: {
    isItemsLoaded: true,
  },
  items,
};
