import { DELETE, GET, PATCH, POST } from "../../axios/apiService";

export const checkListsNameAvailability = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/check-name-availability`;
  return POST(url, data, options);
};

export const createList = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/create`;
  return POST(url, { lists: [data] }, options);
};

export const duplicateList = async ({ data, options, listId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/create/${listId}`;
  return POST(url, data, options);
};

export const editList = async ({ data, options, listId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/${listId}`;
  return PATCH(url, data, options);
};

export const getLists = async ({ query, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const getListsAttributes = async ({ query, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/attributes${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const getList = async ({ query, options, listId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/${listId}${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const addItemsToList = async ({ data, options, listId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/${listId}/items`;
  return POST(url, { items: data }, options);
};

export const deleteList = async ({ options, listId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/${listId}`;
  return DELETE(url, options);
};

export const removeItemsFromList = async ({ options, data, listId, removeAll = false }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/lists/${listId}/items/remove`;
  return POST(url, { remove_all: removeAll, items: data }, options);
};
