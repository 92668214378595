import React from "react";
import Layout from "../../Layout";
import Groups from "../../containers/Groups";

export default function GroupsPage() {
  return (
    <Layout>
      <Groups />
    </Layout>
  );
}
