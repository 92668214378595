import { createContext, useContext, useEffect, useState } from "react";

const initialState = {
  theme: "system",
  setTheme: () => null,
};

const ThemeProviderContext = createContext(initialState);

export function ThemeProvider({ children, defaultTheme = "light", storageKey = "pros-ui-theme", ...props }) {
  const [theme, updateTheme] = useState(() => localStorage.getItem(storageKey) || defaultTheme);

  useEffect(() => {
    const root = window.document.documentElement;
    const systemThemeListener = (e) => {
      if (theme === "system") {
        root.classList.remove("light", "dark");
        root.classList.add(e.matches ? "dark" : "light");
        localStorage.setItem(storageKey, e.matches ? "dark" : "light");
        updateTheme(e.matches ? "dark" : "light");
      }
    };

    const applyTheme = (theme) => {
      root.classList.remove("light", "dark");

      if (theme === "system") {
        const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        root.classList.add(systemTheme);

        // Add event listener for changes in system theme
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", systemThemeListener);

        localStorage.setItem(storageKey, systemTheme);
        updateTheme(systemTheme);
      } else {
        root.classList.add(theme);

        // Remove event listener if not using system theme
        window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", systemThemeListener);
      }
    };

    applyTheme(theme);

    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", systemThemeListener);
    };
  }, [theme]);

  const value = {
    theme,
    setTheme: (newTheme) => {
      console.log(theme);
      localStorage.setItem(storageKey, newTheme);
      updateTheme(newTheme);
    },
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};
