import { useEffect } from "react";

const useClickOutside = (classToExclude, stateVariables) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedInside = event.target.closest(`.${classToExclude}`);
      if (!clickedInside) {
        for (let i = 0; i < stateVariables.length; i++) {
          stateVariables[i](false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [classToExclude, stateVariables]);
};

export default useClickOutside;
