import React from "react";

const Stepper = ({ steps }) => {
  const stepKeys = Object.keys(steps);
  return (
    <div className="flex space-x-3">
      {stepKeys.map((key, index) => (
        <span
          key={index}
          className={`flex-1  h-1.5 rounded-3xl ${
            steps[key] ? "bg-gray-800" : "bg-gray-300"
          }`}
        />
      ))}
    </div>
  );
};

export default Stepper;
