import { DELETE, GET, PATCH, POST } from "../../axios/apiService";

export const getAuditLogs = async ({ query, options }) => {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/api/v1/workspace-settings/security/audit-logs${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const getCreditConsumptionLog = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/security/credit-consumption-logs`;
  return POST(url, data, options);
};

export const getApiKeys = async ({ query, options }) => {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/api/v1/workspace-settings/api-keys${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const createApiKey = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/api-keys`;
  return POST(url, data, options);
};

export const rotateApiKey = async ({ data, id, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/api-keys/${id}/rotate`;
  return POST(url, data, options);
};

export const updateApiKey = async ({ data, id, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/api-keys/${id}`;
  return PATCH(url, data, options);
};

export const deleteApiKey = async ({ id, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/api-keys/${id}`;
  return DELETE(url, options);
};
