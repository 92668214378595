import React from "react";
import Layout from "../../Layout";
import Dashboard from "../../containers/Dashboard";

export default function DashboardPage() {
  return (
    <Layout leftNav={false}>
      <Dashboard />
    </Layout>
  );
}
