import React, { useContext, useEffect, useState } from "react";
import EnrichLeftNav from "../../containers/Enrich/EnrichLeftNav";
import Layout from "../../Layout";
import SharedDataContext from "../../../SharedDataContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { enrichProfile, getContactDetails } from "../../../apis/enrich";
import { useParams } from "react-router-dom";
import Text from "../../Tags/Text";
import {
  ChevronLeft,
  LoaderCircle,
  LoaderCircleIcon,
  LucideLoader2,
  MapPinnedIcon,
  PlusIcon,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import NavAnchor from "../../Tags/NavAnchor";
import Image from "../../Tags/Image";
import { getIntegrations } from "../../../apis/workspace/integrations";

import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import EnrichedPhone from "../../EnrichedPhone";
import EnrichedEmail from "../../EnrichedEmail";
import StripeLoader from "../../StripeLoader";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export default function ContactCard({ contactId, onboarding = false }) {
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};

  const [contact, setContact] = useState({});
  const getContactDetailsFn = useMutation(
    "get_contact_details",
    getContactDetails,
    {
      onSuccess(data, variables, context) {
        console.log(data);
        if (data && data.contact) {
          setContact(data.contact);
        }
        if (
          data &&
          data.contact &&
          data.contact.phone_numbers &&
          data.contact.phone_numbers.length === 0 &&
          data.contact.email_addresses &&
          data.contact.email_addresses.length === 0
        ) {
          enrichProfileDetails();
        }
      },
    }
  );
  const handleGetContactDetails = () => {
    getContactDetailsFn.mutate({
      contactId: contactId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetContactDetails();
    }
  }, [activeWorkspace, contactId]);

  const topics = ["Summary", "Skills", "Experience", "Education"];
  const [currentTopic, setCurrentTopic] = useState("Summary");

  // get integrations
  const [logos, setLogos] = useState({});
  const getIntegrationsFn = useQuery(
    "get_integrations",
    () => {
      return getIntegrations({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        if (data && data.available && data.available.length > 0) {
          const keylogos = data.available.reduce((acc, curr) => {
            acc[curr.key] = curr?.logo;
            return acc;
          }, {});
          setLogos(keylogos);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetIntegrations = () => {
    getIntegrationsFn.refetch();
  };

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetIntegrations();
    }
  }, [activeWorkspace]);

  // enrich profile by contact id

  const enrichProfileFn = useMutation("enrich_profile", enrichProfile, {
    onSuccess(data, variables, context) {
      // toast.success(data?.message);

      if (
        data &&
        data.list &&
        data.list.contacts &&
        data.list.contacts.length > 0
      ) {
        let contact = data.list.contacts[0];
        setContact(contact);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const enrichProfileDetails = () => {
    enrichProfileFn.mutate({
      data: {
        records: [
          {
            contact_id: contactId,
          },
        ],
      },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    // This function is what you want to run at every interval
    const doPolling = () => {
      if (contact && contact.id && !enrichProfileFn.isLoading) {
        enrichProfileDetails();
      }
    };

    // Start polling
    const intervalId = setInterval(doPolling, 5000); // 5000ms = 5 seconds

    if (
      contact &&
      contact.enrichment_timeline &&
      contact.enrichment_timeline.length > 0
    ) {
      const hasPendingInTimeline = contact.enrichment_timeline.some(
        (item) =>
          item.status.toLowerCase() === "pending" ||
          item.status.toLowerCase() === "processing"
      );
      const hasPendingInMobile =
        contact.phone_numbers &&
        contact.phone_numbers.length > 0 &&
        contact.phone_numbers.some(
          (item) =>
            item.verification &&
            item.verification.status.toLowerCase() === "pending"
        );
      const hasPendingInEmail =
        contact.email_addresses &&
        contact.email_addresses.length > 0 &&
        contact.email_addresses.some(
          (item) =>
            item.verification &&
            item.verification.status.toLowerCase() === "pending"
        );
      if (!hasPendingInTimeline) {
        // If no items are 'Pending', stop polling
        if (!hasPendingInMobile && !hasPendingInEmail) {
          clearInterval(intervalId);
        }
      }
    }
    if (
      contact &&
      contact.enrichment_timeline &&
      contact.enrichment_timeline.length === 0
    ) {
      clearInterval(intervalId);
    }

    // Cleanup function to clear the interval when the component unmounts or the effect re-runs
    return () => clearInterval(intervalId);
  }, [contact, activeWorkspace && activeWorkspace.id]); // Empty dependency array means this effect runs only once on mount

  useEffect(() => {
    // This function is what you want to run at every interval
    const doPolling = () => {
      if (contact && contact.id && !enrichProfileFn.isLoading) {
        enrichProfileDetails();
      }
    };

    // Start polling
    const intervalId = setInterval(doPolling, 5000); // 5000ms = 5 seconds

    if (contact && contact.icp_ratings && contact.icp_ratings.length > 0) {
      const hasPendingInICP =
        contact.icp_ratings &&
        contact.icp_ratings.length > 0 &&
        contact.icp_ratings.some(
          (item) => item.status && item.status.toLowerCase() === "pending"
        );
      if (!hasPendingInICP) {
        // If no items are 'Pending', stop polling
        clearInterval(intervalId);
      }
    }
    if (contact && contact.icp_ratings && contact.icp_ratings.length === 0) {
      clearInterval(intervalId);
    }

    // Cleanup function to clear the interval when the component unmounts or the effect re-runs
    return () => clearInterval(intervalId);
  }, [contact, activeWorkspace && activeWorkspace.id]); // Empty dependency array means this effect runs only once on mount

  const showStripeLoader = () => {
    if (
      contact &&
      contact.enrichment_timeline &&
      contact.enrichment_timeline.some(
        (item) =>
          item.status.toLowerCase() === "pending" ||
          item.status.toLowerCase() === "processing"
      )
    ) {
      return <StripeLoader />;
    } else if (getContactDetailsFn.isLoading) {
      return <StripeLoader />;
    }
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      {" "}
      {!onboarding && (
        <NavAnchor
          className="inline-flex items-center cursor-pointer"
          onClick={() => {
            setContact({});
          }}
          to={"/enrich/people"}
        >
          <ChevronLeft width={20} height={20} />
          Back
        </NavAnchor>
      )}
      <div className="space-x-4 mt-6 flex">
        <div className="w-8/12 pr-10">
          <Text size="h5">{contact.full_name}</Text>
          <Text size="md-base" className="mt-2">
            {contact.headline}
          </Text>
          <p className="text-base mt-1 items-center inline-flex text-gray-700 font-semibold space-x-2">
            <MapPinnedIcon width={20} height={20} />{" "}
            <span>{contact.location}</span>
          </p>
          {/* <div className="my-6 space-x-3 items-center flex">
                <Button>
                  <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Add to collection
                </Button>
                <Button variant="outline">Add to CRM</Button>
              </div> */}
          <div className="border-b border-border mb-2 space-x-2 mt-6 bg-gray-25 sticky top-[-28px]">
            {topics &&
              topics.length > 0 &&
              topics.map((el) => (
                <Button
                  className={`font-semibold text-text-500 focus:bg-none px-10 rounded-none border-t-0 border-x-0 shadow-none ${
                    el === currentTopic
                      ? "bg-brand-100 text-brand-700 pointer-events-none border-b-2 border-brand-600"
                      : "border-b-2 border-transparent"
                  }`}
                  variant={el === currentTopic ? "purple-outline" : "ghost"}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentTopic(el);
                    scrollToSection(el);
                  }}
                >
                  {el}
                </Button>
              ))}
          </div>
          <div className="py-3 pt-0 px-8">
            <div className="py-6 border-b border-border" id="Summary">
              <h6 className="text-base text-gray-700 font-semibold">Summary</h6>
              <p className="text-base text-gray-600 mt-3">
                {contact.bio ? contact.bio : "-"}
              </p>
            </div>

            <div className="border-b border-border py-6" id="Skills">
              <h6 className="text-base text-gray-700 font-semibold">Skills</h6>
              <div className="mt-3 space-x-3">
                {contact.skills && contact.skills.length > 0 ? (
                  <>
                    <Badge variant={"gray"}>Selenium</Badge>
                    <Badge variant={"gray"}>TestNG</Badge>
                    <Badge variant={"gray"}>Python</Badge>
                    <Badge variant={"gray"}>C++</Badge>
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>

            <div className="border-b border-border py-6" id="Experience">
              <h6 className="text-base text-gray-700 font-semibold">
                Experience
              </h6>
              {contact && contact.companies && contact.companies.length > 0 ? (
                <div className="mt-3 space-y-6">
                  {contact.companies.map((company) => (
                    <div className="border border-border rounded-lg p-6 shadow-sm">
                      <div className="space-y-1">
                        <h6 className="text-base text-gray-700 font-semibold">
                          {company?.info?.position}
                        </h6>
                        <Text>{company?.name}</Text>
                        {company?.info?.start_date && (
                          <Text>
                            {company?.info?.start_date} –{" "}
                            {company?.info?.is_current
                              ? "Present"
                              : company?.info?.end_date}
                          </Text>
                        )}
                      </div>
                      {company?.info?.description && (
                        <p className="mt-6">{company?.info?.description}</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                "-"
              )}
            </div>

            <div className="border-b border-border py-6" id="Education">
              <h6 className="text-base text-gray-700 font-semibold">
                Education
              </h6>
              {contact.education && contact.education.length > 0 ? (
                <div className="mt-3 space-y-6">
                  {contact.education.map((education) => (
                    <div className="border border-border rounded-lg p-6 shadow-sm">
                      <div className="space-y-1">
                        <h6 className="text-base text-gray-700 font-semibold">
                          {education?.institute}
                        </h6>
                        <Text>{education?.program}</Text>
                        <Text>
                          {education?.start_year}{" "}
                          {education?.end_year
                            ? `- ${education?.end_year}`
                            : ""}
                        </Text>
                      </div>
                      {/* <p className="mt-6">
                            Advise FIIs on derivative products to trade in
                            indian stock market. Co-heading options and quant
                            desk in the team.
                          </p> */}
                    </div>
                  ))}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
        <div className="w-4/12">
          {contact &&
            contact.phone_numbers &&
            contact.phone_numbers.length !== 0 &&
            contact.email_addresses &&
            contact.email_addresses.length !== 0 && (
              <div className="border border-border p-4 rounded-[12px] bg-gray-50">
                <Text size="md">Contact</Text>
                {contact &&
                  contact.phone_numbers &&
                  contact.phone_numbers.length > 0 && (
                    <>
                      <div className="mt-5 space-y-2">
                        {contact.phone_numbers.map((number) => (
                          <div className="flex items-center">
                            <div className="w-8">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.38028 8.85323C9.07627 10.3028 10.0251 11.6615 11.2266 12.8631C12.4282 14.0646 13.7869 15.0134 15.2365 15.7094C15.3612 15.7693 15.4235 15.7992 15.5024 15.8222C15.7828 15.904 16.127 15.8453 16.3644 15.6752C16.4313 15.6274 16.4884 15.5702 16.6027 15.4559C16.9523 15.1063 17.1271 14.9315 17.3029 14.8172C17.9658 14.3862 18.8204 14.3862 19.4833 14.8172C19.6591 14.9315 19.8339 15.1063 20.1835 15.4559L20.3783 15.6508C20.9098 16.1822 21.1755 16.448 21.3198 16.7333C21.6069 17.3009 21.6069 17.9712 21.3198 18.5387C21.1755 18.8241 20.9098 19.0898 20.3783 19.6213L20.2207 19.7789C19.6911 20.3085 19.4263 20.5733 19.0662 20.7756C18.6667 21 18.0462 21.1614 17.588 21.16C17.1751 21.1588 16.8928 21.0787 16.3284 20.9185C13.295 20.0575 10.4326 18.433 8.04466 16.045C5.65668 13.6571 4.03221 10.7947 3.17124 7.76131C3.01103 7.19687 2.93092 6.91464 2.9297 6.5017C2.92833 6.04347 3.08969 5.42298 3.31411 5.02348C3.51636 4.66345 3.78117 4.39863 4.3108 3.86901L4.46843 3.71138C4.99987 3.17993 5.2656 2.91421 5.55098 2.76987C6.11854 2.4828 6.7888 2.4828 7.35636 2.76987C7.64174 2.91421 7.90747 3.17993 8.43891 3.71138L8.63378 3.90625C8.98338 4.25585 9.15819 4.43065 9.27247 4.60643C9.70347 5.26932 9.70347 6.1239 9.27247 6.78679C9.15819 6.96257 8.98338 7.13738 8.63378 7.48698C8.51947 7.60129 8.46231 7.65845 8.41447 7.72526C8.24446 7.96269 8.18576 8.30695 8.26748 8.5873C8.29048 8.6662 8.32041 8.72854 8.38028 8.85323Z"
                                  stroke="#344054"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="inline-flex items-center space-x-2">
                              {/* <p className="font-semibold text-brand-700">
                                {number?.value}
                              </p> */}
                              <EnrichedPhone
                                type={number?.type}
                                phoneValue={number?.value}
                                isValid={number?.verification?.is_valid}
                                lineType={number?.verification?.line_type}
                                foundFrom={
                                  number?.integration_config?.integration
                                    ?.display_name
                                }
                                feedback={number?.feedback}
                                value={
                                  number?.verification?.status === "Completed"
                                    ? number?.verification?.international_format
                                      ? number?.verification
                                          ?.international_format
                                      : number?.value
                                    : number?.value
                                }
                                activeWorkspace={activeWorkspace}
                                contactId={contact.id}
                                onSuccess={() => {
                                  handleGetContactDetails();
                                }}
                              />
                              {/* <Badge variant={"gray-blue"}>
                                {number?.type}
                              </Badge> */}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex">
                        <div className="w-8"></div>
                        <div className="border-b border-border my-5 h-[1px] flex-1"></div>
                        <div className="w-8"></div>
                      </div>
                    </>
                  )}
                {contact &&
                  contact.email_addresses &&
                  contact.email_addresses.length > 0 && (
                    <div className="space-y-2">
                      {contact.email_addresses.map((email) => (
                        <div className="flex items-center">
                          <div className="w-8">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.5 18L14.8571 12M9.14286 12L2.50003 18M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                                stroke="#344054"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="inline-flex items-center space-x-2">
                            {/* <p className="font-semibold text-brand-700">
                              {email.value}
                            </p> */}
                            <EnrichedEmail
                              type={email?.type}
                              contactId={contact.id}
                              feedback={email?.feedback}
                              foundFrom={
                                email?.integration_config?.integration
                                  ?.display_name
                              }
                              value={email?.value}
                              badge={
                                email?.verification &&
                                email?.verification?.result
                              }
                              activeWorkspace={activeWorkspace}
                              onSuccess={() => {
                                handleGetContactDetails();
                              }}
                              autoVerify={
                                userData?.preferences
                                  ?.chrome_ext_auto_verify_email ?? false
                              }
                            />
                            {/* <Badge variant={"gray-blue"}>{email?.type}</Badge> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            )}
          {/* <div className="border border-border rounded-lg p-6 shadow-sm mt-5">
                <div className="flex space-x-3">
                  <div>
                    <div className="border border-border rounded-full h-10 w-10 inline-flex justify-center items-center bg-gray-100">
                      <Image
                        src={"/images/icons/untitledui/user.svg"}
                        width="24"
                      />
                    </div>
                  </div>
                  <div className="flex-1 space-y-1">
                    <Text size="md">{contact.full_name}</Text>
                    <Text size="p">{contact?.companies[0]?.name}</Text>
                    <Text size="p">{contact.linkedin_url}</Text>
                  </div>
                  <div className="">
                    <div className="inline-flex items-center space-x-2">
                      <div
                        className="loader_circle"
                        style={{
                          width: 24,
                          height: 24,
                          borderLeftColor: "var(--brand-600)",
                        }}
                      ></div>
                      <Text>Searching</Text>
                    </div>
                  </div>
                </div>
              </div> */}
          <div className="border border-border rounded-lg p-6 shadow-sm mt-5 relative overflow-hidden">
            <div className="absolute left-0 z-10 w-full top-0">
              {showStripeLoader()}
            </div>
            <Text size="xl" className="mb-6 flex items-center space-x-3">
              <span>Enrichment Timeline</span>
              {enrichProfileFn.isLoading && (
                <div
                  className="loader_circle inline-block"
                  style={{
                    width: 20,
                    height: 20,
                    borderLeftColor: "var(--brand-600)",
                  }}
                ></div>
              )}
            </Text>
            <div className="space-y-5">
              {contact &&
                contact.enrichment_timeline &&
                contact.enrichment_timeline.length > 0 &&
                contact.enrichment_timeline.map((timeline, index) => (
                  <div className="flex space-x-3 relative">
                    <div
                      className={
                        contact.enrichment_timeline.length !== index + 1
                          ? "add_bottom_border"
                          : ""
                      }
                    >
                      {logos[timeline?.integration_config?.integration?.key] ? (
                        <span
                          className="svg__32 inline-block rounded-[--radius] p-1 bg-white"
                          dangerouslySetInnerHTML={{
                            __html:
                              logos[
                                timeline?.integration_config?.integration?.key
                              ],
                          }}
                        />
                      ) : (
                        <div className="border border-border rounded-[--radius] h-8 w-8 inline-flex justify-center items-center bg-gray-100"></div>
                      )}
                    </div>
                    <div className="flex-1 space-y-1">
                      <p className="text-gray-700 font-semibold space-x-2">
                        <span> {timeline?.integration_config.name}</span>
                        <span className="text-xs font-normal">
                          {timeline?.created_at
                            ? dayjs(timeline?.created_at).fromNow()
                            : ""}
                        </span>
                      </p>
                      <p className="text-gray-600">{timeline?.status}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Tabs defaultValue="contact" className="w-full mt-6 hidden">
            <TabsList className="w-full">
              <TabsTrigger value="contact" className="flex-1">
                Contact
              </TabsTrigger>
              <TabsTrigger value="crm" className="flex-1">
                CRM
              </TabsTrigger>
            </TabsList>
            <TabsContent value="contact">
              <div className="border-b border-border py-6">
                <h6 className="text-sm text-gray-700 font-semibold flex items-center space-x-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.38028 8.85323C9.07627 10.3028 10.0251 11.6615 11.2266 12.8631C12.4282 14.0646 13.7869 15.0134 15.2365 15.7094C15.3612 15.7693 15.4235 15.7992 15.5024 15.8222C15.7828 15.904 16.127 15.8453 16.3644 15.6752C16.4313 15.6274 16.4884 15.5702 16.6027 15.4559C16.9523 15.1063 17.1271 14.9315 17.3029 14.8172C17.9658 14.3862 18.8204 14.3862 19.4833 14.8172C19.6591 14.9315 19.8339 15.1063 20.1835 15.4559L20.3783 15.6508C20.9098 16.1822 21.1755 16.448 21.3198 16.7333C21.6069 17.3009 21.6069 17.9712 21.3198 18.5387C21.1755 18.8241 20.9098 19.0898 20.3783 19.6213L20.2207 19.7789C19.6911 20.3085 19.4263 20.5733 19.0662 20.7756C18.6667 21 18.0462 21.1614 17.588 21.16C17.1751 21.1588 16.8928 21.0787 16.3284 20.9185C13.295 20.0575 10.4326 18.433 8.04466 16.045C5.65668 13.6571 4.03221 10.7947 3.17124 7.76131C3.01103 7.19687 2.93092 6.91464 2.9297 6.5017C2.92833 6.04347 3.08969 5.42298 3.31411 5.02348C3.51636 4.66345 3.78117 4.39863 4.3108 3.86901L4.46843 3.71138C4.99987 3.17993 5.2656 2.91421 5.55098 2.76987C6.11854 2.4828 6.7888 2.4828 7.35636 2.76987C7.64174 2.91421 7.90747 3.17993 8.43891 3.71138L8.63378 3.90625C8.98338 4.25585 9.15819 4.43065 9.27247 4.60643C9.70347 5.26932 9.70347 6.1239 9.27247 6.78679C9.15819 6.96257 8.98338 7.13738 8.63378 7.48698C8.51947 7.60129 8.46231 7.65845 8.41447 7.72526C8.24446 7.96269 8.18576 8.30695 8.26748 8.5873C8.29048 8.6662 8.32041 8.72854 8.38028 8.85323Z"
                      stroke="#344054"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>Phone numbers</span>
                </h6>
                <div className="mt-5 space-y-1">
                  <div className="flex items-center">
                    <div className="w-20">
                      <Badge variant={"blue"}>Work</Badge>
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_214_103451)">
                          <path
                            d="M13.8999 3.18783C14.0708 3.60128 14.399 3.92992 14.8122 4.1015L16.2611 4.70165C16.6746 4.87292 17.0031 5.20143 17.1743 5.61491C17.3456 6.0284 17.3456 6.49299 17.1743 6.90647L16.5746 8.3543C16.4033 8.76797 16.403 9.23303 16.5752 9.6465L17.1738 11.0939C17.2587 11.2987 17.3025 11.5182 17.3025 11.7399C17.3025 11.9616 17.2589 12.1811 17.174 12.386C17.0892 12.5908 16.9648 12.7769 16.808 12.9336C16.6513 13.0904 16.4651 13.2147 16.2603 13.2994L14.8124 13.8991C14.399 14.0701 14.0703 14.3983 13.8988 14.8114L13.2986 16.2603C13.1274 16.6738 12.7988 17.0023 12.3854 17.1736C11.9719 17.3449 11.5073 17.3449 11.0938 17.1736L9.64597 16.5739C9.23247 16.403 8.76805 16.4034 8.35481 16.5748L6.90594 17.1741C6.49268 17.345 6.0285 17.3449 5.61535 17.1737C5.2022 17.0026 4.87387 16.6745 4.70248 16.2614L4.10216 14.8121C3.93121 14.3987 3.60306 14.07 3.18986 13.8985L1.74099 13.2983C1.32769 13.1271 0.999271 12.7988 0.827937 12.3856C0.656603 11.9723 0.65637 11.5079 0.827291 11.0945L1.427 9.64669C1.59785 9.23319 1.5975 8.76877 1.42603 8.35553L0.827182 6.90562C0.742277 6.70083 0.698558 6.48131 0.698522 6.25961C0.698487 6.03792 0.742136 5.81839 0.826975 5.61357C0.911815 5.40874 1.03618 5.22265 1.19297 5.06591C1.34976 4.90917 1.53589 4.78486 1.74074 4.70009L3.18858 4.10038C3.60167 3.92957 3.93011 3.60184 4.10182 3.18912L4.70196 1.74024C4.87323 1.32676 5.20174 0.998247 5.61523 0.826976C6.02871 0.655705 6.4933 0.655705 6.90679 0.826976L8.35462 1.42669C8.76812 1.59754 9.23254 1.59719 9.64578 1.42572L11.0953 0.827905C11.5087 0.65673 11.9732 0.656765 12.3866 0.828003C12.8 0.99924 13.1285 1.32766 13.2998 1.74104L13.9001 3.19034L13.8999 3.18783Z"
                            fill="#1570EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.5249 6.64078C12.6452 6.45191 12.6855 6.22301 12.6369 6.00444C12.5884 5.78586 12.455 5.59552 12.2662 5.47528C12.0773 5.35503 11.8484 5.31474 11.6298 5.36327C11.4113 5.41179 11.2209 5.54516 11.1007 5.73403L7.79654 10.9259L6.28454 9.0359C6.14476 8.86106 5.94124 8.74891 5.71876 8.72412C5.49629 8.69933 5.27308 8.76393 5.09823 8.90371C4.92339 9.0435 4.81123 9.24702 4.78644 9.46949C4.76165 9.69197 4.82626 9.91518 4.96604 10.09L7.21604 12.9025C7.2999 13.0075 7.4076 13.0909 7.53018 13.1458C7.65277 13.2008 7.7867 13.2256 7.92084 13.2184C8.05498 13.2111 8.18544 13.1719 8.30138 13.1041C8.41732 13.0362 8.51539 12.9417 8.58742 12.8283L12.5249 6.64078Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_214_103451">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <Text>+9193920399293</Text>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-20">
                      <Badge variant={"blue"}>Personal</Badge>
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_214_103451)">
                          <path
                            d="M13.8999 3.18783C14.0708 3.60128 14.399 3.92992 14.8122 4.1015L16.2611 4.70165C16.6746 4.87292 17.0031 5.20143 17.1743 5.61491C17.3456 6.0284 17.3456 6.49299 17.1743 6.90647L16.5746 8.3543C16.4033 8.76797 16.403 9.23303 16.5752 9.6465L17.1738 11.0939C17.2587 11.2987 17.3025 11.5182 17.3025 11.7399C17.3025 11.9616 17.2589 12.1811 17.174 12.386C17.0892 12.5908 16.9648 12.7769 16.808 12.9336C16.6513 13.0904 16.4651 13.2147 16.2603 13.2994L14.8124 13.8991C14.399 14.0701 14.0703 14.3983 13.8988 14.8114L13.2986 16.2603C13.1274 16.6738 12.7988 17.0023 12.3854 17.1736C11.9719 17.3449 11.5073 17.3449 11.0938 17.1736L9.64597 16.5739C9.23247 16.403 8.76805 16.4034 8.35481 16.5748L6.90594 17.1741C6.49268 17.345 6.0285 17.3449 5.61535 17.1737C5.2022 17.0026 4.87387 16.6745 4.70248 16.2614L4.10216 14.8121C3.93121 14.3987 3.60306 14.07 3.18986 13.8985L1.74099 13.2983C1.32769 13.1271 0.999271 12.7988 0.827937 12.3856C0.656603 11.9723 0.65637 11.5079 0.827291 11.0945L1.427 9.64669C1.59785 9.23319 1.5975 8.76877 1.42603 8.35553L0.827182 6.90562C0.742277 6.70083 0.698558 6.48131 0.698522 6.25961C0.698487 6.03792 0.742136 5.81839 0.826975 5.61357C0.911815 5.40874 1.03618 5.22265 1.19297 5.06591C1.34976 4.90917 1.53589 4.78486 1.74074 4.70009L3.18858 4.10038C3.60167 3.92957 3.93011 3.60184 4.10182 3.18912L4.70196 1.74024C4.87323 1.32676 5.20174 0.998247 5.61523 0.826976C6.02871 0.655705 6.4933 0.655705 6.90679 0.826976L8.35462 1.42669C8.76812 1.59754 9.23254 1.59719 9.64578 1.42572L11.0953 0.827905C11.5087 0.65673 11.9732 0.656765 12.3866 0.828003C12.8 0.99924 13.1285 1.32766 13.2998 1.74104L13.9001 3.19034L13.8999 3.18783Z"
                            fill="#1570EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.5249 6.64078C12.6452 6.45191 12.6855 6.22301 12.6369 6.00444C12.5884 5.78586 12.455 5.59552 12.2662 5.47528C12.0773 5.35503 11.8484 5.31474 11.6298 5.36327C11.4113 5.41179 11.2209 5.54516 11.1007 5.73403L7.79654 10.9259L6.28454 9.0359C6.14476 8.86106 5.94124 8.74891 5.71876 8.72412C5.49629 8.69933 5.27308 8.76393 5.09823 8.90371C4.92339 9.0435 4.81123 9.24702 4.78644 9.46949C4.76165 9.69197 4.82626 9.91518 4.96604 10.09L7.21604 12.9025C7.2999 13.0075 7.4076 13.0909 7.53018 13.1458C7.65277 13.2008 7.7867 13.2256 7.92084 13.2184C8.05498 13.2111 8.18544 13.1719 8.30138 13.1041C8.41732 13.0362 8.51539 12.9417 8.58742 12.8283L12.5249 6.64078Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_214_103451">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <Text>+9193920399293</Text>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-20">
                      <Badge variant={"blue"}>Mobile</Badge>
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_214_103451)">
                          <path
                            d="M13.8999 3.18783C14.0708 3.60128 14.399 3.92992 14.8122 4.1015L16.2611 4.70165C16.6746 4.87292 17.0031 5.20143 17.1743 5.61491C17.3456 6.0284 17.3456 6.49299 17.1743 6.90647L16.5746 8.3543C16.4033 8.76797 16.403 9.23303 16.5752 9.6465L17.1738 11.0939C17.2587 11.2987 17.3025 11.5182 17.3025 11.7399C17.3025 11.9616 17.2589 12.1811 17.174 12.386C17.0892 12.5908 16.9648 12.7769 16.808 12.9336C16.6513 13.0904 16.4651 13.2147 16.2603 13.2994L14.8124 13.8991C14.399 14.0701 14.0703 14.3983 13.8988 14.8114L13.2986 16.2603C13.1274 16.6738 12.7988 17.0023 12.3854 17.1736C11.9719 17.3449 11.5073 17.3449 11.0938 17.1736L9.64597 16.5739C9.23247 16.403 8.76805 16.4034 8.35481 16.5748L6.90594 17.1741C6.49268 17.345 6.0285 17.3449 5.61535 17.1737C5.2022 17.0026 4.87387 16.6745 4.70248 16.2614L4.10216 14.8121C3.93121 14.3987 3.60306 14.07 3.18986 13.8985L1.74099 13.2983C1.32769 13.1271 0.999271 12.7988 0.827937 12.3856C0.656603 11.9723 0.65637 11.5079 0.827291 11.0945L1.427 9.64669C1.59785 9.23319 1.5975 8.76877 1.42603 8.35553L0.827182 6.90562C0.742277 6.70083 0.698558 6.48131 0.698522 6.25961C0.698487 6.03792 0.742136 5.81839 0.826975 5.61357C0.911815 5.40874 1.03618 5.22265 1.19297 5.06591C1.34976 4.90917 1.53589 4.78486 1.74074 4.70009L3.18858 4.10038C3.60167 3.92957 3.93011 3.60184 4.10182 3.18912L4.70196 1.74024C4.87323 1.32676 5.20174 0.998247 5.61523 0.826976C6.02871 0.655705 6.4933 0.655705 6.90679 0.826976L8.35462 1.42669C8.76812 1.59754 9.23254 1.59719 9.64578 1.42572L11.0953 0.827905C11.5087 0.65673 11.9732 0.656765 12.3866 0.828003C12.8 0.99924 13.1285 1.32766 13.2998 1.74104L13.9001 3.19034L13.8999 3.18783Z"
                            fill="#1570EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.5249 6.64078C12.6452 6.45191 12.6855 6.22301 12.6369 6.00444C12.5884 5.78586 12.455 5.59552 12.2662 5.47528C12.0773 5.35503 11.8484 5.31474 11.6298 5.36327C11.4113 5.41179 11.2209 5.54516 11.1007 5.73403L7.79654 10.9259L6.28454 9.0359C6.14476 8.86106 5.94124 8.74891 5.71876 8.72412C5.49629 8.69933 5.27308 8.76393 5.09823 8.90371C4.92339 9.0435 4.81123 9.24702 4.78644 9.46949C4.76165 9.69197 4.82626 9.91518 4.96604 10.09L7.21604 12.9025C7.2999 13.0075 7.4076 13.0909 7.53018 13.1458C7.65277 13.2008 7.7867 13.2256 7.92084 13.2184C8.05498 13.2111 8.18544 13.1719 8.30138 13.1041C8.41732 13.0362 8.51539 12.9417 8.58742 12.8283L12.5249 6.64078Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_214_103451">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <Text>+9193920399293</Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b border-border py-6">
                <h6 className="text-sm text-gray-700 font-semibold flex items-center space-x-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 18L14.8571 12M9.14286 12L2.50003 18M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                      stroke="#344054"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span>Email addresses</span>
                </h6>
                <div className="mt-5 space-y-1">
                  <div className="flex items-center">
                    <div className="w-20">
                      <Badge variant={"blue"}>Work</Badge>
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_214_103451)">
                          <path
                            d="M13.8999 3.18783C14.0708 3.60128 14.399 3.92992 14.8122 4.1015L16.2611 4.70165C16.6746 4.87292 17.0031 5.20143 17.1743 5.61491C17.3456 6.0284 17.3456 6.49299 17.1743 6.90647L16.5746 8.3543C16.4033 8.76797 16.403 9.23303 16.5752 9.6465L17.1738 11.0939C17.2587 11.2987 17.3025 11.5182 17.3025 11.7399C17.3025 11.9616 17.2589 12.1811 17.174 12.386C17.0892 12.5908 16.9648 12.7769 16.808 12.9336C16.6513 13.0904 16.4651 13.2147 16.2603 13.2994L14.8124 13.8991C14.399 14.0701 14.0703 14.3983 13.8988 14.8114L13.2986 16.2603C13.1274 16.6738 12.7988 17.0023 12.3854 17.1736C11.9719 17.3449 11.5073 17.3449 11.0938 17.1736L9.64597 16.5739C9.23247 16.403 8.76805 16.4034 8.35481 16.5748L6.90594 17.1741C6.49268 17.345 6.0285 17.3449 5.61535 17.1737C5.2022 17.0026 4.87387 16.6745 4.70248 16.2614L4.10216 14.8121C3.93121 14.3987 3.60306 14.07 3.18986 13.8985L1.74099 13.2983C1.32769 13.1271 0.999271 12.7988 0.827937 12.3856C0.656603 11.9723 0.65637 11.5079 0.827291 11.0945L1.427 9.64669C1.59785 9.23319 1.5975 8.76877 1.42603 8.35553L0.827182 6.90562C0.742277 6.70083 0.698558 6.48131 0.698522 6.25961C0.698487 6.03792 0.742136 5.81839 0.826975 5.61357C0.911815 5.40874 1.03618 5.22265 1.19297 5.06591C1.34976 4.90917 1.53589 4.78486 1.74074 4.70009L3.18858 4.10038C3.60167 3.92957 3.93011 3.60184 4.10182 3.18912L4.70196 1.74024C4.87323 1.32676 5.20174 0.998247 5.61523 0.826976C6.02871 0.655705 6.4933 0.655705 6.90679 0.826976L8.35462 1.42669C8.76812 1.59754 9.23254 1.59719 9.64578 1.42572L11.0953 0.827905C11.5087 0.65673 11.9732 0.656765 12.3866 0.828003C12.8 0.99924 13.1285 1.32766 13.2998 1.74104L13.9001 3.19034L13.8999 3.18783Z"
                            fill="#1570EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.5249 6.64078C12.6452 6.45191 12.6855 6.22301 12.6369 6.00444C12.5884 5.78586 12.455 5.59552 12.2662 5.47528C12.0773 5.35503 11.8484 5.31474 11.6298 5.36327C11.4113 5.41179 11.2209 5.54516 11.1007 5.73403L7.79654 10.9259L6.28454 9.0359C6.14476 8.86106 5.94124 8.74891 5.71876 8.72412C5.49629 8.69933 5.27308 8.76393 5.09823 8.90371C4.92339 9.0435 4.81123 9.24702 4.78644 9.46949C4.76165 9.69197 4.82626 9.91518 4.96604 10.09L7.21604 12.9025C7.2999 13.0075 7.4076 13.0909 7.53018 13.1458C7.65277 13.2008 7.7867 13.2256 7.92084 13.2184C8.05498 13.2111 8.18544 13.1719 8.30138 13.1041C8.41732 13.0362 8.51539 12.9417 8.58742 12.8283L12.5249 6.64078Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_214_103451">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <Text>jamesw[...]mwddawd@apple.com</Text>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-20">
                      <Badge variant={"blue"}>Personal</Badge>
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_214_103451)">
                          <path
                            d="M13.8999 3.18783C14.0708 3.60128 14.399 3.92992 14.8122 4.1015L16.2611 4.70165C16.6746 4.87292 17.0031 5.20143 17.1743 5.61491C17.3456 6.0284 17.3456 6.49299 17.1743 6.90647L16.5746 8.3543C16.4033 8.76797 16.403 9.23303 16.5752 9.6465L17.1738 11.0939C17.2587 11.2987 17.3025 11.5182 17.3025 11.7399C17.3025 11.9616 17.2589 12.1811 17.174 12.386C17.0892 12.5908 16.9648 12.7769 16.808 12.9336C16.6513 13.0904 16.4651 13.2147 16.2603 13.2994L14.8124 13.8991C14.399 14.0701 14.0703 14.3983 13.8988 14.8114L13.2986 16.2603C13.1274 16.6738 12.7988 17.0023 12.3854 17.1736C11.9719 17.3449 11.5073 17.3449 11.0938 17.1736L9.64597 16.5739C9.23247 16.403 8.76805 16.4034 8.35481 16.5748L6.90594 17.1741C6.49268 17.345 6.0285 17.3449 5.61535 17.1737C5.2022 17.0026 4.87387 16.6745 4.70248 16.2614L4.10216 14.8121C3.93121 14.3987 3.60306 14.07 3.18986 13.8985L1.74099 13.2983C1.32769 13.1271 0.999271 12.7988 0.827937 12.3856C0.656603 11.9723 0.65637 11.5079 0.827291 11.0945L1.427 9.64669C1.59785 9.23319 1.5975 8.76877 1.42603 8.35553L0.827182 6.90562C0.742277 6.70083 0.698558 6.48131 0.698522 6.25961C0.698487 6.03792 0.742136 5.81839 0.826975 5.61357C0.911815 5.40874 1.03618 5.22265 1.19297 5.06591C1.34976 4.90917 1.53589 4.78486 1.74074 4.70009L3.18858 4.10038C3.60167 3.92957 3.93011 3.60184 4.10182 3.18912L4.70196 1.74024C4.87323 1.32676 5.20174 0.998247 5.61523 0.826976C6.02871 0.655705 6.4933 0.655705 6.90679 0.826976L8.35462 1.42669C8.76812 1.59754 9.23254 1.59719 9.64578 1.42572L11.0953 0.827905C11.5087 0.65673 11.9732 0.656765 12.3866 0.828003C12.8 0.99924 13.1285 1.32766 13.2998 1.74104L13.9001 3.19034L13.8999 3.18783Z"
                            fill="#1570EF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.5249 6.64078C12.6452 6.45191 12.6855 6.22301 12.6369 6.00444C12.5884 5.78586 12.455 5.59552 12.2662 5.47528C12.0773 5.35503 11.8484 5.31474 11.6298 5.36327C11.4113 5.41179 11.2209 5.54516 11.1007 5.73403L7.79654 10.9259L6.28454 9.0359C6.14476 8.86106 5.94124 8.74891 5.71876 8.72412C5.49629 8.69933 5.27308 8.76393 5.09823 8.90371C4.92339 9.0435 4.81123 9.24702 4.78644 9.46949C4.76165 9.69197 4.82626 9.91518 4.96604 10.09L7.21604 12.9025C7.2999 13.0075 7.4076 13.0909 7.53018 13.1458C7.65277 13.2008 7.7867 13.2256 7.92084 13.2184C8.05498 13.2111 8.18544 13.1719 8.30138 13.1041C8.41732 13.0362 8.51539 12.9417 8.58742 12.8283L12.5249 6.64078Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_214_103451">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <Text>jameawdawd@apple.com</Text>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="crm">
              <div className="border-b border-border py-6">
                <div className="mt-3 space-y-3">
                  <div className="flex items-center">
                    <div className="w-5/12 text-sm text-gray-700 font-semibold">
                      Full Name
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <Text>S. S. Rahman</Text>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-5/12 text-sm text-gray-700 font-semibold">
                      Owner
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <Text>Misbah Farooqi</Text>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-5/12 text-sm text-gray-700 font-semibold">
                      Type
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <Text>Deal</Text>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-5/12 text-sm text-gray-700 font-semibold">
                      CRM
                    </div>
                    <div className="inline-flex items-center space-x-2">
                      <Text className="flex items-center">
                        <svg
                          width="55"
                          height="20"
                          viewBox="0 0 55 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_214_104249)">
                            <path
                              d="M1.00426 15V4.81818H2.84872V13.4538H7.3331V15H1.00426ZM8.8544 15V7.36364H10.6541V15H8.8544ZM9.75923 6.27983C9.4742 6.27983 9.22893 6.18537 9.02344 5.99645C8.81795 5.80421 8.7152 5.57386 8.7152 5.3054C8.7152 5.03362 8.81795 4.80327 9.02344 4.61435C9.22893 4.42211 9.4742 4.32599 9.75923 4.32599C10.0476 4.32599 10.2929 4.42211 10.495 4.61435C10.7005 4.80327 10.8033 5.03362 10.8033 5.3054C10.8033 5.57386 10.7005 5.80421 10.495 5.99645C10.2929 6.18537 10.0476 6.27983 9.75923 6.27983ZM14.3045 10.5256V15H12.5048V7.36364H14.225V8.66122H14.3145C14.4901 8.23366 14.7702 7.89394 15.1547 7.64205C15.5424 7.39015 16.0214 7.2642 16.5914 7.2642C17.1184 7.2642 17.5775 7.37689 17.9686 7.60227C18.363 7.82765 18.6679 8.15412 18.8833 8.58168C19.1021 9.00923 19.2098 9.52794 19.2065 10.1378V15H17.4068V10.4162C17.4068 9.90578 17.2742 9.50639 17.0091 9.21804C16.7472 8.92969 16.3843 8.78551 15.9203 8.78551C15.6054 8.78551 15.3253 8.85511 15.0801 8.99432C14.8381 9.13021 14.6475 9.32741 14.5083 9.58594C14.3725 9.84446 14.3045 10.1577 14.3045 10.5256ZM22.6767 12.6037L22.6717 10.4311H22.96L25.7044 7.36364H27.8074L24.4316 11.1222H24.0588L22.6767 12.6037ZM21.036 15V4.81818H22.8358V15H21.036ZM25.8287 15L23.3429 11.5249L24.5559 10.2571L27.9814 15H25.8287Z"
                              fill="#6941C6"
                            />
                            <path
                              d="M52.5 7.5L52.5 2.5M52.5 2.5H47.5M52.5 2.5L45.8333 9.16667M43.3333 4.16667H41.5C40.0999 4.16667 39.3998 4.16667 38.865 4.43915C38.3946 4.67883 38.0122 5.06129 37.7725 5.53169C37.5 6.06647 37.5 6.76654 37.5 8.16667V13.5C37.5 14.9001 37.5 15.6002 37.7725 16.135C38.0122 16.6054 38.3946 16.9878 38.865 17.2275C39.3998 17.5 40.0999 17.5 41.5 17.5H46.8333C48.2335 17.5 48.9335 17.5 49.4683 17.2275C49.9387 16.9878 50.3212 16.6054 50.5608 16.135C50.8333 15.6002 50.8333 14.9001 50.8333 13.5V11.6667"
                              stroke="#6941C6"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_214_104249">
                              <rect width="55" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
}
