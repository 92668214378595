import { FormikProvider, useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import Input from "../../Tags/Input";
import FormError from "../../Tags/FormError";
import { Button } from "../../ui/button";
import SharedDataContext from "../../../SharedDataContext";
import { createContact } from "../../../apis/enrich";
import { useMutation } from "react-query";
import { generateRandomString } from "../../../utils/GenericFn";
import * as yup from "yup";
import toast from "react-hot-toast";
import Image from "../../Tags/Image";

export default function EnrichPeopleForm({ disabledSubmit = false, onSuccess, shouldResetForm }) {
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const linkedinUrlRegex = /^https:\/\/www\.linkedin\.com\//;
  const validationSchema = yup.object().shape({
    full_name: yup.string().test("full_name-or-linkedin_url", "Full name is required", function (value) {
      const { company_name, linkedin_url } = this.parent;
      return !!value || !!linkedin_url || (!!value && !!company_name);
    }),
    company_name: yup
      .string()
      .test("company_name-or-linkedin_url", "Company name is required", function (value) {
        const { full_name, linkedin_url } = this.parent;
        return !!value || !!linkedin_url || (!!value && !!full_name);
      }),
    linkedin_url: yup
      .string()
      .matches(linkedinUrlRegex, 'LinkedIn URL must be in the format "https://www.linkedin.com/..."')
      .test("linkedin_url-or-full_name-company_name", "LinkedIn URL is required", function (value) {
        const { full_name, company_name } = this.parent;
        return !!value || (!!full_name && !!company_name);
      }),
  });
  const createContactFn = useMutation("create_contact", createContact, {
    onSuccess(data, variables, context) {
      console.log(data);
      if (data && data.records && data.records.length > 0 && data.records[0].contact) {
        console.log(data);

        onSuccess({
          profile_url: formik.values.linkedin_url,
          id: data.records[0].contact.id,
          company_url:
            data.records[0]?.contact.companies &&
            data.records[0]?.contact.companies.length > 0 &&
            data.records[0]?.contact.companies[0]?.linkedin_url
              ? data.records[0]?.contact.companies[0]?.linkedin_url
              : "",
        });
        // setContact(data.records[0].contact);
        // navigate(data.records[0].contact.id);
      }
      formik.setSubmitting(false);
      // formik.resetForm();
    },
    onError(error) {
      if (error && error.data && error.status === 422) {
        if (error.data.records && error.data.records.length > 0) {
          toast.error(error.data.records[0]?.message);
        } else if (
          error.data.errors &&
          error.data.errors["records.0.linkedin_url"] &&
          error.data.errors["records.0.linkedin_url"].length > 0
        ) {
          toast.error(error.data.errors["records.0.linkedin_url"][0]);
        } else {
          toast.error("Something went wrong!");
        }
      }
      formik.setSubmitting(false);
    },
  });

  const removeEmptyKeys = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== ""));
  };

  const formik = useFormik({
    initialValues: {
      full_name: "",
      company_name: "",
      linkedin_url: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Submitted values:", values);

      let payload = removeEmptyKeys(values);
      console.log(payload);

      createContactFn.mutate({
        data: {
          records: [
            {
              contact_key: generateRandomString(),
              referral: "app",
              ...payload,
            },
          ],
        },
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    },
  });
  useEffect(() => {
    if (shouldResetForm) {
      formik.resetForm();
    }
  }, [shouldResetForm]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {/* <div className="space-y-3">
          <div>
            <label htmlFor="full_name" className="block text-sm font-medium leading-6 mb-1">
              Full Name
            </label>
            <Input
              id="full_name"
              type="text"
              name="full_name"
              placeholder="Enter person’s full name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.full_name}
            />
            <FormError field="full_name" />
          </div>
          <div>
            <label htmlFor="company_name" className="block text-sm font-medium leading-6 mb-1">
              Company Name
            </label>
            <Input
              id="company_name"
              type="text"
              name="company_name"
              placeholder="Enter company name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company_name}
            />
            <FormError field="company_name" />
          </div>
        </div>
        <p className="text-gray-600 mt-6 text-center">OR</p> */}
        <div>
          <label htmlFor="linkedin_url" className="block text-sm font-medium leading-6 mb-1">
            LinkedIn URL
          </label>
          <Input
            icon={<Image src="/images/icons/linkedin-icon.svg" className="no-invert" />}
            id="linkedin_url"
            type="text"
            name="linkedin_url"
            placeholder="e.g. https://www.linkedin.com/in/williamhgates/"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.linkedin_url}
          />
          <FormError field="linkedin_url" />
        </div>
        <div className="mt-8">
          <Button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || disabledSubmit}
            className="w-full"
          >
            Find Person
            {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
