import { FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import FormError from "../../Tags/FormError";
import useDebouncedInput from "../../../hooks/useDebouncedInput";
import { checkSlugAvailability, createWorkspace, patchWorkspace, updateWorkspace } from "../../../apis/user";
import { useMutation, useQueryClient } from "react-query";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ImageUploader from "../../ImageUploader";
import Image from "../../Tags/Image";
import { useState } from "react";
import { Button } from "../../ui/button";
import Input from "../../Tags/Input";
import Text from "../../Tags/Text";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import { AlertTriangle, ArrowRight } from "lucide-react";
import { Badge } from "../../ui/badge";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function WorkspaceForm({
  edit = false,
  formData = {},
  closeModal,
  onboarding = false,
  onboardingStatus,
  newWorkspacePage = false,
}) {
  const [changeType, setChangeType] = useState("name");
  const [slugIsAvailable, setSlugIsAvailable] = useState(null);
  const checkSlugAvailabilityFn = useMutation("check_slug_availability", checkSlugAvailability, {
    onSuccess(data, variables, context) {
      setSlugIsAvailable(true);
    },
    onError(err) {
      setSlugIsAvailable(false);
    },
  });

  const createWorkspaceFn = useMutation("create_workspace", createWorkspace, {
    onSuccess(data, variables, context) {
      formik.resetForm();
      if (!onboarding) {
        toast.success(data?.message);
        window.location.href = `/${process.env.REACT_APP_APP_HOME}/${data?.workspace?.slug}/`;
      }
      if (onboardingStatus && onboarding) {
        onboardingStatus(data);
      }
    },
    onError(err) {
      formik.setSubmitting(false);
    },
  });

  const updateWorkspaceFn = useMutation("update_workspace", patchWorkspace, {
    onSuccess(data, variables, context) {
      formik.resetForm();
      toast.success(data?.message);
      formik.setFieldValue("name", data?.workspace.name);
      formik.setFieldValue("slug", data?.workspace.slug);
      window.location.href = `/${process.env.REACT_APP_APP_HOME}/${data?.workspace?.slug}/`;
    },
    onError(err) {
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      slug: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter Workspace name")
        .max(40, "Workspace cannot be more than 40 characters")
        .matches(/^[a-zA-Z0-9]+(?:[ -][a-zA-Z0-9]+)*$/, {
          message:
            "Workspace name can only contain letters, numbers, spaces, and hyphens. No leading or trailing hyphens.",
        }),
      slug: Yup.string()
        .required("Please enter Slug name")
        .matches(/^[a-z0-9-]+$/, "Slug can only contain lowercase alphanumeric characters and hyphens")
        .min(3, "Slug must contain atleast 3 characters")
        .max(100, "Slug cannot be more than 100 characters"),
    }),
    onSubmit: (values) => {
      if (edit) {
        if (!slugIsAvailable && formData && !formData.slug) {
          toast.error("Slug is not available");
          formik.setSubmitting(false);
          return;
        }
        updateWorkspaceFn.mutate({ ...values, id: formData.id });
      } else {
        createWorkspaceFn.mutate(values);
      }
    },
  });

  const makeSlugFromWorkspaceName = (value) => {
    let slugValue = value.toLowerCase().replace(/\s+/g, "-");
    slugValue = slugValue.replace(/^-+|-+$/g, "");
    formik.setFieldValue("slug", slugValue);
    console.log(formData);
    if (slugValue && slugValue.length >= 3 && slugValue !== formData?.slug) {
      checkSlugAvailabilityFn.mutate({ slug: slugValue });
    }
  };

  const [workspaceName, setWorkspaceName] = useDebouncedInput({
    debounceTime: 200,
  });

  const [slugName, setSlugName] = useDebouncedInput({
    debounceTime: 500,
  });

  useEffect(() => {
    if (!edit) {
      makeSlugFromWorkspaceName(workspaceName);
    }
  }, [workspaceName]);

  const { isOpen, openModal: openModalSlug, closeModal: closeModalSlug } = useModal(false);

  useEffect(() => {
    makeSlugFromWorkspaceName(slugName);
  }, [slugName]);

  // handle edit case
  const prefillWorkspaceForm = () => {
    formik.setFieldValue("name", formData.name);
    formik.setFieldValue("slug", formData.slug);
  };
  useEffect(() => {
    if (formData.id) {
      prefillWorkspaceForm();
    }
  }, [formData.slug]);

  const [understand, setUnderstand] = useState(false);
  const captureEvent = useCaptureEvent();
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="mt-6">
        <div className="flex gap-x-8 mobile:block mobile:grid-x-0">
          <div className="w-3/12 mobile:w-full">
            <Text size="sm" className="text-gray-700">
              Workspace name
            </Text>
            <Text size="p">A short name to describe the workspace and a unique slug</Text>
          </div>
          <div className={` ${newWorkspacePage ? "w-8/12" : "w-5/12"} mobile:w-full mobile:mt-5`}>
            <div className="mt-2">
              <Input
                name="name"
                id="name"
                autoComplete="name"
                placeholder="Acme Inc"
                onChange={(e) => {
                  formik.handleChange(e);
                  setWorkspaceName(e.target.value);
                  formik.setFieldTouched("slug", true);
                  formik.setFieldTouched("name", true);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <FormError field="name" />
            </div>
            <div className="mt-2 relative">
              <Input
                pretext={`${process.env.REACT_APP_BASE_URL}/app/`}
                type="text"
                name="slug"
                id="slug"
                autoComplete="slug"
                placeholder="Slug"
                onChange={(e) => {
                  formik.handleChange(e);
                  setSlugName(e.target.value);
                  setChangeType("");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.slug}
              />
              {formik.values.name && !formik.errors.slug && (
                <>
                  {checkSlugAvailabilityFn.isLoading && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <div className="loader_circle"></div>
                    </div>
                  )}
                  {checkSlugAvailabilityFn.isError && formData.slug !== formik.values.slug && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                  )}
                  {checkSlugAvailabilityFn.isSuccess && formData.slug !== formik.values.slug && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <Image src="/images/icons/green-check-filled.svg" width={20} className="no-invert" />
                    </div>
                  )}
                </>
              )}
              <FormError field="slug" />
            </div>
          </div>
        </div>
        {edit && (
          <>
            <div className="border-b border-border my-5"></div>
            <div className="flex gap-x-8 mobile:block mobile:grid-x-0">
              <div className="w-3/12 mobile:w-full">
                <Text size="sm" className="text-gray-700">
                  Workspace logo
                </Text>
                <Text size="p">Add or update your workspace logo</Text>
              </div>
              <div className="w-5/12 mobile:w-full mobile:mt-5">
                <div
                  className="mt-2"
                  onClick={() => {
                    captureEvent("SET_Workspaces_UploadLogoBtn");
                  }}
                >
                  {formData && formData.id && edit && (
                    <ImageUploader workspaceId={formData.id} icon={formData.icon} />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {!onboarding && <div className="border-b border-border my-5"></div>}
        <div className="flex gap-x-8">
          <div className="w-3/12 mobile:hidden"></div>
          <div className="w-5/12">
            <div
              className={`flex items-center gap-x-2 workspace-form-controls ${
                onboarding ? "justify-center" : ""
              }`}
            >
              {edit && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    prefillWorkspaceForm();
                    closeModal && closeModal();
                    captureEvent("SET_Workspaces_CancelBtn");
                  }}
                  variant="outline"
                >
                  Cancel
                </Button>
              )}
              {!onboarding ? (
                <Button
                  // type="submit"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    (formData.name === formik.values.name && formData.slug === formik.values.slug)
                  }
                  onClick={(e) => {
                    {
                      e.preventDefault();
                      if (edit && slugIsAvailable) {
                        openModalSlug();
                      } else {
                        formik.submitForm();
                      }
                    }
                    captureEvent("TOP_CreateWorkspace_CreateBtn");
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-1.5"
                  >
                    <g id="save-01">
                      <path
                        id="Icon"
                        d="M5.83333 2.5V5.33333C5.83333 5.80004 5.83333 6.0334 5.92416 6.21166C6.00406 6.36846 6.13154 6.49594 6.28834 6.57584C6.4666 6.66667 6.69996 6.66667 7.16667 6.66667H12.8333C13.3 6.66667 13.5334 6.66667 13.7117 6.57584C13.8685 6.49594 13.9959 6.36846 14.0758 6.21166C14.1667 6.0334 14.1667 5.80004 14.1667 5.33333V3.33333M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z"
                        stroke="white"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                  {edit ? "Save" : "Create"}{" "}
                  {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
                </Button>
              ) : (
                <Button
                  className="mt-4"
                  variant="outline"
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    (formData.name === formik.values.name && formData.slug === formik.values.slug)
                  }
                >
                  Continue
                  {formik.isSubmitting ? (
                    <div className="loader_circle ml-2"></div>
                  ) : (
                    <ArrowRight width={16} height={16} className="ml-2" />
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
      <GenericModal isOpen={isOpen} closeModal={closeModalSlug}>
        <div className="mb-1">
          <Badge
            variant="warning"
            className={
              "h-12 w-12 inline-flex items-center justify-center border-none bg-warning-100 text-error-600"
            }
          >
            <AlertTriangle />
          </Badge>
          <div className="mt-4">
            <h2 className="text-base font-semibold leading-7 ">Confirm slug change</h2>
            <div className="mt-2 space-y-3">
              <p className="text-gray-600">
                Changing the workspace slug may affect links and references associated with this workspace.
              </p>
              <p className="text-gray-600">
                {" "}
                If you wish to maintain stability in your links and references, consider updating only the
                workspace name.
              </p>
              <p className="text-gray-600">
                {" "}
                However, if you understand the implications and choose to change the slug, proceed with
                caution.
              </p>
            </div>
            <div className="flex items-center space-x-2 mt-5">
              <input
                type="checkbox"
                className="form-checkbox text-brand-700 rounded dark:text-brand-200"
                onChange={(e) => {
                  setUnderstand(e.target.checked);
                }}
                id="understand_check"
              />{" "}
              <label for="understand_check" className="text-warning-600 font-bold">
                I understand and want to change the slug.
              </label>
            </div>
            <div className="mt-8 flex space-x-2">
              <Button
                variant="outline"
                onClick={(e) => {
                  e.preventDefault();
                  formik.setFieldValue("slug", formData.slug);
                  setChangeType("name");
                  closeModalSlug();
                }}
                className="flex-1"
              >
                Cancel
              </Button>
              <Button
                disabled={!understand}
                onClick={(e) => {
                  e.preventDefault();
                  setChangeType("both");
                  closeModalSlug();
                  formik.submitForm();
                }}
                className="flex-1"
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </GenericModal>
    </FormikProvider>
  );
}
