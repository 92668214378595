import { useFormikContext } from "formik";

const FormError = ({ field, className = "" }) => {
  const { errors, touched } = useFormikContext();

  return (
    <>
      {touched && touched[field] && errors && errors[field] ? (
        <p className={`text-red-700 dark:text-red-400 text-size-10 mt-2 ${className}`}>{errors[field]}</p>
      ) : null}
    </>
  );
};

export default FormError;
