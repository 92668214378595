import React, { useContext, useEffect, useState } from "react";
import Text from "../../Tags/Text";
import { Button } from "../../ui/button";
import SharedDataContext from "../../../SharedDataContext";

import { useQuery, useQueryClient } from "react-query";
import AlertTag from "../../Alerts/Alert";
import { getIntegrations } from "../../../apis/workspace/integrations";
import { useNavigate } from "react-router-dom";
import EnrichPeopleForm from "./EnrichPeopleForm";
import NavAnchor from "../../Tags/NavAnchor";
import { Cross1Icon } from "@radix-ui/react-icons";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import Image from "../../Tags/Image";

export default function EnrichPeople() {
  const { isOpen, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};

  const queryClient = useQueryClient();
  const [noIntegration, setNoIntegration] = useState(false);
  const [integrations, setIntegrations] = useState({});
  const getIntegrationsFn = useQuery(
    "get_integrations",
    () => {
      return getIntegrations({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        setIntegrations(data);
        if (data && data.installed && data.installed.length === 0) {
          setNoIntegration(true);
        } else {
          setNoIntegration(false);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetIntegrations = () => {
    getIntegrationsFn.refetch();
  };

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetIntegrations();
    }
  }, [activeWorkspace]);

  const [iframePayload, setIframePayload] = useState("");

  const handleSetIframePayload = (data) => {
    const payload = btoa(
      JSON.stringify({
        hasCompany: true,
        url: data?.profile_url,
        contact_id: data.id,
        company_url: data?.company_url,
      })
    );
    setIframePayload(payload);
  };

  const generateRandomString = () => {
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var length = 40;
    var randomString = "";

    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  };

  useEffect(() => {
    if (window && typeof window !== "undefined") {
      window.addEventListener("message", function (event) {
        if (event.data === "COMPANY_TAB_ACTIVE") {
          const wrapperDiv = document.querySelector("#pros__iframe__wrapper");
          if (wrapperDiv) {
            wrapperDiv.classList.add("pros__iframe__wrapper__company");
          }
        }
        if (event.data === "COMPANY_TAB_INACTIVE") {
          const wrapperDiv = document.querySelector("#pros__iframe__wrapper");
          if (wrapperDiv) {
            wrapperDiv.classList.remove("pros__iframe__wrapper__company");
          }
        }
      });
    }
  }, []);

  return (
    <>
      <div className="">
        {noIntegration && (
          <AlertTag
            heading={"Data providers not connected"}
            description={`You do not have any data providers connected to perform people enrichment. Connect at least one data provider to perform enrichment`}
            dismiss={false}
            cta={
              <NavAnchor to={"/settings/integrations"}>
                <Button className="h-9">Connect a Provider</Button>
              </NavAnchor>
            }
          />
        )}
        <div className="">
          <div className="">
            <div className="w-[500px] mt-10  mx-auto">
              <div className="text-center mb-6">
                <Text size="lg" className="flex items-center space-x-2 justify-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z"
                      stroke="#667085"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>People Enrichment</span>
                </Text>
                <Text size="p" className="mt-1">
                  Find contact information for professionals using their LinkedIn URL
                </Text>
                <div className="flex justify-center mt-6">
                  <Image src="/images/icons/untitledui/people-enrich-placeholder.svg" />
                </div>
              </div>
              <EnrichPeopleForm
                disabledSubmit={noIntegration}
                onSuccess={(data) => {
                  handleSetIframePayload(data);
                  openModal();
                }}
                shouldResetForm={!isOpen}
              />
            </div>
          </div>
        </div>
      </div>
      <GenericModal
        isOpen={isOpen}
        closeModal={closeModal}
        drawer={true}
        drawerWidth=" pros__iframe__wrapper__drawer"
      >
        <div id="pros__iframe__wrapper">
          <span
            id="pros__iframe__wrapper__hide"
            onClick={() => {
              setIframePayload("");
              closeModal();
            }}
            className="dark:text-gray-200"
          >
            <Cross1Icon width={12} height={12} />
          </span>
          <iframe
            src={`${process.env.REACT_APP_BASE_URL}/contact-viewer/?ref=${iframePayload}&source=app&refresh=${generateRandomString()}`}
          />
        </div>
      </GenericModal>
    </>
  );
}
