import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePageAttributes from "../../../hooks/usePageAttributes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { acceptInvite, declineInvite, getInviteData } from "../../../apis/workspace/members";
import Image from "../../Tags/Image";
import Text from "../../Tags/Text";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Input from "../../Tags/Input";
import FormError from "../../Tags/FormError";
import NavAnchor from "../../Tags/NavAnchor";
import { Button } from "../../ui/button";
import { Skeleton } from "../../ui/skeleton";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import Login from "../Login";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
import { useTheme } from "../../ThemeProvider";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { getUser, login } from "../../../apis/user";
import { fetchCsrfCookie } from "../../../apis/csrf";
const cookieName = process.env.REACT_APP_PR_ACCESS_TOKEN
  ? process.env.REACT_APP_PR_ACCESS_TOKEN
  : "accessToken";

const accessToken = Cookies.get(cookieName);

export default function AcceptInvite() {
  const captureEvent = useCaptureEvent();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [userData,setUserData] = useState({})
  const { inviteId, inviteToken } = useParams();
  const { logo, appname, setTitle } = usePageAttributes();
  const { theme, setTheme } = useTheme();
  const [inviteData, setInviteData] = useState({});
  const [inviteError, setInviteError] = useState("");
  const getInviteDataFn = useQuery(
    "get_invite",
    () => {
      return getInviteData({
        inviteId: inviteId,
        inviteToken: inviteToken,
      });
    },
    {
      enabled: !!inviteId && !!inviteToken,
      refetchOnWindowFocus: false,
      manual: true,
      retry: false,
      onSuccess: (data) => {
        setInviteData(data?.invitation);
        setInviteError("");
      },
      onError: (error) => {
        setInviteError(error?.data?.message);
        setInviteData({});
        console.error(error);
      },
    }
  );

  const getUserData = useQuery("get_user_on_invite", getUser, {
    refetchOnWindowFocus: false,
    enabled: !!accessToken
  });
 
  useEffect(() => {
    if(getUserData?.data) {
      setUserData(getUserData?.data?.data)
      if(inviteData && getUserData?.data?.data && getUserData?.data?.data?.email && inviteData.email) {
        if(getUserData?.data?.data?.email.toLowerCase() !== inviteData.email.toLowerCase()) {
          window.location.href = `/app/${getUserData?.data?.data?.activeWorkspace?.slug}`
        }
      }
    }
  }, [getUserData?.data, inviteData])

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(64, "Password must not exceed 64 characters"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      acceptInviteFn.mutate({
        invitation_id: inviteId,
        token: inviteToken,
        name: values.name,
        password: values.password,
      });
    },
  });

  // add login 
  const loginFn = useMutation("login", login, {
    onSuccess(data, variables, context) {
      window.location.href = "/app/";
    },
    onError(err) {
      window.location.href = "/app/login";
    },
  });

  // handle accept invite
  const acceptInviteFn = useMutation("accept_invite", acceptInvite, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      setInviteData({});
     if(!accessToken) {
      fetchCsrfCookie()
      .then((xsrfToken) => {
        loginFn.mutate({email: inviteData?.email, password: formik.values.password});
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      }); 
     } else {
      navigate("/")
     }
    },
    onError(error) {
      console.log(error);
      formik.setSubmitting(false);
    },
  });
  const handleAcceptInvite = (payload) => {
    if (acceptInviteFn.isLoading) {
      return;
    }
    acceptInviteFn.mutate({ ...payload });
  }; 

  // handle decline invite
  const declineInviteFn = useMutation("decline_invite", declineInvite, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      // queryClient.invalidateQueries("get_invite");
      setInviteData({});
      navigate("/");
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleDeclineInvite = (payload) => {
    if (declineInviteFn.isLoading) {
      return;
    }
    declineInviteFn.mutate({ ...payload });
  };

  if (inviteData && inviteData?.invitee_user_exists && !accessToken) {
    return <Login invite={true} inviteData={inviteData} />;
  }

  return (
    <div className="flex  min-h-screen w-full">
      <div className="w-full bg-white dark:bg-gray-50 mobile:w-full">
        <div className="p-8 flex h-full flex-col justify-between">
          {appname === "PrimeRole" ? (
            <>
              {theme === "dark" ? (
                <Image src="/images/apps/primerole/logo-dark.svg" width={166} className="logo no-invert" />
              ) : (
                <Image src={logo} width={166} className="logo" />
              )}
            </>
          ) : (
            <Image src={logo} width={166} className="logo" />
          )}
          <div className="mx-auto py-4  mobile:w-full flex items-center">
            <div className="w-[360px] mobile:mx-auto">
              <div className="text-left mobile:hidden">
                <Text size="h5">Welcome Aboard</Text>
                {inviteData && inviteData.id && !inviteData.invitee_user_exists && (
                  <p className="text-base text-gray-600 mt-2">
                    Create free account to start using {process.env.REACT_APP_APP_NAME}
                  </p>
                )}
              </div>
              {getInviteDataFn.isLoading && (
                <div className="mt-10 space-y-4">
                  <Skeleton className={"h-10"} />
                  <Skeleton className={"h-10"} />
                  <Skeleton className={"h-10"} />
                  <Skeleton className={"h-10"} />
                  <Skeleton className={"h-10"} />
                  <Skeleton className={"h-10"} />
                </div>
              )}
              {inviteError && !getInviteDataFn.isLoading && (
                <div className="text-center">
                  <p className="text-base text-gray-600 mt-5">{inviteError}</p>
                  {accessToken ? (
                    <Button className="w-full mt-4" asChild>
                      <a href="/app">Go to Dashboard</a>
                    </Button>
                  ) : (
                    <Button className="w-full mt-4" asChild>
                      <a href="/app/login">Login</a>
                    </Button>
                  )}
                </div>
              )}
              {inviteData && inviteData.id && (
                <>
                  <div className="text-center mt-6 mobile:block hidden">
                    <Text size="h6">Welcome Aboard</Text>
                    <p className="text-base text-gray-600 mt-1">
                      You’ve been invited to join <b>{inviteData?.workspace?.name}</b> workspace as{" "}
                      {inviteData?.role === "Admin" ? "an" : "a"} <b>{inviteData?.role}</b>
                    </p>
                  </div>
                  {!inviteData.invitee_user_exists ? (
                    <FormikProvider value={formik}>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="mt-6">
                          <div className="mt-3">
                            <label htmlFor="" className="block text-sm font-medium leading-6 mb-1">
                              Email
                            </label>
                            <Input
                              className={"w-full bg-gray-100"}
                              placeholder="Email Id"
                              disabled
                              value={inviteData?.email}
                            />
                          </div>
                          <div className="mt-3">
                            <label htmlFor="" className="block text-sm font-medium leading-6 mb-1">
                              Name*
                            </label>
                            <Input
                              className={"w-full"}
                              placeholder="Enter your name"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            <FormError field="name" />
                          </div>

                          <div className="mt-3">
                            <label htmlFor="" className="block text-sm font-medium leading-6 mb-1">
                              Password*
                            </label>
                            <Input
                              className={"w-full "}
                              placeholder="Set a password"
                              type="password"
                              name="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                            />
                            <FormError field="password" />
                          </div>

                          <div className="mt-5">
                            <Button
                              className="w-full"
                              type="submit"
                              disabled={formik.isSubmitting || !formik.isValid || declineInviteFn.isLoading}
                              onClick={() => {
                                captureEvent("ONB_Invitation_AcceptInviteBtn");
                              }}
                            >
                              Accept Invite
                              {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
                            </Button>
                            {/* <div className="text-gray-400 text-center mt-2">
                              By signing up, you agree to our terms and privacy.
                            </div> */}
                            {/* <Button
                              className="w-full mt-3"
                              variant="danger-outline"
                              disabled={declineInviteFn.isLoading || formik.isSubmitting}
                              onClick={() => {
                                handleDeclineInvite({
                                  invitation_id: inviteId,
                                  token: inviteToken,
                                });
                                captureEvent("ONB_Invitation_DeclineBtn");
                              }}
                            >
                              Decline
                              {declineInviteFn.isLoading && <div className="loader_circle ml-2"></div>}
                            </Button> */}
                          </div>
                        </div>
                      </form>
                    </FormikProvider>
                  ) : (
                    <div className="mt-5">
                      <Button
                        className="w-full"
                        disabled={acceptInviteFn.isLoading || declineInviteFn.isLoading}
                        onClick={() => {
                          handleAcceptInvite({
                            invitation_id: inviteId,
                            token: inviteToken,
                          });
                          captureEvent("ONB_Invitation_AcceptInviteBtn");
                        }}
                      >
                        Accept Invite
                        {acceptInviteFn.isLoading && <div className="loader_circle ml-2"></div>}
                      </Button>
                      <Button
                        className="w-full mt-3"
                        variant="danger-outline"
                        disabled={acceptInviteFn.isLoading || declineInviteFn.isLoading}
                        onClick={() => {
                          handleDeclineInvite({
                            invitation_id: inviteId,
                            token: inviteToken,
                          });
                          captureEvent("ONB_Invitation_DeclineBtn");
                        }}
                      >
                        Decline
                        {declineInviteFn.isLoading && <div className="loader_circle ml-2"></div>}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="flex-1 pl-24 mobile:hidden">
              <div className="text-center">
                {inviteData && inviteData.id && (
                  <p className="text-base text-gray-600 mt-1 text-center">
                    You’ve been invited to join{" "}
                    <Text size="h5" className="block leading-normal text-gray-900">
                      {inviteData?.workspace?.name}
                    </Text>{" "}
                    workspace as {inviteData?.role === "Admin" ? "an" : "a"} {inviteData?.role}
                  </p>
                )}
                {theme === "dark" ? (
                  <Image src="/images/high-five-dark.svg" className="no-invert inline-block mt-8" />
                ) : (
                  <Image src="/images/high-five.svg" className="no-invert inline-block mt-8" />
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <span className="text-sm">© PrimeRole {new Date().getFullYear()}</span>
            <span className="text-sm flex items-center">
              <EnvelopeIcon width={20} className="mr-2" />{" "}
              <a href="mailto:support@primerole.com">support@primerole.com</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
