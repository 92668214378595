import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GeneralPage from "./components/pages/General";
import MembersPage from "./components/pages/Members";
import GroupsPage from "./components/pages/GroupsPage";
import IntegrationsPage from "./components/pages/IntegrationsPage";
import SecurityPage from "./components/pages/Security";
import DevelopersPage from "./components/pages/Developers";
import ProspectSearchPage from "./components/pages/ProspectSearch";
import DashboardPage from "./components/pages/Dashboard";
import NewWorkspacePage from "./components/pages/NewWorkspace";
import PageNotFoundPage from "./components/pages/PageNotFound";
import Workspaces from "./components/pages/Workspaces";
import GroupDetailsPage from "./components/pages/GroupDetailsPage";
import EnrichListPage from "./components/pages/EnrichList";
import EnrichListDetailsPage from "./components/pages/EnrichListDetailsPage";
import CreditConsumptionLogPage from "./components/pages/CreditConsumptionLog";
import ToastContainer from "./components/ToastContainer";
import EnrichCompanyPage from "./components/pages/EnrichCompany";
import EnrichPeoplePage from "./components/pages/EnrichPeople";
import CompanyPage from "./components/pages/Company";
import ContactPage from "./components/pages/Contact";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import Onboarding from "./components/pages/Onboarding";
import PrivateRoute from "./PrivateRoute";
import ProspectSearchListPage from "./components/pages/ProspectSearchList";
import ForgetPassword from "./components/pages/ForgetPassword";
import SalesforceMapping from "./components/containers/Integrations/Salesforce/SalesforceMapping";
import AcceptInvite from "./components/pages/AcceptInvite";
import Logout from "./components/pages/Logout";
import Profile from "./components/pages/Profile";
import ApiKeysPage from "./components/pages/ApiKeys";
import { Helmet } from "react-helmet";
import OffBoarding from "./components/pages/Offboarding";

function App() {
  return (
    <Router basename={`/${process.env.REACT_APP_APP_HOME}`}>
      <Routes>
        <Route
          path="/reset-password"
          element={
            <>
              <Helmet>
                <title>Reset Your Password - {process.env.REACT_APP_APP_NAME}</title>
              </Helmet>
              <ForgetPassword />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <Helmet>
                <title>Login to Your Account - {process.env.REACT_APP_APP_NAME}</title>
              </Helmet>
              <Login />
            </>
          }
        />
        <Route
          path="/register"
          element={
            <>
              <Helmet>
                <title>Create an Account - {process.env.REACT_APP_APP_NAME}</title>
              </Helmet>
              <Register />
            </>
          }
        />
        <Route
          path="/invitation/accept/:inviteId/:inviteToken"
          element={
            <>
              <Helmet>
                <title>Accept Your Invitation - {process.env.REACT_APP_APP_NAME}</title>
              </Helmet>
              <AcceptInvite />
            </>
          }
        />
        <Route
          path="/logout"
          element={
            <>
              <Helmet>
                <title>Logging Out - {process.env.REACT_APP_APP_NAME}</title>
              </Helmet>
              <Logout />
            </>
          }
        />
         <Route
            path="/chrome-extension-offboarding"
            element={
              <>
                <Helmet>
                  <title>Sad to see you go!</title>
                </Helmet>
                <OffBoarding />
              </>
            }
          />

        <Route path="/" element={<PrivateRoute />}>
          <Route
            exact
            path="/:workspaceSlug/"
            element={
              <>
                <Helmet>
                  <title>Dashboard - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <DashboardPage />
              </>
            }
          />
          <Route
            exact
            path="/workspaces"
            element={
              <>
                <Helmet>
                  <title>Your Workspaces - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <Workspaces />
              </>
            }
          />
          <Route
            path="/onboarding"
            element={
              <>
                <Helmet>
                  <title>Get Started with {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <Onboarding />
              </>
            }
          /> 

          <Route
            exact
            path="/:workspaceSlug/settings/general"
            element={
              <>
                <Helmet>
                  <title>General settings - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <GeneralPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/members"
            element={
              <>
                <Helmet>
                  <title>Manage members - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <MembersPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/groups"
            element={
              <>
                <Helmet>
                  <title>Groups setting - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <GroupsPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/groups/:groupId"
            element={
              <>
                <Helmet>
                  <title>Group Details - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <GroupDetailsPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/integrations"
            element={
              <>
                <Helmet>
                  <title>Integrations - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <IntegrationsPage />
              </>
            }
          />

          <Route
            path="/:workspaceSlug/settings/integrations/salesforce/:salesforceConfigId/"
            element={
              <>
                <Helmet>
                  <title>CRM settings - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <SalesforceMapping />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/security"
            element={
              <>
                <Helmet>
                  <title>Security settings - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <SecurityPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/api-keys"
            element={
              <>
                <Helmet>
                  <title>API Keys - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <ApiKeysPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/credit-consumption-log"
            element={
              <>
                <Helmet>
                  <title>Credit Consumption Log - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <CreditConsumptionLogPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/settings/developers"
            element={
              <>
                <Helmet>
                  <title>Developers - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <DevelopersPage />
              </>
            }
          />

          <Route
            path="/:workspaceSlug/enrich/list"
            element={
              <>
                <Helmet>
                  <title>Enrichment List - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <EnrichListPage />
              </>
            }
          />
          {/* <Route path="/:workspaceSlug/enrich/company" element={<EnrichCompanyPage />} />
          <Route path="/:workspaceSlug/enrich/company/:companyId" element={<CompanyPage />} /> */}
          <Route
            path="/:workspaceSlug/enrich/people"
            element={
              <>
                <Helmet>
                  <title>Enrich People - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <EnrichPeoplePage />
              </>
            }
          />
          {/* <Route path="/:workspaceSlug/enrich/people/:contactId" element={<ContactPage />} /> */}
          <Route
            path="/:workspaceSlug/enrich/list/:enrichListId"
            element={
              <>
                <Helmet>
                  <title>Enrichment Details - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <EnrichListDetailsPage />
              </>
            }
          />
          <Route
            path="/:workspaceSlug/prospect-search"
            element={
              <>
                <Helmet>
                  <title>Prospect Search - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <ProspectSearchPage />
              </>
            }
          />

          <Route
            path="/:workspaceSlug/prospect-search/list/:listNameSlug"
            element={
              <>
                <Helmet>
                  <title>Prospect Search Results - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <ProspectSearchListPage />
              </>
            }
          />

          <Route
            path="/create-workspace"
            element={
              <>
                <Helmet>
                  <title>Create a New Workspace - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <NewWorkspacePage />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <Helmet>
                  <title>Your Profile - {process.env.REACT_APP_APP_NAME}</title>
                </Helmet>
                <Profile />
              </>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <>
              <Helmet>
                <title>Page Not Found - {process.env.REACT_APP_APP_NAME}</title>
              </Helmet>
              <PageNotFoundPage />
            </>
          }
        />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
