import { DELETE, GET, PATCH, POST, PUT } from "../axios/apiService";

export const getEnrichmentList = async ({ query, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich${
    query ? `?${query}` : ""
  }`;
  return GET(url, options);
};

export const enrichCSVMapColumns = async ({ data, listId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/csv/${listId}/map-columns`;
  return POST(url, data, options);
};

export const deleteEnrichment = async ({ listId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/${listId}`;
  return DELETE(url, options);
};

export const updateEnrichlistName = async ({ data, listId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/${listId}`;
  return POST(url, data, options);
};

/* export const downloadEnrichListCSV = async ({ listId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/${listId}/csv`;
  return GET(url, options);
}; */

export const downloadEnrichListCSV = async ({ listId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/${listId}/csv`;
  // Set responseType to blob
  return GET(url, { ...options, responseType: "blob" });
};

export const downloadSummarizedEnrichListCSV = async ({ listId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/${listId}/csv/summarized`;
  // Set responseType to blob
  return GET(url, { ...options, responseType: "blob" });
};

export const getEnrichmentListRecords = async ({ query, listId, options }) => {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/api/v1/enrich/${listId}/records${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const getEnrichmentDetails = async ({ listId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/${listId}`;
  //console.log(options);
  return GET(url, options);
};

export const createContact = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/contacts`;
  return POST(url, data, options);
};

export const enrichCompany = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich-company`;
  return POST(url, data, options);
};

export const getContactDetails = async ({ contactId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/contacts/${contactId}`;
  return GET(url, options);
};

export const feedbackForEnrichedPhoneEmail = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/contacts/record-feedback`;
  return POST(url, data, options);
};

export const verifyEnrichedEmail = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/verify/email`;
  return POST(url, data, options);
};

export const enrichProfile = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich`;
  return POST(url, data, options);
};
