export const isEmptyObject = (obj) => {
  return (
    obj &&
    typeof obj === "object" &&
    !Array.isArray(obj) &&
    Object.keys(obj).length === 0
  );
};

export const isNullableValue = (obj) =>
  obj === null ||
  obj === undefined ||
  (typeof obj === "string" && obj === "") ||
  (Array.isArray(obj) && obj.length === 0);

export const returnArray = (data) => {
  if (data && data.length > 0) {
    return data;
  } else {
    return [];
  }
};

export const objectToQueryString = (obj) => {
  const entries = Object.entries(obj);

  const queryString = entries
    .map(([key, value]) => {
      let transformedKey;
      switch (key) {
        case "perPage":
          transformedKey = "per_page";
          break;
        default:
          transformedKey = key;
          break;
      }
      return `${transformedKey}=${value}`;
    })
    .join("&");

  return queryString;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getSubdomain = (url) => {
  const hostname = new URL(url).hostname; // This extracts "staging.primerole.com" from "https://staging.primerole.com/"
  const parts = hostname.split(".");

  if (parts.length === 3) {
    // e.g., ["staging", "primerole", "com"]
    return parts[1]; // Returns "primerole"
  } else if (parts.length === 2) {
    // e.g., ["primerole", "com"]
    return parts[0]; // Returns "primerole"
  }
};

export const badgeMapping = {
  updated: "blue-light",
  created: "gray",
  deleted: "error",
  declined: "error",
  approved: "success",
  requested: "gray",
  accepted: "success",
  withdrawn: "orange",
  pending: "warning",
  expired: "gray",
  default: "gray",
};

export const getVariant = (status) => {
  if (status && status.toLowerCase() && badgeMapping[status.toLowerCase()]) {
    console.log(badgeMapping[status.toLowerCase()]);
    return badgeMapping[status.toLowerCase()];
  } else {
    return badgeMapping.default;
  }
};

export const generateRandomString = () => {
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var length = 40;
  var randomString = "";

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};
