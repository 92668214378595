import { Fragment, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { addItemsToList, removeItemsFromList } from "../../../../apis/workspace/lists";
import ConfirmModal from "../../../Modals/ConfirmModal";
import TableRowActionDropdown from "../../../TableRowActionDropdown";
import Image from "../../../Tags/Image";
import { Button } from "../../../ui/button";
import { baseRowActionItems } from "./tableDropdownRowOptionConfig";

export default function TableDropdownRowOptions({ row, workspaceId, invalidateQuery }) {
  const queryClient = useQueryClient();
  const [openConfirmModal, setOpenConfirmModal] = useState({ open: false, type: null });

  const addItemToListMutation = useMutation({
    mutationFn: addItemsToList,
    onSuccess(data) {
      toast.success(data?.message);
    },
  });

  const deleteListMutation = useMutation({
    mutationFn: removeItemsFromList,
    onSuccess(data) {
      queryClient.invalidateQueries(invalidateQuery);
      toast.success(data?.message);
    },
  });

  const onHandleRowActionClick = useCallback(
    (actionType, data) => {
      switch (actionType) {
        case "info":
          break;
        case "add":
          addItemToListMutation.mutate({
            data: [
              {
                item_id: row.item_id,
                item_type: row.item_type,
              },
            ],
            options: { headers: { "X-Workspace-Id": workspaceId } },
            listId: data.id,
          });
          break;
        case "delete":
          setOpenConfirmModal({ open: true, type: "delete" });
          break;
        default:
          break;
      }
    },
    [setOpenConfirmModal]
  );

  const confirmModalState = async (data) => {
    if (data) {
      if (data.confirm && data.type && data.type === "delete") {
        deleteListMutation.mutate({
          options: {
            headers: {
              "X-Workspace-Id": workspaceId,
            },
          },
          data: [row.item_id],
          listId: row.list_id,
        });
      }
      setOpenConfirmModal({ open: false, type: null });
    }
  };

  return (
    <Fragment>
      <TableRowActionDropdown
        rowActionItems={baseRowActionItems.items}
        isItemsLoaded={baseRowActionItems.props.isItemsLoaded}
        onHandleRowActionClick={onHandleRowActionClick}
      >
        <Button className={`bg-transparent hover:bg-transparent`} size="icon" variant="white">
          <Image src="/images/icons/untitledui/dots-vertical.svg" height="20" width="20" aria-hidden="true" />
        </Button>
      </TableRowActionDropdown>
      <ConfirmModal
        openModal={openConfirmModal.open}
        modalType={openConfirmModal.type}
        confirmModalState={confirmModalState}
        subText="You are about to remove the item from this list. Please confirm."
      />
    </Fragment>
  );
}
