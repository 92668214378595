import React from "react";
import ReactDOM from "react-dom";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import Image from "../Tags/Image";

const ToastContainer = () => {
  return ReactDOM.createPortal(
    <Toaster
      toastOptions={{
        success: {
          className:
            "bg-gray-25 min-h-42 py-5 px-20 text-gray-600 border-input min-w-[370px] text-size-14 font-medium toast__custom",
          icon: (
            <Image
              src="/images/icons/untitledui/alert-success.svg"
              width="36"
              height="36"
              className="no-invert"
            />
          ),
        },
        error: {
          className:
            "bg-gray-25 min-h-42 py-5 px-20 text-gray-600 border-input min-w-[370px] text-size-14 font-medium toast__custom",
          icon: (
            <div className="w-[30px] min-w-[30px]">
              <Image
                src="/images/icons/untitledui/alert-error.svg"
                width="30"
                height="30"
                className="no-invert"
              />
            </div>
          ),
        },
        duration: 3000,
      }}
      position="top-right"
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <div className="w-[18px] min-w-[18px]">
                  <Image
                    width="16"
                    height="16"
                    className="no-invert cursor-pointer -mt-0.5"
                    onClick={() => toast.dismiss(t.id)}
                    src="/images/icons/untitledui/close.svg"
                  />
                </div>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>,
    document.body
  );
};

export default ToastContainer;
