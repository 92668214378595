import { DELETE, GET, PATCH, POST } from "../../axios/apiService";

export const getIntegrations = async ({ options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations`;
  return GET(url, options);
};

export const installIntegration = async ({ data, key, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${key}`;
  return POST(url, data, options);
};

export const deleteIntegration = async ({ configId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/config/${configId}/uninstall`;
  return DELETE(url, options);
};

export const updateIntegration = async ({ data, options, configId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/config/${configId}`;
  return POST(url, data, options);
};

export const requestIntegration = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/request`;
  return POST(url, data, options);
};

export const getEnrichmentIntegrations = async ({ options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/integrations/active`;
  return GET(url, options);
};

export const enableConfig = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/config/${data.id}/enable`;
  return POST(url, undefined, data.options);
};
export const disableConfig = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/config/${data.id}/disable`;
  return POST(url, undefined, data.options);
};

export const getEnrichmentPriorityConfig = async ({ options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/enrichment-config-priority`;
  return GET(url, options);
};

export const setEnrichmentPriorityConfig = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/enrichment-config-priority`;
  return POST(url, data, options);
};

export const salesforceAuthLogin = async ({ env, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/workspace-settings/integrations/salesforce/login/${env}`;
  return GET(url, options);
};

export const getSalesforceObjectList = async ({ options, salesforceConfigId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/objects`;
  return GET(url, options);
};

export const saveSalesforceObjectsConfig = async ({ data, salesforceConfigId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/configuration/objects`;
  return POST(url, data, options);
};

export const getSavedSalesforceConfig = async ({ options, salesforceConfigId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/configuration/objects`;
  return GET(url, options);
};

export const getPlatformFields = async ({ options, type }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/crm/system-fields`;
  return GET(url, options);
};

export const getSalesforceObjectFields = async ({ options, object, salesforceConfigId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/objects/${object}`;
  return GET(url, options);
};

export const saveSalesforceObjectsMapping = async ({ data, salesforceConfigId, type, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/configuration/objects/${type}/fields`;
  return POST(url, data, options);
};

export const updateSalesforceObjectsConfig = async ({ data, salesforceConfigId, objectId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/configuration/objects/${objectId}`;
  return PATCH(url, data, options);
};

export const deleteSalesforceObjectsConfig = async ({ salesforceConfigId, objectId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/configuration/objects/${objectId}`;
  return DELETE(url, options);
};

export const getCRMUserMapping = async ({ options, salesforceConfigId, type }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/configuration/user-mapping`;
  return GET(url, options);
};

export const saveCRMUserMapping = async ({ data, salesforceConfigId, type, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/${salesforceConfigId}/salesforce/configuration/user-mapping`;
  return POST(url, data, options);
};
