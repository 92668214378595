import React from "react";

export default function PrintDate({ date = "" }) {
  const dateStr = new Date(date); // Assuming this is UTC time
  const mydate = new Date(dateStr);

  const userLocale = navigator.language;

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = mydate.toLocaleDateString(userLocale, options);

  return <div>{formattedDate}</div>;
}
