import React, { useState, useRef, useEffect, useMemo } from "react";
import Sortable from "sortablejs";
import useClickOutside from "../../hooks/useClickOutside";
import { Button } from "../ui/button";

export default function EditColumn({ columnMetadata, getColumns }) {
  const [isOpen, setIsOpen] = useState(false);
  const allColumns = useMemo(() => {
    return Object.keys(JSON.parse(JSON.stringify(columnMetadata)));
  }, [columnMetadata]);

  const [orderOfColumns, setOrderOfColumns] = useState(allColumns);
  const listRef = useRef(null);

  const [isSortableInitialized, setIsSortableInitialized] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState(() => {
    return allColumns.reduce((acc, column, index) => {
      acc[column] = {
        ...columnMetadata[column],
        visible: columnMetadata[column].default,
        order: index,
        key: column,
      };
      return acc;
    }, {});
  });
  const handleCheckboxChange = (columnKey) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnKey]: {
        ...prevState[columnKey],
        visible: !prevState[columnKey].visible,
        key: columnKey,
      },
    }));
  };

  useEffect(() => {
    if (listRef.current && isOpen && !isSortableInitialized) {
      Sortable.create(listRef.current, {
        animation: 150,
        chosenClass: "bg-gray-100",
        ghostClass: "bg-gray-100",
      });

      setIsSortableInitialized(true);
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (isOpen === false) {
      setIsSortableInitialized(false);
    }
  };

  const handleApply = () => {
    // Retrieve order directly from the DOM
    const currentOrder = Array.from(listRef.current.children).map((item) => item.getAttribute("data-key"));
    ////console.log(currentOrder); // Logs the current order of columns to the console

    setVisibleColumns((prevState) => {
      const newColumns = { ...prevState };

      currentOrder.forEach((key, index) => {
        if (newColumns[key]) {
          newColumns[key].order = currentOrder.indexOf(key); // setting the order key based on the index in currentOrder
        }
      });

      return newColumns;
    });

    // Set this order to the state if needed
    setOrderOfColumns(currentOrder);

    // Any other logic you wish to run after clicking "Apply" can be placed here.
    // For instance, you can make an API call to save the current order, etc.

    setIsOpen(false); // Optional: Close the dropdown after clicking apply.
    getColumns(visibleColumns);
  };

  useEffect(() => {
    getColumns(visibleColumns);
  }, []);
  useEffect(() => {
    getColumns(visibleColumns);
  }, [isOpen]);

  const resetColumns = () => {
    //console.log(":allColumns", columnMetadata);
    setVisibleColumns((prevState) => {
      const newColumns = { ...prevState };
      allColumns.forEach((key, index) => {
        if (newColumns[key]) {
          newColumns[key].order = allColumns.indexOf(key);
          newColumns[key].visible = newColumns[key].default;
        }
      });
      return newColumns;
    });
    setIsOpen(false);
  };

  useClickOutside("editcolumn_dropdown", [setIsOpen]);

  return (
    <div className="relative inline-block text-left editcolumn_dropdown mobile:ml-4">
      <div>
        <Button
          onClick={(e) => {
            e.preventDefault();
            toggleDropdown();
          }}
          variant="outline"
          className="h-[34px] text-xs"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          Edit Column
        </Button>
      </div>
      {isOpen && (
        <div className="dropdown-content mt-2 absolute right-0 w-56 rounded-md shadow-lg bg-background z-10">
          <div className="py-1" ref={listRef}>
            {orderOfColumns.map((columnKey, index) => (
              <div
                key={columnKey}
                data-key={columnKey}
                className="flex items-center px-4 py-2 text-sm justify-between cursor-move"
              >
                <div className="inline-flex items-center">
                  <input
                    type="checkbox"
                    id={columnKey}
                    checked={visibleColumns[columnKey].visible}
                    onChange={() => handleCheckboxChange(columnKey)}
                    className={`mr-2 form-checkbox text-brand-700 dark:text-brand-200 rounded ${
                      !columnMetadata[columnKey].canBeHidden ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    disabled={!columnMetadata[columnKey].canBeHidden}
                  />
                  <label htmlFor={columnKey} className="block ">
                    {columnMetadata[columnKey].display_name}
                  </label>
                </div>
                <div className="inline-flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div>
          <div className="px-4 flex space-x-2 mb-4">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleApply();
              }}
              className="w-full"
            >
              Apply
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                resetColumns();
              }}
              variant="outline"
              className="w-full"
            >
              Reset
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
