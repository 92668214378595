import { useNavigate, useLocation } from "react-router-dom";

function useUpdateQuery() {
  const navigate = useNavigate();
  const location = useLocation();

  const setQueryString = (queryString) => {
    navigate({
      ...location,
      search: queryString,
    });
  };

  return setQueryString;
}

export default useUpdateQuery;
