import React, { useContext, useEffect, useState } from "react";
import Text from "../../Tags/Text";
import { Button } from "../../ui/button";
import Image from "../../Tags/Image";

import Layout from "../../Layout";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import VerifyEmail from "./VerifyEmail";
import SharedDataContext from "../../../SharedDataContext";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { getIntegrations } from "../../../apis/workspace/integrations";
import InstallIntegration from "../../containers/Integrations/InstallIntegration";
import InviteModal from "../../containers/Invitations/InviteModal";
import EnrichPeopleForm from "../../containers/EnrichPeople/EnrichPeopleForm";
import ContactCard from "../Contact/ContactCard";
import Stepper from "../../Stepper";
import { Tooltip } from "react-tooltip";
import {
  getUserOnboardingStatus,
  logOutUser,
  setActiveWorkspace,
  setJobFunction,
  updateOnboardingStatus,
} from "../../../apis/user";
import usePageAttributes from "../../../hooks/usePageAttributes";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { fetchCsrfCookie } from "../../../apis/csrf";
import { ArrowRight, ChevronLeft, ChevronRight, PlusIcon } from "lucide-react";
import WorkspaceForm from "../../containers/NewWorkspace/WorkspaceForm";
import WorkspaceList from "../Workspaces/WorkspaceList";
import { Badge } from "../../ui/badge";
import { useTheme } from "../../ThemeProvider";
import { getWorkspaces } from "../../../apis/workspace/invitations";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent"
import useFeatureFlag from "../../../hooks/useFeatureFlag";
import { restrictedIntegrations } from "../../../axios/constant";
const job_functions = [
  {
    name: "Sales",
    img: "sales.svg",
  },
  {
    name: "Marketing",
    img: "marketing.svg",
  },
  {
    name: "Recruitment",
    img: "recruitment.svg",
  },
  {
    name: "Founder",
    img: "founder.svg",
  },
];
const beta_providers = [
  {
    name: "Hunter",
    img: "provider_hunter.svg",
  },
  {
    name: "Snov.io",
    img: "provider_snov.svg",
  },
  {
    name: "Dropcontact",
    img: "provider_dropcontact.svg",
  },
  {
    name: "Clearbit",
    img: "provider_clearbit.svg",
  },
  {
    name: "Datagma",
    img: "provider_datagma.svg",
  },
];
const features = [
  "Reveal contacts on LinkedIn",
  "120+ company insights on any website",
  "Personalized outreach messages",
  "Add people and companies to lists",
  "ICP and Persona scoring",
];

export default function Onboarding() {
  const isPremiumProvidersEnabled = useFeatureFlag("enable-premium-providers");
  const isAdvancePhoneVerificationEnabled = useFeatureFlag("advanced-phone-verification");
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const [activeTab, setActiveTab] = useState("account");
  const tabs = [
    {
      name: "Create your account",
      id: "account",
      step: "account_creation",
    },
    // {
    //   name: "Install Chrome Extension",
    //   id: "chrome",
    //   step: "chrome_extension_installed",
    // },
    {
      name: "Connect data providers",
      id: "provider",
      step: "connected_data_provider",
    },
    // {
    //   name: "Enrich your first contact",
    //   id: "enrich",
    //   step: "enriched_contact",
    // },
    {
      name: "Invite your team",
      id: "invite",
      step: "invited_team_members",
    },
  ];

  const sortObjectAndFindLastTrueKey = (obj) => {
    // Create an array from the object entries and sort it such that `true` values come first
    const sortedEntries = Object.entries(obj).sort((a, b) => (b[1] === true) - (a[1] === true));

    // Convert the sorted array back to an object
    const sortedObject = Object.fromEntries(sortedEntries);

    // Find the last key with the value `true`
    let lastTrueKey = null;
    for (let [key, value] of sortedEntries) {
      if (value) {
        lastTrueKey = key;
      }
    }

    return { sortedObject, lastTrueKey };
  };

  // steps
  const [currentStep, setCurrentStep] = useState(1); // Starts at 1
  const [onboardingStatus, setOnboardingStatus] = useState({});
  const { isOpen, openModal, closeModal } = useModal(false);
  const {
    isOpen: isOpenWelcome,
    openModal: openModalWelcome,
    closeModal: closeModalWelcome,
  } = useModal(false);

  useEffect(() => {
    if (userData && userData.activeWorkspace && userData.activeWorkspace.slug) {
      console.log("onboardingStatus", onboardingStatus);
      if (!userData.email_verified) {
        openModal();
      } else if (userData.job_function === null) {
        openModalWelcome();
      } else if (
        userData.job_function &&
        onboardingStatus.account_creation &&
        !onboardingStatus.connected_data_provider
      ) {
        setStepsCompleted({
          step1: true,
          step2: true,
          step3: false,
          step4: false,
        });
        openModalWelcome();
        setCurrentStep(2);
      } else if (
        userData.job_function &&
        onboardingStatus.connected_data_provider &&
        !onboardingStatus.chrome_extension_installed
      ) {
        setStepsCompleted({
          step1: true,
          step2: true,
          step3: true,
          step4: true,
        });
        openModalWelcome();
        setCurrentStep(4);
      } else if (userData.job_function && onboardingStatus.chrome_extension_installed) {
        console.log("chrome_extension_installed");
        navigate(`/${userData?.activeWorkspace?.slug}/`);
      } else {
        console.log("app");
        // navigate(`/${userData?.activeWorkspace?.slug}/`);
      }
    }
  }, [userData, onboardingStatus]);

  // get integrations
  const [integrations, setIntegrations] = useState({});
  const getIntegrationsFn = useQuery(
    "get_integrations",
    () => {
      return getIntegrations({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace && userData?.email_verified === true,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        console.log(data);
        setIntegrations(data);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetIntegrations = () => {
    getIntegrationsFn.refetch();
  };

  useEffect(() => {
    if (activeWorkspace?.id && userData?.email_verified) {
      handleGetIntegrations();
    }
  }, [activeWorkspace]);

  const [currentIntegrationKey, setCurrentIntegrationKey] = useState("");
  const [currentIntegrationLogo, setCurrentIntegrationLogo] = useState("");
  const [showForm, setShowForm] = useState(false);

  const isKeyFound = (categories) => {
    return categories.includes("Data Providers");
  };

  // get onboarding status
  const getUserOnboardingStatusFn = useQuery(
    "get_onboarding",
    () => {
      return getUserOnboardingStatus({});
    },
    {
      refetchOnWindowFocus: false,
      enabled: userData?.email_verified === true,
      manual: true,
      onSuccess: (data) => {
        console.log(data);
        if (data?.onboarding_status) {
          const result = sortObjectAndFindLastTrueKey(data?.onboarding_status);

          const stepObj = result?.sortedObject || {};
          // if (stepObj && stepObj.hasOwnProperty("chrome_extension_installed")) {
          //   delete stepObj.chrome_extension_installed;
          // }
          if (stepObj && stepObj.hasOwnProperty("enriched_contact")) {
            delete stepObj.enriched_contact;
          }

          console.log("stepObj", stepObj);

          setOnboardingStatus(stepObj);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  // update onboarding
  const updateOnboardingStatusFn = useMutation("update_onboarding", updateOnboardingStatus, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries("get_user");
      queryClient.invalidateQueries("get_onboarding");
      if (variables?.context?.nextActiveTab) {
        if (variables?.context?.nextActiveTab === "chrome_extension_installed") {
          navigate(`/${userData?.activeWorkspace?.slug}/`);
          resetStates();
        }
        setActiveTab(variables?.context?.nextActiveTab);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleUpdateOnboardingStatus = (key, nextActiveTab) => {
    let payload = { ...onboardingStatus };
    if (key) {
      payload[key] = true;
      if (key === "chrome_extension_installed") {
        payload.connected_data_provider = true;
      }
    }

    updateOnboardingStatusFn.mutate({
      data: {
        onboarding_status: payload,
      },
      context: { nextActiveTab },
    });
  };
  const { logo, appname, setTitle } = usePageAttributes();
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();

  const logOutUserFn = useMutation("logout", logOutUser, {
    onSuccess(data, variables, context) {
      // toast.success(data?.message);
      Cookies.remove(process.env.REACT_APP_PR_ACCESS_TOKEN);
      Cookies.remove(process.env.REACT_APP_PR_APP_SESSION_COOKIE);
      navigate("/login");
    },
  });

  const handleLogout = () => {
    fetchCsrfCookie()
      .then((xsrfToken) => {
        logOutUserFn.mutate({});
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      });
  };

  // welcome code

  const [stepsCompleted, setStepsCompleted] = useState({
    step1: true, // First step is always true
    step2: false,
    step3: false,
    step4: false,
  });

  // Use useEffect to handle the state update so it ensures UI updates correctly
  useEffect(() => {
    if (currentStep > 1) {
      // This ensures the first step remains always true
      setStepsCompleted((prev) => ({
        ...prev,
        [`step${currentStep}`]: true,
      }));
    }
  }, [currentStep]); // Dependency on currentStep to trigger the effect

  // Handles clicking on the "Next" button
  const handleNextStep = () => {
    // Move to the next step if it's not the last step
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const setJobFunctionFn = useMutation("set_jobfunction", setJobFunction, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries("get_user");
      handleNextStep();
    },
    onError(error) {
      console.log(error);
    },
  });
  const [currentJobFunction, setCurrentJobFunction] = useState("");
  const updateJobFunction = (type) => {
    setCurrentJobFunction(type);
    setJobFunctionFn.mutate({
      data: { job_function: type },
    });
  };

  const setActiveWorkspaceFn = useMutation(setActiveWorkspace, {
    onSuccess(data, variables, context) {
      console.log("workspace is set");
    },
  });

  const [newWorkspace, setNewWorkspace] = useState(false);
  const [onboardingWorkspace, setOnboardingWorkspace] = useState({});
  const getOnboardingStatus = (data) => {
    console.log(data);
    setOnboardingWorkspace(data?.workspace);
    setActiveWorkspaceFn.mutate(data?.workspace?.id);
    handleNextStep();
  };

  const [shouldShowWorkspaceStep, setShouldShowWorkspaceStep] = useState(true);
  const getWorkspacesData = useQuery("get_workspaces_onboard", getWorkspaces, {
    refetchOnWindowFocus: false,
    enabled: !!userData.email_verified,
  });
  useEffect(() => {
    if (getWorkspacesData?.data) {
      if (
        getWorkspacesData.data &&
        getWorkspacesData.data.membershipRequests &&
        getWorkspacesData.data.membershipRequests.length === 0 &&
        getWorkspacesData.data.pendingInvitations &&
        getWorkspacesData.data.pendingInvitations.length === 0 &&
        getWorkspacesData.data.workspaces &&
        getWorkspacesData.data.workspaces.length === 0
      ) {
        setShouldShowWorkspaceStep(false);
      } else {
        setShouldShowWorkspaceStep(true);
      }
    }
  }, [getWorkspacesData?.data]);

  useEffect(() => {
    if (userData && userData.job_function) {
      if (!shouldShowWorkspaceStep) {
        if(userData && userData.activeWorkspace && userData.activeWorkspace?.role === "Owner") {
          setStepsCompleted({
            step1: true,
            step2: true,
            step3: true,
            step4: false,
          });
          setCurrentStep(3);
        }
        if(userData && userData.activeWorkspace && userData.activeWorkspace?.role !== "Owner") {
          setStepsCompleted({
            step1: true,
            step2: true,
            step3: true,
            step4: true,
          });  
        }
      }
      return () => {
        setStepsCompleted({
          step1: true,
          step2: false,
          step3: false,
          step4: false,
        });
        setCurrentStep(1);
      };
    }
  }, [shouldShowWorkspaceStep, userData && userData.job_function]);

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.role !== "Owner" && getWorkspacesData.isFetched) {
      handleUpdateOnboardingStatus("connected_data_provider", "chrome");
    }
  }, [activeWorkspace, getWorkspacesData.isFetched]);

  const resetStates = () => {
    setCurrentStep(1);
    setOnboardingStatus({});
    setIntegrations({});
    setCurrentIntegrationKey("");
    setCurrentIntegrationLogo("");
    setShowForm(false);
    setStepsCompleted({
      step1: true, // First step is always true
      step2: false,
      step3: false,
      step4: false,
    });
    setCurrentJobFunction("");
    setNewWorkspace(false);
  };

  return (
    <>
      <div className="min-h-screen">
        <header className="border-b border-border bg-white dark:bg-gray-25 py-4">
          <nav className="mx-auto flex items-center  px-6 lg:px-8" aria-label="Global">
            <div className="flex items-center gap-x-2 w-4/12">
              <a
                href={`/${process.env.REACT_APP_APP_HOME}/${userData?.activeWorkspace?.slug}`}
                className="-m-1.5 p-1.5 w-[264px]"
              >
                {appname === "PrimeRole" ? (
                  <>
                    {theme === "dark" ? (
                      <Image
                        src="/images/apps/primerole/logo-dark.svg"
                        className="logo no-invert w-[170px]"
                        alt="Logo"
                      />
                    ) : (
                      <Image src={logo} className="logo w-[170px]" alt="Logo" />
                    )}
                  </>
                ) : (
                  <Image src={logo} className="logo w-[170px]" alt="Logo" />
                )}
              </a>
            </div>
            <div className="w-4/12 text-center">
              {/* {userData.email_verified && isOpenWelcome && (
                <div>
                  <p className="text-base text-gray-500 text-center">Step {currentStep} of 4</p>
                  <div className="space-x-3">
                    <span
                      className={`inline-block h-1.5 w-16 rounded-3xl ${
                        stepsCompleted.step1 ? "bg-gray-800" : "bg-gray-300"
                      } `}
                    ></span>
                    <span
                      className={`inline-block h-1.5 w-16 rounded-3xl ${
                        stepsCompleted.step2 ? "bg-gray-800" : "bg-gray-300"
                      } `}
                    ></span>
                    <span
                      className={`inline-block h-1.5 w-16 rounded-3xl ${
                        stepsCompleted.step3 ? "bg-gray-800" : "bg-gray-300"
                      } `}
                    ></span>
                    <span
                      className={`inline-block h-1.5 w-16 rounded-3xl ${
                        stepsCompleted.step4 ? "bg-gray-800" : "bg-gray-300"
                      } `}
                    ></span>
                  </div>
                </div>
              )} */}
            </div>
            <div className="w-4/12 text-right">
              <Button variant="outline" 
        
              onClick={() => {
                handleLogout();
                captureEvent("ONB_Extension_LogOutBtn");
              }}
              >
                Logout
                <svg
                  className="ml-2"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </nav>
        </header>
        <div
          className="mx-auto justify-between bg__gradient__onboarding"
          style={{ minHeight: "calc(100vh - 72.65px)" }}
        >
          <div className="flex w-full container__layout ">
            <div className="flex-1 justify-center items-center inline-flex overflow-y-scroll">
              <div className="px-8 py-7 h-full">
                <div className="bg-white dark:bg-gray-50 rounded-xl p-10 shadow w-[768px] min-h-[70vh] mx-auto">
                  {isOpen && <VerifyEmail userData={userData} closeModal={closeModal} />}
                  {isOpenWelcome && (
                    <>
                      <>
                        {stepsCompleted.step1 &&
                          !stepsCompleted.step2 &&
                          !stepsCompleted.step3 &&
                          !stepsCompleted.step4 && (
                            <div>
                              <div className="text-center flex justify-center flex-col items-center">
                                <Image src={"/images/onboarding/bag.svg"} className="mb-6 no-invert" />
                                <Text size="h5">Select your job role</Text>
                                <p className="text-base text-gray-700 mt-2">
                                  This is to help us customize the platform for your needs
                                </p>
                              </div>

                              <div className="w-[600px] mx-auto">
                                <div className="flex flex-wrap -mx-3">
                                  {job_functions.map((job_function) => (
                                    <div className="w-6/12 px-3 mt-6">
                                      <div
                                        
                                        onClick={() => {
                                          updateJobFunction(job_function.name);
                                          captureEvent("ONB_JobRole_SelectRole", { SelectRole:job_function.name});
                                        }}
                                        className="border border-border rounded-xl px-4 py-5 bg-[#F9FAFB] dark:bg-gray-100 cursor-pointer font-bold text-base text-[#101010] text-center hover:border-brand-600"
                                      >
                                        <Image
                                          src={`/images/onboarding/${job_function.img}`}
                                          className="inline-block no-invert h-[120px]"
                                        />
                                        <span className="flex  justify-center items-center mt-6 dark:text-gray-900">
                                          {job_function.name}{" "}
                                          {setJobFunctionFn.isLoading &&
                                            job_function.name === currentJobFunction && (
                                              <div className="loader_circle ml-2"></div>
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        {stepsCompleted.step1 &&
                          stepsCompleted.step2 &&
                          !stepsCompleted.step3 &&
                          !stepsCompleted.step4 && (
                            <div>
                              <div className="text-center flex justify-center flex-col items-center">
                                <Image src={"/images/onboarding/workspaces.svg"} className="mb-6 no-invert" />
                                <Text size="h5">Workspaces</Text>
                                <p className="text-base text-gray-700 mt-2">
                                  Your {process.env.REACT_APP_APP_NAME} workspace is the ideal place for your
                                  team to collaborate! Join your colleagues in one of the workspaces below or
                                  create a new workspace.
                                </p>
                              </div>
                              <div className="mb-6">
                                {newWorkspace && (
                                  <div className="flex items-center justify-between mt-8">
                                    <Text size="lg">Create a workspace</Text>
                                    <Button
                                      variant="outline"
                                      onClick={() => {
                                        setNewWorkspace(false);
                                        captureEvent("CreateWorkspaceBtn");
                                      }}
                                    >
                                      <ChevronLeft width={16} height={16} className="mr-1" />
                                      Back
                                    </Button>
                                  </div>
                                )}
                              </div>

                              <div className="mx-auto workspace__onboarding">
                                {newWorkspace ? (
                                  <div className="workspace__form__modal">
                                    <WorkspaceForm onboarding={true} onboardingStatus={getOnboardingStatus} />
                                  </div>
                                ) : (
                                  <WorkspaceList
                                    onboarding={true}
                                    getAvailableWorkspaces={(shouldShow) => {
                                      if (shouldShow === 0) {
                                        setShouldShowWorkspaceStep(false);
                                      }
                                      if (shouldShow === 1) {
                                        setShouldShowWorkspaceStep(true);
                                      }
                                    }}
                                  />
                                )}
                              </div>
                              {!newWorkspace && (
                                <div className="text-center">
                                  {/* <div className="flex justify-center items-center mt-6">
                                    <Button 
                                   
                                    onClick={() => {
                                      handleNextStep();
                                      captureEvent("ONB_Workspaces_SkipAndContinueBtn");
                                    }}
                                    >
                                      Continue
                                      <ArrowRight width={16} height={16} className="ml-2" />
                                    </Button>
                                  </div> */}
                                  <span
                                    
                                    onClick={() => {
                                      // setNewWorkspace(!newWorkspace);
                                      handleNextStep();
                                      captureEvent("ONB_CreateWorkspace_CreateWorkspaceBtn");
                                    }}
                                    className="inline-flex items-center mt-6 text-gray-950 font-semibold cursor-pointer"
                                  >
                                    Or create a new workspace <ArrowRight width={16} height={16} className="ml-1" />
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        {stepsCompleted.step1 &&
                          stepsCompleted.step2 &&
                          stepsCompleted.step3 &&
                          !stepsCompleted.step4 && (
                            <div>
                              <div className="text-center flex justify-center flex-col items-center">
                                <Image src={"/images/onboarding/providers.svg"} className="mb-6 no-invert" />
                                <Text size="h5">Connect providers</Text>
                                <p className="text-base text-gray-700 mt-2">
                                  Connecting data providers enables contact enrichment through browser
                                  extension and in bulk. Connection is enabled through API token from the
                                  provider.
                                </p>
                              </div>
                              {showForm &&  <Button
                                      variant="outline"
                                      onClick={() => {
                                        setShowForm(false)
                                      }}
                                      className="ml-6 mt-2 relative top-5"
                                    >
                                      <ChevronLeft width={16} height={16} className="mr-1" />
                                      Back
                                    </Button> }

                              {!showForm && (
                                <>
                                  <div className="flex flex-wrap  m-auto mt-10">
                                    {integrations &&
                                      integrations.available &&
                                      integrations.available.length > 0 &&
                                      integrations.available.filter((integration) => {
                                        if (restrictedIntegrations.includes(integration.key)) {
                                          return isPremiumProvidersEnabled;
                                        }
                                        if (integration.key === "advanced_phone_verification") {
                                          return isAdvancePhoneVerificationEnabled;
                                        }
                                        return true;
                                      }).map((integration) => (
                                        <div
                                          onClick={() => {
                                            setCurrentIntegrationKey(integration.key);
                                            setCurrentIntegrationLogo(integration.logo);
                                            setShowForm(true);
                                            captureEvent("ONB_ConnectProviders_ProvidersBtn", {ProvidersBtn:integration.key});
                                          }}
                                        
                                          className={`inline-flex w-[48%] mr-[2%] mb-4 items-center justify-between border border-border rounded-xl py-3 px-3  ${
                                            isKeyFound(integration.categories) ? "" : "hidden"
                                          } 
                              ${
                                integrations.installed &&
                                integrations.installed.length > 0 &&
                                integrations.installed.filter((installInt) =>
                                  installInt.integration.key
                                    .toLowerCase()
                                    .includes(integration.key.toLowerCase())
                                ).length > 0
                                  ? "border-green-500 bg-green-50 dark:bg-green-25 cursor-default pointer-events-none"
                                  : "bg-white cursor-pointer dark:bg-gray-200"
                              }
                              `}
                                          data-tooltip-id="integrations"
                                          data-tooltip-content={`${
                                            integrations.installed &&
                                            integrations.installed.length > 0 &&
                                            integrations.installed.filter((installInt) =>
                                              installInt.integration.key
                                                .toLowerCase()
                                                .includes(integration.key.toLowerCase())
                                            ).length > 0
                                              ? "Already Connected"
                                              : "Connect"
                                          }`}
                                          data-tooltip-place="bottom"
                                        >
                                          <span className="inline-flex items-center">
                                            <span
                                              className="svg__32"
                                              dangerouslySetInnerHTML={{
                                                __html: integration.logo,
                                              }}
                                            />
                                            <span className="text-base font-bold text-gray-600 ml-3">
                                              {integration.display_name}
                                            </span>
                                          </span>

                                          <svg
                                            width="21"
                                            height="20"
                                            viewBox="0 0 21 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M7.16699 10.0008L10.5003 13.3342M10.5003 13.3342L13.8337 10.0008M10.5003 13.3342V5.66749C10.5003 4.50857 10.5003 3.92911 10.0416 3.28032C9.73675 2.84926 8.85914 2.31722 8.33597 2.24634C7.54857 2.13966 7.24955 2.29564 6.65152 2.60761C3.98644 3.99785 2.16699 6.78686 2.16699 10.0008C2.16699 14.6032 5.89795 18.3342 10.5003 18.3342C15.1027 18.3342 18.8337 14.6032 18.8337 10.0008C18.8337 6.91631 17.1578 4.22322 14.667 2.78234"
                                              stroke="#475467"
                                              stroke-width="1.66667"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      ))}
                                    {beta_providers &&
                                      beta_providers.length > 0 &&
                                      beta_providers.map((provider) => (
                                        <div
                                          className={`inline-flex w-[48%] mr-[2%] mb-4 items-center justify-between border border-border rounded-xl py-3 px-3 bg-[#F9FAFB] dark:bg-gray-200 `}
                                          data-tooltip-id="integrations"
                                          data-tooltip-content={`Currently in beta`}
                                          data-tooltip-place="bottom"
                                        >
                                          <span className="inline-flex items-center">
                                            <Image
                                              src={`/images/onboarding/${provider.img}`}
                                              className="inline-block no-invert h-[32px] w-[32px]"
                                            />
                                            <span className="text-base font-bold text-gray-600 mx-3">
                                              {provider.name}
                                            </span>
                                            <Badge variant={"outline"}>Beta</Badge>
                                          </span>

                                          <svg
                                            width="36"
                                            height="36"
                                            viewBox="0 0 36 36"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M22.1667 17.1667V14.6667C22.1667 12.3655 20.3012 10.5 18 10.5C15.6988 10.5 13.8333 12.3655 13.8333 14.6667V17.1667M14.5 25.5H21.5C22.9001 25.5 23.6002 25.5 24.135 25.2275C24.6054 24.9878 24.9878 24.6054 25.2275 24.135C25.5 23.6002 25.5 22.9001 25.5 21.5V21.1667C25.5 19.7665 25.5 19.0665 25.2275 18.5317C24.9878 18.0613 24.6054 17.6788 24.135 17.4392C23.6002 17.1667 22.9001 17.1667 21.5 17.1667H14.5C13.0999 17.1667 12.3998 17.1667 11.865 17.4392C11.3946 17.6788 11.0122 18.0613 10.7725 18.5317C10.5 19.0665 10.5 19.7665 10.5 21.1667V21.5C10.5 22.9001 10.5 23.6002 10.7725 24.135C11.0122 24.6054 11.3946 24.9878 11.865 25.2275C12.3998 25.5 13.0999 25.5 14.5 25.5Z"
                                              stroke="#475467"
                                              stroke-width="1.66667"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      ))}
                                    <Tooltip id="integrations" />
                                  </div>
                                  <div className="text-center pt-6">
                                    <span
                                      className={` inline-flex items-center ${onboardingStatus.account_creation ? "cursor-pointer" : "pointer-events-none opacity-50"}`}
                                      onClick={() => {
                                        handleNextStep();
                                        handleUpdateOnboardingStatus("connected_data_provider", "chrome");
                                        captureEvent("ONB_ConnectProviders_ConnectLaterBtn");
                                      }}
                                    >
                                      {integrations && integrations.installed &&
                                integrations.installed.length > 0 ? "Continue" : "Connect later"} <ChevronRight width={16} height={16} className="ml-1" />
                                    </span>
                                  </div>
                                </>
                              )}
                              {showForm && (
                                <div className="install__integration py-8">
                                  <div className="mx-auto">
                                    <InstallIntegration
                                      onboarding={true}
                                      closeModal={(btn) => {
                                        setShowForm(false);

                                        btn !== "cancel" && handleNextStep();
                                        btn !== "cancel" &&
                                          handleUpdateOnboardingStatus("connected_data_provider", "chrome");
                                      }}
                                      integrationKey={currentIntegrationKey}
                                      logo={currentIntegrationLogo}
                                      edit={false}
                                      formData={{
                                        name: currentIntegrationKey === "zoom_info" ? "ZoomInfo" : "",
                                        configId: "",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        {stepsCompleted.step1 &&
                          stepsCompleted.step2 &&
                          stepsCompleted.step3 &&
                          stepsCompleted.step4 && (
                            <div>
                              <div className="text-center flex justify-center flex-col items-center">
                                <Image src={"/images/onboarding/extension.svg"} className="mb-6 no-invert" />
                                <Text size="h5">Browser Extension</Text>
                                <p className="text-base text-gray-700 mt-2">
                                  {process.env.REACT_APP_APP_HOME} browser extension unlocks powerful company
                                  and people insights everywhere on the web.
                                </p>
                              </div>
                              <div className="mx-auto">
                                <div>
                                  <div className="flex justify-center items-center mt-5">
                                    <Image
                                      src={"/images/chrome-web-store.svg"}
                                      className="dark:rounded-xl dark:p-1 no-invert dark:mb-5"
                                    />
                                  </div>
                                  <Button className="w-full" asChild
                                   onClick={() => {
                                    captureEvent("ONB_Extension_InstallBtn");
                                  }}
                                  >
                                    <a href={process.env.REACT_APP_CHROME_EXTENSION} target="_blank">
                                      <Image
                                        src={"/images/icons/untitledui/google-chrome.svg"}
                                        className="mr-2"
                                      />{" "}
                                      Install
                                    </a>
                                  </Button>

                                  <div className="my-8 rounded-2xl border border-border shadow-lg p-8">
                                    <div className="space-y-4">
                                      {features.map((feature) => (
                                        <p className="flex items-center">
                                          <svg
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mr-3"
                                          >
                                            <path
                                              d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5Z"
                                              fill="#DCFAE6"
                                            />
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M17.0965 7.89004L9.9365 14.8L8.0365 12.77C7.6865 12.44 7.1365 12.42 6.7365 12.7C6.3465 12.99 6.2365 13.5 6.4765 13.91L8.7265 17.57C8.9465 17.91 9.3265 18.12 9.7565 18.12C10.1665 18.12 10.5565 17.91 10.7765 17.57C11.1365 17.1 18.0065 8.91004 18.0065 8.91004C18.9065 7.99004 17.8165 7.18004 17.0965 7.88004V7.89004Z"
                                              fill="#079455"
                                            />
                                          </svg>
                                          {feature}
                                        </p>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="mx-auto w-[220px] space-y-2">
                                    <Button
                                      className="w-full"
                                      variant="link"
                                      onClick={() => {
                                        handleUpdateOnboardingStatus(
                                          "chrome_extension_installed",
                                          "chrome_extension_installed"
                                        );
                                      
                                        captureEvent("ONB_Extension_SkipForNowBtn");
                                        
                                      }}
                                    >
                                      Skip for now{" "}
                                      {updateOnboardingStatusFn.isLoading &&
                                        onboardingStatus?.connected_data_provider && (
                                          <div className="loader_circle ml-2"></div>
                                        )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
