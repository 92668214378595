import React from "react";
import Layout from "../../Layout";
import Members from "../../containers/Members";

export default function MembersPage() {
  return (
    <Layout>
      <Members />
    </Layout>
  );
}
