import React, { useContext, useEffect, useState } from "react";
import EnrichLeftNav from "../../containers/Enrich/EnrichLeftNav";
import Layout from "../../Layout";
import SharedDataContext from "../../../SharedDataContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { enrichCompany, enrichProfile, getContactDetails } from "../../../apis/enrich";
import { useParams } from "react-router-dom";
import Text from "../../Tags/Text";
import {
  ChevronLeft,
  LoaderCircle,
  LoaderCircleIcon,
  LucideLoader2,
  MapPinnedIcon,
  PlusIcon,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import NavAnchor from "../../Tags/NavAnchor";
import Image from "../../Tags/Image";
import { getIntegrations } from "../../../apis/workspace/integrations";

import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import EnrichedPhone from "../../EnrichedPhone";
import EnrichedEmail from "../../EnrichedEmail";
import StripeLoader from "../../StripeLoader";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export default function CompanyPage() {
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};

  const { companyId } = useParams();
  const [company, setCompany] = useState({});

  // const [contact, setContact] = useState({});
  // const getContactDetailsFn = useMutation(
  //   "get_contact_details",
  //   getContactDetails,
  //   {
  //     onSuccess(data, variables, context) {
  //       console.log(data);
  //       if (data && data.contact) {
  //         setContact(data.contact);
  //       }
  //       if (
  //         data &&
  //         data.contact &&
  //         data.contact.phone_numbers &&
  //         data.contact.phone_numbers.length === 0 &&
  //         data.contact.email_addresses &&
  //         data.contact.email_addresses.length === 0
  //       ) {
  //         enrichProfileDetails();
  //       }
  //     },
  //   }
  // );
  // const handleGetContactDetails = () => {
  //   getContactDetailsFn.mutate({
  //     contactId: companyId,
  //     options: {
  //       headers: { "X-Workspace-Id": activeWorkspace?.id },
  //     },
  //   });
  // };

  const enrichCompanyFn = useMutation("enrich_company", enrichCompany, {
    onSuccess(data, variables, context) {
      if (data && data.id) {
        setCompany(data);
      }
    },
    onError(error) {
      console.log(err);
    },
  });

  const getCompanyDetails = () => {
    const payload = JSON.parse(atob(companyId));
    enrichCompanyFn.mutate({
      data: { ...payload },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (activeWorkspace?.id && companyId) {
      getCompanyDetails();
    }
  }, [activeWorkspace, companyId]);

  const topics = ["Summary", "Industries", "Technologies", "People", "Financials"];
  const [currentTopic, setCurrentTopic] = useState("Summary");
  // console.log("company", company);
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Layout leftNav={<EnrichLeftNav />}>
      {company && company.id && (
        <>
          {" "}
          <NavAnchor
            className="inline-flex items-center cursor-pointer"
            onClick={() => {
              setCompany({});
            }}
            to={"/enrich/company"}
          >
            <ChevronLeft width={20} height={20} />
            Back
          </NavAnchor>
          <div className="space-x-4 mt-6 flex">
            <div className="w-8/12 pr-10">
              <div className="relative mb-6">
                <div className="bg-brand-50 rounded-2xl h-60"></div>
                <div className="border border-border bg-white shadow p-6 rounded-2xl inline-block -mt-20 ml-2">
                  <img src={company?.logo} width={100} />
                </div>
              </div>
              <Text size="h5">{company.name}</Text>
              <Text size="md-base" className="mt-2">
                {company?.description_short}
              </Text>
              <p className="text-base mt-1 items-center inline-flex text-gray-700 font-semibold space-x-2">
                <MapPinnedIcon width={20} height={20} />{" "}
                <span>{JSON.parse(company?.city) ? JSON.parse(company?.city)?.name : ""}</span>
              </p>
              {/* <div className="my-6 space-x-3 items-center flex">
                <Button>
                  <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Add to collection
                </Button>
                <Button variant="outline">Add to CRM</Button>
              </div> */}
              <div className="border-b border-border mb-2 space-x-2 mt-6 bg-gray-25 sticky top-[-28px]">
                {topics &&
                  topics.length > 0 &&
                  topics.map((el) => (
                    <Button
                      className={`font-semibold text-text-500 focus:bg-none px-10 rounded-none border-t-0 border-x-0 shadow-none ${
                        el === currentTopic
                          ? "bg-brand-100 text-brand-700 pointer-events-none border-b-2 border-brand-600"
                          : "border-b-2 border-transparent"
                      }`}
                      variant={el === currentTopic ? "purple-outline" : "ghost"}
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentTopic(el);
                        scrollToSection(el);
                      }}
                    >
                      {el}
                    </Button>
                  ))}
              </div>
              <div className="py-3 pt-0 px-8">
                <div className="py-6 border-b border-border" id="Summary">
                  <h6 className="text-base text-gray-700 font-semibold">Summary</h6>
                  <p className="text-base text-gray-600 mt-3">
                    {company.description ? company.description : "-"}
                  </p>
                </div>

                <div className="border-b border-border py-6" id="Industries">
                  <h6 className="text-base text-gray-700 font-semibold">Industries</h6>
                  <div className="mt-3 space-y-3">
                    {company.industries && company.industries.length > 0
                      ? JSON.parse(company?.industries).map((industy) => (
                          <Badge variant={"gray"} className={"mr-3"}>
                            {industy}
                          </Badge>
                        ))
                      : "-"}
                  </div>
                </div>
                <div className="border-b border-border py-6" id="Technologies">
                  <h6 className="text-base text-gray-700 font-semibold">Technologies</h6>
                  <div className="mt-3 space-y-3">
                    {company.technologies && company.technologies.length > 0
                      ? JSON.parse(company?.technologies).map((technology) => (
                          <Badge variant={"gray"} className={"mr-3"}>
                            {technology}
                          </Badge>
                        ))
                      : "-"}
                  </div>
                </div>

                <div className="border-b border-border py-6" id="People">
                  <h6 className="text-base text-gray-700 font-semibold">People</h6>
                  <div className="rounded-[--radius] border border-border p-4 mt-6">
                    <div className="flex -mx-4">
                      <div className="w-6/12 px-4">
                        <div className="space-y-2">
                          <p className="text-base text-gray-900">Total Employees</p>
                          <p className="text-base text-gray-600">{company?.total_employees}</p>
                        </div>
                      </div>
                      <div className="w-6/12 px-4">
                        <div className="space-y-2">
                          <p className="text-base text-gray-900">Total Employees Exact</p>
                          <p className="text-base text-gray-600">{company?.total_employees_exact}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b border-border py-6" id="Financials">
                  <h6 className="text-base text-gray-700 font-semibold">Financials</h6>
                  <div className="rounded-[--radius] border border-border p-4 mt-6">
                    <div className="flex -mx-4">
                      <div className="w-6/12 px-4">
                        <div className="space-y-2">
                          <p className="text-base text-gray-900">Total funding</p>
                          <p className="text-base text-gray-600">-</p>
                        </div>
                      </div>
                      <div className="w-6/12 px-4">
                        <div className="space-y-2">
                          <p className="text-base text-gray-900">Total Revenue (Annual)</p>
                          <p className="text-base text-gray-600">{company?.revenue}</p>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-border my-4"></div>
                    <div className="flex -mx-4">
                      <div className="w-6/12 px-4">
                        <div className="space-y-2">
                          <p className="text-base text-gray-900">Stock exchange</p>
                          <p className="text-base text-gray-600">
                            {company?.stock_exchange ? company?.stock_exchange : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="w-6/12 px-4">
                        <div className="space-y-2">
                          <p className="text-base text-gray-900">Stock Symbol</p>
                          <p className="text-base text-gray-600">
                            {company?.stock_symbol ? company?.stock_symbol : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-4/12 space-y-6">
              {JSON.parse(company.companies_acquisitions) &&
                JSON.parse(company.companies_acquisitions).length > 0 && (
                  <div className="border border-border rounded-lg p-6 shadow-sm">
                    <Text size="md">Acquisitions</Text>
                    <div className="mt-6 space-y-4 overflow-y-scroll px-6 -mx-6" style={{ maxHeight: 400 }}>
                      {JSON.parse(company.companies_acquisitions).map((item) => (
                        <div className="flex space-x-3 items-center rounded-[--radius] bg-gray-100 border border-input p-3">
                          <div>
                            <div className="border bg-white border-border rounded h-12 w-12 inline-flex justify-center items-center bg-gray-100">
                              <Image src={"/images/icons/untitledui/org.svg"} width="18" className="" />
                            </div>
                          </div>
                          <div className="inline-flex flex-col">
                            <Text size="md">{item?.name}</Text>
                            {item.domain && (
                              <a
                                href={`https://${item.domain}`}
                                target="_blank"
                                className="block text-brand-700"
                              >
                                {item.domain}
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {JSON.parse(company.companies_subsidiaries) &&
                JSON.parse(company.companies_subsidiaries).length > 0 && (
                  <div className="border border-border rounded-lg p-6 shadow-sm">
                    <Text size="md">Subsidiaries</Text>
                    <div className="mt-6 space-y-4 overflow-y-scroll px-6 -mx-6" style={{ maxHeight: 400 }}>
                      {JSON.parse(company.companies_subsidiaries).map((item) => (
                        <div className="flex space-x-3 items-center rounded-[--radius] bg-gray-100 border border-input p-3">
                          <div>
                            <div className="border bg-white border-border rounded h-12 w-12 inline-flex justify-center items-center bg-gray-100">
                              <Image src={"/images/icons/untitledui/org.svg"} width="18" className="" />
                            </div>
                          </div>
                          <div className="inline-flex flex-col">
                            <Text size="md">{item?.name}</Text>
                            {item.domain && (
                              <a
                                href={`https://${item.domain}`}
                                target="_blank"
                                className="block text-brand-700"
                              >
                                {item.domain}
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {JSON.parse(company.companies_similar) && JSON.parse(company.companies_similar).length > 0 && (
                <div className="border border-border rounded-lg p-6 shadow-sm">
                  <Text size="md">Similar companies</Text>
                  <div className="mt-6 space-y-4 overflow-y-scroll px-6 -mx-6" style={{ maxHeight: 400 }}>
                    {JSON.parse(company.companies_similar).map((item) => (
                      <div className="flex space-x-3 items-center rounded-[--radius] bg-gray-100 border border-input p-3">
                        <div>
                          <div className="border bg-white border-border rounded h-12 w-12 inline-flex justify-center items-center bg-gray-100">
                            <Image src={"/images/icons/untitledui/org.svg"} width="18" className="" />
                          </div>
                        </div>
                        <div className="inline-flex flex-col">
                          <Text size="md">{item?.name}</Text>
                          {item.domain && (
                            <a
                              href={`https://${item.domain}`}
                              target="_blank"
                              className="block text-brand-700"
                            >
                              {item.domain}
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
