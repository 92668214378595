import React, { useEffect, useState } from "react";
import Text from "../../Tags/Text";
import { Button } from "../../ui/button";
import OtpInput from "react-otp-input";
import { useMutation, useQueryClient } from "react-query";
import { resendOtp, updateOnboardingStatus, verifyEmail } from "../../../apis/user";
import toast from "react-hot-toast";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function VerifyEmail({ userData, closeModal }) {
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const [timeLeft, setTimeLeft] = useState(30);
  const [isClickable, setIsClickable] = useState(false);
  const [otp, setOtp] = useState();

  useEffect(() => {
    if(otp && otp.length === 6) {
      handleVerifyEmail();
    }
  }, [otp]);

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft === 0) {
      setIsClickable(true);
      return;
    }

    // Save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const verifyEmailFn = useMutation("verify_email", verifyEmail, {
    onSuccess(data, variables, context) {
      toast.success(data.message);
      closeModal();
      queryClient.invalidateQueries("get_user");
      handleUpdateOnboardingStatus();
    },
  });
  const handleVerifyEmail = () => {
    verifyEmailFn.mutate({ otp: otp });
  };

  const resendOtpFn = useMutation("resend_otp", resendOtp, {
    onSuccess(data, variables, context) {
      toast.success(data.message);
      setIsClickable(false);
      setTimeLeft(30);
    },
  });
  const handleResendOtp = () => {
    resendOtpFn.mutate({
      email: userData?.email,
      action: "email-verification",
    });
  };

  // update onboarding
  const updateOnboardingStatusFn = useMutation("update_onboarding", updateOnboardingStatus, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries("get_user");
      queryClient.invalidateQueries("get_onboarding");
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleUpdateOnboardingStatus = () => {
    updateOnboardingStatusFn.mutate({
      data: {
        onboarding_status: {
          account_creation: true,
          chrome_extension_installed: false,
          connected_data_provider: false,
          enriched_contact: false,
          invited_team_members: false,
        },
      },
    });
  };

  return (
    <div className="w-[444px] mx-auto">
      <div className="text-center">
        <span className="border border-border p-3 inline-block rounded-xl mb-6">
          <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.83301 8.16667L12.3587 14.8347C13.1301 15.3746 13.5158 15.6446 13.9353 15.7492C14.3059 15.8416 14.6935 15.8416 15.064 15.7492C15.4836 15.6446 15.8692 15.3746 16.6406 14.8347L26.1663 8.16667M8.43301 23.3333H20.5663C22.5265 23.3333 23.5066 23.3333 24.2553 22.9519C24.9139 22.6163 25.4493 22.0809 25.7849 21.4223C26.1663 20.6736 26.1663 19.6935 26.1663 17.7333V10.2667C26.1663 8.30649 26.1663 7.3264 25.7849 6.57771C25.4493 5.91914 24.9139 5.38371 24.2553 5.04815C23.5066 4.66667 22.5265 4.66667 20.5663 4.66667H8.43301C6.47282 4.66667 5.49273 4.66667 4.74404 5.04815C4.08547 5.38371 3.55004 5.91914 3.21448 6.57771C2.83301 7.3264 2.83301 8.30649 2.83301 10.2667V17.7333C2.83301 19.6935 2.83301 20.6736 3.21448 21.4223C3.55004 22.0809 4.08547 22.6163 4.74404 22.9519C5.49273 23.3333 6.47282 23.3333 8.43301 23.3333Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <Text size="h5">Check your email</Text>
        <div className="mt-3">
          <p className="text-base text-gray-600">We have sent a verification link to </p>
          <p className="text-base text-gray-900">{userData?.email}</p>
        </div>
        <div className="my-8 space-x-3">
          <OtpInput
            inputType="number"
            containerStyle={"w-full space-x-3"}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={""}
            placeholder={"000000"}
            inputStyle={
              "remove-arrow h-16 w-16 border border-border rounded-xl text-5xl text-center placeholder-gray-300 dark:text-black font-bold dark:placeholder-gray-700"
            }
            skipDefaultStyles={true}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <Button
          className="w-full"
          disabled={otp?.length < 6 || verifyEmailFn.isLoading || !otp}
          onClick={() => {
            handleVerifyEmail();
            captureEvent("ONB_EmailVerification_VerifyEmailBtn");
          }}
        >
          Verify email {verifyEmailFn.isLoading && <div className="loader_circle ml-2"></div>}
        </Button>
        <div className="mt-8">
          <span className="text-gray-600">Didn’t receive the email?</span>{" "}
          <Button
            variant="link"
            href="#none"
            className="text-brand-700 font-bold p-0 h-min"
            disabled={resendOtpFn.isLoading}
            onClick={() => {
              handleResendOtp();
              captureEvent("ONB_EmailVerification_ClickToResendBtn");
            }}
          >
            Click to resend {resendOtpFn.isLoading && <div className="loader_circle ml-2"></div>}
          </Button>
        </div>
      </div>
    </div>
  );
}
