export default function TextAreaInput({ pretext = "", className, rows = 3, ...rest }) {
  return (
    <>
      {pretext ? (
        <div className="flex relative rounded-[--radius] border border-input">
          <span className="bg-smoke inline-flex items-center rounded-l-[--radius] px-[14px] text-text-600 border-r border-border">
            {pretext}
          </span>
          <textarea
            rows={rows}
            className={`resize-none px-[14px] py-[10px] text-sm w-full rounded-r-[--radius] border-none bg-background text-text-900 ${className}`}
            {...rest}
          />
        </div>
      ) : (
        <textarea
          rows={rows}
          className={`resize-none px-[14px] py-[10px] text-sm w-full rounded-[--radius] border border-input bg-background text-text-900 ${className}`}
          {...rest}
        />
      )}
    </>
  );
}
