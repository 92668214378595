import React from "react";
import Layout from "../../Layout";
import WorkspaceList from "./WorkspaceList";
import { NavLink } from "react-router-dom";
import { Button } from "../../ui/button";
import { PlusIcon } from "lucide-react";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function Workspaces() {
  const captureEvent = useCaptureEvent();
  return (
    <Layout leftNav={false}>
      <div className="container max-w-[1200px] mx-auto p-8 mobile:p-0">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="text-lg font-semibold leading-7 text-text-900">Manage workspaces</h2>
            <p className="text-text-600 mt-1">Explore more with workspaces</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <NavLink to={`/create-workspace`}>
              <Button
              onClick={() => {
                captureEvent("TOP_ManageWorkspaces_CreateWorkspaceBtn");
                     }}>
                <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Create workspace
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="mt-8">
          <WorkspaceList />
        </div>
      </div>
    </Layout>
  );
}
