import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

export default function TooltipWrapper({
  content,
  children,
  className = "",
  cursor = "cursor-default",
}) {
  return (
    <Tooltip>
      <TooltipTrigger
        className={`${
          className ? className : "p-0 border-0 bg-none text-gray-500"
        } ${cursor && cursor}`}
      >
        {children}
      </TooltipTrigger>
      <TooltipContent>{content}</TooltipContent>
    </Tooltip>
  );
}
