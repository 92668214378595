import { FormikProvider, useFormik } from "formik";
import { debounce } from "lodash";
import { XCircleIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import {
  checkListsNameAvailability,
  createList,
  duplicateList,
  editList,
} from "../../../../apis/workspace/lists";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import { ReactComponent as BuildingIcon } from "../../../../static/media/icons/building-detail-icon.svg";
import { ReactComponent as CircleModalBackground } from "../../../../static/media/utils/circle-modal-background.svg";
import FormError from "../../../Tags/FormError";
import Image from "../../../Tags/Image";
import Input from "../../../Tags/Input";
import Text from "../../../Tags/Text";
import TextAreaInput from "../../../Tags/TextAreaInput";
import { Button } from "../../../ui/button";
import YupValid from "./YupListValidation";

export default function CreateOrEditListModal({ closeModal, mode = "create", listData }) {
  const { activeWorkspace } = useRoleCheck();
  const queryClient = useQueryClient();
  const [isNameFieldActive, setIsNameFieldActive] = useState(true);
  const checkListNameAvailabilityFn = useMutation(checkListsNameAvailability);

  const handleModalClose = () => {
    formik.resetForm();
    closeModal();
  };

  const listMutation = useMutation({
    mutationFn: mode === "create" ? createList : mode === "duplicate" ? duplicateList : editList,
    onSuccess(data) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_lists");
      handleModalClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      name: mode === "duplicate" ? `${listData?.name} - Copy` : mode === "edit" ? listData?.name ?? "" : "",
      ...(mode !== "duplicate" && { description: mode === "edit" ? listData?.description ?? "" : "" }),
    },
    validationSchema: YupValid.object({
      name: YupValid.string().sequence([
        () =>
          YupValid.string()
            .required("List name is required")
            .min(3, "Name should contain atleast 3 characters")
            .max(30, "Name cannot contain more than 30 characters")
            .matches(/^[a-zA-Z0-9\s\-_]{3,50}$/, {
              message: "List name must only contain letters, numbers, dashes, and underscores.",
            }),
        () =>
          YupValid.string().uniqueName(
            "List with the given name already exists",
            activeWorkspace?.id,
            isNameFieldActive,
            checkListNameAvailabilityFn,
            mode === "edit" && listData ? { id: listData?.id } : {}
          ),
      ]),
      ...(mode !== "duplicate" && { description: YupValid.string().optional() }),
    }),
    validateOnMount: true,
    validateOnChange: false,
    onSubmit: (values) => {
      listMutation.mutate({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
        data: {
          ...(values.name && { name: values.name }),
          ...(mode !== "duplicate" && values.description && { description: values.description }),
        },
        ...((mode === "edit" || mode === "duplicate") && { listId: listData.id }),
      });
    },
  });

  const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm]);

  useEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values, debouncedValidate]);

  return (
    <div>
      <div className="absolute top-0 left-0 -z-40">
        <CircleModalBackground className="stroke-gray-300 dark:stroke-gray-100" />
      </div>
      <div className="h-[48px] w-[48px] items-center flex items-center justify-center border rounded-lg inline-block border-gray-300 dark:border-gray-100">
        <BuildingIcon className="stroke-gray-600" width="24" height="24" />
      </div>
      <Text size="lg" className="mt-3">
        {mode === "edit" ? "Edit" : mode === "duplicate" ? "Duplicate" : "Create New"} List
      </Text>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col py-6 gap-4">
            <div>
              <label htmlFor="name" className="block mb-1.5 text-sm font-medium leading-6">
                List name *
              </label>
              <div className="relative">
                <Input
                  autoFocus
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  placeholder="e.g. America sales directors"
                  onFocus={() => setIsNameFieldActive(true)}
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    setIsNameFieldActive(false);
                    formik.handleBlur(e);
                  }}
                  value={formik.values.name}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (mode === "duplicate") formik.handleSubmit();
                    }
                  }}
                />
                {formik.values.name && (
                  <>
                    {checkListNameAvailabilityFn.isLoading && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <div className="loader_circle"></div>
                      </div>
                    )}
                    {checkListNameAvailabilityFn.isError && formik.errors.name && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                      </div>
                    )}
                    {checkListNameAvailabilityFn.isSuccess && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <Image src="/images/icons/green-check-filled.svg" width={20} className="no-invert" />
                      </div>
                    )}
                  </>
                )}
              </div>
              <FormError field="name" className="mt-1.5" />
            </div>
            {mode !== "duplicate" && (
              <div>
                <label htmlFor="description" className="block mb-1.5 text-sm font-medium leading-6">
                  Description
                </label>
                <TextAreaInput
                  type="text"
                  name="description"
                  id="description"
                  autoComplete="off"
                  placeholder="Optionally, specify the intended use for this list"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />
                <FormError field="description" className="mt-1.5" />
              </div>
            )}
          </div>
          <div className="mt-0 flex flex-col justify-stretch md:flex-row md:justify-center gap-3">
            <Button
              className="flex-1"
              onClick={(e) => {
                e.preventDefault();
                handleModalClose();
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid} className="flex-1">
              {mode === "edit" ? "Update" : mode === "duplicate" ? "Duplicate" : "Create"}
              {formik.isSubmitting ? <div className="loader_circle ml-3"></div> : ""}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
}
