import React, { useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { Check, ChevronDown } from "lucide-react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/command";
import { cn } from "../../lib/utils";

export default function SearchDropdown({ data = [], selected = "", getCurrent, type }) {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState({});
  useEffect(() => {
    if (data && data.length > 0 && selected) {
      const obj = data.find((obj) => obj.salesforce_object_api_name === selected);
      setCurrent(obj);
      // console.log("current", obj);
    }
  }, [data && data.length && selected]);

  return (
    <Popover open={open} onOpenChange={setOpen} className="w-[400px]">
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between h-11 w-[290px] rounded-[8px] font-semibold pr-2.5"
        >
          {/* {value
                              ? frameworks.find((framework) => framework.value === value)?.label
                              : "Save"} */}
          {current && current?.salesforce_object_label ? current?.salesforce_object_label : "--Select--"}
          <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className=" p-0" align="start">
        <Command>
          <div className="p-2">
            <div className="border  rounded-[8px] border-b-0 overflow-hidden">
              <CommandInput placeholder="Search" />
            </div>
          </div>
          <CommandList>
            <CommandEmpty>No List found.</CommandEmpty>
            <CommandGroup>
              {/* <CommandItem
                className="min-h-[40px] pl-8"
                onSelect={() => {
                  setCurrent("");
                  getCurrent("");
                  setOpen(false);
                }}
              >
                --Select--
              </CommandItem> */}
              {data &&
                data.length > 0 &&
                data.map((item) => {
                  const newItem = {
                    ...item,
                    value: item.salesforce_object_api_name,
                    label: item.salesforce_object_label,
                    type: type,
                  };

                  return (
                    <CommandItem
                      key={newItem.salesforce_object_api_name}
                      value={newItem.label}
                      onSelect={() => {
                        if (newItem.salesforce_object_api_name === current?.salesforce_object_api_name) {
                          setCurrent({
                            ...newItem,
                            salesforce_object_api_name: "",
                            salesforce_object_label: "",
                          });
                          getCurrent({
                            ...newItem,
                            salesforce_object_api_name: "",
                            salesforce_object_label: "",
                          });
                          setOpen(false);
                        } else {
                          setCurrent(newItem);
                          getCurrent(newItem);
                          setOpen(false);
                        }
                      }}
                      className="min-h-[40px]"
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          current?.salesforce_object_api_name === newItem.salesforce_object_api_name
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {newItem.label}
                    </CommandItem>
                  );
                })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
