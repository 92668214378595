// hooks/usePageAttributes.js
import { useState, useEffect } from "react";
import { getSubdomain } from "../utils/GenericFn";
import { apps } from "../constants/apps";

const usePageAttributes = () => {
  const currentUrl = window.location.href;
  //   const currentUrl = "https://staging-prospecto.lambdatestinternal.com";
  // const currentUrl = "https://staging.primerole.com/app";
  const subdomain = getSubdomain(currentUrl);
  let appConfig = apps[subdomain] || {};

  if (!appConfig) {
    appConfig = apps.default;
  }

  // State for the dynamic title
  const [dynamicTitle, setDynamicTitle] = useState("");

  useEffect(() => {
    // Update favicon
    if (appConfig.favicon) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = appConfig.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    // Update title
    // if (dynamicTitle) {
    //   document.title = dynamicTitle;
    // }
  }, [appConfig.favicon, dynamicTitle]);

  return {
    logo: appConfig.logo,
    appname: appConfig.app_name,
    setTitle: setDynamicTitle,
  };
};

export default usePageAttributes;
