import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SharedDataContext from "./SharedDataContext";
import { getUser, getUserOnboardingStatus, updateOnboardingStatus } from "./apis/user";
import posthog from "posthog-js";

const PrivateRoute = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const getUserData = useQuery("get_user", getUser, {
    refetchOnWindowFocus: false,
  });

  const updateOnboardingStatusFn = useMutation("update_onboarding", updateOnboardingStatus, {
    onSuccess(data, variables, context) {
      console.log(data);
      queryClient.invalidateQueries("get_onboarding");
    },
    onError(error) {},
  });

  const [onboardingStatus, setOnboardingStatus] = useState({});
  const getUserOnboardingStatusFn = useQuery(
    "get_onboarding",
    () => {
      return getUserOnboardingStatus({});
    },
    {
      refetchOnWindowFocus: false,
      enabled: userData?.email_verified === true,
      manual: true,
      onSuccess: (data) => {
        console.log("onboarding", data);
        if (!data?.onboarding_status || data?.onboarding_status === null) {
          updateOnboardingStatusFn.mutate({
            data: {
              onboarding_status: {
                account_creation: true,
                enriched_contact: false,
                invited_team_members: false,
                connected_data_provider: false,
                chrome_extension_installed: false,
              },
            },
          });
        } else {
          setOnboardingStatus(data?.onboarding_status);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    if (getUserData?.data) {
      setUserData(getUserData.data.data);
      const newEvent = new CustomEvent("user_workspace", {
        detail: {
          activeWorkspace: getUserData.data.data.activeWorkspace,
          workspaces: getUserData.data.data.workspaces.data,
        },
      });
      window.dispatchEvent(newEvent);
      posthog.identify(getUserData?.data?.data?.id, {
        email: getUserData?.data?.data?.email,
        name: getUserData?.data?.data?.name,
      });
      posthog.group('workspace', getUserData.data.data.activeWorkspace?.slug, {
        name: getUserData.data.data.activeWorkspace?.name, 
        slug: getUserData.data.data.activeWorkspace?.slug,
        id: getUserData.data.data.activeWorkspace?.id
      });
      posthog.setGroupPropertiesForFlags({ 'workspace': {'slug': getUserData.data.data.activeWorkspace?.slug}})
    }
  }, [getUserData?.data]);

  useEffect(() => {
    // Redirect only if on the root path "/"
    if (location.pathname === "/" && userData && userData.activeWorkspace && userData.activeWorkspace.slug) {
      navigate(`/${userData.activeWorkspace.slug}`);
    }
    if (
      location.pathname !== "/onboarding" &&
      userData &&
      userData.activeWorkspace &&
      userData.activeWorkspace.slug
    ) {
      if (!userData.email_verified) {
        navigate(`/onboarding`);
      } else if (userData.job_function === null) {
        navigate(`/onboarding`);
      } else if (
        onboardingStatus &&
        Object.keys(onboardingStatus).length > 0 &&
        !onboardingStatus?.chrome_extension_installed
      ) {
        navigate(`/onboarding`);
      } else {
        console.log("app");
      }
    }
  }, [userData, navigate, location.pathname, onboardingStatus]);

  if (getUserData.isLoading) {
    return (
      <div className="flex h-screen w-full justify-center items-center">
        <div className="flex justify-center flex-col items-center">
          <div className="loader_circle loader_loading"></div>
          <p className="text-base font-semibold text-gray-500 mt-3">Loading</p>
        </div>
      </div>
    );
  }

  if (!getUserData.isSuccess) {
    return <Navigate to="/login" />;
  }

  return (
    <SharedDataContext.Provider value={userData}>
      <Outlet />
    </SharedDataContext.Provider>
  );
};

export default PrivateRoute;
