import axios from "./axios";

export const GET = async (url, options = {}) => {
  try {
    const response = await axios.get(url, options);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return Promise.reject(err.response);
  }
};

export const DELETE = async (url, options = {}) => {
  try {
    const response = await axios.delete(url, options);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return Promise.reject(err.response);
  }
};

export const POST = async (url, data = undefined, options = {}) => {
  try {
    const response = await axios.post(url, data, options);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return Promise.reject(err.response);
  }
};

export const PUT = async (url, data = undefined, options = {}) => {
  try {
    const response = await axios.put(url, data, options);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return Promise.reject(err.response);
  }
};

export const PATCH = async (url, data = undefined, options = null) => {
  try {
    const response = await axios.patch(url, data, options);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return Promise.reject(err.response);
  }
};
