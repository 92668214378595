import {
  ArrowDownIcon,
  ArrowDownTrayIcon,
  ArrowUpIcon,
  PlusIcon,
  TrashIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState, useRef } from "react";
import Image from "../../Tags/Image";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteEnrichment,
  downloadEnrichListCSV,
  downloadSummarizedEnrichListCSV,
  enrichCSVMapColumns,
  getEnrichmentList,
} from "../../../apis/enrich";
import SharedDataContext from "../../../SharedDataContext";
import { toast } from "react-hot-toast";
import { paginationConfig } from "../../../axios/constant";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import { isEmptyObject, returnArray } from "../../../utils/GenericFn";
import { Tooltip } from "react-tooltip";
import TableLoader from "../../Loaders/TableLoader";
import NoData from "../../NoData";
import Pagination from "../../Pagination";
import EditColumn from "../../EditColumn";
import Filter from "../../Filter";
import PrintDate from "../../Tags/PrintDate";
import ConfirmModal from "../../Modals/ConfirmModal";
import { PencilIcon } from "@heroicons/react/20/solid";
import UploadCSV from "../Enrich/UploadCSV";
import EditFileName from "../Enrich/EditFileName";
import { getEnrichmentIntegrations } from "../../../apis/workspace/integrations";
import Sortable from "sortablejs";
import NavAnchor from "../../Tags/NavAnchor";
import { Switch } from "@headlessui/react";
import { Button } from "../../ui/button";
import { Info, LucideUploadCloud, PencilLineIcon, UploadCloud } from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../ui/breadcrumb";
import { InfoCircledIcon, Pencil1Icon, Pencil2Icon } from "@radix-ui/react-icons";
import { Badge } from "../../ui/badge";
import TooltipWrapper from "../../TooltipWrapper";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
import AlertTag from "../../Alerts/Alert";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function EnrichList() {
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};

  const [enrichmentList, setEnrichmentList] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  // get enrichment list
  const getEnrichmentListFn = useQuery(
    "get_enrichment_list",
    () => {
      return getEnrichmentList({
        query: filterQuery,
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        setEnrichmentList(data);
        setCSVResponse(null);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetEnrichmentList = () => {
    getEnrichmentListFn.refetch();
  };

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetEnrichmentList();
    }
    return () => setFilterQuery("");
  }, [activeWorkspace, filterQuery]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetSort = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => a.order - b.order);
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // enrich column api

  const enrichCSVMapColumnsFn = useMutation("enrich_columns", enrichCSVMapColumns, {
    onSuccess(data, variables, context) {
      //console.log(data);
      toast.success(data.message);
      queryClient.invalidateQueries("get_enrichment_list");
      setEnrichmentTypeValue("Email");
      setEnrichmentType(false);
      setShowIntegrations(false);
    },
  });

  // upload csv

  const { isOpen, openModal, closeModal } = useModal(false);
  const { isOpen: isOpenMap, openModal: openModalMap, closeModal: closeModalMap } = useModal();

  const [csvResponse, setCSVResponse] = useState(null);

  const getUploadCSVResponse = (response) => {
    setCSVResponse(response);
  };

  const [predictedMapping, setPredictedMapping] = useState(null);
  const predictMapping = (header, sampleRecord, mappingColumns) => {
    const result = {};

    for (let i = 0; i < header.length; i++) {
      const headerValue = header[i].trim();
      let headerKey = null;
      let headerKeyIndex = null;

      for (const key in mappingColumns) {
        if (mappingColumns[key].toLowerCase().includes(headerValue.toLowerCase())) {
          headerKey = key;
          headerKeyIndex = i;
          break;
        }
      }

      if (headerKey) {
        result[headerKey] = {
          value: sampleRecord[i],
          header_key: headerValue,
          header_key_index: headerKeyIndex,
        };
      } else {
        result[headerValue] = sampleRecord[i];
      }
    }
    setPredictedMapping(result);
  };

  useEffect(() => {
    if (csvResponse) {
      predictMapping(csvResponse.header, csvResponse.sample_record, csvResponse.mapping_columns);
    }
  }, [csvResponse]);

  const [newMappingOfCSVCols, setNewMappingOfCSVCols] = useState();

  const extractKeyIndices = (result) => {
    const keyIndices = {};
    for (const key in result) {
      if (result[key].header_key_index !== undefined) {
        keyIndices[key] = result[key].header_key_index;
      }
    }
    return keyIndices;
  };
  const [showHide, setShowHide] = useState(true);
  const [overRideMapColumn, setOverRideMapColumn] = useState({
    oldkey: null,
    newkey: null,
    index: null,
  });
  const handleChangeCSVColumn = (index, key, confirm = false) => {
    const updatedMapping = { ...newMappingOfCSVCols };

    // Check if the provided index already exists in updatedMapping
    const existingKey = Object.keys(updatedMapping).find((k) => updatedMapping[k] === +index);

    if (!confirm && index !== "") {
      if (existingKey) {
        setOverRideMapColumn({
          newkey: key,
          oldkey: existingKey,
          index: index,
        });
        openModalMap();
        return;
      }
    }
    if (confirm) {
      setOverRideMapColumn({
        oldkey: null,
        newkey: null,
        index: null,
      });
      closeModalMap();
      delete updatedMapping[existingKey];
    }

    if (index === "") {
      delete updatedMapping[key];
    } else {
      updatedMapping[key] = +index;
    }
    setNewMappingOfCSVCols(updatedMapping);
  };

  useEffect(() => {
    setNewMappingOfCSVCols(extractKeyIndices(predictedMapping));
  }, [predictedMapping]);

  const handleEnrichCSVMapColumns = () => {
    const payload = {
      listId: csvResponse.id,
      data: {
        mapping: newMappingOfCSVCols,
        enrichment_mode: enrichmentTypeValue,
      },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    };
    if(csvResponse && csvResponse?.enrichment_type === 'Company') {
        delete payload.data.enrichment_mode
    }

    // Conditionally add integrationConfigPriorityList to the payload
    if (integrationConfigPriorityList && integrationConfigPriorityList.length > 0) {
      payload.data.integration_config_priority_list = integrationConfigPriorityList;
    }

    enrichCSVMapColumnsFn.mutate(payload);
  };

  // delete enrichment list
  const [currentEnrichmentId, setCurrentEnrichmentId] = useState("");
  const deleteEnrichmentFn = useMutation("delete_enrich", deleteEnrichment, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_enrichment_list");
    },
  });
  const handleDeleteEnrichment = () => {
    deleteEnrichmentFn.mutate({
      listId: currentEnrichmentId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // download enrich list csv - summarized
  const downloadSummarizedEnrichListCSVFn = useMutation("download_enrich", downloadSummarizedEnrichListCSV, {
    onSuccess(data, variables, context) {
      toast.success("CSV downloaded.");
      queryClient.invalidateQueries("get_enrichment_list");
    },
  });
  const handleDownloadSummarizedEnrichListCSV = (item) => {
    setCurrentEnrichmentId(item.id);
    downloadSummarizedEnrichListCSVFn.mutate(
      {
        listId: item.id,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      },
      {
        onSuccess: (data) => {
          // Sanitize the item name to be used as the file name
          const sanitizedFileName = sanitizeFilename("Summarized-" + item.name) + ".csv";

          // Create a URL for the blob
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          // Create a temporary anchor tag
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", sanitizedFileName); // Set the sanitized file name for download
          document.body.appendChild(fileLink);

          // Programmatically click the link to start download
          fileLink.click();

          // Clean up
          fileLink.remove();
          window.URL.revokeObjectURL(fileURL);
        },
      }
    );
  };

  // download enrich list csv
  const downloadEnrichListCSVFn = useMutation("download_enrich", downloadEnrichListCSV, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_enrichment_list");
    },
  });
  const handleDownloadEnrichListCSV = (item) => {
    setCurrentEnrichmentId(item.id);
    downloadEnrichListCSVFn.mutate(
      {
        listId: item.id,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      },
      {
        onSuccess: (data) => {
          // Sanitize the item name to be used as the file name
          const sanitizedFileName = sanitizeFilename(item.name) + ".csv";

          // Create a URL for the blob
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          // Create a temporary anchor tag
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", sanitizedFileName); // Set the sanitized file name for download
          document.body.appendChild(fileLink);

          // Programmatically click the link to start download
          fileLink.click();

          // Clean up
          fileLink.remove();
          window.URL.revokeObjectURL(fileURL);
        },
      }
    );
  };

  function sanitizeFilename(name) {
    // Remove invalid characters
    const invalidChars = /[\\/:*?"<>|\x00-\x1F\x80-\x9F]/g; // Regex for invalid characters
    let sanitized = name.replace(invalidChars, "");

    // Prepend 'Enriched-' to the filename
    sanitized = "Enriched-" + sanitized;

    // Truncate the filename if it's too long (200 characters is a common maximum)
    const maxLength = 200;
    sanitized = sanitized.substring(0, maxLength);

    // Avoid filenames that are reserved in Windows
    const reservedNames = [
      "CON",
      "PRN",
      "AUX",
      "NUL",
      "COM1",
      "COM2",
      "COM3",
      "COM4",
      "COM5",
      "COM6",
      "COM7",
      "COM8",
      "COM9",
      "LPT1",
      "LPT2",
      "LPT3",
      "LPT4",
      "LPT5",
      "LPT6",
      "LPT7",
      "LPT8",
      "LPT9",
    ];
    if (reservedNames.includes(sanitized.toUpperCase())) {
      sanitized = "_" + sanitized;
    }

    // Ensure the filename is not empty
    if (sanitized.trim() === "") {
      sanitized = "unnamed_file";
    }

    return sanitized;
  }

  // handle open confirm
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteEnrichment();
    }
  };

  // edit name of enrichment
  const [showForm, setShowForm] = useState(false);

  // trim .csv

  const trimCsvExtension = (filename) => {
    // Check if the filename ends with ".csv" (case-insensitive)
    if (filename.toLowerCase().endsWith(".csv")) {
      // Remove the ".csv" extension
      return filename.slice(0, -4);
    }

    // If the filename doesn't end with ".csv", return it as is
    return filename;
  };
  // is valid linkedin url
  const isLinkedInURL = (url) => {
    const cleanedUrl = url.replace(/[/,]$/, "");
    const pattern = /^https?:\/\/(www\.)?linkedin\.com\/.+/i;
    return pattern.test(cleanedUrl);
  };

  // check enrichment type
  const [enrichmentType, setEnrichmentType] = useState(false);
  const [enrichmentTypeValue, setEnrichmentTypeValue] = useState("Email");

  const [logos, setLogos] = useState({});
  const [noIntegration, setNoIntegration] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [integrationConfigPriorityList, setIntegrationConfigPriorityList] = useState([]);
  const [activeIntegrations, setActiveIntegrations] = useState([]);

  const { refetch: refetchIntegrations } = useQuery(
    ["get_integrations", activeWorkspace?.id],
    () =>
      getEnrichmentIntegrations({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      }),
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      staleTime: Infinity, // never mark as stale
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      onSuccess: (data) => {
        //const installedIntegrations = data.installed || [];
        const installedIntegrations = (data.installed || []).filter((integration) =>
          integration.integration?.capabilities?.includes("csv_contact_enrichment")
        );
        setIntegrations(installedIntegrations);
        setActiveIntegrations(installedIntegrations.map((integration) => integration.id));
        if (data && data.available && data.available.length > 0) {
          const keylogos = data.available.reduce((acc, curr) => {
            acc[curr.key] = curr?.logo;
            return acc;
          }, {});
          setLogos(keylogos);
        }
        if (data && data.installed && data.installed.length === 0) {
          setNoIntegration(true);
        } else {
          setNoIntegration(false);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const [showIntegrations, setShowIntegrations] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const sortableContainer = useRef(null);

  const [integrationOrder, setIntegrationOrder] = useState([]);

  useEffect(() => {
    //console.log("Sorted Priority List:", integrationOrder);
  }, [integrationOrder]);

  useEffect(() => {
    //console.log("Active Integrations List:", activeIntegrations);
  }, [activeIntegrations]);

  const handleShowIntegrationsCheckboxChange = () => {
    setActiveIntegrations(integrations.map((integration) => integration.id));
    setShowIntegrations(!showIntegrations);
  };

  const handleCheckboxChange = (integrationId, isChecked) => {
    if (isChecked) {
      // Add to the list if checked
      setActiveIntegrations((prevList) => [...prevList, integrationId]);
    } else {
      // Prevent unchecking if this is the last integration
      if (activeIntegrations.length === 1) {
        alert("Enrichment List must contain at least one integration");
        return;
      }
      // Remove from the list if unchecked
      setActiveIntegrations((prevList) => prevList.filter((id) => id !== integrationId));
    }
  };

  // enrichment priority
  const { isOpen: isOpenEnp, openModal: openModalEnp, closeModal: closeModalEnp } = useModal();

  useEffect(() => {
    if (sortableContainer.current && showIntegrations && isDataLoaded) {
      const sortable = new Sortable(sortableContainer.current, {
        animation: 150,
        onEnd: () => {
          // Update the state with the new order
          const newOrder = sortable.toArray();
          setIntegrationOrder(newOrder);
          //console.log("New order:", newOrder);
        },
        dataIdAttr: "data-id",
      });

      // Set initial order
      setIntegrationOrder(sortable.toArray());
      return () => {
        sortable.destroy();
      };
    }
  }, [showIntegrations, isDataLoaded]);

  useEffect(() => {
    if (isOpenEnp) {
      setShowIntegrations(true);
    } else {
      setShowIntegrations(false);
    }
  }, [isOpenEnp]);

  useEffect(() => {
    // Convert string IDs in integrationOrder to numbers
    const numericOrder = integrationOrder.map((id) => Number(id));

    // Filter based on activeIntegrations and ensure only numbers are included
    const filteredOrder = numericOrder.filter((id) => activeIntegrations.includes(id));

    // Update integrationConfigPriorityList with the filtered, numeric order
    setIntegrationConfigPriorityList(filteredOrder);
    //console.log("Updated integrationConfigPriorityList (numeric):", filteredOrder);
  }, [integrationOrder, activeIntegrations]);

  useEffect(() => {
    console.log("Integrations Data Loaded:", isDataLoaded);
    console.log("Number of Integrations:", integrations.length);
    console.log("Show Integrations Switch:", showIntegrations);
  }, [isDataLoaded, integrations, showIntegrations]);

  // Existing useEffect for integrations
  useEffect(() => {
    if (integrations.length > 0) {
      setIsDataLoaded(true);
    }
    return () => {
      setIsDataLoaded(false);
    };
  }, [integrations]);

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id) {
      refetchIntegrations();
    }
  }, [activeWorkspace]);

  //  active tab
  const [currentTab, setCurrentTab] = useState("people");

  const returnBadge = (status) => {
    if (status === "Cancelled" || status === "Failed") {
      return <Badge variant={"withdrawn"}>{status}</Badge>;
    } else if (status === "Completed" || status === "Enrichment Completed") {
      return <Badge>{status}</Badge>;
    } else if (
      status === "Finalizing" ||
      status === "Processing" ||
      status === "Retrying" ||
      status === "Pending"
    ) {
      return <Badge variant={"pending"}>{status}</Badge>;
    } else if (status === "No Match" || status === "Paused" || !status) {
      return <Badge variant={"outline"}>{status}</Badge>;
    } else if (status === "Verification Completed") {
      return <Badge variant={"success"}>{status}</Badge>;
    } else {
      return <Badge variant={"outline"}>{status}</Badge>;
    }
  };

  return (
    <>
      <div className="px-4 mobile:px-0">
        {!csvResponse ? (
          enrichmentList &&
          enrichmentList.data &&
          (enrichmentList.data.length > 0 ||
            (enrichmentList.data.length === 0 && !isEmptyObject(filters))) ? (
            <div>
              <div className="sm:flex sm:items-center w-full">
                <div className="sm:flex-auto">
                  <h2 className="text-lg font-semibold leading-7 text-text-900">CSV Enrichment</h2>
                  <p className="text-text-600 mt-1">
                    Enrich a list of People or Companies with a wide range of data points
                  </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <Button
                    onClick={() => {
                      openModal();
                      captureEvent("BLE_UploadCSVBtn");
                    }}
                    type="button"
                  >
                    <LucideUploadCloud className="h-5 w-5 mr-2" aria-hidden="true" />
                    Upload CSV
                  </Button>
                </div>
              </div>
              {/* <div className="border border-border rounded-[--radius] p-[2px] flex bg-smokegray gap-x-1 mt-6">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentTab("people");
                  }}
                  variant={currentTab === "people" ? "white" : "tab_btn"}
                  className="h-9"
                >
                  People
                  {enrichmentList && enrichmentList.pagination && enrichmentList.pagination.total ? (
                    <Badge className="ml-2">{enrichmentList.pagination.total}</Badge>
                  ) : null}
                </Button>
                <Button
                  className="h-9"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentTab("company");
                  }}
                  variant={currentTab === "company" ? "white" : "tab_btn"}
                >
                  Companies
                </Button>
              </div> */}
              <div className="border-b border-border my-6"></div>
              {enrichmentList &&
                enrichmentList.data &&
                (enrichmentList.data.length > 0 || !isEmptyObject(filters)) && (
                  <div className="mt-7 flex justify-between items-center">
                    <div className="space-x-3 inline-flex items-center">
                      {enrichmentList && enrichmentList.filterMetadata && (
                        <>
                          {/* <span>Filters:</span> */}
                          <Filter
                            filterMetadata={enrichmentList.filterMetadata}
                            getFiltersObject={getFiltersObject}
                          />
                        </>
                      )}
                    </div>
                    <div className="space-x-3">
                      {enrichmentList.columnMetadata && (
                        <EditColumn columnMetadata={enrichmentList.columnMetadata} getColumns={getColumns} />
                      )}
                    </div>
                  </div>
                )}
              <div>
                {enrichmentList &&
                  enrichmentList.data &&
                  enrichmentList.data.length > 0 &&
                  getEnrichmentListFn.isSuccess && (
                    <div className="mt-8 flow-root">
                      <div className=" ">
                        <div className=" ">
                          <div className="overflow-hidden overflow-x-auto border border-border rounded-[--radius]">
                            <table className="min-w-full divide-y divide-border">
                              <thead className="bg-background">
                                <tr>
                                  {returnArray(sortedVisibleColumns).map((column, index) => {
                                    return (
                                      <th
                                        key={index}
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-xs font-medium text-text-600 ${
                                          column.key === "name" ? "w-1/4" : ""
                                        } ${column.sortable ? "cursor-pointer" : ""}`}
                                        onClick={() =>
                                          column.sortable ? handleSetSort(column.key) : void 0
                                        }
                                      >
                                        <span className={"inline-flex items-center"}>
                                          {column.display_name}
                                          {column.sortable && (
                                            <>
                                              {sort !== column.key ? (
                                                <ArrowDownIcon width={12} className="inline-block ml-1" />
                                              ) : (
                                                <ArrowUpIcon width={12} className="inline-block ml-1" />
                                              )}
                                            </>
                                          )}
                                        </span>
                                      </th>
                                    )
                                  })}
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-xs font-medium text-text-600 sticky right-0 bg-background"
                                    // style={{
                                    //   boxShadow: "inset 3px 0px 3px #e7e7e7",
                                    // }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-border bg-background">
                                {enrichmentList &&
                                  enrichmentList.data &&
                                  enrichmentList.data.length > 0 &&
                                  enrichmentList.data.map((item, index) => (
                                    <tr key={item.id} className="group">
                                      {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                                        const colKey =
                                          columns &&
                                          Object.keys(columns).find(
                                            (key) => columns[key].order === column.order
                                          );

                                        if (colKey === "created_by") {
                                          return (
                                            <td
                                              key={colIndex}
                                              className="whitespace-nowrap px-3 py-4 text-sm text-text-600"
                                            >
                                              <div className="flex items-center">
                                                <div className="h-9 w-9 flex-shrink-0">
                                                  <Image
                                                    nobaseurl
                                                    className="h-9 w-9 rounded-full"
                                                    src={item[colKey].profile_photo_url}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="ml-4">
                                                  <div className="font-medium text-text-900">
                                                    {item[colKey].name}
                                                  </div>
                                                  <div className="mt-0 ">{item[colKey].email}</div>
                                                </div>
                                              </div>
                                            </td>
                                          );
                                        }
                                        if (colKey === "name") {
                                          return (
                                            <td
                                              key={colIndex}
                                              className="whitespace-nowrap px-3 py-4 text-sm text-text-900 font-medium w-1/4"
                                              onClick={() => {
                                                captureEvent("BLE_List_ListNameBtn");
                                              }}
                                            >
                                              <div>
                                                {showForm && currentEnrichmentId === item.id ? (
                                                  <EditFileName
                                                    name={trimCsvExtension(item.name)}
                                                    workspaceId={activeWorkspace.id}
                                                    listId={item.id}
                                                    hideForm={() => {
                                                      setShowForm(false);
                                                    }}
                                                  />
                                                ) : (
                                                  <span className="inline-flex items-center">
                                                    <NavAnchor
                                                      to={`/enrich/list/${item.id}`}
                                                      className={"text-brand-700"}
                                                    >
                                                      {trimCsvExtension(item.name)}
                                                    </NavAnchor>
                                                    <PencilIcon
                                                      data-tooltip-id="in_table_actions"
                                                      data-tooltip-content="Edit Enrichment List name"
                                                      className=" invisible group-hover:visible group-hover:cursor-pointer  ml-2"
                                                      width={14}
                                                      height={14}
                                                      onClick={() => {
                                                        setCurrentEnrichmentId(item.id);
                                                        setShowForm(true);
                                                        captureEvent("BLE_List_EditEnrichmentListNameBtn");
                                                      }}
                                                    />
                                                  </span>
                                                )}
                                              </div>
                                            </td>
                                          );
                                        }

                                        if (colKey === "total_records") {
                                          return (
                                            <td
                                              key={colIndex}
                                              className={`whitespace-nowrap px-3 py-4 text-sm  ${
                                                item.total_records !== 0 ? "text-text-600" : "text-text-600"
                                              }`}
                                            >
                                              {item.total_records === 0 ? (
                                                item.total_records
                                              ) : (
                                                <NavAnchor to={`/enrich/list/${item.id}`}>
                                                  {item.total_records}
                                                </NavAnchor>
                                              )}
                                            </td>
                                          );
                                        }

                                        if (colKey === "created_at") {
                                          return (
                                            <td
                                              key={colIndex}
                                              className="whitespace-nowrap px-3 py-4 text-sm text-text-600"
                                            >
                                              <PrintDate date={item.created_at} />
                                            </td>
                                          );
                                        }
 
                                        if (colKey === "status") {
                                          return (
                                            <td
                                              key={colIndex}
                                              className="whitespace-nowrap px-3 py-4 text-sm text-text-600"
                                            >
                                              {returnBadge(item.status)}
                                            </td>
                                          );
                                        }
                                        if (colKey === "enrichment_mode") {
                                          return (
                                            <td
                                              key={colIndex}
                                              className="whitespace-nowrap px-3 py-4 text-sm text-text-600"
                                            >
                                              {item.enrichment_mode === "Email" && (
                                                <Image src={"/images/icons/untitledui/email-2.svg"} />
                                              )}
                                              {item.enrichment_mode === "Phone" && (
                                                <Image src={"/images/icons/untitledui/phone-2.svg"} />
                                              )}
                                              {(item.enrichment_mode === "Email and Phone" ||
                                                item.enrichment_mode === "Email or Phone") && (
                                                <TooltipWrapper
                                                  className="inline-flex space-x-2 items-center"
                                                  content={item.enrichment_mode}
                                                >
                                                  <Image src={"/images/icons/untitledui/phone-2.svg"} />
                                                  <Image src={"/images/icons/untitledui/email-2.svg"} />
                                                </TooltipWrapper>
                                              )}
                                            </td>
                                          );
                                        }

                                        return (
                                          <td
                                            key={colIndex}
                                            className="whitespace-nowrap px-3 py-4 text-sm text-text-600"
                                          >
                                            {item[colKey] ? item[colKey] : "-"}
                                          </td>
                                        );
                                      })}
                                      <td
                                        className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 sticky right-0 bg-background"
                                        // style={{
                                        //   boxShadow: "inset 3px 0px 3px #e7e7e7",
                                        // }}
                                      >
                                        {item.status === "Completed" && (
                                          <span className="space-x-1 mr-1">
                                            <Button
                                              data-tooltip-id="in_table_actions"
                                              data-tooltip-content="Download Summarized CSV"
                                              disabled={
                                                downloadSummarizedEnrichListCSVFn.isLoading &&
                                                currentEnrichmentId === item.id
                                              }
                                              onClick={() => {
                                                handleDownloadSummarizedEnrichListCSV(item);
                                                captureEvent("BLE_List_DownloadBtn");
                                              }}
                                              variant="ghost"
                                            >
                                              <ArrowDownTrayIcon width={16} height={16} />
                                              {downloadSummarizedEnrichListCSVFn.isLoading &&
                                                currentEnrichmentId === item.id && (
                                                  <div className="loader_circle ml-2"></div>
                                                )}
                                            </Button>

                                            {/* <Button
                                              data-tooltip-id="in_table_actions"
                                              data-tooltip-content="Download Full CSV"
                                              disabled={
                                                downloadEnrichListCSVFn.isLoading &&
                                                currentEnrichmentId === item.id
                                              }
                                              onClick={() => {
                                                handleDownloadEnrichListCSV(item);
                                              }}
                                              variant="ghost"
                                            >
                                              <ArrowDownTrayIcon width={16} height={16} />
                                              {downloadEnrichListCSVFn.isLoading &&
                                                currentEnrichmentId === item.id && (
                                                  <div className="loader_circle ml-2"></div>
                                                )}
                                            </Button> */}
                                          </span>
                                        )}
                                        <Button
                                          data-tooltip-id="in_table_actions"
                                          data-tooltip-content="Delete List"
                                          disabled={
                                            deleteEnrichmentFn.isLoading && currentEnrichmentId === item.id
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentEnrichmentId(item.id);
                                            setOpenConfirmModal(true);
                                            setOpenConfirmModalType("delete");
                                            captureEvent("BLE_List_DeleteBtn");
                                          }}
                                          variant="ghost"
                                        >
                                          <Image
                                            src="/images/icons/untitledui/trash.svg"
                                            width="16"
                                            height="16"
                                            className="inline-block"
                                          />
                                          {deleteEnrichmentFn.isLoading &&
                                            currentEnrichmentId === item.id && (
                                              <div className="loader_circle ml-2"></div>
                                            )}
                                        </Button>
                                        <Tooltip id="in_table_actions" />
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {getEnrichmentListFn.isSuccess &&
                  enrichmentList &&
                  enrichmentList.data &&
                  enrichmentList.data.length === 0 &&
                  isEmptyObject(filters) && <NoData />}
                {getEnrichmentListFn.isSuccess &&
                  enrichmentList &&
                  enrichmentList.data &&
                  enrichmentList.data.length === 0 &&
                  !isEmptyObject(filters) && (
                    <div className="p-10 border rounded-lg mt-8">
                      <NoData filterMode={true} />
                    </div>
                  )}
                <div className="flex items-center justify-between py-3">
                  <div className="flex-1">
                    {enrichmentList && enrichmentList.pagination && enrichmentList.pagination.total > 0 && (
                      <Pagination data={enrichmentList.pagination} getPageNumber={getPageNumber} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            enrichmentList &&
            enrichmentList.data &&
            enrichmentList.data.length === 0 &&
            !getEnrichmentListFn.isFetching && (
              <>
              {noIntegration && (
                <AlertTag
                  heading={"Data providers not connected"}
                  description={`You do not have any data providers connected to perform people enrichment. Connect at least one data provider to perform enrichment`}
                  dismiss={false}
                  cta={
                    <NavAnchor to={"/settings/integrations"}>
                      <Button className="h-9">Connect a Provider</Button>
                    </NavAnchor>
                  }
                />
              )}
                <NoData
                  icon={
                    <div className="rounded-full inline-block bg-smoke">
                      <div className={"bg-brand-100 text-brand-700 rounded-full inline-block p-4"}>
                        <Image src="/images/icons/untitledui/users-purple.svg" width="20" height="20" />
                      </div>
                    </div>
                  }
                  heading={"CSV Enrichment"}
                  description={"Enrich a collection of People or Companies with a wide range of data points"}
                  filterMode={false}
                >
                  <Button
                    onClick={() => {
                      openModal();
                      captureEvent("BLE_List_UploadCSVBtn");
                    }}
                    type="button"
                    disabled={noIntegration ? true : false}
                  >
                    <LucideUploadCloud className="h-5 w-5 mr-2" aria-hidden="true" />
                    Upload CSV
                  </Button>
                </NoData>
              </>
            )
          )
        ) : (
          <>
            {!enrichmentType ? (
              <div className="">
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <NavAnchor to={`/`}>
                        <Image src="/images/icons/untitledui/home.svg" width="16" height="16" />
                      </NavAnchor>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <NavAnchor to={`/enrich/list/`}>CSV Enrichment</NavAnchor>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>{csvResponse && csvResponse?.enrichment_type}</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
                <div className="flex items-center justify-between mt-6">
                  <div>
                    <h2 className="text-lg font-semibold leading-7 text-text-900">Lets map your columns!</h2>
                    <p className="text-text-600 mt-1">
                      Select the columns in your CSV file that match the properties.
                    </p>
                  </div>
                  <div className="inline-flex items-center space-x-4">
                    <Image src="/images/icons/untitledui/csv-icon-2.svg" className="no-invert" />
                    <div>
                      <p className="text-text-700 font-semibold">{csvResponse.name}</p>
                    </div>
                  </div>
                </div>
                <p className="text-text-600 mt-3 flex items-center">
                  <InfoCircledIcon className="mr-2" /> Map at least one of the following:{" "}
                  {csvResponse && csvResponse?.enrichment_type === 'People' && <b>Linkedin profile URL, Email or Name + Company name/domain</b>}
                  {csvResponse && csvResponse?.enrichment_type === 'Company' && <b>Linkedin URL, Company name or Company domain</b>}
                </p>

                <div className="border-b border-border my-5"></div>

                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden border border-border rounded-[--radius]">
                        <table className="min-w-full divide-y divide-border" style={{ tableLayout: "fixed" }}>
                          <thead className="bg-background">
                            <tr>
                              <th
                                className="px-3 py-3.5 text-left text-xs font-medium text-text-600"
                                style={{ width: "30%" }}
                              >
                                Property
                              </th>
                              <th
                                className="px-3 py-3.5 text-left text-xs font-medium text-text-600"
                                style={{ width: "40%" }}
                              >
                                Your CSV column
                              </th>
                              <th
                                className="px-3 py-3.5 text-left text-xs font-medium text-text-600"
                                style={{ width: "30%" }}
                              >
                                Preview
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-border bg-background">
                            {Object.entries(csvResponse.mapping_columns).map(([key]) => {
                              if(csvResponse?.enrichment_type) {
                                if(!['linkedin_url', 'company_name', 'company_domain'].includes(key) && csvResponse?.enrichment_type === 'Company' ) {
                                  return;
                                }
                                return <tr>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-text-600">
                                  <div className="inline-flex items-center">
                                    {csvResponse.mapping_columns[key]}{" "}
                                    {key === "linkedin_url" && <Badge className={"ml-2"}>
                                    Recommended
                                      </Badge>}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-text-600">
                                  {showHide && (
                                    <select
                                      name=""
                                      id=""
                                      className={`h-11 px-4 py-3 text-sm w-9/12 rounded-[--radius] border border-border bg-background text-text-900 ${
                                        predictedMapping &&
                                        predictedMapping[key] &&
                                        predictedMapping[key].value &&
                                        predictedMapping[key].value
                                          ? "border border-green-500"
                                          : ""
                                      }
                                    
                                    ${
                                      "linkedin_url" === `${[key]}` &&
                                      csvResponse &&
                                      csvResponse.sample_record &&
                                      newMappingOfCSVCols &&
                                      newMappingOfCSVCols[key] >= 0 &&
                                      csvResponse.sample_record[newMappingOfCSVCols[key]]
                                        ? !isLinkedInURL(
                                            csvResponse.sample_record[newMappingOfCSVCols[key]]
                                          ) && "border-red-500"
                                        : ""
                                    }
                                    
                                    `}
                                      onChange={(e) => {
                                        handleChangeCSVColumn(e.target.value, key);
                                        captureEvent("BLE_CSVEnrichmentMap_SelectColumnDropdown", {
                                          csv_column: key,
                                        });
                                      }}
                                      onClick={() => {
                                        captureEvent("BLE_CSVEnrichmentMap_SelectColumnDropdown");
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {csvResponse.header.map((item, index) => (
                                        <option
                                          selected={
                                            csvResponse.sample_record &&
                                            newMappingOfCSVCols &&
                                            newMappingOfCSVCols[key] >= 0 &&
                                            csvResponse.header[newMappingOfCSVCols[key]].trim() ===
                                              item.trim()
                                          }
                                          value={index}
                                        >
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                  {"linkedin_url" === `${[key]}` &&
                                    csvResponse &&
                                    csvResponse.sample_record &&
                                    newMappingOfCSVCols &&
                                    newMappingOfCSVCols[key] >= 0 &&
                                    csvResponse.sample_record[newMappingOfCSVCols[key]] && (
                                      <>
                                        {isLinkedInURL(
                                          csvResponse.sample_record[newMappingOfCSVCols[key]]
                                        ) ? (
                                          <></>
                                        ) : (
                                          <>
                                          {csvResponse.enrichment_type === 'People' && <span className="text-red-500 block text-[12px]">
                                            One or more LinkedIn profiles URLs are invalid. <br /> Make sure
                                            they follow this format: “https://www.linkedin.com/in/profilename.
                                          </span>}
                                          {csvResponse.enrichment_type === 'Company' && <span className="text-red-500 block text-[12px]">
                                            One or more LinkedIn company URLs are invalid. <br /> Make sure
                                            they follow this format: “https://www.linkedin.com/company/apple.
                                          </span>}
                                          </>
                                        )}
                                      </>
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-text-600">
                                  {csvResponse &&
                                    csvResponse.sample_record &&
                                    newMappingOfCSVCols &&
                                    newMappingOfCSVCols[key] >= 0 &&
                                    csvResponse.sample_record[newMappingOfCSVCols[key]]}
                                </td>
                              </tr>
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                 {csvResponse && csvResponse?.enrichment_type === 'People' && <Button
                    disabled={newMappingOfCSVCols && Object.keys(newMappingOfCSVCols).length === 0}
                    onClick={() => { 
                      setEnrichmentType(true);
                      captureEvent("BLE_CSVEnrichmentMap_NextBtn");
                    }}
                  >
                    Next
                  </Button>}
                 {csvResponse && csvResponse?.enrichment_type === 'Company' && <Button
                    disabled={newMappingOfCSVCols && Object.keys(newMappingOfCSVCols).length === 0}
                    onClick={() => { 
                      handleEnrichCSVMapColumns()
                    }}
                  >
                   Start
                  </Button>}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <Breadcrumb>
                    <BreadcrumbList>
                      <BreadcrumbItem>
                        <NavAnchor to={`/`}>
                          <Image src="/images/icons/untitledui/home.svg" width="16" height="16" />
                        </NavAnchor>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <NavAnchor to={`/enrich/list/`}>CSV Enrichment</NavAnchor>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <BreadcrumbPage>People</BreadcrumbPage>
                      </BreadcrumbItem>
                    </BreadcrumbList>
                  </Breadcrumb>
                  <div className="flex items-center justify-between mt-6">
                    <div>
                      <h2 className="text-lg font-semibold leading-7 text-text-900">
                        What would you like to enrich?
                      </h2>
                      <p className="text-text-600 mt-1">
                        Credits will be consumed only for contacts we match in your file
                      </p>
                    </div>
                    {isDataLoaded && integrations.length > 1 && (
                      <div className="inline-flex items-center space-x-4">
                        <Button
                          variant="purple_outline"
                          onClick={() => {
                            setActiveIntegrations(integrations.map((integration) => integration.id));
                            openModalEnp();
                            captureEvent("BLE_CSVEnrichmentContacts_CustomizeProvidersAndPriorityBtn");
                          }}
                        >
                          <PencilLineIcon width={20} height={20} className="mr-2" /> Customize providers and
                          priority
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="border-b border-border my-5"></div>

                  <div className="-mx-4 mt-10 flex mobile:block">
                    <div className="px-4 w-3/12 mobile:w-full mobile:mt-3">
                      <div
                        className={`border rounded-xl p-7 w-full flex flex-col items-center min-h-[283px] cursor-pointer ${
                          enrichmentTypeValue === "Email or Phone"
                            ? "border-brand-600 border-2"
                            : "border-border"
                        }`}
                        onClick={() => {
                          setEnrichmentTypeValue("Email or Phone");
                          captureEvent("BLE_CSVEnrichmentContacts_AnyAvailableDataBtn");
                        }}
                      >
                        <Image
                          src={"/images/icons/type_emailorphone.png"}
                          className="rounded no-invert"
                          width={100}
                        />
                        <h3 className="mt-5 text-lg font-semibold leading-6 text-text-900">
                          Any Available Data
                        </h3>
                        <p className="text-xs text-text-600 leading-6 mt-2 text-center">
                          Any data we get will be added to your file which may include email, phone, company
                          info etc.
                        </p>
                      </div>
                    </div>
                    <div className="px-4 w-3/12 mobile:w-full mobile:mt-3">
                      <div
                        className={`border rounded-xl p-7 w-full flex flex-col items-center min-h-[283px] cursor-pointer ${
                          enrichmentTypeValue === "Email" ? "border-brand-600 border-2" : "border-border"
                        }`}
                        onClick={() => {
                          setEnrichmentTypeValue("Email");
                          captureEvent("BLE_CSVEnrichmentContacts_EmailBtn");
                        }}
                      >
                        <Image
                          src={"/images/icons/type_email.png"}
                          className="rounded no-invert"
                          width={100}
                        />
                        <h3 className="mt-5 text-lg font-semibold leading-6 text-text-900">Email</h3>
                        <p className="text-xs text-text-600 leading-6 mt-2 text-center">
                          Must contains email addresses
                        </p>
                      </div>
                    </div>
                    <div className="px-4 w-3/12 mobile:w-full mobile:mt-3">
                      <div
                        className={`border rounded-xl p-7 w-full flex flex-col items-center min-h-[283px] cursor-pointer ${
                          enrichmentTypeValue === "Phone" ? "border-brand-600 border-2" : "border-border"
                        }`}
                        onClick={() => {
                          setEnrichmentTypeValue("Phone");
                          captureEvent("BLE_CSVEnrichmentContacts_PhoneBtn");
                        }}
                      >
                        <Image
                          src={"/images/icons/type_phone.png"}
                          className="rounded no-invert"
                          width={100}
                        />
                        <h3 className="mt-5 text-lg font-semibold leading-6 text-text-900">Phone</h3>
                        <p className="text-xs text-text-600 leading-6 mt-2 text-center">
                          Must contain phone numbers
                        </p>
                      </div>
                    </div>
                    <div className="px-4 w-3/12 mobile:w-full mobile:mt-3">
                      <div
                        className={`border rounded-xl p-7 w-full flex flex-col items-center min-h-[283px] cursor-pointer ${
                          enrichmentTypeValue === "Email and Phone"
                            ? "border-brand-600 border-2"
                            : "border-border"
                        }`}
                        onClick={() => {
                          setEnrichmentTypeValue("Email and Phone");
                          captureEvent("BLE_CSVEnrichmentContacts_EmailAndPhoneBtn");
                        }}
                      >
                        <Image
                          src={"/images/icons/type_emailandphone.png"}
                          className="rounded no-invert"
                          width={100}
                        />
                        <h3 className="mt-5 text-lg font-semibold leading-6 text-text-900">
                          Email and Phone
                        </h3>
                        <p className="text-xs text-text-600 leading-6 mt-2 text-center">
                          Must contain both email addresses and phone numbers
                        </p>
                      </div>
                    </div>
                  </div>

                  {isDataLoaded && integrations.length > 1 && (
                    <GenericModal isOpen={isOpenEnp} closeModal={closeModalEnp} drawer={true}>
                      <div className="p-6">
                        <h3 className="text-[18px] block font-semibold mb-1 text-text-900">
                          Customise providers
                        </h3>
                        <p className="text-text-600">
                          Choose which providers will be used. Click and drag to change the order.
                        </p>

                        <ul ref={sortableContainer} className="mt-6 mb-6 space-y-2">
                          {integrations.map((integration, index) => {
                            return (
                              <li
                                key={integration.id}
                                data-id={integration.id}
                                className="rounded-2xl  border-brand-600 border-2 py-3 px-4 flex justify-between items-center"
                                onClick={() => {
                                  captureEvent(
                                    "BLE_CSVEnrichmentContactsCustomizeProvidersAndPriority_ChooseBtn"
                                  );
                                }}
                              >
                                <div className="flex items-center">
                                  <input
                                    type="checkbox"
                                    checked={activeIntegrations.includes(integration.id)}
                                    onChange={(e) => {
                                      handleCheckboxChange(integration.id, e.target.checked);
                                    }}
                                    className="mr-2 h-4 w-4 rounded border-gray-300 text-brand-700 focus:ring-brand-700 dark:text-brand-200"
                                  />
                                  <span className="inline-flex items-center">
                                    {logos[integration?.integration?.key] && (
                                      <span
                                        className="svg__24 mr-2 inline-block rounded-[--radius] p-1 bg-white"
                                        dangerouslySetInnerHTML={{
                                          __html: logos[integration?.integration?.key],
                                        }}
                                      />
                                    )}
                                    <span>{integration.name}</span>
                                  </span>
                                </div>

                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 6.5C12.5523 6.5 13 6.05228 13 5.5C13 4.94772 12.5523 4.5 12 4.5C11.4477 4.5 11 4.94772 11 5.5C11 6.05228 11.4477 6.5 12 6.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 13.5C12.5523 13.5 13 13.0523 13 12.5C13 11.9477 12.5523 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5C11 13.0523 11.4477 13.5 12 13.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 20.5C12.5523 20.5 13 20.0523 13 19.5C13 18.9477 12.5523 18.5 12 18.5C11.4477 18.5 11 18.9477 11 19.5C11 20.0523 11.4477 20.5 12 20.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19 6.5C19.5523 6.5 20 6.05228 20 5.5C20 4.94772 19.5523 4.5 19 4.5C18.4477 4.5 18 4.94772 18 5.5C18 6.05228 18.4477 6.5 19 6.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19 13.5C19.5523 13.5 20 13.0523 20 12.5C20 11.9477 19.5523 11.5 19 11.5C18.4477 11.5 18 11.9477 18 12.5C18 13.0523 18.4477 13.5 19 13.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19 20.5C19.5523 20.5 20 20.0523 20 19.5C20 18.9477 19.5523 18.5 19 18.5C18.4477 18.5 18 18.9477 18 19.5C18 20.0523 18.4477 20.5 19 20.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5 6.5C5.55228 6.5 6 6.05228 6 5.5C6 4.94772 5.55228 4.5 5 4.5C4.44772 4.5 4 4.94772 4 5.5C4 6.05228 4.44772 6.5 5 6.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5 13.5C5.55228 13.5 6 13.0523 6 12.5C6 11.9477 5.55228 11.5 5 11.5C4.44772 11.5 4 11.9477 4 12.5C4 13.0523 4.44772 13.5 5 13.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5 20.5C5.55228 20.5 6 20.0523 6 19.5C6 18.9477 5.55228 18.5 5 18.5C4.44772 18.5 4 18.9477 4 19.5C4 20.0523 4.44772 20.5 5 20.5Z"
                                    stroke="#344054"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </GenericModal>
                  )}

                  <div className="mt-4 flex items-center space-x-3">
                    <Button
                      onClick={() => {
                        setEnrichmentType(false);
                        setShowIntegrations(false);
                        captureEvent("BLE_CSVEnrichmentContacts_BackBtn");
                      }}
                      variant="outline"
                    >
                      Back
                    </Button>
                    <Button
                      disabled={enrichCSVMapColumnsFn.isLoading}
                      onClick={() => {
                        handleEnrichCSVMapColumns();
                        captureEvent("BLE_CSVEnrichmentContacts_ConfirmAndStartBtn");
                      }}
                    >
                      Confirm and start
                      {enrichCSVMapColumnsFn.isLoading && <div className="loader_circle ml-2"></div>}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {getEnrichmentListFn.isLoading && <TableLoader />}
      <GenericModal isOpen={isOpen} closeModal={closeModal}>
        <UploadCSV closeModal={closeModal} getResponse={getUploadCSVResponse} />
      </GenericModal>
      <ConfirmModal
        openModal={openConfirmModal}
        modalType={openConfirmModalType}
        confirmModalState={confirmModalState}
      />
      <GenericModal isOpen={isOpenMap} closeModal={closeModalMap}>
        <div className="mt-3 text-center   sm:mt-0 sm:text-left max-w-[500px]">
          <h3 className="text-xl  font-bold leading-6 text-gray-900">This column has already been mapped</h3>
          <div className="mt-5">
            <p className="text-sm text-gray-500">
              Would you like to remove your selection from{" "}
              <span className="font-bold text-black">
                "
                {csvResponse &&
                  csvResponse.mapping_columns &&
                  overRideMapColumn &&
                  overRideMapColumn.oldkey &&
                  csvResponse.mapping_columns[overRideMapColumn.oldkey]}
                "
              </span>{" "}
              property and map it to{" "}
              <span className="font-bold text-black">
                “
                {csvResponse &&
                  csvResponse.mapping_columns &&
                  overRideMapColumn &&
                  overRideMapColumn.newkey &&
                  csvResponse.mapping_columns[overRideMapColumn.newkey]}
                “
              </span>{" "}
              property?
            </p>
          </div>
          <div className="mt-5 flex items-center space-x-3">
            <Button
              variant="outline"
              onClick={() => {
                setShowHide(false);
                setTimeout(() => {
                  setShowHide(true);
                }, 10);
                closeModalMap();
                setOverRideMapColumn({
                  newkey: null,
                  oldkey: null,
                  index: null,
                });
              }}
            >
              No
            </Button>
            <Button
              type="button"
              onClick={() => {
                handleChangeCSVColumn(overRideMapColumn?.index, overRideMapColumn?.newkey, true);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </GenericModal>
    </>
  );
}
