import { useCallback, useEffect, useRef, useState } from "react";
import useRoleCheck from "../../../hooks/useRoleCheck";
import { Button } from "../../ui/button";
import WorkspaceForm from "../NewWorkspace/WorkspaceForm";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import { Label } from "../../ui/label";
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import { updateDiscovery } from "../../../apis/user";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import Text from "../../Tags/Text";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent"
import Input from "../../Tags/Input";
export default function General() {
  const queryClient = useQueryClient();
  const captureEvent = useCaptureEvent();
  const { activeWorkspace } = useRoleCheck();

  const [visibility, setVisibility] = useState("");

  const [domains, setDomains] = useState([]);
  const [domainsValid, setDomainsValid] = useState(true);
  const tagify = useRef();
  const onChange = (e) => {
    const domainArray = e.detail.tagify.value.map((each) => {
      return each.value;
    });
    setDomains(domainArray);

    setDomainsValid(tagify.current.getTagElms().length === tagify.current.value.length);
  };
  // Tagify settings object
  const baseTagifySettings = {
    placeholder: "Enter domains",
    dropdown: {
      enabled: 0, // a;ways show suggestions dropdown
    },
    delimiters: ",| ",
    pattern: /^(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+)([a-z]{2,63})$/,
    keepInvalidTags: true,
    maxTags: 3,
  };

  const onTagRemove = (e) => {
    setDomainsValid(tagify.current.getTagElms().length === tagify.current.value.length);
    // TO DO
  };

  const onTagInvalid = (e) => {
    setDomainsValid(tagify.current.getTagElms().length === tagify.current.value.length);
  };

  const updateDiscoveryFn = useMutation("update_workspace", updateDiscovery, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_user");
    },
  });

  const handleUpdateDiscovery = () => {
    if (visibility !== "invite_only" && !domainsValid) {
      return;
    }
    if (visibility === "invite_only") {
      updateDiscoveryFn.mutate({
        id: activeWorkspace.id,
        visibility: visibility,
      });
    } else {
      updateDiscoveryFn.mutate({
        id: activeWorkspace.id,
        visibility: visibility,
        whitelisted_domains: domains,
      });
    }
  };

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.visibility) {
      setVisibility(activeWorkspace.visibility);
      setDomains(activeWorkspace?.whitelisted_domains);
    }
  }, [activeWorkspace]);

  const radios = [
    {
      name: "Invite only",
      value: "invite_only",
      default: true,
      description:
        "Maintain control over workspace access. New users cannot automatically join and must receive an invitation from workspace administrators.",
    },
    {
      name: "Admin Approval",
      value: "approval_required",
      description:
        "Allow email domain-based discovery of your workspace. Administrators retain the ability to approve or deny access requests from new users.",
    },
    {
      name: "Open Workspace",
      value: "open",
      description:
        "Enable frictionless email domain-based discovery and access. New users from approved domains automatically gain guest permissions without requiring invitations or approval.",
    },
  ];

  return (
    <div>
      <div className="space-y-4 relative">
        <div className="">
          <Text size="lg">Workspace Settings</Text>
          <Text size="p" className="mt-1">
            Update workspace name, slug and logo
          </Text>

          <Tabs defaultValue="general" className="w-full mt-6">
            <TabsList className="w-full">
              <TabsTrigger value="general"
               onClick={() => {
                captureEvent("SET_Workspaces_GeneralBtn");
              }}
              >
                General
              </TabsTrigger>
              <TabsTrigger value="discovery"
               onClick={() => {
                captureEvent("SET_Workspaces_DiscoveryBtn");
              }}
              >
                Discovery
                </TabsTrigger>
            </TabsList>
            {activeWorkspace && activeWorkspace.id && (
              <>
                <TabsContent value="general" className="workspace-form">
                  <WorkspaceForm
                    edit={true}
                    formData={{
                      id: activeWorkspace.id,
                      name: activeWorkspace.name,
                      slug: activeWorkspace.slug,
                      icon: activeWorkspace.icon,
                    }}
                  />
                </TabsContent>
                <TabsContent value="discovery">
                  <div className="mt-6">
                    <div className="flex gap-x-8 mobile:block">
                      <div className="w-3/12 mobile:w-full">
                        <Text size="sm" className="text-gray-700">
                          Workspace visibility
                        </Text>
                        <Text size="p">Choose the visibility level of your workspace</Text>
                      </div>
                      <div className="w-5/12 mobile:w-full mobile:mt-4">
                        <div className="mt-2">
                          <RadioGroup
                            defaultValue={activeWorkspace.visibility}
                            onValueChange={(value) => {
                              setVisibility(value);
                            }}
                          >
                            {radios &&
                              radios.length > 0 &&
                              radios.map((radio) => (
                                <div
                                  className={`flex items-start space-x-3 rounded-xl border-2 ${visibility === radio.value
                                      ? "border-brand-600"
                                      : "border-border hover:border-brand-300"
                                    } p-5 `}
                                >
                                  <div>
                                    <RadioGroupItem 
                                      onClick={() => {
                                        captureEvent("SET_Workspaces_WorkspaceVisibility",{visbility: radio.name});
                                        console.log("visibility", radio.name);
                                      }}
                                    value={radio.value} id={radio.value} />
                                  </div>
                                  <label htmlFor={radio.value}>
                                    <Text size="md" className="text-gray-dark-700 font-medium leading-tight">
                                      {radio.name}
                                      {radio.default && (
                                        <span className="text-text-600 ml-1.5 font-normal">(default)</span>
                                      )}
                                    </Text>

                                    <Text size="p" className="mt-2">
                                      {radio.description}
                                    </Text>
                                  </label>
                                </div>
                              ))}
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-border my-5"></div>
                    <div className="flex gap-x-8 mobile:block">
                      <div className="w-3/12 mobile:w-full">
                        <Text size="sm" className="text-gray-700">
                          Workspace domains
                        </Text>
                        <Text size="p">
                          Users with emails from these domains will be able to see your workspace
                        </Text>
                      </div>
                      <div className="w-5/12 mobile:w-full mobile:mt-4">
                        <div className={`mt-2`}>
                          <div className={`domains_tagify`}>
                            {visibility !== 'invite_only' ? <Tags
                              id="tagify_el"
                              onChange={onChange}
                              settings={baseTagifySettings}
                              tagifyRef={tagify}
                              onRemove={onTagRemove}
                              onInvalid={onTagInvalid}
                              autoFocus
                              defaultValue={domains}
                            /> : <Input placeholder="Enter domains" disabled className="text-xs pt-1 pl-4 opacity-60 bg-gray-200 pointer-events-none" />}
                          </div>
                          <div id="tagify__el__click"></div>
                          {!domainsValid && (
                            <div className="flex text-red-500 text-[12px] mt-2">
                              Please enter valid domains.
                            </div>
                          )}
                          {tagify && tagify.current && tagify.current.getTagElms().length > 3 && (
                            <div className="flex text-red-500 text-[12px] mt-1">
                              Maximum 3 domains are allowed.
                            </div>
                          )}
                          <Text className="mt-2">Enter up to 3 domains, separated by commas.</Text>
                        </div>
                        <div className="flex  workspace-discovery-controls">
                          <Button
                            disabled={updateDiscoveryFn.isLoading}
                            onClick={(e) => {
                              e.preventDefault();
                              handleUpdateDiscovery();
                              captureEvent("SET_Workspaces_SaveBtn");
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-1.5"
                            >
                              <g id="save-01">
                                <path
                                  id="Icon"
                                  d="M5.83333 2.5V5.33333C5.83333 5.80004 5.83333 6.0334 5.92416 6.21166C6.00406 6.36846 6.13154 6.49594 6.28834 6.57584C6.4666 6.66667 6.69996 6.66667 7.16667 6.66667H12.8333C13.3 6.66667 13.5334 6.66667 13.7117 6.57584C13.8685 6.49594 13.9959 6.36846 14.0758 6.21166C14.1667 6.0334 14.1667 5.80004 14.1667 5.33333V3.33333M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z"
                                  stroke="white"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>{" "}
                            Save
                            {updateDiscoveryFn.isLoading ? <div className="loader_circle ml-3"></div> : ""}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
