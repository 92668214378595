import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SharedDataContext from "../../../../SharedDataContext";
import { deleteWorkspace, setActiveWorkspace } from "../../../../apis/user";
import toast from "react-hot-toast";
import useModal from "../../../GenericModal/useModal";
import { getWorkspaces } from "../../../../apis/workspace/invitations";
import {
  acceptInvite,
  createMembershipRequest,
  joinOpenWorkspace,
  withdrawWorkspaceRequest,
} from "../../../../apis/workspace/members";
import GenericModal from "../../../GenericModal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { Button } from "../../../ui/button";
import { PlusIcon, Trash2 } from "lucide-react";
import Image from "../../../Tags/Image";
import Text from "../../../Tags/Text";
import { NavLink } from "react-router-dom";
import { useCaptureEvent } from "../../../../hooks/useCaptureEvent"
export default function WorkspaceList({ onboarding = false, getAvailableWorkspaces = null }) {
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const activeWorkspace = userData || {};
  const [currentId, setCurrentId] = useState("");

  const [selectedWorkspaceToDelete, setSelectedWorkspaceToDelete] = useState({});
  const deleteWorkspaceFn = useMutation("delete_workspace", deleteWorkspace, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      closeModalDelete();
      queryClient.invalidateQueries("get_user");
    },
  });
  const handleDeleteWorkspace = () => {
    deleteWorkspaceFn.mutate(selectedWorkspaceToDelete.id);
  };
  const setActiveWorkspaceWithoutRedirectFn = useMutation(setActiveWorkspace, {
    onSuccess(data, variables, context) {
      if(onboarding) {
        queryClient.invalidateQueries("get_user").then(() => {
          getAvailableWorkspaces(0)
        });
      } else {
        queryClient.invalidateQueries("get_user");
      }
    },
  });

  const setActiveWorkspaceFn = useMutation(setActiveWorkspace, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      window.location.href = `/${process.env.REACT_APP_APP_HOME}/${data?.user?.activeWorkspace?.slug}`;
      queryClient.invalidateQueries("get_user");
    },
  });

  const switchWorkspace = (id) => {
    setActiveWorkspaceFn.mutate(id);
  };

  // handle open edit workspace modal
  const { isOpen: isOpenDelete, openModal: openModalDelete, closeModal: closeModalDelete } = useModal(false);
  const [currentWorkspace, setCurrentWorkspace] = useState({});
  const { isOpen, openModal, closeModal } = useModal(false);

  // get all workspaces

  const [workspaces, setWorkspaces] = useState({});
  const getWorkspacesData = useQuery("get_workspaces", getWorkspaces, {
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (getWorkspacesData?.data) {
      setWorkspaces(getWorkspacesData.data);
      console.log("data", getWorkspacesData.data);
      if (getAvailableWorkspaces) {
        if (
          getWorkspacesData.data &&
          getWorkspacesData.data.membershipRequests &&
          getWorkspacesData.data.membershipRequests.length === 0 &&
          getWorkspacesData.data.pendingInvitations &&
          getWorkspacesData.data.pendingInvitations.length === 0 &&
          getWorkspacesData.data.workspaces &&
          getWorkspacesData.data.workspaces.length === 0
        ) {
          getAvailableWorkspaces(0);
        } else {
          getAvailableWorkspaces(1);
        }
      }
    }
  }, [getWorkspacesData?.data]);

  // create member ship request

  const createMembershipRequestFn = useMutation("create_membership_request", createMembershipRequest, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_user");
      queryClient.invalidateQueries("get_workspaces");
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleCreateMembershipRequest = (id) => {
    if (createMembershipRequestFn.isLoading) {
      return;
    }
    createMembershipRequestFn.mutate({ workspace_id: id });
  };

  // join open workspace

  const joinOpenWorkspaceFn = useMutation("join_workspace", joinOpenWorkspace, {
    onSuccess(data, variables, context) {
      setActiveWorkspaceWithoutRedirectFn.mutate(data?.workspace?.id)
      if(!onboarding) {
        toast.success(data?.message);
        queryClient.invalidateQueries("get_user");
        queryClient.invalidateQueries("get_workspaces");
      }
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleJoinOpenWorkspace = (id) => {
    if (joinOpenWorkspaceFn.isLoading) {
      return;
    }
    joinOpenWorkspaceFn.mutate({ workspace_id: id });
  };

  // handle accept invite

  const acceptInviteFn = useMutation("accept_invite", acceptInvite, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_user");
      queryClient.invalidateQueries("get_workspaces");
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleAcceptInvite = (payload) => {
    if (acceptInviteFn.isLoading) {
      return;
    }
    acceptInviteFn.mutate({ ...payload });
  };

  // withdraw request
  const [currentWithdrawId, setCurrentWithdrawId] = useState("");
  const withdrawWorkspaceRequestFn = useMutation("withdraw_request", withdrawWorkspaceRequest, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_user");
      queryClient.invalidateQueries("get_workspaces");
    },
  });
  const handleWithdrawWorkspaceRequest = (id) => {
    withdrawWorkspaceRequestFn.mutate({
      requestId: id,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  const returnWorkspaces = (data) => {
    const filteredArray = onboarding ? data.filter((item) => item.role !== "Owner") : data;
    return filteredArray;
  };

  return (
    <>
      <div className="">
        <div>
          <div className="flex flex-wrap -mx-3">
            {!userData && (
              <>
                <div className="w-4/12 px-3 mb-6">
                  <div className="rounded-lg overflow-hidden">
                    <div className="placeholder-content" style={{ height: "200px" }}></div>
                  </div>
                </div>
                <div className="w-4/12 px-3 mb-6">
                  <div className="rounded-lg overflow-hidden">
                    <div className="placeholder-content" style={{ height: "200px" }}></div>
                  </div>
                </div>
                <div className="w-4/12 px-3 mb-6">
                  <div className="rounded-lg overflow-hidden">
                    <div className="placeholder-content" style={{ height: "200px" }}></div>
                  </div>
                </div>
                <div className="w-4/12 px-3">
                  <div className="rounded-lg overflow-hidden">
                    <div className="placeholder-content" style={{ height: "200px" }}></div>
                  </div>
                </div>
                <div className="w-4/12 px-3">
                  <div className="rounded-lg overflow-hidden">
                    <div className="placeholder-content" style={{ height: "200px" }}></div>
                  </div>
                </div>
                <div className="w-4/12 px-3">
                  <div className="rounded-lg overflow-hidden">
                    <div className="placeholder-content" style={{ height: "200px" }}></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="space-y-9">
          {userData &&
            userData.workspaces &&
            userData.workspaces.data &&
            userData.workspaces.data.length > 0 &&
            returnWorkspaces(userData.workspaces.data) &&
            returnWorkspaces(userData.workspaces.data).length > 0 && (
              <div className="rounded-xl border border-input py-3 px-6 bg-gray-50 space-y-3">
                <Text size="lg">Joined</Text>
                {returnWorkspaces(userData.workspaces.data).map((item, index) => (
                  <>
                    <div className="py-3 flex justify-between items-center mobile:block mobile:space-y-3">
                      <div className="inline-flex space-x-5 items-center">
                        <span className="rounded-lg border border-border p-1 bg-white">
                          <Image src={"/images/icons/untitledui/org.svg"} className="w-[18px] no-invert" />
                        </span>
                        <div>
                          <p className="text-gray-700 font-semibold">{item?.name}</p>
                          <p className="text-gray-700 mt-[2px]">/{item?.slug}</p>
                        </div>
                      </div>
                      <div>
                        {userData.activeWorkspace && userData.activeWorkspace.id === item.id ? (
                          <>
                            {(userData.activeWorkspace.role === "Admin" ||
                              userData.activeWorkspace.role === "Owner") && (
                              <NavLink to={`/${item.slug}/settings/general`} className={"active__workspace"}>
                                <Button variant="outline"
                                onClick={() => {
                                  captureEvent("TOP_ManageWorkspaces_ManageBtn");
                                }}
                                >
                                  <Image
                                    src={"/images/icons/untitledui/settings-gray.svg"}
                                    className="mr-1.5"
                                  />
                                  Manage
                                </Button>
                              </NavLink>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentWorkspace(item);
                                switchWorkspace(item.id);
                                captureEvent("TOP_ManageWorkspacesManageJoined_LaunchBtn");
                              }}
                              className="flex-1 workspace__launch"
                            >
                              <Image src={"/images/icons/untitledui/rocket.svg"} className="mr-1.5" />
                              Launch
                              {setActiveWorkspaceFn.isLoading && currentWorkspace.id === item.id && (
                                <div className="loader_circle inline-block ml-2"></div>
                              )}
                            </Button>
                            {item?.role === "Owner" && (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedWorkspaceToDelete(item);
                                  openModalDelete();
                                  captureEvent("TOP_ManageWorkspacesManageJoined_DeleteBtn");
                                }}
                                className="flex-1 ml-2 workspace__delete"
                                variant="destructive_outline"
                              >
                                <Trash2 className="h-5 w-5 mr-2" aria-hidden="true" />
                                Delete
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {index < userData.workspaces.data.length - 1 && (
                      <div className="border-b border-border"></div>
                    )}
                  </>
                ))}
              </div>
            )}
          {workspaces && workspaces.pendingInvitations && workspaces.pendingInvitations.length > 0 && (
            <div className="rounded-xl border border-input py-3 px-6 bg-gray-50 space-y-3">
              <Text size="lg">Invitations</Text>
              {workspaces.pendingInvitations.map((item, index) => (
                <>
                  <div className="py-3 flex justify-between items-center mobile:block mobile:space-y-3">
                    <div className="inline-flex space-x-5 items-center">
                      <span className="rounded-lg border border-border p-1 bg-white">
                        <Image src={"/images/icons/untitledui/org.svg"} className="w-[18px] no-invert" />
                      </span>
                      <div>
                        <p className="text-gray-700 font-semibold">{item?.workspace?.name}</p>
                        <p className="text-gray-700 mt-[2px]">/{item?.workspace?.slug}</p>
                      </div>
                    </div>
                    <div>
                      <Button
                        variant="success-solid"
                        onClick={() => {
                          setCurrentId(item.id);
                          handleAcceptInvite({
                            invitation_id: item.id,
                            token: item.token,
                          });
                          captureEvent("ONB_Workspaces_AcceptInvitationBtn");
                        }}
                      >
                        <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                        Accept invitation
                        {acceptInviteFn.isLoading && item.id === currentId && (
                          <div className="inline-block ml-2 loader_circle"></div>
                        )}
                      </Button>
                    </div>
                  </div>
                  {index < workspaces.pendingInvitations.length - 1 && (
                    <div className="border-b border-border"></div>
                  )}
                </>
              ))}
            </div>
          )}
          {workspaces && workspaces.membershipRequests && workspaces.membershipRequests.length > 0 && (
            <div className="rounded-xl border border-input py-3 px-6 bg-gray-50 space-y-3">
              <Text size="lg">Requests</Text>
              {workspaces.membershipRequests.map((item, index) => (
                <>
                  <div className="py-3 flex justify-between items-center mobile:block mobile:space-y-3">
                    <div className="inline-flex space-x-5 items-center">
                      <span className="rounded-lg border border-border p-1 bg-white">
                        <Image src={"/images/icons/untitledui/org.svg"} className="w-[18px] no-invert" />
                      </span>
                      <div>
                        <p className="text-gray-700 font-semibold">{item?.workspace?.name}</p>
                        <p className="text-gray-700 mt-[2px]">/{item?.workspace?.slug}</p>
                      </div>
                    </div>
                    <div>
                      <Button
                        disabled={withdrawWorkspaceRequestFn.isLoading}
                        variant="outline"
                        onClick={() => {
                          setCurrentWithdrawId(item?.id);
                          handleWithdrawWorkspaceRequest(item?.id);
                          captureEvent("ONB_Workspaces_WithdrawBtn");
                        }}
                      >
                        <Image src="/images/icons/untitledui/withdrawn.svg" className="mr-1.5" />
                        Withdraw
                        {withdrawWorkspaceRequestFn.isLoading && item.id === currentWithdrawId && (
                          <div className="inline-block ml-2 loader_circle"></div>
                        )}
                      </Button>
                    </div>
                  </div>
                  {index < workspaces.membershipRequests.length - 1 && (
                    <div className="border-b border-border"></div>
                  )}
                </>
              ))}
            </div>
          )}
          {workspaces && workspaces.workspaces && workspaces.workspaces.length > 0 && (
            <div className="rounded-xl border border-input py-3 px-6 bg-gray-50 space-y-3">
              <Text size="lg">Available to join</Text>
              {workspaces.workspaces.map((item, index) => (
                <>
                  <div className="py-3 flex justify-between items-center mobile:block mobile:space-y-3">
                    <div className="inline-flex space-x-5 items-center">
                      <span className="rounded-lg border border-border p-1 bg-white">
                        <Image src={"/images/icons/untitledui/org.svg"} className="w-[18px] no-invert" />
                      </span>
                      <div>
                        <p className="text-gray-700 font-semibold">{item?.name}</p>
                        <p className="text-gray-700 mt-[2px]">/{item?.slug}</p>
                      </div>
                    </div>
                    <div>
                      {item?.visibility === "approval_required" ? (
                        <Button
                          variant="purple-outline"
                          onClick={() => {
                            setCurrentId(item.id);
                            handleCreateMembershipRequest(item.id);
                            captureEvent("ONB_Workspaces_RequestBtn");
                          }}
                        >
                          <Image src={"/images/icons/untitledui/lock.svg"} className="mr-2" />
                          Request{" "}
                          {createMembershipRequestFn.isLoading && item.id === currentId && (
                            <div className="inline-block ml-2 loader_circle"></div>
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="purple-outline"
                          onClick={() => {
                            setCurrentId(item.id);
                            handleJoinOpenWorkspace(item.id);
                            captureEvent("ONB_Workspaces_JoinBtn");
                          }}
                          
                        >
                          <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                          Join
                          {joinOpenWorkspaceFn.isLoading && item.id === currentId && (
                            <div className="inline-block ml-2 loader_circle"></div>
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                  {index < workspaces.workspaces.length - 1 && <div className="border-b border-border"></div>}
                </>
              ))}
            </div>
          )}
        </div>
      </div>

      <GenericModal isOpen={isOpenDelete} closeModal={closeModalDelete}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
              Workspace "{selectedWorkspaceToDelete.name}" will be Deleted! <br /> Are you Sure?
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to delete your workspace? All of your data will be permanently removed
                from our servers forever. This action cannot be undone.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={deleteWorkspaceFn.isLoading}
            className="inline-flex items-center w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            onClick={handleDeleteWorkspace}
          >
            Delete {deleteWorkspaceFn.isLoading && <div className="loader_circle ml-2"></div>}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-background px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModalDelete()}
          >
            Cancel
          </button>
        </div>
      </GenericModal>
      {/* <GenericModal
    isOpen={isOpen}
    closeModal={closeModal}
    title={"Edit Workspace"}
  >
    <EditWorkspaceModal
      workspace={currentWorkspace}
      closeModal={closeModal}
    />
  </GenericModal> */}
    </>
  );
}
