import React from "react";

export default function Image({ src, alt = "", nobaseurl = false, ...rest }) {
  return (
    <img
      src={nobaseurl ? src : `/${process.env.REACT_APP_APP_HOME}${src}`}
      alt={alt ? alt : "Img"}
      {...rest}
    />
  );
}
