import React from "react";

import { JsonView, collapseAllNested, darkStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

export default function AuditLogsModal({ log }) {
  return (
    <>
      <div className="p-6">
        <h3 className="text-[18px] block font-semibold mb-5">Logs</h3>
        {log && (
          <>
            <h4 className="flex mb-5">
              <span className="font-bold text-black w-[150px]">
                User Agent:
              </span>{" "}
              <span className="leading-relaxed text-sm text-gray-500">
                {log.user_agent}
              </span>
            </h4>
            <h4 className="flex mb-5">
              <span className="font-bold text-black w-[150px]">Changes:</span>
            </h4>
            <div className="p-5 rounded-lg bg-[#002b36]">
              <JsonView
                data={log.values_modified}
                shouldExpandNode={collapseAllNested}
                style={darkStyles}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
