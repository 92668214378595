import React from "react";
import Layout from "../../Layout";
import General from "../../containers/General";
export default function GeneralPage() {
  return (
    <Layout>
      <General />
    </Layout>
  );
}
