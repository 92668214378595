import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import Image from "../Tags/Image";
import { NavLink } from "react-router-dom";
import { useCaptureEvent } from "../../hooks/useCaptureEvent";
import Input from "../Tags/Input";
import { Search } from "lucide-react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function WorkspaceDropdown({ activeWorkspace, workspaces, switchWorkspace }) {
  const captureEvent = useCaptureEvent();
  const [searchQuery, setSearchQuery] = useState("");
  const hasManyWorkspaces = workspaces && workspaces.length > 5;
 
  const filteredWorkspaces = hasManyWorkspaces
    ? workspaces.filter((workspace) =>
        workspace.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : workspaces;

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="flex items-center rounded-full text-text-600 font-semibold"
            onClick={() => {
              captureEvent("TOP_SwitchWorkspaceBtn");
            }}
          >
            {activeWorkspace.icon ? (
              <Image
                src={activeWorkspace.icon}
                className="mr-2 w-[18px] h-[18px] rounded-full no-invert"
                width={18}
                height={18}
                nobaseurl
              />
            ) : (
              <Image src={`/images/icons/untitledui/org.svg`} className="mr-2" width={18} height={18} />
            )}
            <span>{activeWorkspace.name}</span>
            <Image src="/images/icons/arrow-down.svg" className="ml-3" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right 
            divide-y divide-border rounded-md bg-background shadow-lg 
            ring-1 ring-border ring-opacity-5 focus:outline-none"
          >
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#none"
                    className={classNames(
                      active ? "bg-secondary" : "",
                      "flex items-center space-x-2 px-4 py-3 text-xs pointer-events-none text-foreground/60"
                    )}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.3327 10.3333H1.66602M1.66602 10.3333L4.33268 7.66667M1.66602 10.3333L4.33268 13M1.66602 3.66667H12.3327M12.3327 3.66667L9.66602 1M12.3327 3.66667L9.66602 6.33333"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span> Switch to</span>
                  </a>
                )}
              </Menu.Item>

              
              {hasManyWorkspaces && (
                <div className="py-1 px-4 mb-1">
                  <Input
                  icon={<Search width={14} height={14} />}
                    type="text"
                    placeholder="Search workspace"
                    className="w-full rounded-md border border-border h-9 text-sm"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              )} 
              {filteredWorkspaces && filteredWorkspaces.length > 0 && (
                <div className={hasManyWorkspaces && "h-48 overflow-y-scroll custom-scrollable-dropdown"}>
                  {filteredWorkspaces.map((workspace) => (
                    <Menu.Item key={workspace.id}>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            switchWorkspace(workspace.id);
                            captureEvent("TOP_WorkspaceDropdown_SelectWorkspaceBtn");
                          }}
                          className={classNames(
                            active ? "bg-secondary" : "",
                            "flex justify-between px-4 py-2 w-full"
                          )}
                        >
                          <div className="inline-flex items-center break-all">
                            {workspace.icon ? (
                              <Image
                                src={workspace.icon}
                                className="mr-2 rounded-full no-invert"
                                width={20}
                                height={20}
                                nobaseurl
                              />
                            ) : (
                              <Image
                                src={`/images/icons/untitledui/org.svg`}
                                className="mr-2"
                                width={18}
                                height={18}
                              />
                            )}
                            {workspace.name}
                          </div>
                          {workspace.id === activeWorkspace.id && (
                            <Image
                              src="/images/icons/check-circle.svg"
                              alt=""
                              width={20}
                              height={20}
                              className="mr-2"
                            />
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              )}
              {filteredWorkspaces && filteredWorkspaces.length === 0 && <div>
                <p className="p-4">No workspace found!</p>
                </div>
              }
            </div>

            <div className="py-1">
              <Menu.Item>
                <NavLink
                  to={`/workspaces`}
                  className="flex items-center space-x-2 w-full px-4 py-2 text-left"
                  onClick={() => {
                    captureEvent("TOP_WorkspaceDropdown_ManageWorkspaceBtn");
                  }}
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.00065 4L7.00065 7M4.00065 4H2.00065L1.33398 2L2.00065 1.33334L4.00065 2V4ZM12.84 1.82734L11.0882 3.57909C10.8242 3.8431 10.6922 3.97511 10.6428 4.12732C10.5993 4.26122 10.5993 4.40545 10.6428 4.53935C10.6922 4.69157 10.8242 4.82357 11.0882 5.08758L11.2464 5.24576C11.5104 5.50977 11.6424 5.64177 11.7946 5.69123C11.9285 5.73474 12.0728 5.73474 12.2067 5.69123C12.3589 5.64177 12.4909 5.50977 12.7549 5.24576L14.3935 3.60715C14.57 4.03659 14.6673 4.50693 14.6673 5C14.6673 7.02505 13.0257 8.66667 11.0007 8.66667C10.7565 8.66667 10.5179 8.64281 10.2872 8.59729C9.96306 8.53337 9.80101 8.50142 9.70278 8.5112C9.59834 8.52161 9.54686 8.53727 9.45432 8.58679C9.36728 8.63337 9.27997 8.72069 9.10534 8.89532L4.33398 13.6667C3.7817 14.2189 2.88627 14.2189 2.33399 13.6667C1.7817 13.1144 1.7817 12.2189 2.33399 11.6667L7.10534 6.89532C7.27997 6.72069 7.36728 6.63337 7.41386 6.54633C7.46338 6.45379 7.47904 6.40232 7.48945 6.29788C7.49924 6.19964 7.46728 6.03759 7.40336 5.7135C7.35784 5.48271 7.33398 5.24414 7.33398 5C7.33398 2.97496 8.97561 1.33334 11.0007 1.33334C11.671 1.33334 12.2993 1.51322 12.84 1.82734ZM8.00069 9.99997L11.6673 13.6666C12.2196 14.2189 13.115 14.2189 13.6673 13.6666C14.2196 13.1143 14.2196 12.2189 13.6673 11.6666L10.6509 8.6502C10.4373 8.62999 10.2291 8.59147 10.0278 8.53622C9.76847 8.46503 9.48395 8.51671 9.29376 8.70689L8.00069 9.99997Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Manage Workspaces</span>
                </NavLink>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
