import React, { useContext, useRef, useState } from "react";
import Image from "../../Tags/Image";
import Cookies from "js-cookie";
import SharedDataContext from "../../../SharedDataContext";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent"
export default function UploadCSV({ closeModal = null, getResponse }) {
  const captureEvent = useCaptureEvent();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const [isLoading, setIsloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // upload csv

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setIsSuccess(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleUploadCSVFile = () => {
    const cookieName = process.env.REACT_APP_PR_ACCESS_TOKEN
      ? process.env.REACT_APP_PR_ACCESS_TOKEN
      : "accessToken";

    const token = Cookies.get(cookieName);

    if (selectedFile) {
      //   return;
      setIsloading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("enrichment_type", currentTab);
      const config = {
        headers: {
          "X-Workspace-Id": activeWorkspace?.id,
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      //console.log(selectedFile);

      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/enrich/csv/upload`, formData, config)
        .then((response) => {
          //console.log(response.data);
          getResponse(response.data.list);
          // Handle the response here
          setIsSuccess(true);
          setSelectedFile(null);
          setIsloading(false);
          if (closeModal) {
            closeModal();
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle errors here
          setIsloading(false);
          setIsSuccess(false);
        });
    }
  };

  //  active tab
  const [currentTab, setCurrentTab] = useState("People");

  const formatFileSize = (bytes) => {
    const KB = 1024;
    const MB = KB * 1024;

    if (bytes < MB) {
      const fileSizeKB = (bytes / KB).toFixed(2);
      return `${fileSizeKB} KB`;
    } else {
      const fileSizeMB = (bytes / MB).toFixed(2);
      return `${fileSizeMB} MB`;
    }
  };

  return (
    <div>
      <div className="">
        <span className="border border-border rounded-xl p-3 inline-block text-text-700">
          <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 14.2422C1.79401 13.435 1 12.0602 1 10.5C1 8.15643 2.79151 6.23129 5.07974 6.01937C5.54781 3.17213 8.02024 1 11 1C13.9798 1 16.4522 3.17213 16.9203 6.01937C19.2085 6.23129 21 8.15643 21 10.5C21 12.0602 20.206 13.435 19 14.2422M7 14L11 10M11 10L15 14M11 10V19"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <h2 className="text-lg font-semibold leading-7 text-text-900 mt-4">CSV Enrichment</h2>
      <p className="text-text-600 mt-1 mb-5">
        Upload a CSV containing a list of people or companies to enrich.
      </p>
      <p className="text-text-700 mb-2">Enrichment type*</p>
      <div className="border border-border rounded-[--radius] p-[2px] inline-flex bg-smokegray gap-x-1">
        <Button
          onClick={(e) => {
            e.preventDefault();
            setCurrentTab("People");
          }}
          variant={currentTab === "People" ? "white" : "tab_btn"}
        >
          People
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setCurrentTab("Company");
          }}
          variant={currentTab === "Company" ? "white" : "tab_btn"}
        >
          Companies
        </Button>
      </div>

     {currentTab === 'People' && <div>
      <p className="text-base font-semibold mt-5 mb-3">Each row must have at least one of the following:</p>
        <ul className="list-disc  space-y-1 pl-6">
          <li>
            {" "}
            LinkedIn profile URL <Badge>Recommended</Badge>
          </li>
          <li>Full name + company/domain name</li>
          <li>Email</li>
        </ul>
     </div>}
     {currentTab === 'Company' && <div>
      <p className="text-base font-semibold mt-5 mb-3">Each company must have at least one of the following:</p>
        <ul className="list-disc  space-y-1 pl-6">
        <li>Company LinkedIn URL <Badge>Recommended</Badge></li>
          <li>Company domain </li>
          <li>Company name</li>
          
        </ul>
     </div>}

      <div className="my-5">
        <div
          className="leading-5 mt-5 cursor-pointer block border-border border h-full w-full items-center justify-center bg-background py-4 px-6 rounded-xl text-xs"
          onDragOver={handleDragOver}
          onDrop={(e) => {
            handleFileDrop(e); 
        }}
          onClick={openFileInput}
        >
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleFileSelect}
            ref={fileInputRef}
          />
          <>
            <div className="text-center my-1"
            onClick={() => {
              captureEvent("BLE_UploadCSVModal_ClickToUpload");
            }}>
              <span className="border border-border rounded-xl p-3 inline-block text-text-700">
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 14.2422C1.79401 13.435 1 12.0602 1 10.5C1 8.15643 2.79151 6.23129 5.07974 6.01937C5.54781 3.17213 8.02024 1 11 1C13.9798 1 16.4522 3.17213 16.9203 6.01937C19.2085 6.23129 21 8.15643 21 10.5C21 12.0602 20.206 13.435 19 14.2422M7 14L11 10M11 10L15 14M11 10V19"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <p className="block text-text-600 text-center">
              <span className="text-brand-700 font-semibold"
              onClick={() => {
              captureEvent("BLE_UploadCSVModal_ClickToUpload");
            }}>Click to upload</span> or drag and drop <br />
              <p className="mt-1">
                {" "}
                <span className="font-semibold">CSV format</span> (max. 1MB size, up to 5,000 rows and up to
                50 columns)
              </p>
            </p>
          </>
        </div>
        {selectedFile && (
          <div className="leading-5 mt-5 block border-border border h-full w-full items-center justify-center bg-background py-4 px-6 rounded-xl text-xs">
            <div className="flex items-center space-x-4">
              <Image src="/images/icons/untitledui/csv-icon.svg" className="no-invert" />
              <div>
                <p className="text-text-700 font-semibold">{selectedFile.name}</p>
                <p className="text-text-600">
                  {selectedFile.size && formatFileSize(selectedFile.size)} – 100% uploaded
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      
      <div className="mt-5 pt-5 text-right">
        <div className="flex space-x-3 justify-end">
          <Button
            onClick={() => {
              closeModal && closeModal();
              captureEvent("BLE_UploadCSVModal_CancelBtn");
            }}
            variant="outline"
          >
            Cancel
          </Button>
          <Button 
          onClick={() => {
            handleUploadCSVFile();
            captureEvent("BLE_UploadCSVModal_UploadAndEnrichBtn");
          }}
          disabled={!selectedFile || isLoading}>
            Upload and enrich
            {isLoading && <div className="loader_circle ml-2"></div>}
          </Button>
        </div>
      </div>
    </div>
  );
}
