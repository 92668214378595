import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { updateEnrichlistName } from "../../../apis/enrich";
import FormError from "../../Tags/FormError";

export default function EditFileName({ name, listId, workspaceId, className = "", hideForm }) {
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      name: name,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name").max(100, "Name cannot be more than 100 characters"),
    }),
    onSubmit: (values) => {
      const data = { ...values };
      //console.log("data", data);
      updateEnrichlistNameFn.mutate({
        listId: listId,
        data: data,
        options: {
          headers: { "X-Workspace-Id": workspaceId },
        },
      });
    },
  });
  const updateEnrichlistNameFn = useMutation("update_enrichname", updateEnrichlistName, {
    onSuccess(data, variables, context) {
      formik.resetForm();
      toast.success(data?.message);
      //   formik.setFieldValue("name", data?.workspace.name);
      hideForm(false);
      queryClient.invalidateQueries("get_enrichment_list");
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={`${className}`}>
        <div className="relative">
          <div className="">
            <div className="flex relative rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
              <input
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                className="block flex-1 w-full border-0 bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pr-[90px]"
                placeholder="filename"
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldTouched("name", true);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <div className="flex items-center absolute right-1 top-1 rounded-md overflow-hidden">
                <button
                  type="submit"
                  disabled={formik.isSubmitting || !formik.isValid}
                  className="cursor-pointer inline-flex items-center  bg-brand-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                >
                  {formik.isSubmitting ? (
                    <div className="loader_circle"></div>
                  ) : (
                    <CheckIcon width={12} height={12} />
                  )}
                </button>
                <span
                  onClick={() => hideForm(false)}
                  className="cursor-pointer  bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                  <XMarkIcon width={12} height={12} />
                </span>
              </div>
            </div>
            <FormError field="name" />
          </div>
        </div>
      </form>
    </FormikProvider>
  );
}
