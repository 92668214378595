import React, { useContext, useState } from "react";
import Image from "../../../Tags/Image";
import { Button } from "../../../ui/button";
import { useMutation } from "react-query";
import { salesforceAuthLogin } from "../../../../apis/workspace/integrations";
import SharedDataContext from "../../../../SharedDataContext";

export default function SalesforceDrawer({ closeModal }) {
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const features = [
    `Check if existence of Accounts, Contacts and Leads from within ${process.env.REACT_APP_APP_NAME}`,
    `Export data from ${process.env.REACT_APP_APP_NAME} to Salesforce`,
    "Configurable permissions according to your needs",
    "Support for custom objects and fields",
  ];
  const [env, setEnv] = useState("sandbox");

  const salesforceAuthLoginFn = useMutation("salesforce_authlogin", salesforceAuthLogin, {
    onSuccess(data, variables, context) {},
  });

  const handleSalesforceAuthLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/workspace-settings/integrations/salesforce/login/${env}`;
  };

  return (
    <div className="p-6">
      <span className="svg__32 inline-block border border-border rounded-[--radius] p-3 bg-background">
        <Image
          src="/images/logos/integrations/salesforce.svg"
          width="30"
          className="inline-block no-invert"
        />
      </span>
      <h3 className="text-[18px] block font-semibold mb-2 mt-4">Connect Salesforce</h3>
      <p className="text-[14px] text-paragraph leading-relaxed ">
        Permissions required will be requested to your Salesforce instance accordingly
      </p>
      <div className="-mx-6">
        <div className="border-b border-border my-5"></div>
      </div>
      <h3 className="text-[16px] block font-semibold mb-4 mt-4">Supported features</h3>
      <div className="space-y-4">
        {features.map((feature, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            {" "}
            <Image
              src="/images/icons/untitledui/check-purple.svg"
              width="20"
              className="inline-block no-invert"
            />
            <span style={{ marginLeft: "8px" }}>{feature}</span>
          </div>
        ))}
      </div>
      <div className="-mx-6 my-8">
        <div className="border-b border-border my-5"></div>
      </div>
      <div className="-mx-6 px-3">
        <div className="flex">
          <div className="w-6/12 px-3">
            <div
              className={`cursor-pointer border-2 border-border rounded-[--radius] p-6 bg-background text-center ${
                env === "production" ? "border-brand-700" : ""
              }`}
              onClick={() => {
                setEnv("production");
              }}
            >
              <Image
                src="/images/logos/integrations/salesforce.svg"
                className="inline-block my-7  no-invert"
              />
              <h3 className="text-[18px] block font-semibold mb-2 mt-4">Production</h3>
              <p className="text-[14px] text-paragraph leading-relaxed ">
                Select this to connect your production Salesforce instance.
              </p>
            </div>
          </div>
          <div className="w-6/12 px-3">
            <div
              className={`cursor-pointer border-2 border-border rounded-[--radius] p-6 bg-background text-center ${
                env === "sandbox" ? "border-brand-700" : ""
              }`}
              onClick={() => {
                setEnv("sandbox");
              }}
            >
              <Image
                src="/images/logos/integrations/salesforce.svg"
                className="inline-block my-7 no-invert"
              />
              <h3 className="text-[18px] block font-semibold mb-2 mt-4">Sandbox</h3>
              <p className="text-[14px] text-paragraph leading-relaxed ">
                Select this to connect your sandbox Salesforce instance (For Testing)
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6 border-t -mx-6 absolute bottom-0 w-full z-10 bg-background">
        <div className="flex space-x-3">
          <Button variant="outline" className="flex-1" onClick={closeModal}>
            Cancel
          </Button>
          <Button className="flex-1" onClick={handleSalesforceAuthLogin}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
