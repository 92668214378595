import { CheckIcon, ChevronsUpDown } from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import RPNInput, { getCountryCallingCode } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea } from "./scroll-area";

const PhoneInput = React.forwardRef(({ className, onChange, ...props }, ref) => {
  return (
    <RPNInput
      ref={ref}
      className={cn("relative h-11 w-full rounded-[--radius] bg-background", className)}
      flagComponent={FlagComponent}
      countrySelectComponent={CountrySelect}
      inputComponent={InputComponent}
      onChange={(value) => onChange?.(value || "")}
      international={true}
      {...props}
    />
  );
});
PhoneInput.displayName = "PhoneInput";

const InputComponent = React.forwardRef(({ className, ...props }, ref) => (
  <input
    className={cn(
      "h-11 pl-[64px] pr-[14px] py-[10px] text-sm w-full rounded-[--radius] border border-input bg-background text-text-900",
      className
    )}
    ref={ref}
    {...props}
  />
));
InputComponent.displayName = "InputComponent";

const CountrySelect = ({ disabled, value, onChange, options }) => {
  const selectedRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const handleSelect = useCallback(
    (country) => {
      onChange(country);
      setPopoverOpen(false);
    },
    [onChange]
  );

  useEffect(() => {
    if (popoverOpen && selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: "instant", block: "center" });
    }
  }, [popoverOpen, value]);

  return (
    <Popover onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          className={cn(
            "h-11 flex gap-1 rounded-e-none border-0 px-3 absolute top-1/2 transform -translate-y-1/2 bg-transparent hover:bg-transparent"
          )}
          disabled={disabled}
        >
          <FlagComponent country={value} countryName={value} />
          <ChevronsUpDown className={cn("-mr-2 h-4 w-4 opacity-50", disabled ? "hidden" : "opacity-100")} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput
            placeholder="Search country..."
            className="bg-transparent !ring-offset-transparent !ring-transparent focus-visible:outline-0"
          />
          <CommandList>
            <ScrollArea className="h-72">
              <CommandEmpty>No country found.</CommandEmpty>
              <CommandGroup>
                {options
                  .filter((x) => x.value)
                  .map((option) => (
                    <CommandItem
                      className={`gap-2 ${option.value === value ? "bg-accent text-accent-foreground" : ""}`}
                      key={option.value}
                      onSelect={() => handleSelect(option.value)}
                      ref={option.value === value ? selectedRef : null}
                    >
                      <FlagComponent country={option.value} countryName={option.label} />
                      <span className="flex-1 text-sm">{option.label}</span>
                      <span className="text-foreground/50 text-sm">
                        {`+${getCountryCallingCode(option.value)}`}
                      </span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          option.value === value ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const FlagComponent = ({ country, countryName }) => {
  const Flag = flags[country];
  return (
    <span className="bg-foreground/20 flex h-4 w-6 overflow-hidden rounded-sm">
      {Flag && <Flag title={countryName} />}
    </span>
  );
};
FlagComponent.displayName = "FlagComponent";

export { PhoneInput };
