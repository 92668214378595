import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Sun } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import SharedDataContext from "../../../SharedDataContext";
import { setActiveWorkspace } from "../../../apis/user";
import usePageAttributes from "../../../hooks/usePageAttributes";
import ProfileDropdown from "../../ProfileDropdown";
import Image from "../../Tags/Image";
import NavAnchor from "../../Tags/NavAnchor";
import { useTheme } from "../../ThemeProvider";
import WorkspaceDropdown from "../../WorkspaceDropdown";
import Cookies from "js-cookie";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
const navigation = [
  {
    name: "Dashboard",
    id: "dashboard",
    href: "/",
    icon: "/images/icons/untitledui/home.svg",
    activePaths: ["/"],
  },
  // {
  //   name: "Prospect Search",
  //   href: "/prospect-search",
  //   icon: "/images/icons/untitledui/search-refraction.svg",
  //   activePaths: ["/prospect-search", "/prospect-search/list/*"],
  // },
  {
    name: "Enrich",
    id: "enrich",
    href: "/enrich/list",
    icon: "/images/icons/untitledui/magic-wand.svg",
    hasSubMenu: false,
    activePaths: ["/enrich/list", "/enrich/list/*", "/enrich/people", "/enrich/company"],
  },
  {
    name: "Settings",
    id: "settings",
    href: "/settings/general",
    icon: "/images/icons/untitledui/settings2.svg",
    activePaths: [
      "/settings/general",
      "/settings/members",
      "/settings/groups",
      "/settings/integrations",
      "/settings/security",
      "/settings/api-keys",
      "/settings/credit-consumption-log",
    ],
  },
];

export default function Header() {
  const captureEvent = useCaptureEvent();
  const { logo, appname, setTitle } = usePageAttributes();
  const userData = useContext(SharedDataContext);
  const { workspaceSlug } = useParams();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const setActiveWorkspaceFn = useMutation(setActiveWorkspace, {
    onSuccess(data, variables, context) {
      window.location.href = `/${process.env.REACT_APP_APP_HOME}/${data?.user?.activeWorkspace?.slug}/`;
    },
  });

  const switchWorkspace = (id) => {
    setActiveWorkspaceFn.mutate(id);
  };

  useEffect(() => {
    const eventListener = (e) => {
      if (workspaceSlug) {
        if (e.detail?.activeWorkspace?.slug !== workspaceSlug) {
          if (e.detail?.workspaces && e.detail?.workspaces.length > 0) {
            const matchingWorkspace = e.detail?.workspaces.find(
              (workspace) => workspace.slug === workspaceSlug
            );
            if (matchingWorkspace && matchingWorkspace.id) {
              switchWorkspace(matchingWorkspace.id);
            } else {
              navigate(`/${workspaceSlug}/404?activeWorkspace=${e.detail?.activeWorkspace?.slug}`);
            }
          }
        }
      }
    };
    window.addEventListener("user_workspace", eventListener);
    return () => {
      window.removeEventListener("user_workspace", eventListener);
    };
  }, [workspaceSlug, navigate]);

  const { theme, setTheme } = useTheme();


  return (
    <>
      <header className="border-b border-border bg-white dark:bg-gray-25">
        <nav
          className="mx-auto flex items-center justify-between px-6 lg:px-8 mobile:py-6"
          aria-label="Global"
        >
          <div className="flex items-center gap-x-2">
            <a
              href={`/${process.env.REACT_APP_APP_HOME}/${userData?.activeWorkspace?.slug}`}
              onClick={() => {
                captureEvent("TOP_PrimeRoleBtn");
              }}
              className="-m-1.5 p-1.5 w-[264px]"
            >
              {appname === "PrimeRole" ? (
                <>
                  {theme === "dark" ? (
                    <Image
                      src="/images/apps/primerole/logo-dark.svg"
                      className="logo no-invert w-[170px]"
                      alt="Logo"
                    />
                  ) : (
                    <Image src={logo} className="logo w-[170px]" alt="Logo" />
                  )}
                </>
              ) : (
                <Image src={logo} className="logo w-[170px]" alt="Logo" />
              )}
            </a>
            <ul className="hidden lg:flex lg:gap-x-10 list-none">
              {navigation.map((item) => (
                <>
                  {item && item.hasSubMenu ? (
                    <li className="relative group inline-flex justify-center items-center">
                      <NavAnchor
                        key={item.name}
                        to={`${item.href}`}
                        activeClassName={`text-text-700 inline-flex items-center font-semibold leading-6 py-[23px] text-base border-b-2 border-brand-600 `}
                        className={
                          "text-text-700 inline-flex items-center font-semibold leading-6 py-[23px] text-base border-b-2 border-transparent"
                        }
                        activePaths={item.activePaths}
                      >
                        {item.icon && <Image className="mr-3" src={item.icon} alt="" width="18" />}{" "}
                        {item.name}
                      </NavAnchor>
                      <ul className="hidden group-hover:block absolute left-0 top-full z-20 min-w-[200px] w-full bg-background border border-border rounded">
                        <li className="flex w-full">
                          {item.submenu &&
                            item.submenu.length > 0 &&
                            item.submenu.map((subitem) => (
                              <NavAnchor
                                key={subitem.name}
                                to={`${subitem.href}`}
                                activeClassName={`text-sm font-semibold leading-6 py-2 px-3 border-b-2 border-transparent  w-full`}
                                className={
                                  "text-sm font-semibold leading-6 py-2 px-3 border-b-2 border-transparent  w-full"
                                }
                              >
                                {subitem.name}
                              </NavAnchor>
                            ))}
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <>
                      {(item.id !== "settings" ||
                        (item.id === "settings" &&
                          (userData?.activeWorkspace?.role === "Owner" ||
                            userData?.activeWorkspace?.role === "Admin"))) && (
                        <li className="relative group inline-flex justify-center items-center">
                          <NavAnchor
                            key={item.name}
                            to={`${item.href}`}
                            activeClassName={`text-text-700 inline-flex items-center font-semibold leading-6 py-[23px] text-base border-b-2 border-brand-600 ${item?.id === "dashboard" && "top__nav__dashboard__link_active"}`}
                            className={`text-text-700 inline-flex items-center font-semibold leading-6 py-[23px] text-base border-b-2 border-transparent ${item?.id === "dashboard" && "top__nav__dashboard__link"}`}
                            activePaths={item.activePaths}
                            onClick={() => {
                              captureEvent("TOP_" + item.name + "Btn");
                            }}
                          >
                            {item.icon && (
                              <Image
                                src={item.icon}
                                alt=""
                                width={item.id === "settings" ? 16 : 18}
                                className="mr-3"
                              />
                            )}{" "}
                            {item.name}
                          </NavAnchor>
                        </li>
                      )}
                    </>
                  )}
                </>
              ))}
            </ul>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex items-center">
            {theme === "light" && (
              <span
                className="mr-8 cursor-pointer transition-all text-text-700"
                onClick={() => {
                  setTheme("dark");
                  captureEvent("TOP_ThemeBtn");
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3333 13.2035C17.2388 13.6985 16.0239 13.974 14.7446 13.974C9.92941 13.974 6.02597 10.0706 6.02597 5.25544C6.02597 3.97612 6.30151 2.76115 6.79648 1.66666C3.77147 3.03468 1.66667 6.07891 1.66667 9.61473C1.66667 14.4299 5.57012 18.3333 10.3853 18.3333C13.9211 18.3333 16.9653 16.2285 18.3333 13.2035Z"
                    stroke="currentColor"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            )}
            {theme === "dark" && (
              <Sun
                onClick={() => {
                  setTheme("light");
                  captureEvent("TOP_ThemeBtn");
                }}
                className="h-[1.25rem] w-[1.25rem] cursor-pointer transition-all mr-8"
              />
            )}

            {userData?.workspaces?.data && (
              <WorkspaceDropdown
                activeWorkspace={userData?.activeWorkspace}
                workspaces={userData?.workspaces?.data}
                switchWorkspace={switchWorkspace}
              />
            )}
            {/* <Image src="/images/icons/bell.svg" alt="" className="mx-7" /> */}
            <span className="mx-4"></span>
            {userData?.id && (
              <ProfileDropdown
                data={{
                  name: userData?.name,
                  icon: userData?.profile_photo_url,
                  email: userData?.email,
                }}
              />
            )}
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-background px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href={`/${userData?.activeWorkspace?.slug}/`} className="-m-1.5 p-1.5 w-[260px]">
                <span className="sr-only">LambdaTest</span>
                {/* <Image className="h-8 w-auto" src="/images/logos/logo.svg" alt="" /> */}
                {appname === "PrimeRole" ? (
                  <>
                    {theme === "dark" ? (
                      <Image
                        src="/images/apps/primerole/logo-dark.svg"
                        className="logo no-invert w-[170px]"
                        alt="Logo"
                      />
                    ) : (
                      <Image src={logo} className="logo w-[170px]" alt="Logo" />
                    )}
                  </>
                ) : (
                  <Image src={logo} className="logo w-[170px]" alt="Logo" />
                )}
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <NavAnchor
                      key={item.name}
                      to={`${item.href}`}
                      className="-mx-3 block text-right rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      onClick={() => {
                        captureEvent("TOP_" + item.name + "Btn");
                      }}
                    >
                      {item.name}
                    </NavAnchor>
                  ))}
                </div>
                <div className="py-6">
                  <div className="text-right flex justify-end">
                    {theme === "light" && (
                      <span
                        className="cursor-pointer transition-all text-text-700"
                        onClick={() => {
                          setTheme("dark");
                          captureEvent("TOP_ThemeBtn");
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.3333 13.2035C17.2388 13.6985 16.0239 13.974 14.7446 13.974C9.92941 13.974 6.02597 10.0706 6.02597 5.25544C6.02597 3.97612 6.30151 2.76115 6.79648 1.66666C3.77147 3.03468 1.66667 6.07891 1.66667 9.61473C1.66667 14.4299 5.57012 18.3333 10.3853 18.3333C13.9211 18.3333 16.9653 16.2285 18.3333 13.2035Z"
                            stroke="currentColor"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    )}
                    {theme === "dark" && (
                      <Sun
                        onClick={() => {
                          setTheme("light");
                          captureEvent("TOP_ThemeBtn");
                        }}
                        className="h-[1.25rem] w-[1.25rem] cursor-pointer transition-all"
                      />
                    )}
                  </div>
                  <div className="flex justify-end mt-6">
                    {userData?.workspaces?.data && (
                      <WorkspaceDropdown
                        activeWorkspace={userData?.activeWorkspace}
                        workspaces={userData?.workspaces?.data}
                        switchWorkspace={switchWorkspace}
                      />
                    )}
                  </div>
                  <div className="flex justify-end mt-6">
                    {userData?.id && (
                      <ProfileDropdown
                        data={{
                          name: userData?.name,
                          icon: userData?.profile_photo_url,
                          email: userData?.email,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
}
