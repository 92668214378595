import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import Layout from "../../../Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../../ui/breadcrumb";
import NavAnchor from "../../../Tags/NavAnchor";
import Image from "../../../Tags/Image";
import Text from "../../../Tags/Text";
import { Button } from "../../../ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import SharedDataContext from "../../../../SharedDataContext";
import {
  getIntegrations,
  getPlatformFields,
  getSalesforceObjectFields,
  getSavedSalesforceConfig,
  saveSalesforceObjectsMapping,
} from "../../../../apis/workspace/integrations";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "../../../ui/skeleton";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import toast from "react-hot-toast";
import SelectField from "./SelectField";
import { Alert, AlertDescription, AlertTitle } from "../../../ui/alert";

import TooltipWrapper from "../../../TooltipWrapper";
import ConfirmModal from "../../../Modals/ConfirmModal";
import RefTable from "./RefTable";
import GeneralInfo from "./GeneralInfo";
import ObjectSettings from "./ObjectSettings";
import UserMapping from "./UserMapping";
import AdvanceCountry from "./AdvanceCountry";

import { v4 as uuidv4 } from "uuid";
import { PlusIcon } from "lucide-react";
import CustomFieldSelect from "./CustomFieldSelect";
import AdvanceMapping from "./AdvanceMapping";

export default function SalesforceMapping() {
  const { salesforceConfigId } = useParams();
  const navigate = useNavigate();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState("general");
  const [mapActiveTab, setMapActiveTab] = useState("company");
  const [mapActiveTabApi, setMapActiveTabApi] = useState("");
  // get integrations
  const [currentIntegration, setCurrentIntegration] = useState({});
  const getIntegrationsFn = useQuery(
    "get_integrations",
    () => {
      return getIntegrations({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        if (data && data.installed && data.installed.length > 0) {
          const obj = data.installed.find((item) => item.id === +salesforceConfigId);
          // console.log("obj", obj);
          setCurrentIntegration(obj);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetIntegrations = () => {
    getIntegrationsFn.refetch();
  };

  useEffect(() => {
    if (activeWorkspace?.id && salesforceConfigId) {
      handleGetIntegrations();
    }
  }, [activeWorkspace, salesforceConfigId]);

  // end integrations

  const [mappedSavedConfig, setMappedSavedConfig] = useState({});
  const [apiCalledEntry, setApiCalledEntry] = useState([]);
  const addApiCalledEntry = (newEntry) => {
    setApiCalledEntry((prevEntries) => [...prevEntries, newEntry]);
  };

  const updateMappedSavedConfig = (key, obj) => {
    setMappedSavedConfig((prevConfig) => ({
      ...prevConfig,
      [key]: {
        ...(prevConfig[key] === null ? {} : prevConfig[key]),
        ...obj,
      },
    }));
  };

  const [savedConfig, setSavedConfig] = useState([]);
  const [companiesArr, setCompaniesArr] = useState([]);

  const updateSavedConfigById = (id, updatedData) => {
    setSavedConfig((prevConfig) =>
      prevConfig.map((item) => (item.id === id ? { ...item, ...updatedData } : item))
    );
  };

  //  mapping obj

  const [mapping, setMapping] = useState([]);
  const [currentMapping, setCurrentMapping] = useState("company");

  useEffect(() => {
    if (activeTab !== "map") {
      setMapActiveTabApi("");
      setMappedSavedConfig({});
      setApiCalledEntry([]);
      setCurrentConfig({});
      setSavedConfig([]);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id && salesforceConfigId && activeTab) {
      if (activeTab === "map") {
        handleGetPlatformFields();
      }
    }
  }, [activeWorkspace, salesforceConfigId, activeTab]);

  const [currentConfig, setCurrentConfig] = useState({});

  // get getPlateformFields

  const [platformFieldsWrapper, setPlatformFieldsWrapper] = useState({});
  const getPlatformFieldsFn = useMutation("get_platform_fields", getPlatformFields, {
    onSuccess(data, variables, context) {
      setPlatformFieldsWrapper(data);
    },
  });
  const handleGetPlatformFields = () => {
    getPlatformFieldsFn.mutate({
      type: "organizations",
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  const processedApiNames = useRef(new Set()); // Track already processed API names

  useEffect(() => {
    if (activeTab === "map") {
      if (
        savedConfig &&
        savedConfig.length > 0 &&
        platformFieldsWrapper &&
        (platformFieldsWrapper.company || platformFieldsWrapper.person)
      ) {
        savedConfig.forEach((el) => {
          const apiName = el?.salesforce_object_api_name;
          // Check if the API name has already been processed
          if (apiName && !processedApiNames.current.has(apiName)) {
            handleGetSalesforceObjectFields(apiName);
            processedApiNames.current.add(apiName); // Mark as processed
          }
        });
      }
    } else {
      processedApiNames.current.clear();
    }
  }, [savedConfig, platformFieldsWrapper, activeTab]);

  useEffect(() => {
    if (savedConfig && savedConfig.length > 0 && apiCalledEntry && activeTab === "map") {
      if (savedConfig.length === apiCalledEntry.length) {
        setMapActiveTabApi(savedConfig[0].salesforce_object_api_name);
        setMapActiveTab(savedConfig[0].type);

        if (savedConfig[0].type === "person") {
          setCurrentMapping("person");
        }
        if (savedConfig[0].type === "company") {
          setCurrentMapping("company");
        }
      }
    }
  }, [apiCalledEntry.length, activeTab]);

  // salesforceobject fields

  const [salesforceObjectFields, setSalesforceObjectFields] = useState([]);
  const getSalesforceObjectFieldsFn = useMutation("get_salesforce_object_fields", getSalesforceObjectFields, {
    onSuccess(data, variables, context) {
      // console.log("getSalesforceObjectFieldsFn", data);
      if (data?.fields && data?.fields.length > 0) {
        const obj = savedConfig.find((el) => el.salesforce_object_api_name === data?.salesforce_object);
        if (obj) {
          let arr = [];
          if (obj.type === "person") {
            arr = data?.fields;
            // arr = data?.fields.filter((item) => item?.salesforce_field_type !== "reference");
          } else {
            arr = data?.fields;
          }
          const requiredFields = arr.filter((field) => field.required);
          const noRequiredFields = arr.filter((field) => !field.required);
          const requiredFieldsPicklist = arr.filter(
            (field) => field.required && field.salesforce_field_type === "picklist"
          );
          const requiredFieldsWithoutPicklist = arr.filter(
            (field) => field.required && field.salesforce_field_type !== "picklist"
          );
          const noRequiredFieldsPicklist = arr.filter(
            (field) => !field.required && field.salesforce_field_type === "picklist"
          );
          const noRequiredFieldsWithoutPicklist = arr.filter(
            (field) => !field.required && field.salesforce_field_type !== "picklist"
          );

          setSalesforceObjectFields(arr);
          // setMappedSavedConfig({ salesforce_field_api_name: data?.salesforce_object, fields: data?.fields });
          const deepCopyObj = JSON.parse(JSON.stringify(obj));
          if (deepCopyObj && deepCopyObj.integrationConfig) {
            delete deepCopyObj.integrationConfig;
          }

          const initObj = {
            ...deepCopyObj,
            salesforceObjectFieldsRaw: data?.fields,
            salesforceObjectFields: arr,
            salesforceObjectRequiredFields: requiredFields,
            salesforceObjectNonRequiredFields: noRequiredFields,
            salesforceObjectRequiredFieldsPicklist: requiredFieldsPicklist,
            salesforceObjectNonRequiredFieldsPicklist: noRequiredFieldsPicklist,
            salesforceObjectRequiredFieldsWithoutPicklist: requiredFieldsWithoutPicklist,
            salesforceObjectNonRequiredFieldsWithoutPicklist: noRequiredFieldsWithoutPicklist,
            fieldsFetched: true,
          };

          if (
            obj.type === "person" &&
            platformFieldsWrapper &&
            platformFieldsWrapper.person &&
            platformFieldsWrapper.person.length > 0
          ) {
            let arr = platformFieldsWrapper.person.map((item) => ({
              system_field_name: item?.key,
              system_field_label: item?.label,
              salesforce_field_api_name: null,
              salesforce_field_label: null,
              salesforce_field_type: null,
              action: "overwrite_if_exists",
            }));
            initObj.mapping = arr;

            if (initObj.mappings && initObj.mappings.length > 0) {
              const updatedMapping = initObj.mapping.map((item) => {
                const newItem = initObj.mappings.find(
                  (item2) => item2.system_field_name === item?.system_field_name
                );
                let matchedObj = {};
                matchedObj = data?.fields?.find(
                  (field) => field.salesforce_field_api_name === newItem?.salesforce_field_api_name
                );
                return newItem && matchedObj ? { ...item, ...newItem, required: matchedObj?.required ? true : false, } : item;
              });
              initObj.mapping = updatedMapping;

              const customMappings = initObj.mappings.filter((item) => item.is_custom);
              if (customMappings && customMappings.length > 0) {
                customMappings.forEach((el) => {
                  if (
                    !initObj.mapping.some(
                      (item) => item.key === el?.system_field_name && item.salesforce_field_api_name !== null
                    )
                  ) {
                    let matchedObj = {};
                    matchedObj = data?.fields?.find(
                      (field) => field.salesforce_field_api_name === el?.salesforce_field_api_name
                    );
                    if(matchedObj) {
                      initObj.mapping.push({
                        ...el,
                        required: matchedObj?.required ? true : false,
                        system_field_type:
                          el?.salesforce_field_type === "picklist"
                            ? "custom_dropdown_field"
                            : "custom_text_field",
                      });
                    }
                  }
                });
              }
            }

            initObj.platformFields = platformFieldsWrapper.person;

            const requiredFields = platformFieldsWrapper.person.filter((field) => field.required);
            initObj.requiredPlatformFields = requiredFields;

            if (initObj.mappings && initObj.mappings.length > 0) {
              const customMappings = initObj.mappings.filter((item) => item.is_custom);
              if (customMappings && customMappings.length > 0) {
                customMappings.forEach((el) => {
                  if (!initObj.platformFields.some((field) => field.key === el?.system_field_name)) {
                    initObj.platformFields.push({
                      key: el?.system_field_name,
                      is_custom: true,
                      api_key: obj?.salesforce_object_api_name,
                    });
                  }
                });
              }
            }
          }
          if (
            obj.type === "company" &&
            platformFieldsWrapper &&
            platformFieldsWrapper.company &&
            platformFieldsWrapper.company.length > 0
          ) {
            let arr = platformFieldsWrapper.company.map((item) => ({
              system_field_name: item?.key,
              system_field_label: item?.label,
              salesforce_field_api_name: null,
              salesforce_field_label: null,
              salesforce_field_type: null,
              action: "overwrite_if_exists",
            }));
            initObj.mapping = arr;

            if (initObj.mappings && initObj.mappings.length > 0) {
              const updatedMapping = initObj.mapping.map((item) => {
                const newItem = initObj.mappings.find(
                  (item2) => item2.system_field_name === item?.system_field_name
                );

                let matchedObj = {};
                matchedObj = data?.fields?.find(
                  (field) => field.salesforce_field_api_name === newItem?.salesforce_field_api_name
                );
                return newItem && matchedObj ? { ...item, ...newItem, required: matchedObj?.required ? true : false, } : item;
              });
              initObj.mapping = updatedMapping;

              const customMappings = initObj.mappings.filter((item) => item.is_custom);
              if (customMappings && customMappings.length > 0) {
                customMappings.forEach((el) => {
                  if (!initObj.mapping.some((item) => item.key === el?.system_field_name)) {
                    let matchedObj = {};
                    matchedObj = data?.fields?.find(
                      (field) => field.salesforce_field_api_name === el?.salesforce_field_api_name
                    );
                    if(matchedObj) {
                      initObj.mapping.push({
                        ...el,
                        required: matchedObj?.required ? true : false,
                        system_field_type:
                          el?.salesforce_field_type === "picklist"
                            ? "custom_dropdown_field"
                            : "custom_text_field",
                      });
                    }
                  }
                });
              }
            }

            initObj.platformFields = platformFieldsWrapper.company;
            const requiredFields = platformFieldsWrapper.company.filter((field) => field.required);
            initObj.requiredPlatformFields = requiredFields;

            if (initObj.mappings && initObj.mappings.length > 0) {
              const customMappings = initObj.mappings.filter((item) => item.is_custom);
              if (customMappings && customMappings.length > 0) {
                customMappings.forEach((el) => {
                  if (!initObj.platformFields.some((field) => field.key === el?.system_field_name)) {
                    initObj.platformFields.push({
                      key: el?.system_field_name,
                      is_custom: true,
                      api_key: obj?.salesforce_object_api_name,
                    });
                  }
                });
              }
            }
          }

          updateMappedSavedConfig(data?.salesforce_object, initObj);
          addApiCalledEntry(data?.salesforce_object);
        }
      }
    },
  });
  const handleGetSalesforceObjectFields = (apiName) => {
    // setApiCalledEntry([]);
    getSalesforceObjectFieldsFn.mutate({
      salesforceConfigId: salesforceConfigId,
      object: apiName,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // use effect

  const updateKeysOfSpecificObject = (systemFieldName, newFields, keysToDelete = []) => {
    const newarr = mappedSavedConfig[mapActiveTabApi]?.mapping.map((item) => {
      if (
        item?.salesforce_field_api_name === newFields?.salesforce_field_api_name &&
        item?.salesforce_field_api_name !== null
      ) {
        const updatedItem = {
          ...item,
          salesforce_field_api_name: null,
          salesforce_field_label: null,
          salesforce_field_type: null,
        };
        // delete updatedItem.required;
        keysToDelete.forEach((key) => {
          delete updatedItem[key];
        });

        return updatedItem;
      }
      if (item.system_field_name === systemFieldName) {
        const updatedItem = { ...item, ...newFields };
        // delete updatedItem.required;
        keysToDelete.forEach((key) => {
          delete updatedItem[key];
        });

        return updatedItem;
      }
      return item;
    });

    updateMappedSavedConfig(mapActiveTabApi, {
      mapping: newarr,
    });

    setMapping(newarr);
    findUnmappedRequiredFields(
      newarr,
      mappedSavedConfig[mapActiveTabApi]?.salesforceObjectRequiredFields,
      mappedSavedConfig[mapActiveTabApi]?.requiredPlatformFields
    );
  };

  const checkIfSalesforceFieldApiNameIsNull = (array, systemFieldName) => {
    const item = array.find((obj) => obj.system_field_name === systemFieldName);
    return item ? item.salesforce_field_api_name === null : false;
  };
  const mappingObjByPlatformField = (array, systemFieldName) => {
    const item = array.find((obj) => obj.system_field_name === systemFieldName);
    return item ? item : {};
  };

  const saveSalesforceObjectsMappingFn = useMutation(
    "save_salesforce_object_config_mapping",
    saveSalesforceObjectsMapping,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);

        const updatedMapping = mappedSavedConfig[mapActiveTabApi]?.mapping.map((item) => {
          const newItem = data?.data.mappings.find(
            (item2) => item2.system_field_name === item?.system_field_name
          );

          return newItem ? { ...item, ...newItem } : item;
        });
        updateMappedSavedConfig(mapActiveTabApi, {
          mapping: updatedMapping,
          mappings: data?.data.mappings,
        });
      },
    }
  );

  const [refMapped, setRefMapped] = useState([]);

  const handleSaveSalesforceObjectsMapping = () => {
    const newarr = mappedSavedConfig[mapActiveTabApi]?.mapping.filter(
      (item) => item.salesforce_field_api_name !== null
    );
    if (saveSalesforceObjectsMappingFn.isLoading) {
      return;
    }
    let type = "";

    const obj = savedConfig.find((item) => item.salesforce_object_api_name === mapActiveTabApi) || {};

    type = obj?.id;

    const finalMappingArr = newarr.map((item) => ({
      action: item?.action,
      salesforce_field_api_name: item?.salesforce_field_api_name,
      salesforce_field_label: item?.salesforce_field_label,
      salesforce_field_type: item?.salesforce_field_type,
      system_field_name: item?.system_field_name,
      system_field_label: item?.system_field_label,
      is_custom: item?.is_custom ? true : false,
      required: item?.required ? true : false,
      ...(item.advanced_mapping ? { advanced_mapping: item.advanced_mapping } : {}),
      ...(item.salesforce_field_type === "picklist" ? { picklist_values: item.picklist_values } : {}),
      ...(item.salesforce_field_type === "reference" ? { reference_to: item.reference_to } : {}),
    }));

    let payload = { mappings: finalMappingArr };
    if (mapActiveTab === "person") {
      payload = {
        mappings: finalMappingArr,
        reference_fields: mappedSavedConfig[mapActiveTabApi]?.reference_mappings,
      };
      if (
        (payload.reference_fields && payload.reference_fields.length === 0) ||
        payload.reference_fields === null
      ) {
        delete payload.reference_fields;
      }
    }

    console.log("save map fields payload", payload);

    saveSalesforceObjectsMappingFn.mutate({
      salesforceConfigId: salesforceConfigId,
      type: type,
      data: payload,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  const findUnmappedRequiredFields = (mapping, requiredFields, requiredPlatformFields) => {
    if (mapping && mapping.length > 0) {
      const mappingMap = new Map(mapping.map((item) => [item.salesforce_field_api_name, item]));

      const unmappedFields = requiredFields.filter((requiredItem) => {
        const mappingItem = mappingMap.get(requiredItem.salesforce_field_api_name);

        return !mappingItem || mappingItem.salesforce_field_api_name === null;
      });

      updateMappedSavedConfig(mapActiveTabApi, { unmappedRequiredFields: unmappedFields });

      // code for platform required fields
      const mappingMap2 = new Map(mapping.map((item) => [item.system_field_name, item]));
      const unmappedFields2 = requiredPlatformFields.filter((requiredItem) => {
        const mappingItem = mappingMap2.get(requiredItem.key);
        return !mappingItem || mappingItem.salesforce_field_api_name === null;
      });

      updateMappedSavedConfig(mapActiveTabApi, { unmappedRequiredPlatformFields: unmappedFields2 });
    }
  };

  useLayoutEffect(() => {
    if (
      mappedSavedConfig &&
      mapActiveTabApi &&
      mappedSavedConfig[mapActiveTabApi] &&
      mappedSavedConfig[mapActiveTabApi].mapping &&
      mappedSavedConfig[mapActiveTabApi].mapping.length > 0 &&
      mappedSavedConfig[mapActiveTabApi].salesforceObjectRequiredFields &&
      mappedSavedConfig[mapActiveTabApi].requiredPlatformFields
    ) {
      findUnmappedRequiredFields(
        mappedSavedConfig[mapActiveTabApi].mapping,
        mappedSavedConfig[mapActiveTabApi].salesforceObjectRequiredFields,
        mappedSavedConfig[mapActiveTabApi].requiredPlatformFields
      );
    }
  }, [mapActiveTabApi]);

  // const [referenceToMapping, setReferenceToMapping] = useState([]);

  // useEffect(() => {
  //   // console.log("mapping", mapping);
  //   if (
  //     mapping &&
  //     mapping.length > 0 &&
  //     salesforceObjectFields &&
  //     salesforceObjectFields.length > 0 &&
  //     savedConfig &&
  //     savedConfig.length > 0
  //   ) {
  //     const filteredArray = salesforceObjectFields.filter(
  //       (item) => item.required && item.hasOwnProperty("reference_to")
  //     );
  //     const notInSavedConfig = filteredArray.filter(
  //       (field) => !savedConfig.some((config) => config.salesforce_object_api_name === field?.reference_to[0])
  //     );
  //     setReferenceToMapping(notInSavedConfig);
  //   }
  // }, [mapping, salesforceObjectFields, savedConfig]);

  useEffect(() => {
    // console.log("mapping", mapping);
    if (
      mappedSavedConfig &&
      mappedSavedConfig[mapActiveTabApi] &&
      mappedSavedConfig[mapActiveTabApi].mapping &&
      mappedSavedConfig[mapActiveTabApi].mapping.length > 0 &&
      mappedSavedConfig[mapActiveTabApi].salesforceObjectFields &&
      mappedSavedConfig[mapActiveTabApi].salesforceObjectFields.length > 0 &&
      savedConfig &&
      savedConfig.length > 0
    ) {
      const filteredArray = mappedSavedConfig[mapActiveTabApi].salesforceObjectFields.filter(
        (item) => item.required && item.hasOwnProperty("reference_to")
      );
      const notInSavedConfig = filteredArray.filter(
        (field) => !savedConfig.some((config) => config.salesforce_object_api_name === field?.reference_to[0])
      );

      // setReferenceToMapping(notInSavedConfig);
      updateMappedSavedConfig(mapActiveTabApi, { referenceToMapping: notInSavedConfig });
    }
  }, [mapActiveTabApi]);

  // click next
  const goNext = () => {
    const hasPerson = savedConfig.some((item) => item.type === "person");
    const hasCompany = savedConfig.some((item) => item.type === "company");
    if (hasPerson && hasCompany) {
      setActiveTab("map");
    } else {
      toast.error("Atleast one Company and One People should be mapped to continue.");
    }
  };

  // update config name
  const [isUpdateIntegrationSubmitting, setIsUpdateIntegrationSubmitting] = useState(false);
  const [isUpdateIntegrationLoading, setIsUpdateIntegrationLoading] = useState(false);

  // confirm modal
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    if (data && data.confirm && data.type && data.type === "new") {
      navigate(`/${activeWorkspace.slug}/settings/integrations`);
    }
    if (data && data.confirm && data.type && data.type === "saved") {
      navigate(`/${activeWorkspace.slug}/settings/integrations`);
    }
  };

  // get salesforce object list
  const getSavedSalesforceConfigFn = useMutation("get_saved_salesforce_object", getSavedSalesforceConfig, {
    onSuccess(data, variables, context) {
      if (data && data.data && data.data.length > 0) {
        const objCompany = data?.data.find((obj) => obj.type === "company");
        const objPeople = data?.data.find((obj) => obj.type === "person");

        const arr = data?.data.filter((obj) => obj.type === "company");
        setCompaniesArr(arr);

        const configArr = data?.data.sort((a, b) => a.type.localeCompare(b.type));
        setSavedConfig(configArr);

        setCurrentConfig(objCompany);
      }
    },
  });

  const handleGetSavedSalesforceConfig = () => {
    getSavedSalesforceConfigFn.mutate({
      salesforceConfigId: salesforceConfigId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id && salesforceConfigId && activeTab !== "object_settings") {
      handleGetSavedSalesforceConfig();
    }
  }, [activeWorkspace, salesforceConfigId, activeTab]);

  //  updating user mapping
  const [isUserMappingSubmitting, setIsUserMappingSubmitting] = useState(false);
  const [isUserMappingLoading, setIsUserMappingLoading] = useState(false);
  const [isUserMappingSaveDisabled, setIsUserMappingSaveDisabled] = useState(false);

  const filterAndMapCustomFields = (array, key) => {
    const defaultArr = [
      {
        system_field_type: "custom_text_field",
        system_field_name: "custom_text_field",
        system_field_label: "Custom Text Field",
      },
      {
        system_field_type: "custom_dropdown_field",
        system_field_name: "custom_dropdown_field",
        system_field_label: "Custom Dropdown Field",
      },
    ];

    // Filter to get matched items with the specified key and specific types
    const matchedArray = array
      .filter(
        (item) =>
          item.is_custom === true &&
          item.system_field_label &&
          item.system_field_name === key &&
          (item.system_field_type === "custom_text_field" ||
            item.system_field_type === "custom_dropdown_field")
      )
      .map((item) => ({
        system_field_name: item.system_field_name,
        system_field_label: item.system_field_label,
        system_field_type: item.system_field_type,
      }));

    // Check if custom_text_field or custom_dropdown_field types are in matchedArray
    const hasCustomTextField = matchedArray.some((item) => item.system_field_type === "custom_text_field");
    const hasCustomDropdownField = matchedArray.some(
      (item) => item.system_field_type === "custom_dropdown_field"
    );

    // Add the missing type from defaultArr if it doesn't exist in matchedArray
    const additionalDefaultItems = defaultArr.filter((defaultItem) => {
      if (
        (defaultItem.system_field_type === "custom_text_field" && !hasCustomTextField) ||
        (defaultItem.system_field_type === "custom_dropdown_field" && !hasCustomDropdownField)
      ) {
        return true; // Include this item if not in matchedArray
      }
      return false; // Exclude if already in matchedArray
    });

    // Return matched items plus filtered default items
    const finalArr = [...matchedArray, ...additionalDefaultItems];

    return finalArr;
  };

  const findCustomMappingBySystemFieldName = (array, systemFieldName, required = true) => {
    const obj = array.find((item) => item.system_field_name === systemFieldName && item.is_custom) || {};
    if (obj && obj.system_field_type === "custom_dropdown_field" && required) {
      return mappedSavedConfig[mapActiveTabApi]?.salesforceObjectRequiredFieldsPicklist;
    } else if (obj && obj.system_field_type === "custom_text_field" && required) {
      return mappedSavedConfig[mapActiveTabApi]?.salesforceObjectRequiredFieldsWithoutPicklist;
    } else if (obj && obj.system_field_type === "custom_dropdown_field" && !required) {
      return mappedSavedConfig[mapActiveTabApi]?.salesforceObjectNonRequiredFieldsPicklist;
    } else if (obj && obj.system_field_type === "custom_text_field" && !required) {
      return mappedSavedConfig[mapActiveTabApi]?.salesforceObjectNonRequiredFieldsWithoutPicklist;
    } else if (required) {
      return mappedSavedConfig[mapActiveTabApi]?.salesforceObjectRequiredFields;
    } else {
      return mappedSavedConfig[mapActiveTabApi]?.salesforceObjectNonRequiredFields;
    }
  };

  const getCurrentCustomMapRowObj = (array, key) => {
    const obj = array.find((item) => item.system_field_name === key) || {};
    // console.log("getCurrentCustomMapRowObj", obj);
    if (obj && obj.system_field_label) {
      return {
        label: obj?.system_field_label,
        system_field_label: obj?.system_field_label,
        system_field_name: key,
        system_field_type: obj?.system_field_type,
        value: obj?.system_field_type,
      };
    } else {
      return {};
    }
  };

  // useEffect(() => {
  //   console.log(" mappedSavedConfig[mapActiveTabApi]", mappedSavedConfig[mapActiveTabApi]);
  // }, [mapActiveTabApi, mapping]);

  return (
    <Layout leftNav={false}>
      <div className="px-12">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <NavAnchor to={`/`}>
                <Image src="/images/icons/untitledui/home.svg" width="16" height="16" />
              </NavAnchor>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <NavAnchor to={`/settings/integrations/`}>Integrations</NavAnchor>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Configure Salesforce connection</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="sm:flex sm:items-center my-6">
          {activeTab !== "user_mapping" ? (
            <div className="sm:flex-auto">
              <Text size="lg">Select objects and choose object type</Text>
              <Text size="p" className="mt-1">
                Select and configure the objects that you want to sync with the platform
              </Text>
            </div>
          ) : (
            <div className="sm:flex-auto">
              <Text size="lg">User Mapping</Text>
              <Text size="p" className="mt-1">
                User mapping allows Prospecto to set the record owner as the user creating the record
              </Text>
            </div>
          )}
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none space-x-2 flex items-center">
            {activeTab !== "user_mapping" ? (
              <>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpenConfirmModal(true);
                    setOpenConfirmModalType("new");
                  }}
                >
                  Cancel
                </Button>
                {activeTab === "general" && (
                  <Button
                    type="button"
                    onClick={() => {
                      setIsUpdateIntegrationSubmitting(true);
                    }}
                    disabled={isUpdateIntegrationLoading}
                  >
                    Update
                    {isUpdateIntegrationLoading && <div className="loader_circle inline-block ml-1"></div>}
                  </Button>
                )}
                {activeTab === "object_settings" && (
                  <Button
                    type="button"
                    onClick={() => {
                      goNext();
                    }}
                  >
                    Next
                  </Button>
                )}
                {activeTab === "map" && (
                  <Button
                    disabled={
                      saveSalesforceObjectsMappingFn.isLoading ||
                      (mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields &&
                        mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields.length > 0) ||
                      (mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredPlatformFields &&
                        mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredPlatformFields.length > 0) ||
                      mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.length > 0
                    }
                    onClick={handleSaveSalesforceObjectsMapping}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1.5"
                    >
                      <g id="save-01">
                        <path
                          id="Icon"
                          d="M5.83333 2.5V5.33333C5.83333 5.80004 5.83333 6.0334 5.92416 6.21166C6.00406 6.36846 6.13154 6.49594 6.28834 6.57584C6.4666 6.66667 6.69996 6.66667 7.16667 6.66667H12.8333C13.3 6.66667 13.5334 6.66667 13.7117 6.57584C13.8685 6.49594 13.9959 6.36846 14.0758 6.21166C14.1667 6.0334 14.1667 5.80004 14.1667 5.33333V3.33333M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z"
                          stroke="white"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>{" "}
                    Save
                    {saveSalesforceObjectsMappingFn.isLoading && (
                      <div className="loader_circle inline-block ml-2"></div>
                    )}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  type="button"
                  onClick={() => {
                    setIsUserMappingSubmitting(true);
                  }}
                  disabled={isUserMappingSaveDisabled || isUserMappingLoading}
                >
                  Save
                  {isUserMappingLoading && <div className="loader_circle inline-block ml-1"></div>}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="border-b border-border my-6"></div>
        <div className={""}>
          <Tabs
            defaultValue="general"
            value={activeTab}
            className="w-full mt-6"
            onValueChange={(value) => {
              // console.log("value", value);
              setActiveTab(value);
            }}
          >
            <TabsList className="w-full">
              <TabsTrigger value="general" className="flex-1 h-11">
                General
              </TabsTrigger>
              <TabsTrigger value="object_settings" className="flex-1 h-11">
                Object Settings
              </TabsTrigger>
              {savedConfig && savedConfig.length === 0 ? (
                <div className="flex-1 justify-center flex h-11">
                  {" "}
                  <TooltipWrapper content={"Please map Salesforce objects in Object Settings tab."}>
                    Map Fields
                  </TooltipWrapper>
                </div>
              ) : (
                <TabsTrigger value="map" className="flex-1 h-11">
                  Map Fields
                </TabsTrigger>
              )}
              <TabsTrigger value="user_mapping" className="flex-1 h-11">
                User Mapping
              </TabsTrigger>
            </TabsList>
            <TabsContent value="general">
              <div className="mt-6">
                <GeneralInfo
                  onSuccess={() => {
                    setIsUpdateIntegrationSubmitting(false);
                    queryClient.invalidateQueries("get_integrations");
                    setActiveTab("object_settings");
                  }}
                  onError={() => {
                    setIsUpdateIntegrationSubmitting(false);
                  }}
                  isLoading={(state) => {
                    setIsUpdateIntegrationLoading(state);
                  }}
                  activeWorkspace={activeWorkspace}
                  currentIntegration={currentIntegration}
                  isSubmitting={isUpdateIntegrationSubmitting}
                />
              </div>
            </TabsContent>
            <TabsContent value="object_settings">
              <div className={"w-8/12 mt-6"}>
                <div>
                  <ObjectSettings
                    onSuccess={() => {
                      // setIsUpdateIntegrationSubmitting(false);
                      // queryClient.invalidateQueries("get_integrations");
                      // setActiveTab("object_settings");
                    }}
                    onError={() => {
                      // setIsUpdateIntegrationSubmitting(false);
                    }}
                    isLoading={(state) => {
                      // setIsUpdateIntegrationLoading(state);
                    }}
                    activeWorkspace={activeWorkspace}
                    getSavedConfig={(data) => {
                      setSavedConfig(data);
                    }}
                    getCompaniesArr={(data) => {
                      setCompaniesArr(data);
                    }}
                    getCurrentConfig={(data) => {
                      setCurrentConfig(data);
                    }}
                  />
                </div>
              </div>
            </TabsContent>
            <TabsContent value="map">
              {/* {apiCalledEntry.length} */}
              {savedConfig && savedConfig.length > 0 && (
                // mappedSavedConfig[mapActiveTabApi]?.mapping &&
                // mappedSavedConfig[mapActiveTabApi]?.mapping.length > 0 &&
                // mappedSavedConfig[mapActiveTabApi]?.platformFields &&
                // mappedSavedConfig[mapActiveTabApi]?.platformFields.length > 0 &&
                <Tabs
                  defaultValue={mapActiveTabApi}
                  value={mapActiveTabApi}
                  className="w-full mt-6"
                  onValueChange={(value) => {
                    // console.log("value", value);
                    // setMapping([]);
                    // setUnmappedRequiredFields([]);
                    // setUnmappedRequiredPlatformFields([]);
                    // setSalesforceObjectFieldsRaw([]);
                    // setPlatformFields([]);
                    setMapActiveTabApi(value);
                    const obj = savedConfig.find((item) => item.salesforce_object_api_name === value) || {};
                    setMapActiveTab(obj?.type);
                    setCurrentConfig(obj);
                  }}
                >
                  <div className="flex justify-between">
                    <TabsList
                      className={
                        getSalesforceObjectFieldsFn.isLoading ? "pointer-events-none cursor-not-allowed" : ""
                      }
                    >
                      {savedConfig &&
                        savedConfig.length > 0 &&
                        savedConfig.map((item) => (
                          <TabsTrigger value={item?.salesforce_object_api_name} className="h-11">
                            {item?.salesforce_object_label}
                          </TabsTrigger>
                        ))}
                    </TabsList>
                  </div>
                  {savedConfig && savedConfig.length > 0 && (
                    <>
                      <TabsContent value={mapActiveTab === "company" && mapActiveTabApi}>
                        <div className="my-6">
                          <div className="w-full">
                            {!getSalesforceObjectFieldsFn.isLoading && !getPlatformFieldsFn.isLoading && (
                              <div>
                                {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.length > 0 && (
                                  <Alert className="mb-4">
                                    <div className="flex items-start space-x-3">
                                      <Image
                                        src="/images/icons/untitledui/alert-error.svg"
                                        width="30"
                                        height="30"
                                        className="no-invert inline-block -mt-1 -ml-1"
                                      />
                                      <div>
                                        <AlertTitle className="text-gray-900 font-bold mb-2">
                                          Reference Objects:{" "}
                                          {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.length}{" "}
                                          mandatory {referenceToMapping.length === 1 ? "object" : "objects"}{" "}
                                          left to map
                                        </AlertTitle>
                                        <AlertDescription>
                                          {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.map(
                                            (item, index) => (
                                              <>
                                                <span>{item?.salesforce_field_label}</span>
                                                {index + 1 ===
                                                mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.length
                                                  ? ""
                                                  : ", "}
                                              </>
                                            )
                                          )}{" "}
                                          {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping.length === 1
                                            ? "is"
                                            : "are"}{" "}
                                          mandatory to map to create new records
                                        </AlertDescription>
                                      </div>
                                    </div>
                                  </Alert>
                                )}

                                {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields &&
                                  mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields.length > 0 && (
                                    <Alert>
                                      <div className="flex items-start space-x-3">
                                        <Image
                                          src="/images/icons/untitledui/alert-error.svg"
                                          width="30"
                                          height="30"
                                          className="no-invert inline-block -mt-1 -ml-1"
                                        />
                                        <div>
                                          <AlertTitle className="text-gray-900 font-bold mb-2">
                                            Salesforce Field:{" "}
                                            {
                                              mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                                .length
                                            }{" "}
                                            mandatory{" "}
                                            {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                              .length === 1
                                              ? "field"
                                              : "fields"}{" "}
                                            left to map
                                          </AlertTitle>
                                          <AlertDescription>
                                            {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields.map(
                                              (item, index) => (
                                                <>
                                                  <span>{item?.salesforce_field_label}</span>
                                                  {index + 1 ===
                                                  mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                                    .length
                                                    ? ""
                                                    : ", "}
                                                </>
                                              )
                                            )}{" "}
                                            {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                              .length === 1
                                              ? "is"
                                              : "are"}{" "}
                                            mandatory to map to create new records
                                          </AlertDescription>
                                        </div>
                                      </div>
                                    </Alert>
                                  )}
                              </div>
                            )}
                            {/* {!getPlatformFieldsFn.isLoading && !getSalesforceObjectFieldsFn.isLoading && (
                              <div className="mt-6">
                                {unmappedRequiredPlatformFields.length > 0 && (
                                  <Alert>
                                    <div className="flex items-start space-x-3">
                                      <Image
                                        src="/images/icons/untitledui/alert-error.svg"
                                        width="30"
                                        height="30"
                                        className="no-invert inline-block -mt-1 -ml-1"
                                      />
                                      <div>
                                        <AlertTitle className="text-gray-900 font-bold mb-2">
                                          System Field: {unmappedRequiredPlatformFields.length} mandatory{" "}
                                          {unmappedRequiredPlatformFields.length === 1 ? "field" : "fields"}{" "}
                                          left to map
                                        </AlertTitle>
                                        <AlertDescription>
                                          {unmappedRequiredPlatformFields.map((item, index) => (
                                            <>
                                              <span>{item?.label}</span>
                                              {index + 1 === unmappedRequiredPlatformFields.length
                                                ? ""
                                                : ", "}
                                            </>
                                          ))}{" "}
                                          {unmappedRequiredPlatformFields.length === 1 ? "is" : "are"}{" "}
                                          mandatory to map to create new records
                                        </AlertDescription>
                                      </div>
                                    </div>
                                  </Alert>
                                )}
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div className="mt-6 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden border border-border rounded-[--radius]">
                                <table className="min-w-full divide-y divide-border">
                                  <thead className="bg-background">
                                    <tr>
                                      <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-normal w-3/12`}
                                      >
                                        <span className="inline-flex items-center space-x-2">
                                          <Image
                                            src={"/favicon.ico"}
                                            width={20}
                                            height={20}
                                            className="inline-block"
                                          />{" "}
                                          <span>System Field</span>
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-normal w-3/12"
                                      >
                                        <span className="inline-flex items-center space-x-2">
                                          <Image
                                            src="/images/logos/integrations/salesforce.svg"
                                            className="no-invert"
                                            width={24}
                                          />
                                          <span>Salesforce Field</span>
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-normal w-4/12"
                                      >
                                        Action
                                      </th>
                                      {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal ">
                                        Example
                                      </th> */}
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-normal w-2/12"
                                      >
                                        Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-border bg-background">
                                    {mappedSavedConfig[mapActiveTabApi]?.platformFields &&
                                      mappedSavedConfig[mapActiveTabApi]?.platformFields?.length > 0 &&
                                      mappedSavedConfig[mapActiveTabApi]?.platformFields.map(
                                        (item, index) => (
                                          <tr className={index % 2 !== 0 ? "bg-smoke" : ""}>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph w-3/12">
                                              {item?.is_custom ? (
                                                <CustomFieldSelect
                                                  data={[
                                                    ...filterAndMapCustomFields(
                                                      mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                      item?.key
                                                    ),
                                                  ]}
                                                  getCurrent={(value) => {
                                                    updateKeysOfSpecificObject(item?.key, {
                                                      system_field_type: value?.value,
                                                      system_field_label: value?.system_field_label,
                                                    });
                                                  }}
                                                  selected={item?.key}
                                                  defaultCurrent={getCurrentCustomMapRowObj(
                                                    mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                    item?.key
                                                  )}
                                                />
                                              ) : (
                                                <>
                                                  {item?.label}
                                                  {item?.required && <span className="text-red-500">*</span>}
                                                </>
                                              )}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-3/12">
                                              {getSalesforceObjectFieldsFn.isLoading ? (
                                                <Skeleton className={"rounded-lg h-11 w-[290px]"} />
                                              ) : (
                                                <>
                                                  {/* {mappingObjByPlatformField(mapping, item?.label)} */}
                                                  <SelectField
                                                    mapping={mappedSavedConfig[mapActiveTabApi]?.mapping}
                                                    salesforceObjectRequiredFields={findCustomMappingBySystemFieldName(
                                                      mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                      item?.key,
                                                      true
                                                    )}
                                                    salesforceObjectNonRequiredFields={findCustomMappingBySystemFieldName(
                                                      mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                      item?.key,
                                                      false
                                                    )}
                                                    data={
                                                      mappedSavedConfig[mapActiveTabApi]
                                                        ?.salesforceObjectFields
                                                    }
                                                    selected={
                                                      mappingObjByPlatformField(
                                                        mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                        item?.key
                                                      )?.salesforce_field_api_name
                                                    }
                                                    getCurrent={(value) => {
                                                      // console.log("first", value);
                                                      const selectedValue = value.salesforce_field_api_name;
                                                      let selectedOption = mappedSavedConfig[
                                                        mapActiveTabApi
                                                      ].salesforceObjectFields.find(
                                                        (option) =>
                                                          option.salesforce_field_api_name === selectedValue
                                                      );

                                                      // console.log("selectedOption", selectedOption);
                                                      updateKeysOfSpecificObject(
                                                        item?.key,
                                                        selectedOption
                                                          ? {
                                                              ...selectedOption,
                                                              ...(item?.key === "headquarters_country"
                                                                ? {
                                                                    advanced_mapping: {
                                                                      value: "full",
                                                                      type: "country",
                                                                    },
                                                                  }
                                                                : {}),
                                                            }
                                                          : {
                                                              salesforce_field_api_name: null,
                                                              salesforce_field_label: null,
                                                              salesforce_field_type: null,
                                                            }
                                                      );
                                                    }}
                                                    type={mapActiveTabApi}
                                                  />
                                                  {item?.key === "headquarters_country" &&
                                                    item?.advanced_mapping_options && (
                                                      <span className="ml-3">
                                                        <AdvanceCountry
                                                          formats={item?.advanced_mapping_options?.formats}
                                                          onConfirm={(obj) => {
                                                            updateKeysOfSpecificObject(item?.key, {
                                                              advanced_mapping: obj,
                                                            });
                                                          }}
                                                          advanced_mapping={
                                                            mappingObjByPlatformField(
                                                              mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                              item?.key
                                                            )?.advanced_mapping
                                                          }
                                                        />
                                                      </span>
                                                    )}
                                                </>
                                              )}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-4/12">
                                              <select
                                                value={
                                                  mappingObjByPlatformField(
                                                    mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                    item?.key
                                                  )?.action
                                                    ? mappingObjByPlatformField(
                                                        mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                        item?.key
                                                      )?.action
                                                    : "overwrite_if_exists"
                                                }
                                                className="font-bold inline-flex h-11 px-[14px] py-[10px] text-sm  rounded-[--radius] border border-input bg-background text-text-900 w-[290px]"
                                                onChange={(event) => {
                                                  const selectedValue = event.target.value;
                                                  // console.log("selectedValue", selectedValue);
                                                  let action = "overwrite_if_exists";
                                                  if (selectedValue === "add_if_empty") {
                                                    action = "add_if_empty";
                                                  } else if (selectedValue === "overwrite_if_exists") {
                                                    action = "overwrite_if_exists";
                                                  } else {
                                                    action = "overwrite_if_exists";
                                                  }
                                                  updateKeysOfSpecificObject(item?.key, { action: action });
                                                }}
                                              >
                                                <option value="overwrite_if_exists">
                                                  Overwrite if exists
                                                </option>
                                                <option value="add_if_empty">Add if empty </option>
                                              </select>
                                            </td>
                                            {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                            linkedin.com/company/alph
                                          </td> */}
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-2/12">
                                              {/* {!item?.required && (
                                                <Image
                                                  src="/images/icons/untitledui/trash.svg"
                                                  width="20"
                                                  className="inline-block no-invert cursor-pointer"
                                                  onClick={() => {
                                                    const updatedPlatformFields = (
                                                      mappedSavedConfig[mapActiveTabApi]?.platformFields || []
                                                    ).filter((el) => el.key !== item?.key);

                                                    const updatedMappingFields = (
                                                      mappedSavedConfig[mapActiveTabApi]?.mapping || []
                                                    ).filter((el) => el.system_field_name !== item?.key);

                                                    updateMappedSavedConfig(mapActiveTabApi, {
                                                      mapping: updatedMappingFields,
                                                    });
                                                    updateMappedSavedConfig(mapActiveTabApi, {
                                                      platformFields: updatedPlatformFields,
                                                    });
                                                  }}
                                                />
                                              )} */}
                                              {item?.is_custom ? (
                                                <>
                                                  <Image
                                                    src="/images/icons/untitledui/trash.svg"
                                                    width="20"
                                                    className="inline-block no-invert cursor-pointer"
                                                    onClick={() => {
                                                      const updatedPlatformFields = (
                                                        mappedSavedConfig[mapActiveTabApi]?.platformFields ||
                                                        []
                                                      ).filter((el) => el.key !== item?.key);

                                                      const updatedMappingFields = (
                                                        mappedSavedConfig[mapActiveTabApi]?.mapping || []
                                                      ).filter((el) => el.system_field_name !== item?.key);

                                                      updateMappedSavedConfig(mapActiveTabApi, {
                                                        mapping: updatedMappingFields,
                                                      });
                                                      updateMappedSavedConfig(mapActiveTabApi, {
                                                        platformFields: updatedPlatformFields,
                                                      });
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  {mappedSavedConfig[mapActiveTabApi]?.mapping &&
                                                  mappedSavedConfig[mapActiveTabApi]?.mapping.length > 0 &&
                                                  checkIfSalesforceFieldApiNameIsNull(
                                                    mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                    item?.key
                                                  ) ? (
                                                    <CrossCircledIcon
                                                      width={20}
                                                      height={20}
                                                      className="text-gray-600"
                                                    />
                                                  ) : (
                                                    <Image
                                                      src="/images/icons/untitledui/check-purple.svg"
                                                      width="20"
                                                      className="inline-block no-invert"
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    {mappedSavedConfig[mapActiveTabApi]?.platformFields &&
                                      mappedSavedConfig[mapActiveTabApi]?.platformFields?.length > 0 && (
                                        <tr>
                                          <td>
                                            <button
                                              className="py-2"
                                              onClick={() => {
                                                const id = uuidv4();
                                                const updatedPlatformFields = [
                                                  ...(mappedSavedConfig[mapActiveTabApi]?.platformFields ||
                                                    []), // Safely copy the existing array or use an empty array if undefined
                                                  {
                                                    key: id,
                                                    is_custom: true,
                                                    api_key: mapActiveTabApi,
                                                  },
                                                ];
                                                const updatedMappingFields = [
                                                  ...(mappedSavedConfig[mapActiveTabApi]?.mapping || []), // Safely copy the existing array or use an empty array if undefined
                                                  {
                                                    action: "overwrite_if_exists",
                                                    salesforce_field_api_name: "",
                                                    salesforce_field_label: "",
                                                    salesforce_field_type: "string",
                                                    system_field_name: id,
                                                    is_custom: true,
                                                  },
                                                ];

                                                updateMappedSavedConfig(mapActiveTabApi, {
                                                  mapping: updatedMappingFields,
                                                });
                                                updateMappedSavedConfig(mapActiveTabApi, {
                                                  platformFields: updatedPlatformFields,
                                                });
                                              }}
                                            >
                                              <PlusIcon className="inline-block" width={16} height={16} /> Add
                                              field
                                            </button>
                                          </td>
                                        </tr>
                                      )}

                                    {savedConfig.length > apiCalledEntry.length && (
                                      <>
                                        <tr>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                          <td>
                                            <Skeleton className="h-10" />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabsContent>
                      <TabsContent value={mapActiveTab === "person" && mapActiveTabApi}>
                        <div className="my-6">
                          <div className="w-full">
                            {!getSalesforceObjectFieldsFn.isLoading && !getPlatformFieldsFn.isLoading && (
                              <div>
                                {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.length > 0 && (
                                  <Alert className="mb-4">
                                    <div className="flex items-start space-x-3">
                                      <Image
                                        src="/images/icons/untitledui/alert-error.svg"
                                        width="30"
                                        height="30"
                                        className="no-invert inline-block -mt-1 -ml-1"
                                      />
                                      <div>
                                        <AlertTitle className="text-gray-900 font-bold mb-2">
                                          Reference Objects:{" "}
                                          {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.length}{" "}
                                          mandatory{" "}
                                          {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping?.length ===
                                          1
                                            ? "object"
                                            : "objects"}{" "}
                                          left to map
                                        </AlertTitle>
                                        <AlertDescription>
                                          {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping.map(
                                            (item, index) => (
                                              <>
                                                <span>{item?.salesforce_field_label}</span>
                                                {index + 1 ===
                                                mappedSavedConfig[mapActiveTabApi]?.referenceToMapping.length
                                                  ? ""
                                                  : ", "}
                                              </>
                                            )
                                          )}{" "}
                                          {mappedSavedConfig[mapActiveTabApi]?.referenceToMapping.length === 1
                                            ? "is"
                                            : "are"}{" "}
                                          mandatory to map to create new records
                                        </AlertDescription>
                                      </div>
                                    </div>
                                  </Alert>
                                )}
                                {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields &&
                                  mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields.length > 0 && (
                                    <Alert>
                                      <div className="flex items-start space-x-3">
                                        <Image
                                          src="/images/icons/untitledui/alert-error.svg"
                                          width="30"
                                          height="30"
                                          className="no-invert inline-block -mt-1 -ml-1"
                                        />
                                        <div>
                                          <AlertTitle className="text-gray-900 font-bold mb-2">
                                            Salesforce Field:{" "}
                                            {
                                              mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                                .length
                                            }{" "}
                                            mandatory{" "}
                                            {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                              .length === 1
                                              ? "field"
                                              : "fields"}{" "}
                                            left to map
                                          </AlertTitle>
                                          <AlertDescription>
                                            {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields.map(
                                              (item, index) => (
                                                <>
                                                  <span>{item?.salesforce_field_label}</span>
                                                  {index + 1 ===
                                                  mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                                    .length
                                                    ? ""
                                                    : ", "}
                                                </>
                                              )
                                            )}{" "}
                                            {mappedSavedConfig[mapActiveTabApi]?.unmappedRequiredFields
                                              .length === 1
                                              ? "is"
                                              : "are"}{" "}
                                            mandatory to map to create new records
                                          </AlertDescription>
                                        </div>
                                      </div>
                                    </Alert>
                                  )}
                              </div>
                            )}
                            {/* {!getPlatformFieldsFn.isLoading && !getSalesforceObjectFieldsFn.isLoading && (
                              <div className="mt-6">
                                {unmappedRequiredPlatformFields.length > 0 && (
                                  <Alert>
                                    <div className="flex items-start space-x-3">
                                      <Image
                                        src="/images/icons/untitledui/alert-error.svg"
                                        width="30"
                                        height="30"
                                        className="no-invert inline-block -mt-1 -ml-1"
                                      />
                                      <div>
                                        <AlertTitle className="text-gray-900 font-bold mb-2">
                                          System Field: {unmappedRequiredPlatformFields.length} mandatory{" "}
                                          {unmappedRequiredPlatformFields.length === 1 ? "field" : "fields"}{" "}
                                          left to map
                                        </AlertTitle>
                                        <AlertDescription>
                                          {unmappedRequiredPlatformFields.map((item, index) => (
                                            <>
                                              <span>{item?.label}</span>
                                              {index + 1 === unmappedRequiredPlatformFields.length
                                                ? ""
                                                : ", "}
                                            </>
                                          ))}{" "}
                                          {unmappedRequiredPlatformFields.length === 1 ? "is" : "are"}{" "}
                                          mandatory to map to create new records
                                        </AlertDescription>
                                      </div>
                                    </div>
                                  </Alert>
                                )}
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div className="mt-6 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="overflow-hidden border border-border rounded-[--radius]">
                                <table className="min-w-full divide-y divide-border">
                                  <thead className="bg-background">
                                    <tr>
                                      <th
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-normal w-3/12`}
                                      >
                                        <span className="inline-flex items-center space-x-2">
                                          <Image
                                            src={"/favicon.ico"}
                                            width={20}
                                            height={20}
                                            className="inline-block"
                                          />{" "}
                                          <span>System Field</span>
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-normal w-3/12"
                                      >
                                        <span className="inline-flex items-center space-x-2">
                                          <Image
                                            src="/images/logos/integrations/salesforce.svg"
                                            className="no-invert"
                                            width={24}
                                          />
                                          <span>Salesforce Field</span>
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-normal w-4/12"
                                      >
                                        Action
                                      </th>
                                      {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal ">
                                        Example
                                      </th> */}
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-normal w-2/12"
                                      >
                                        Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-border bg-background">
                                    {mappedSavedConfig[mapActiveTabApi]?.platformFields &&
                                      mappedSavedConfig[mapActiveTabApi]?.platformFields?.length > 0 &&
                                      mappedSavedConfig[mapActiveTabApi]?.platformFields.map(
                                        (item, index) => (
                                          <>
                                            {(item.data_type ||
                                              (item.is_custom && item.api_key === mapActiveTabApi)) && (
                                              <tr
                                                className={index % 2 !== 0 ? "bg-smoke" : ""}
                                                key={item?.key}
                                              >
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph w-3/12">
                                                  {item?.is_custom ? (
                                                    <CustomFieldSelect
                                                      data={[
                                                        ...filterAndMapCustomFields(
                                                          mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                          item?.key
                                                        ),
                                                      ]}
                                                      getCurrent={(value) => {
                                                        updateKeysOfSpecificObject(item?.key, {
                                                          system_field_type: value?.value,
                                                          system_field_label: value?.system_field_label,
                                                        });
                                                      }}
                                                      selected={item?.key}
                                                      defaultCurrent={getCurrentCustomMapRowObj(
                                                        mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                        item?.key
                                                      )}
                                                    />
                                                  ) : (
                                                    <>
                                                      {item?.label}
                                                      {item?.required && (
                                                        <span className="text-red-500">*</span>
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-3/12">
                                                  {getSalesforceObjectFieldsFn.isLoading ? (
                                                    <Skeleton className={"rounded-lg h-11 w-[290px]"} />
                                                  ) : (
                                                    <>
                                                      <SelectField
                                                        mapping={mappedSavedConfig[mapActiveTabApi]?.mapping}
                                                        salesforceObjectRequiredFields={findCustomMappingBySystemFieldName(
                                                          mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                          item?.key,
                                                          true
                                                        )}
                                                        salesforceObjectNonRequiredFields={findCustomMappingBySystemFieldName(
                                                          mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                          item?.key,
                                                          false
                                                        )}
                                                        data={
                                                          mappedSavedConfig[mapActiveTabApi]
                                                            ?.salesforceObjectFields
                                                        }
                                                        selected={
                                                          mappingObjByPlatformField(
                                                            mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                            item?.key
                                                          )?.salesforce_field_api_name
                                                        }
                                                        getCurrent={(value) => {
                                                          // console.log("first", value);
                                                          const selectedValue =
                                                            value.salesforce_field_api_name;
                                                          let selectedOption = mappedSavedConfig[
                                                            mapActiveTabApi
                                                          ].salesforceObjectFields.find(
                                                            (option) =>
                                                              option.salesforce_field_api_name ===
                                                              selectedValue
                                                          );

                                                          // console.log("selectedOption", selectedOption);
                                                          updateKeysOfSpecificObject(
                                                            item?.key,
                                                            selectedOption
                                                              ? {
                                                                  ...selectedOption,
                                                                  ...(item?.key === "country"
                                                                    ? {
                                                                        advanced_mapping: {
                                                                          value: "full",
                                                                          type: "country",
                                                                        },
                                                                      }
                                                                    : {}),
                                                                }
                                                              : {
                                                                  salesforce_field_api_name: null,
                                                                  salesforce_field_label: null,
                                                                  salesforce_field_type: null,
                                                                }
                                                          );
                                                        }}
                                                        type={mapActiveTabApi}
                                                      />
                                                      {item?.key === "country" &&
                                                        item?.advanced_mapping_options && (
                                                          <span className="ml-3">
                                                            <AdvanceCountry
                                                              formats={
                                                                item?.advanced_mapping_options?.formats
                                                              }
                                                              onConfirm={(obj) => {
                                                                updateKeysOfSpecificObject(item?.key, {
                                                                  advanced_mapping: obj,
                                                                });
                                                              }}
                                                              advanced_mapping={
                                                                mappingObjByPlatformField(
                                                                  mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                                  item?.key
                                                                )?.advanced_mapping
                                                              }
                                                            />
                                                          </span>
                                                        )}

                                                      {item?.key !== "country" &&
                                                        item?.data_type === "enum" &&
                                                        item?.advanced_mapping_options &&
                                                        item?.advanced_mapping_options?.values &&
                                                        item?.advanced_mapping_options?.values.length > 0 &&
                                                        mappingObjByPlatformField(
                                                          mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                          item?.key
                                                        ) &&
                                                        mappingObjByPlatformField(
                                                          mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                          item?.key
                                                        )?.salesforce_field_type === "picklist" && (
                                                          <span className="ml-3">
                                                            <AdvanceMapping
                                                              onConfirm={(obj) => {
                                                                if (
                                                                  obj === null ||
                                                                  (obj && Object.keys(obj).length === 0)
                                                                ) {
                                                                  updateKeysOfSpecificObject(item?.key, {}, [
                                                                    "advanced_mapping",
                                                                  ]);
                                                                } else {
                                                                  updateKeysOfSpecificObject(item?.key, {
                                                                    advanced_mapping: { value_mapping: obj },
                                                                  });
                                                                }
                                                              }}
                                                              item={item}
                                                              selected={mappingObjByPlatformField(
                                                                mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                                item?.key
                                                              )}
                                                              advanced_mapping={
                                                                mappingObjByPlatformField(
                                                                  mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                                  item?.key
                                                                )?.advanced_mapping
                                                              }
                                                            />
                                                          </span>
                                                        )}
                                                    </>
                                                  )}
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-4/12">
                                                  <select
                                                    value={
                                                      mappingObjByPlatformField(
                                                        mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                        item?.key
                                                      )?.action
                                                        ? mappingObjByPlatformField(
                                                            mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                            item?.key
                                                          )?.action
                                                        : "overwrite_if_exists"
                                                    }
                                                    className="font-bold inline-flex h-11 px-[14px] py-[10px] text-sm  rounded-[--radius] border border-input bg-background text-text-900 w-[290px]"
                                                    onChange={(event) => {
                                                      const selectedValue = event.target.value;
                                                      // console.log("selectedValue", selectedValue);
                                                      let action = "overwrite_if_exists";
                                                      if (selectedValue === "add_if_empty") {
                                                        action = "add_if_empty";
                                                      } else if (selectedValue === "overwrite_if_exists") {
                                                        action = "overwrite_if_exists";
                                                      } else {
                                                        action = "overwrite_if_exists";
                                                      }
                                                      updateKeysOfSpecificObject(item?.key, {
                                                        action: action,
                                                      });
                                                    }}
                                                  >
                                                    <option value="overwrite_if_exists">
                                                      Overwrite if exists
                                                    </option>
                                                    <option value="add_if_empty">Add if empty </option>
                                                  </select>
                                                </td>
                                                {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                            linkedin.com/company/alph
                                          </td> */}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-2/12">
                                                  {/* {mappedSavedConfig[mapActiveTabApi]?.mapping &&
                                              mappedSavedConfig[mapActiveTabApi]?.mapping.length > 0 &&
                                              checkIfSalesforceFieldApiNameIsNull(
                                                mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                item?.key
                                              ) ? (
                                                <CrossCircledIcon
                                                  width={20}
                                                  height={20}
                                                  className="text-gray-600"
                                                />
                                              ) : (
                                                <Image
                                                  src="/images/icons/untitledui/check-purple.svg"
                                                  width="20"
                                                  className="inline-block no-invert"
                                                />
                                              )} */}

                                                  {item?.is_custom ? (
                                                    <>
                                                      <Image
                                                        src="/images/icons/untitledui/trash.svg"
                                                        width="20"
                                                        className="inline-block no-invert cursor-pointer"
                                                        onClick={() => {
                                                          const updatedPlatformFields = (
                                                            mappedSavedConfig[mapActiveTabApi]
                                                              ?.platformFields || []
                                                          ).filter((el) => el.key !== item?.key);

                                                          const updatedMappingFields = (
                                                            mappedSavedConfig[mapActiveTabApi]?.mapping || []
                                                          ).filter(
                                                            (el) => el.system_field_name !== item?.key
                                                          );

                                                          updateMappedSavedConfig(mapActiveTabApi, {
                                                            mapping: updatedMappingFields,
                                                          });
                                                          updateMappedSavedConfig(mapActiveTabApi, {
                                                            platformFields: updatedPlatformFields,
                                                          });
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      {mappedSavedConfig[mapActiveTabApi]?.mapping &&
                                                      mappedSavedConfig[mapActiveTabApi]?.mapping.length >
                                                        0 &&
                                                      checkIfSalesforceFieldApiNameIsNull(
                                                        mappedSavedConfig[mapActiveTabApi]?.mapping,
                                                        item?.key
                                                      ) ? (
                                                        <CrossCircledIcon
                                                          width={20}
                                                          height={20}
                                                          className="text-gray-600"
                                                        />
                                                      ) : (
                                                        <Image
                                                          src="/images/icons/untitledui/check-purple.svg"
                                                          width="20"
                                                          className="inline-block no-invert"
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      )}
                                    {mappedSavedConfig[mapActiveTabApi]?.platformFields &&
                                      mappedSavedConfig[mapActiveTabApi]?.platformFields?.length > 0 && (
                                        <tr>
                                          <td>
                                            <button
                                              className="py-2"
                                              onClick={() => {
                                                const id = uuidv4();
                                                const updatedPlatformFields = [
                                                  ...(mappedSavedConfig[mapActiveTabApi]?.platformFields ||
                                                    []), // Safely copy the existing array or use an empty array if undefined
                                                  {
                                                    key: id,
                                                    is_custom: true,
                                                    api_key: mapActiveTabApi,
                                                  },
                                                ];
                                                const updatedMappingFields = [
                                                  ...(mappedSavedConfig[mapActiveTabApi]?.mapping || []), // Safely copy the existing array or use an empty array if undefined
                                                  {
                                                    action: "overwrite_if_exists",
                                                    salesforce_field_api_name: "",
                                                    salesforce_field_label: "",
                                                    salesforce_field_type: "string",
                                                    system_field_name: id,
                                                    is_custom: true,
                                                  },
                                                ];

                                                updateMappedSavedConfig(mapActiveTabApi, {
                                                  mapping: updatedMappingFields,
                                                });
                                                updateMappedSavedConfig(mapActiveTabApi, {
                                                  platformFields: updatedPlatformFields,
                                                });
                                              }}
                                            >
                                              <PlusIcon className="inline-block" width={16} height={16} /> Add
                                              field
                                            </button>
                                          </td>
                                        </tr>
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {companiesArr &&
                          companiesArr.length > 0 &&
                          !getSalesforceObjectFieldsFn.isLoading && (
                            <>
                              <RefTable
                                data={companiesArr}
                                salesforceConfigId={salesforceConfigId}
                                activeWorkspaceId={activeWorkspace?.id}
                                getRefMappedArr={(refMapped) => {
                                  updateMappedSavedConfig(mapActiveTabApi, {
                                    reference_mappings: refMapped,
                                  });

                                  setRefMapped(refMapped);
                                }}
                                sourceData={mappedSavedConfig[mapActiveTabApi]?.salesforceObjectFieldsRaw}
                                reference_mappings={
                                  mappedSavedConfig[mapActiveTabApi]?.reference_mappings
                                    ? mappedSavedConfig[mapActiveTabApi]?.reference_mappings
                                    : null
                                }
                                activeTab={mapActiveTabApi}
                              />
                            </>
                          )}
                      </TabsContent>
                    </>
                  )}
                </Tabs>
              )}
            </TabsContent>
            <TabsContent value="user_mapping">
              <UserMapping
                activeWorkspace={activeWorkspace}
                configId={salesforceConfigId}
                onSuccess={() => {
                  setIsUserMappingSubmitting(false);
                }}
                onError={() => {
                  setIsUserMappingSubmitting(false);
                }}
                isLoading={(state) => {
                  setIsUserMappingLoading(state);
                }}
                isSubmitting={isUserMappingSubmitting}
                saveDisabled={(state) => {
                  setIsUserMappingSaveDisabled(state);
                }}
              />
            </TabsContent>
          </Tabs>
        </div>
        <ConfirmModal
          openModal={openConfirmModal}
          modalType={openConfirmModalType}
          confirmModalState={confirmModalState}
          title="Salesforce configuration alert!"
          confirmTitle="Exit"
          cancelTitle="No"
          subText={
            currentConfig?.salesforce_object_api_name
              ? "Are you sure you want to exit configuration? Any changes made will not be saved."
              : "Salesforce connection configuration is incomplete and connection will not be active until completed. Are you sure you want to exit configuration?"
          }
        />
      </div>
    </Layout>
  );
}
