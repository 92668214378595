import React, { useContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import SharedDataContext from "../../../SharedDataContext";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { getCreditConsumptionLog } from "../../../apis/workspace/security";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";
import Text from "../../Tags/Text";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function CreditConsumptionLog() {
  const captureEvent = useCaptureEvent();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const validationSchema = yup.object().shape({
    from_date: yup.string().required("From date is required"),
    to_date: yup.string().required("To date is required"),
  });

  const formik = useFormik({
    initialValues: {
      from_date: "",
      to_date: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Submitted values:", values);
      getCreditConsumptionLogFn.mutate({
        data: {
          from_date: values.from_date,
          to_date: values.to_date,
        },
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    },
  });

  function sanitizeFilename(name) {
    // Remove invalid characters
    const invalidChars = /[\\/:*?"<>|\x00-\x1F\x80-\x9F]/g; // Regex for invalid characters
    let sanitized = name.replace(invalidChars, "");

    // Prepend 'Enriched-' to the filename
    // sanitized = "Enriched-" + sanitized;

    // Truncate the filename if it's too long (200 characters is a common maximum)
    const maxLength = 200;
    sanitized = sanitized.substring(0, maxLength);

    // Avoid filenames that are reserved in Windows
    const reservedNames = [
      "CON",
      "PRN",
      "AUX",
      "NUL",
      "COM1",
      "COM2",
      "COM3",
      "COM4",
      "COM5",
      "COM6",
      "COM7",
      "COM8",
      "COM9",
      "LPT1",
      "LPT2",
      "LPT3",
      "LPT4",
      "LPT5",
      "LPT6",
      "LPT7",
      "LPT8",
      "LPT9",
    ];
    if (reservedNames.includes(sanitized.toUpperCase())) {
      sanitized = "_" + sanitized;
    }

    // Ensure the filename is not empty
    if (sanitized.trim() === "") {
      sanitized = "unnamed_file";
    }

    return sanitized;
  }

  const getCreditConsumptionLogFn = useMutation("credit_consumption_log", getCreditConsumptionLog, {
    onSuccess(data, variables, context) {
      console.log(data);
      toast.success("Logs Downloaded");
      formik.setSubmitting(false);
      formik.resetForm();

      // Sanitize the item name to be used as the file name
      const sanitizedFileName = sanitizeFilename("credit-consumption-log") + ".csv";

      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([data]));
      // Create a temporary anchor tag
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", sanitizedFileName); // Set the sanitized file name for download
      document.body.appendChild(fileLink);

      // Programmatically click the link to start download
      fileLink.click();

      // Clean up
      fileLink.remove();
      window.URL.revokeObjectURL(fileURL);
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });
  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Text size="lg">Download Credit Consumption Log</Text>
          <Text size="p" className="mt-1">
            Download logs
          </Text>
        </div>
      </div>
      <div className="border-b border-border my-5"></div>
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <div className="flex -mx-2 mobile:block">
          <div className="w-3/12 px-2 mobile:w-full">
            <div>
              <label htmlFor="from_date" className="block text-sm font-medium text-paragraph  mb-1">
                From
              </label>
              <Input
                id="from_date"
                name="from_date"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.from_date}
              />
              {formik.touched.from_date && formik.errors.from_date && (
                <p className="mt-1 text-red-500 text-sm">{formik.errors.from_date}</p>
              )}
            </div>
          </div>
          <div className="w-3/12 px-2 mobile:w-full mobile:mt-4">
            <div>
              <label htmlFor="to_date" className="block text-sm font-medium text-paragraph mb-1">
                To
              </label>
              <Input
                id="to_date"
                name="to_date"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.to_date}
              />
              {formik.touched.to_date && formik.errors.to_date && (
                <p className="mt-1 text-red-500 text-sm">{formik.errors.to_date}</p>
              )}
            </div>
          </div>
          <div className="w-3/12 px-2">
            <div className="mt-6">
              <Button type="submit" disabled={formik.isSubmitting || !formik.isValid}
              onClick={() => {
                captureEvent("SET_CreditLog_DownloadBtn");
                }}
              >
                Download
                {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
