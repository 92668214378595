import React, { useEffect, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
import { cn } from "../../../../lib/utils";
import { Button } from "../../../ui/button";
import { Check, ChevronDown } from "lucide-react";
import Input from "../../../Tags/Input";
import TooltipWrapper from "../../../TooltipWrapper";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export default function CustomFieldSelect({ data = [], defaultCurrent = {}, selected = "", getCurrent }) {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState({});
  const [initLoad, setInitLoad] = useState(true);
  const updateCurrentKey = (key, value) => {
    setCurrent((prevState) => ({
      ...prevState, // Copy the existing state
      [key]: value, // Update the specific key with the new value
    }));
  };
  useEffect(() => {
    if (data && data.length > 0 && selected) {
      const obj = data.find((obj) => obj.system_field_type === selected);
      setCurrent(obj);
      // console.log("current", obj);
    }
  }, [data && data.length && selected]);
  useEffect(() => {
    if (!initLoad) {
      getCurrent(current);
    }
  }, [current, initLoad]);

  useEffect(() => {
    if (defaultCurrent && defaultCurrent.label && defaultCurrent.system_field_type && initLoad) {
      setCurrent(defaultCurrent);
      setInitLoad(false);
    }
  }, [defaultCurrent, initLoad]);

  const returnToolTip = (type) => {
    if (type === "custom_text_field") {
      return "Custom Text Field";
    } else if (type === "custom_dropdown_field") {
      return "Custom Dropdown Field";
    } else {
      return "Custom Field";
    }
  };

  return (
    <>
      {!current?.system_field_type ? (
        <Popover open={open} onOpenChange={setOpen} className="w-[400px]">
          {/* <div className={"flex justify-end relative w-[290px]"}>
        <Input
          placeholder="--Select--"
          className={"absolute w-[290px] max-w-[290px] left-0 font-bold"}
          value={current && current?.system_field_label && current?.system_field_label}
          defaultValue={current && current?.system_field_label && current?.system_field_label}
          onChange={(e) => {
            updateCurrentKey("system_field_label", e.target.value);
          }}
        />
       
      </div> */}
          <PopoverTrigger asChild className="z-10">
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="justify-between h-11 w-[290px] rounded-[8px] font-semibold pr-2.5"
            >
              {current && current?.system_field_label ? current?.system_field_label : "--Select--"}
              <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className=" p-0" align="end">
            <Command>
              <div className="p-2">
                <div className="border  rounded-[8px] border-b-0 overflow-hidden">
                  <CommandInput placeholder="Search" />
                </div>
              </div>
              <CommandList>
                <CommandEmpty>No List found.</CommandEmpty>
                <CommandGroup>
                  {data &&
                    data.length > 0 &&
                    data.map((item) => {
                      const newItem = {
                        ...item,
                        value: item.system_field_type,
                        label: item.system_field_label,
                      };

                      return (
                        <CommandItem
                          key={newItem.system_field_type}
                          value={newItem.label}
                          onSelect={() => {
                            if (newItem.system_field_type === current?.system_field_type) {
                              setCurrent({
                                ...newItem,
                                system_field_type: "",
                                system_field_label: "",
                              });
                              getCurrent({
                                ...newItem,
                                system_field_type: "",
                                system_field_label: "",
                              });
                              setOpen(false);
                            } else {
                              setCurrent(newItem);
                              getCurrent(newItem);
                              setOpen(false);
                            }
                          }}
                          className="min-h-[40px]"
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              current?.system_field_type === newItem.system_field_type
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {newItem.label}
                        </CommandItem>
                      );
                    })}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      ) : (
        <div className="relative w-[290px] max-w-[290px] ">
          <Input
            placeholder="--Select--"
            className={"font-bold"}
            value={current && current?.system_field_label && current?.system_field_label}
            defaultValue={current && current?.system_field_label && current?.system_field_label}
            onChange={(e) => {
              updateCurrentKey("system_field_label", e.target.value);
            }}
          />
          <span className="absolute right-3 top-3.5">
            <TooltipWrapper content={returnToolTip(current?.system_field_type)}>
              <InfoCircledIcon width={16} height={16} />
            </TooltipWrapper>
          </span>
        </div>
      )}
    </>
  );
}
