import React, { useEffect, useState } from "react";
import NavAnchor from "../../Tags/NavAnchor";
import Image from "../../Tags/Image";
import { ChevronLeftCircle, ChevronRightCircle } from "lucide-react";

export default function EnrichLeftNav() {
  const menus = [
    {
      name: "People",
      href: "/enrich/people",
      icon: "/images/icons/untitledui/user-square.svg",
      activePaths: ["/enrich/people", "/enrich/people/*"],
    },
    // {
    //   name: "Companies",
    //   href: "/enrich/company",
    //   icon: "/images/icons/untitledui/building.svg",
    //   activePaths: ["/enrich/company", "/enrich/company/*"],
    // },
    {
      name: "CSV Enrichment",
      href: "/enrich/list",
      icon: "/images/icons/untitledui/table.svg",
      activePaths: ["/enrich/list", "/enrich/list/*"],
    },
  ];

  const [sidebarMini, setSidebarMini] = useState(() => {
    // Initialize state from local storage or fallback to false
    const savedState = localStorage.getItem("sidebarMini");
    return savedState !== null ? JSON.parse(savedState) : false;
  });

  const toggleSidebar = () => {
    setSidebarMini((prevSidebarMini) => !prevSidebarMini);
  };

  // Update local storage whenever sidebarMini changes
  useEffect(() => {
    localStorage.setItem("sidebarMini", JSON.stringify(sidebarMini));
  }, [sidebarMini]);

  return (
    <div
      className={`relative p-4  transition-[width] ease-in-out ${
        sidebarMini ? "w-[78px] sidebar__mini" : "w-[260px] sidebar__full"
      }`}
    >
      <div className="flex items-center w-full justify-between mb-4 sidebar__heading__wrapper">
        <h3 className="font-medium text-base text-text-900 sidebar__heading">Enrichment</h3>
        <div className="text-brand-600 font-normal bg-brand-100 rounded-full cursor-pointer">
          {sidebarMini ? (
            <ChevronRightCircle width={18} height={18} onClick={toggleSidebar} />
          ) : (
            <ChevronLeftCircle width={18} height={18} onClick={toggleSidebar} />
          )}
        </div>
      </div>
      {menus.map((item) => (
        <NavAnchor
          key={item.name}
          to={`${item.href}`}
          activeClassName={
            "sidebar__item__menu text-base bg-secondary text-text-700 my-1 group relative flex items-center rounded-lg py-2 px-3 leading-6 hover:bg-secondary"
          }
          className={`sidebar__item__menu text-base text-text-700 my-1 group relative flex items-center rounded-lg py-2 px-3 leading-6 hover:bg-secondary`}
          activePaths={item.activePaths}
        >
          {item.icon && (
            <Image src={item.icon} alt="" width="20" className="mr-3 sidebar__item__menu__icon" />
          )}
          <div className="flex-auto sidebar__item__menu__text">
            <span className="block font-semibold ">{item.name}</span>
          </div>
        </NavAnchor>
      ))}
    </div>
  );
}
