import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-brand-600 dark:bg-brand-300 text-white shadow-sm hover:bg-brand-700",
        purple:
          "bg-brand-600 dark:bg-brand-300 text-white shadow-sm hover:bg-brand-700",
        purple_outline:
          "border border-brand-600 text-brand-600 bg-background shadow-sm hover:bg-brand-600 hover:text-white dark:hover:bg-brand-300",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        destructive_outline:
          "border border-destructive text-destructive bg-background shadow-sm hover:bg-destructive hover:text-destructive-foreground",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        white:
          "bg-background shadow-sm hover:bg-background/80 text-text-700",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        tab_btn: "hover:bg-accent hover:text-accent-foreground text-text-500",
        link: "text-primary underline-offset-4 hover:underline",

        "gray-outline": "border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 hover:text-gray-800 shadow-sm dark:bg-gray-dark-50  dark:border-gray-dark-200 dark:text-gray-dark-600 dark:hover:bg-gray-dark-100",
        "purple-outline": "border border-brand-300 text-brand-700 bg-white hover:bg-brand-50 hover:text-brand-800 shadow-sm dark:bg-gray-dark-50  dark:border-gray-dark-200 dark:text-gray-dark-600 dark:hover:bg-gray-dark-100",
        "plain": "border border-transparent text-gray-700 bg-transparent hover:bg-gray-50 hover:text-gray-800  dark:bg-gray-dark-50  dark:border-gray-dark-200 dark:text-gray-dark-600 dark:hover:bg-gray-dark-100",
        "purple-plain": "border border-transparent text-brand-700 bg-transparent hover:bg-brand-50 hover:text-brand-800 dark:bg-gray-dark-50  dark:border-gray-dark-200 dark:text-gray-dark-600 dark:hover:bg-gray-dark-100",
        "danger": "bg-error-600 dark:bg-error-300 text-white shadow-sm hover:bg-error-700",
        "danger-outline": "border border-error-300 text-error-700 bg-white hover:bg-error-50 hover:text-error-800 shadow-sm dark:bg-error-25  dark:border-error-25 dark:text-error-700 dark:hover:bg-error-100",
        "danger-plain": "border border-transparent text-error-700 bg-transparent hover:bg-error-50 hover:text-error-800  dark:bg-error-25  dark:border-error-25 dark:text-error-700 dark:hover:bg-error-100",
        "success-outline": "border border-green-300 text-green-500 bg-white hover:bg-green-50 hover:text-green-800 shadow-sm dark:bg-green-25  dark:border-green-25 dark:text-green-700 dark:hover:bg-green-100",
        "success-solid": "border border-green-500 text-white bg-green-500 hover:bg-green-600 shadow-sm dark:bg-green-200  dark:border-green-25 dark:text-white dark:hover:bg-green-100",
      },
      size: {
        default: "h-10 px-3.5 py-2.5 rounded-lg text-sm",
        sm: "h-9 px-3 py-2 rounded-lg text-sm",
        md: "h-10 px-3.5 py-2.5 rounded-lg text-sm",
        lg: "h-11 px-4 py-3 rounded-lg text-base",
        xl: "h-12 px-[18px] py-3.5 rounded-lg text-base",
        xxl: "h-16 px-[22px] py-[22px] rounded-lg text-lg",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button"
  return (
    (<Comp
      // className="text-base px-[18px] text-lg"
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props} />)
  );
})
Button.displayName = "Button"

export { Button, buttonVariants };

