import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import {
  installIntegration,
  updateIntegration,
} from "../../../apis/workspace/integrations";
import { toast } from "react-hot-toast";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import SharedDataContext from "../../../SharedDataContext";
import { Switch } from "@headlessui/react";
import SuccessAlert from "../../Alerts/Timer/SuccessAlert";
import Cookies from "js-cookie";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ZoomInfoForm({
  logo,
  edit,
  closeForm,
  zoomInfoformData,
  onboarding,
}) {
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const queryClient = useQueryClient();
  const setQueryString = useUpdateQuery();
  const [authPassword, setAuthPassword] = useState(true);
  const [showFields, setShowFields] = useState(false);

  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const onTimerEnd = () => {
    closeForm();
  };

  // handle form submit function
  const installIntegrationFn = useMutation(
    "install_integration",
    installIntegration,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);
        setIsTimerVisible(true);
        queryClient.invalidateQueries("get_integrations");
        formik.setSubmitting(false);
        formik.resetForm();
        setQueryString(`key=${formik.values.name}&tab=installed`);
      },
      onError(error) {
        formik.setSubmitting(false);
      },
    }
  );

  // handle update form

  const updateIntegrationFn = useMutation(
    "install_integration",
    updateIntegration,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);
        closeForm();
        queryClient.invalidateQueries("get_integrations");

        formik.setSubmitting(false);
        formik.resetForm();
      },
      onError(error) {
        formik.setSubmitting(false);
      },
    }
  );

  const getValidationSchema = (authPassword) => {
    if (edit && !showFields) {
      return yup.object().shape({
        name: yup.string().required("Connection Name is required"),
      });
    } else {
      if (authPassword) {
        return yup.object().shape({
          name: yup.string().required("Connection Name is required"),
          username: yup.string().required("Username is required"),
          client_id: yup.string().required("Client ID is required"),
          private_key: yup.string().required("Private key is required"),
        });
      } else {
        return yup.object().shape({
          name: yup.string().required("Connection Name is required"),
          username: yup.string().required("Username is required"),
          password: yup.string().required("Password is required"),
        });
      }
    }
  };

  const getInitialValues = (isAuthPassword) => {
    if (edit && !showFields) {
      return {
        name: edit && zoomInfoformData?.name ? zoomInfoformData?.name : "",
      };
    } else if (onboarding) {
      return { name: zoomInfoformData?.name ? zoomInfoformData?.name : "" };
    } else {
      if (isAuthPassword) {
        return {
          name: edit && zoomInfoformData?.name ? zoomInfoformData?.name : "",
          username: "",
          client_id: "",
          private_key: "",
        };
      } else {
        return {
          name: edit && zoomInfoformData?.name ? zoomInfoformData?.name : "",
          username: "",
          password: "",
        };
      }
    }
  };

  const [formConfig, setFormConfig] = useState({
    initialValues: getInitialValues(authPassword),
    validationSchema: getValidationSchema(authPassword),
  });

  useEffect(() => {
    //console.log(authPassword);
    setFormConfig({
      initialValues: getInitialValues(authPassword),
      validationSchema: getValidationSchema(authPassword),
    });
    // Optionally, reset the form here if needed
    formik.resetForm({ values: getInitialValues(authPassword) });
  }, [authPassword, showFields]);

  const submitValuesWithPrivateKey = (values) => {
    const cookieName = process.env.REACT_APP_PR_ACCESS_TOKEN
      ? process.env.REACT_APP_PR_ACCESS_TOKEN
      : "accessToken";

    const token = Cookies.get(cookieName);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("X-Workspace-Id", activeWorkspace?.id);

    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("username", values.username);
    formData.append("client_id", values.client_id);
    formData.append("authentication_type", "private_key");
    if (values.private_key && values.private_key instanceof File) {
      formData.append("private_key", values.private_key);
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    const fetchURL = edit
      ? `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/config/${zoomInfoformData.configId}`
      : `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/integrations/zoom_info`;

    fetch(fetchURL, requestOptions)
      .then(async (response) => {
        // Extract JSON data from response
        const data = await response.json();

        // Check response status
        if (response.ok) {
          toast.success(data?.message);
          if (!edit) {
            setIsTimerVisible(true);
          }
          queryClient.invalidateQueries("get_integrations");
          formik.setSubmitting(false);
          formik.resetForm();
          if (!edit) {
            setQueryString(`key=${formik.values.name}&tab=installed`);
          }
          if (edit) {
            formik.resetForm();
            closeForm();
          }
        } else {
          // Use the returned error message, or fallback to a generic message
          toast.error(data.message || "An error occurred during the process.");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        toast.error("An unexpected error occurred. Please try again later.");
      });
  };

  const formik = useFormik({
    initialValues: formConfig.initialValues,
    validationSchema: formConfig.validationSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        authentication_type: !authPassword ? "password" : "private_key",
      };
      if (edit) {
        if (!showFields) {
          delete payload.authentication_type;
          updateIntegrationFn.mutate({
            data: payload,
            configId: zoomInfoformData.configId,
            options: {
              headers: { "X-Workspace-Id": activeWorkspace?.id },
            },
          });
        } else if (!authPassword && showFields) {
          updateIntegrationFn.mutate({
            data: payload,
            configId: zoomInfoformData.configId,
            options: {
              headers: { "X-Workspace-Id": activeWorkspace?.id },
            },
          });
        } else {
          //console.log("edit new");
          submitValuesWithPrivateKey(values);
        }
      } else {
        if (!authPassword) {
          installIntegrationFn.mutate({
            data: payload,
            key: "zoom_info",
            options: {
              headers: { "X-Workspace-Id": activeWorkspace?.id },
            },
          });
        } else {
          submitValuesWithPrivateKey(values);
        }
      }
      //console.log("Submitted values:", payload);
    },
  });
  const validateFileExtension = (fileName, validExtensions) => {
    const extension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    return validExtensions.includes(`.${extension.toLowerCase()}`);
  };

  const validateFileSize = (fileSize, maxSizeInKB) => {
    const maxSizeInBytes = maxSizeInKB * 1024;
    return fileSize <= maxSizeInBytes;
  };
  return (
    <>
      {isTimerVisible && !edit ? (
        <SuccessAlert
          onTimerEnd={onTimerEnd}
          isVisible={isTimerVisible}
          heading={"Integration Installed Successfully!"}
          // description={"Integration has been done."}
          timer={onboarding ? 3 : 5}
        />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <span
            className="svg__32 inline-block border border-border rounded-[--radius] p-1 bg-white"
            dangerouslySetInnerHTML={{
              __html: logo,
            }}
          />
          <h3 className="text-[18px] block font-semibold mb-5 mt-4">
            Zoom Info
          </h3>
          <div className={`mb-4 ${onboarding ? "hidden" : ""}`}>
            <label
              className="text-sm font-medium leading-6 mb-2 flex items-center"
              htmlFor={"name"}
            >
              Connection name
            </label>
            <div className="">
              <Input
                id={"name"}
                name={"name"}
                type={"text"}
                placeholder={`Enter unique connection name. Ex - Zoominfo1`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </div>
            {formik.touched.name && formik.errors.name && (
              <p className="mt-1 text-red-500 text-sm">{formik.errors.name}</p>
            )}
          </div>
          {edit && !showFields && (
            <div>
              <span
                onClick={() => setShowFields(true)}
                className="text-indigo-500 cursor-pointer inline-flex items-center"
              >
                Change Credentials{" "}
                <PencilSquareIcon width={12} height={12} className="ml-2" />
              </span>
            </div>
          )}
          {(showFields || !edit) && (
            <>
              <div className="mb-4">
                <label
                  className="text-sm font-medium leading-6 mb-2 flex items-center"
                  htmlFor={"username"}
                >
                  ZoomInfo Username
                </label>
                <div className=" ">
                  <Input
                    id={"username"}
                    name={"username"}
                    type={"text"}
                    placeholder={`Enter ZoomInfo Username`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                  />
                </div>
                {formik.touched.username && formik.errors.username && (
                  <p className="mt-1 text-red-500 text-sm">
                    {formik.errors.username}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label className="text-sm font-medium leading-6 mb-2 flex items-center">
                  Authentication Type
                </label>
                <div>
                  <select
                    onChange={(e) => {
                      e.target.value === "password"
                        ? setAuthPassword(false)
                        : setAuthPassword(true);
                    }}
                    className="h-11 px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
                  >
                    <option value="private_key">Private Key</option>
                    <option value="password">Password</option>
                  </select>
                </div>
                {/* <div className="flex items-center">
              Password
              <Switch
                checked={authPassword}
                onChange={setAuthPassword}
                className={classNames(
                  authPassword ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 mx-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    authPassword ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              Private key
            </div> */}
              </div>
              {!authPassword ? (
                <div className="mb-4">
                  <label
                    className="text-sm font-medium leading-6  mb-2 flex items-center"
                    htmlFor={"password"}
                  >
                    ZoomInfo Password
                  </label>
                  <div>
                    <Input
                      id={"password"}
                      name={"password"}
                      type={"password"}
                      placeholder={`Enter ZoomInfo Password`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <p className="mt-1 text-red-500 text-sm">
                      {formik.errors.password}
                    </p>
                  )}
                </div>
              ) : (
                <>
                  <div className="mb-4">
                    <label
                      className="text-sm font-medium leading-6  mb-2 flex items-center"
                      htmlFor={"client_id"}
                    >
                      ZoomInfo Client ID
                    </label>
                    <div>
                      <Input
                        id={"client_id"}
                        name={"client_id"}
                        type={"text"}
                        placeholder={`Enter ZoomInfo client id`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.client_id}
                      />
                    </div>
                    {formik.touched.client_id && formik.errors.client_id && (
                      <p className="mt-1 text-red-500 text-sm">
                        {formik.errors.client_id}
                      </p>
                    )}
                  </div>
                  {/* <div className="mb-4">
                <label
                  className="text-sm font-medium leading-6 text-gray-900 mb-2 flex items-center"
                  htmlFor={"private_key"}
                >
                  ZoomInfo private key
                </label>
                <div className="flex relative rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                  <input
                    id={"private_key"}
                    name={"private_key"}
                    type={"text"}
                    placeholder={`Enter ZoomInfo private key`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.private_key}
                    className="block flex-1 border-0 bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                {formik.touched.private_key && formik.errors.private_key && (
                  <p className="mt-1 text-red-500 text-sm">
                    {formik.errors.private_key}
                  </p>
                )}
              </div> */}
                  <div className="mb-4">
                    <label
                      className="text-sm font-medium leading-6  mb-2 flex items-center"
                      htmlFor={"private_key"}
                    >
                      ZoomInfo Private Key
                    </label>
                    <div>
                      <Input
                        id={"private_key"}
                        name={"private_key"}
                        type={"file"}
                        accept=".txt,.key,.pem,.cer"
                        onChange={(event) => {
                          formik.setFieldTouched("private_key", true, true);
                          const file = event.currentTarget.files[0];
                          if (file) {
                            if (
                              validateFileExtension(file.name, [
                                ".txt",
                                ".key",
                                ".pem",
                                ".cer",
                              ]) &&
                              validateFileSize(file.size, 512)
                            ) {
                              formik.setFieldValue("private_key", file);
                              formik.setFieldError("private_key", ""); // Clear any previous error
                            } else {
                              event.currentTarget.value = ""; // Clear the input
                              formik.setFieldValue("private_key", null);
                              formik.setFieldError(
                                "private_key",
                                "Invalid file type or size. Acceptable types are .txt, .key, .pem, .cer with a max size of 512 KB."
                              );
                            }
                          }
                        }}
                      />
                    </div>

                    {formik.touched.private_key &&
                      formik.errors.private_key && (
                        <p className="mt-1 text-red-500 text-sm">
                          {formik.errors.private_key}
                        </p>
                      )}
                    <div className="border mt-4 border-orange-200 bg-orange-50 text-orange-600 rounded-md py-3 px-5 text-[12px]">
                      Acceptable private key file types are .txt, .key, .pem,
                      .cer with a max size of 512 KB.
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className="mt-8 flex items-center  space-x-2 absolute p-6 border-t border-border left-0 bottom-0 z-10 bg-background w-full">
            <Button
              onClick={(e) => {
                e.preventDefault();
                closeForm("cancel");
              }}
              variant="outline"
              className="flex-1"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                formik.isSubmitting || (formik.dirty && !formik.isValid)
              }
              className="flex-1"
            >
              {edit ? "Update" : "Submit"}{" "}
              {formik.isSubmitting && (
                <div className="loader_circle ml-2"></div>
              )}
            </Button>
          </div>
        </form>
      )}
    </>
  );
}
