import WorkspaceForm from "./WorkspaceForm";

export default function NewWorkspace() {
  return (
    <div className="w-full mx-auto p-10 px-[154px] mobile:w-full mobile:px-0">
      <h2 className="text-lg font-semibold leading-7 text-text-900">Create Workspace</h2>
      <p className="text-text-600 mt-1">Manage leads at one place</p>
      <WorkspaceForm newWorkspacePage={true} />
    </div>
  );
}
