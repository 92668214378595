import React, { useRef, useState } from "react";
import Image from "../Tags/Image";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { Button } from "../ui/button";

const ImageUploader = ({ workspaceId, icon }) => {
  const queryClient = useQueryClient();
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    readAndSetFile(file);
  };
  const readAndSetFile = (file) => {
    if (file && file.type.match(/^image\//)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageFile({
          dataUrl: event.target.result,
          file: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setImageFile(null);
    }
    setIsSuccess(false);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setImageFile(null);
    }
  };

  const handleUpdateWorkspaceIcon = () => {
    const cookieName = process.env.REACT_APP_PR_ACCESS_TOKEN
      ? process.env.REACT_APP_PR_ACCESS_TOKEN
      : "accessToken";
    const token = Cookies.get(cookieName);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("icon", imageFile.file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    setIsloading(true);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/${workspaceId}/icon`, requestOptions)
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          toast.success(data.message);
          setIsSuccess(true);
          queryClient.invalidateQueries("get_user");
          setImageFile(null);
        } else {
          toast.error(data.message || "An error occurred during the upload.");
          setIsSuccess(false);
        }
        setIsloading(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        toast.error("An unexpected error occurred. Please try again later.");
        setIsloading(false);
        setIsSuccess(false);
      });
  };

 
  const handleDragOver = (e) => {
    e.preventDefault();  
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      readAndSetFile(file);
      e.dataTransfer.clearData();
    }
  }; 

  return (
    <div className="w-full">
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageChange}
        className="hidden"
      />
      {imageFile ? (
        <div className="relative flex items-center">
          <img
            src={imageFile.dataUrl}
            alt="Preview"
            className="mr-2 inline-block rounded-full no-invert"
            style={{ maxHeight: "24px", maxWidth: "24px" }}
          />
          <div>
            {imageFile && (
              <Button
                variant="outline"
                onClick={(e) => {
                  e.preventDefault();
                  clearFileInput();
                }}
                className="mr-2"
              >
                Cancel
              </Button>
            )}
            {imageFile && !isSuccess && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  isLoading ? () => void 0 : handleUpdateWorkspaceIcon();
                }}
              >
                {isLoading ? (
                  <div className="loader_circle"></div>
                ) : (
                  "Upload"
                )}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            className="relative mr-2 text-center" 
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {icon ? (
              <Image
                src={icon}
                className="inline-block rounded-full no-invert"
                width={24}
                height={24}
                nobaseurl
              />
            ) : (
              <Image
                src={`/images/icons/org.svg`}
                className="mr-2 inline-block"
                width={24}
                height={24}
              />
            )}
            <span
              className="leading-5 mt-5 cursor-pointer block border-border border h-full w-full items-center justify-center bg-background py-4 px-6 rounded-xl text-xs"
              onClick={triggerFileInput}
            >
              <span className="rounded-[--radius] border-border border inline-block p-1 px-2 text-center">
                <Image
                  src={`/images/icons/untitledui/upload-cloud.svg`}
                  className="inline-block"
                  width={24}
                  height={24}
                />
              </span>
              <p className="block text-text-600 mt-3">
                <span className="text-brand-700 font-semibold">Click to upload</span> or drag and drop <br />
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageUploader;
