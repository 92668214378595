import React, { useState } from "react";
import NavAnchor from "../../../Tags/NavAnchor";
import { Badge } from "../../../ui/badge";

const GroupList = ({ groups }) => {
  const [showAll, setShowAll] = useState(false);
  const initialDisplayCount = 2;
  const visibleGroups = showAll ? groups : groups.slice(0, initialDisplayCount);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      {visibleGroups.map((group, index) => (
        <NavAnchor key={group.id} to={`/settings/groups/${group.id}`}>
          <Badge variant="outline" className={"font-normal"}>
            {group.name}
          </Badge>
          {index < visibleGroups.length - 1 ? ", " : ""}
        </NavAnchor>
      ))}
      {groups.length > initialDisplayCount && (
        <button onClick={toggleShowAll} className="text-brand-700 ml-2">
          {showAll
            ? "Show Less"
            : `+${groups.length - initialDisplayCount} More`}
        </button>
      )}
    </div>
  );
};

export default GroupList;
