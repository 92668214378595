import { CrossCircledIcon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import Image from "../../../Tags/Image";
import { getSalesforceObjectFields } from "../../../../apis/workspace/integrations";
import { useMutation } from "react-query";
import { Skeleton } from "../../../ui/skeleton";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
import { Button } from "../../../ui/button";
import { Check, ChevronDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../ui/command";
import { cn } from "../../../../lib/utils";

export default function RefTable({
  data = [],
  salesforceConfigId = "",
  activeWorkspaceId = "",
  getRefMappedArr,
  sourceData = [],
  reference_mappings = [],
  activeTab,
}) {
  const [companies, setCompanies] = useState(data);

  const returnObjectApiRefFields = (apiName) => {
    let arr = [];
    if (sourceData && sourceData.length > 0) {
      arr = sourceData.filter(
        (item) =>
          item?.salesforce_field_type === "reference" &&
          item?.reference_to?.length > 0 &&
          item?.reference_to.includes(apiName)
      );
    }
    return arr;
  };

  // const getSalesforceObjectFieldsFn = useMutation("get_salesforce_object_fields", getSalesforceObjectFields, {
  //   onSuccess(data, variables, context) {
  //     console.log("data in ref", data);
  //     if (data?.fields && data?.fields.length > 0) {
  //       setCompanies((prevState) =>
  //         prevState.map((item) =>
  //           item.salesforce_object_api_name === data?.salesforce_object
  //             ? {
  //                 ...item,
  //                 fields: data?.fields,
  //                 selectFields: returnObjectApiRefFields(data?.salesforce_object),
  //               }
  //             : item
  //         )
  //       );
  //     }
  //   },
  // });
  // const handleGetSalesforceObjectFields = (apiName) => {
  //   getSalesforceObjectFieldsFn.mutate({
  //     salesforceConfigId: salesforceConfigId,
  //     object: apiName,
  //     options: {
  //       headers: { "X-Workspace-Id": activeWorkspaceId },
  //     },
  //   });
  // };

  useEffect(() => {
    if (data && data.length > 0) {
      console.log(" data ref", data);
      data.forEach((dataEl) => {
        // handleGetSalesforceObjectFields(item?.salesforce_object_api_name);
        console.log(returnObjectApiRefFields(dataEl?.salesforce_object_api_name));
        setCompanies((prevState) =>
          prevState.map((item) =>
            item.salesforce_object_api_name === dataEl?.salesforce_object_api_name
              ? {
                  ...item,
                  fields: sourceData,
                  selectFields: returnObjectApiRefFields(dataEl?.salesforce_object_api_name),
                }
              : item
          )
        );
      });
    }
  }, [data && data.length, activeTab]);

  // const [companyUpdated, setCompanyUpdated] = useState(0);
  // useEffect(() => {
  //   if (companies && companies.length > 0) {
  //     console.log("companies", companies);
  //     const refMappingArray = companies
  //       .filter((item) => item.refMapping && Object.keys(item.refMapping).length > 0)
  //       .map((item) => item.refMapping);
  //     console.log("refMappingArray", refMappingArray);
  //     getRefMappedArr(refMappingArray);
  //   }
  // }, [companyUpdated, activeTab]);

  const handleRefMapping = (obj, apiName) => {
    console.log({ obj, apiName });
    if (obj && obj.salesforce_field_api_name) {
      getRefMappedArr([{ ...obj }]);
    } else {
      getRefMappedArr([]);
    }

    setCompanies((prevState) =>
      prevState.map((item) =>
        item.salesforce_object_api_name === apiName
          ? {
              ...item,
              refMapping: { ...obj },
              selectedRef: { ...obj },
            }
          : item
      )
    );
    // setCompanyUpdated(companyUpdated + 1);
  };

  const [processedReferences, setProcessedReferences] = useState(false);
  useEffect(() => {
    // console.log(reference_mappings);
    if (companies && companies.length > 0) {
      setCompanies((prevState) =>
        prevState.map((item) => {
         // console.log("reference_mappings", { item, activeTab, reference_mappings });
          // Find the matching object in reference_mappings
            // const matchedReference =
            // reference_mappings &&
            // reference_mappings.find((ref) => ref.reference_object === item.salesforce_object_api_name);
           
            // const matchedReferenceExists =
            // reference_mappings &&
            // reference_mappings.find((ref) => ref.salesforce_field_api_name === item.salesforce_object_api_name);
            let matchedReference = {};
            const matchedReferenceArr = reference_mappings && reference_mappings.length > 0 && reference_mappings.filter(item1 => {
              if(item.selectFields && item.selectFields.length > 0) {
                return item.selectFields.some(item2 => 
                  item2?.salesforce_field_api_name === item1?.salesforce_field_api_name
                );
              }
            });
            if(matchedReferenceArr && matchedReferenceArr.length > 0) {
                matchedReference = matchedReferenceArr[0]
            } 
            if(!matchedReference && !processedReferences) {
              handleRefMapping({}, item?.salesforce_object_api_name);
              setProcessedReferences(true)
            }
          return {
            ...item,

            selectedRef: matchedReference ? { ...matchedReference } : {},
          };
        })
      );
    }
  }, [reference_mappings, activeTab, processedReferences]);

  const [open, setOpen] = useState(false);
  return (
    <div className="mt-6 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border border-border rounded-[--radius]">
            <table className="min-w-full divide-y divide-border">
              <thead className="bg-background">
                <tr>
                  <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal w-3/12`}>
                    <span className="inline-flex items-center space-x-2">Reference Object</span>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal w-3/12">
                    <span className="inline-flex items-center space-x-2">
                      <Image
                        src="/images/logos/integrations/salesforce.svg"
                        width={24}
                        className="no-invert"
                      />
                      <span>Salesforce Field</span>
                    </span>
                  </th>

                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal w-2/12">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-border bg-background">
                {companies &&
                  companies.length > 0 &&
                  companies.map((item, index) => (
                    <tr className={index % 2 !== 0 ? "bg-smoke" : ""} key={item?.id}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph w-3/12">
                        {item?.salesforce_object_label}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-4/12">
                        {false ? (
                          <Skeleton className={"rounded-lg h-11 w-[290px]"} />
                        ) : (
                          <>
                            <Popover open={open} onOpenChange={setOpen} className="w-[400px]">
                              <PopoverTrigger asChild>
                                <Button
                                  variant="outline"
                                  role="combobox"
                                  aria-expanded={open}
                                  className="justify-between h-11 w-[290px] rounded-[8px] font-semibold pr-2.5"
                                >
                                  {item?.selectedRef?.salesforce_field_label
                                    ? item?.selectedRef?.salesforce_field_label
                                    : "--Select--"}
                                  <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className=" p-0" align="start">
                                <Command>
                                  <div className="p-2">
                                    <div className="border  rounded-[8px] border-b-0 overflow-hidden">
                                      <CommandInput placeholder="Search" />
                                    </div>
                                  </div>
                                  <CommandList>
                                    <CommandEmpty>No List found.</CommandEmpty>
                                    <CommandGroup>
                                      {item.selectFields &&
                                        item.selectFields.length > 0 &&
                                        item.selectFields.map((el) => {
                                          const newItem = {
                                            ...el,
                                            value: el?.salesforce_field_api_name,
                                            label: el?.salesforce_field_label,
                                          };

                                          return (
                                            <CommandItem
                                              key={newItem?.salesforce_field_api_name}
                                              value={newItem.label}
                                              onSelect={() => {
                                                console.log(newItem);
                                                if (
                                                  item?.selectedRef?.salesforce_field_api_name ===
                                                  newItem.salesforce_field_api_name
                                                ) {
                                                  handleRefMapping({}, item?.salesforce_object_api_name);
                                                } else {
                                                  handleRefMapping(
                                                    {
                                                      reference_object: item?.salesforce_object_api_name,
                                                      salesforce_field_label: newItem?.salesforce_field_label,
                                                      salesforce_field_api_name:
                                                        newItem?.salesforce_field_api_name,
                                                    },
                                                    item?.salesforce_object_api_name
                                                  );
                                                }

                                                setOpen(false);
                                              }}
                                              className="min-h-[40px]"
                                            >
                                              <Check
                                                className={cn(
                                                  "mr-2 h-4 w-4",
                                                  item?.selectedRef?.salesforce_field_api_name ===
                                                    newItem?.salesforce_field_api_name
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                )}
                                              />
                                              {newItem.label}
                                            </CommandItem>
                                          );
                                        })}
                                    </CommandGroup>
                                  </CommandList>
                                </Command>
                              </PopoverContent>
                            </Popover>
                          </>
                        )}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 w-2/12">
                        {item?.selectedRef?.salesforce_field_api_name ? (
                          <Image
                            src="/images/icons/untitledui/check-purple.svg"
                            width="20"
                            className="inline-block no-invert"
                          />
                        ) : (
                          <CrossCircledIcon width={20} height={20} className="text-gray-600" />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
