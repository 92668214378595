import React, { useEffect, useState } from "react";
import Filter from "../../../Filter";
import EditColumn from "../../../EditColumn";
import { isEmptyObject, returnArray } from "../../../../utils/GenericFn";
import Image from "../../../Tags/Image";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import GroupList from "../GroupList";
import PrintDate from "../../../Tags/PrintDate";
import { Button } from "../../../ui/button";
import { Tooltip } from "react-tooltip";
import TableLoader from "../../../Loaders/TableLoader";
import NoData from "../../../NoData";
import Pagination from "../../../Pagination";
import ConfirmModal from "../../../Modals/ConfirmModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import { membersRoleOptions, paginationConfig } from "../../../../axios/constant";
import { changeMemberRole, deleteMember, getMembers } from "../../../../apis/workspace/members";
import useUpdateQuery from "../../../../hooks/useUpdateQuery";
import toast from "react-hot-toast";
import { useCaptureEvent } from "../../../../hooks/useCaptureEvent";
export default function ActiveMembers({ getCount }) {
  const queryClient = useQueryClient();
  const { activeWorkspace } = useRoleCheck();
  const [members, setMembers] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");
  const captureEvent = useCaptureEvent();
  const getMembersFn = useQuery(
    ["get_members", filterQuery],
    () => {
      return getMembers({
        query: filterQuery,
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        setMembers(data);
        getCount(data?.data?.length);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetMembers = () => {
    getMembersFn.refetch();
  };

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetMembers();
    }
  }, [activeWorkspace, filterQuery]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    //console.log(page);
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetRole = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    //console.log(columns);
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => a.order - b.order);
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // handle change role
  const [currentRole, setCurrentRole] = useState("");
  const changeMemberRoleFn = useMutation("change_member_role", changeMemberRole, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleGetMembers();
    },
  });
  const handleRoleChange = () => {
    changeMemberRoleFn.mutate({
      memberId: currentMemberId,
      data: { role: currentRole },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // handle delete member
  const [currentMemberId, setCurrentMemberId] = useState("");
  const deleteMemberFn = useMutation("change_member_role", deleteMember, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleGetMembers();
      queryClient.invalidateQueries("get_members");
    },
  });
  const handleDeleteMember = () => {
    deleteMemberFn.mutate({
      memberId: currentMemberId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // handle open confirm
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    //console.log(data);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteMember();
    }
    if (data && data.confirm && data.type && data.type === "change_role") {
      handleRoleChange();
    }
  };

  return (
    <div>
      <div>
        {members && members.data && (members.data.length > 0 || !isEmptyObject(filters)) && (
          <div className="mt-7 flex justify-between items-center">
            <div className="space-x-3 inline-flex items-center">
              {members && members.filterMetadata && (
                <>
                  <span>Filters:</span>
                  <Filter filterMetadata={members.filterMetadata} getFiltersObject={getFiltersObject} />
                </>
              )}
            </div>
            <div className="space-x-3">
              {members.columnMetadata && (
                <EditColumn columnMetadata={members.columnMetadata} getColumns={getColumns} />
              )}
            </div>
          </div>
        )}
        <div>
          {members && members.data && members.data.length > 0 && getMembersFn.isSuccess && (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-border rounded-[--radius]">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="bg-background">
                        <tr>
                          {returnArray(sortedVisibleColumns).map((column, index) => (
                            <th
                              key={index}
                              scope="col"
                              className={`px-3 py-3.5 text-left text-sm font-normal   ${
                                column.sortable ? "cursor-pointer" : ""
                              }`}
                              onClick={() => (column.sortable ? handleSetRole(column.key) : void 0)}
                            >
                              <span className={"inline-flex items-center"}>
                                {column.display_name}
                                {column.sortable && (
                                  <>
                                    {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                      <Image
                                        src="/images/icons/dual-arrow.svg"
                                        width="7"
                                        height="7"
                                        className="inline-block ml-1"
                                      />
                                    ) : (
                                      <>
                                        {sort === column.key && (
                                          <ArrowDownIcon width={12} className="inline-block ml-1" />
                                        )}
                                        {sort === `-${column.key}` && (
                                          <ArrowUpIcon width={12} className="inline-block ml-1" />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </span>
                            </th>
                          ))}
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold "></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border bg-background">
                        {members &&
                          returnArray(members.data).map((person, index) => (
                            <tr key={person.id} className={index % 2 !== 0 ? "bg-smoke" : ""}>
                              {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                                const colKey =
                                  columns &&
                                  Object.keys(columns).find((key) => columns[key].order === column.order);

                                if (colKey === "name") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      <div className="flex items-center">
                                        <div className="h-9 w-9 flex-shrink-0">
                                          <Image
                                            nobaseurl
                                            className="h-9 w-9 rounded-full"
                                            src={person.profile_photo_url}
                                            alt=""
                                          />
                                        </div>
                                        <div className="ml-4">
                                          <div className="font-medium ">{person.name}</div>
                                          {/* <div className="mt-0 text-gray-500">
                                      {person.email}
                                    </div> */}
                                        </div>
                                      </div>
                                    </td>
                                  );
                                }
                                if (colKey === "role") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph "
                                    >
                                      <select
                                        value={person[colKey]}
                                        defaultValue={person[colKey]}
                                        className="h-9 px-4 py-0 text-sm w-auto rounded-[--radius] border border-border bg-background text-foreground"
                                        onChange={(e) => {
                                          setCurrentRole(e.target.value);
                                          setCurrentMemberId(person.id);
                                          setOpenConfirmModal(true);
                                          setOpenConfirmModalType("change_role");
                                          captureEvent("SET_MembersActive_RoleDropdown", {
                                            RoleDropdown: e.target.value,
                                          });
                                        }}
                                        onClick={(e) => {
                                          captureEvent("SET_MembersActive_RoleDropdown", {
                                            RoleDropdown: e.target.value,
                                          });
                                        }}
                                      >
                                        {membersRoleOptions.map((role) => (
                                          <option value={role.value} key={role.value}>
                                            {role.role}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                  );
                                }

                                if (colKey === "group") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      {person["groups"] && person["groups"].length > 0 ? (
                                        <GroupList groups={person["groups"]} />
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  );
                                }

                                if (colKey === "member_since") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      <PrintDate date={person.member_since} />
                                    </td>
                                  );
                                }

                                return (
                                  <td
                                    key={colIndex}
                                    className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                  >
                                    {person[colKey] ? person[colKey] : "-"}
                                  </td>
                                );
                              })}
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-auto">
                                <Button
                                  data-tooltip-id="in_table_actions"
                                  data-tooltip-content="Delete Member"
                                  disabled={deleteMemberFn.isLoading && currentMemberId === person.id}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentMemberId(person.id);
                                    setOpenConfirmModal(true);
                                    setOpenConfirmModalType("delete");
                                    captureEvent("SET_MembersRequests_DeleteBtn");
                                  }}
                                  variant="ghost"
                                  className="w-[44px]"
                                >
                                  {/* <TrashIcon width={16} height={16} /> */}
                                  <Image
                                    src="/images/icons/untitledui/trash.svg"
                                    width="16"
                                    height="16"
                                    className="inline-block"
                                  />
                                  {deleteMemberFn.isLoading && currentMemberId === person.id && (
                                    <div className="loader_circle ml-2"></div>
                                  )}
                                </Button>
                                <Tooltip id="in_table_actions" />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {getMembersFn.isLoading && <TableLoader />}

          {getMembersFn.isSuccess &&
            members &&
            members.data &&
            members.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No Member found!"} />}
          {getMembersFn.isSuccess &&
            members &&
            members.data &&
            members.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No Member found!"} filterMode={true} />
              </div>
            )}
          <div className="flex items-center justify-between  rounded-[--radius] py-3">
            <div className="flex-1">
              {members && members.pagination && members.pagination.total > 0 && (
                <Pagination data={members.pagination} getPageNumber={getPageNumber} />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        openModal={openConfirmModal}
        modalType={openConfirmModalType}
        confirmModalState={confirmModalState}
      />
    </div>
  );
}
