import { cn } from "../../lib/utils";

function Seperator({ className, text = "", ...props }) {
  return (
    <div className="flex items-center w-full gap-2 px-2">
      {text ? (
        <>
          <hr className={cn("flex-1 border-1 border-input", className)} />
          <span className="flex-2 text-sm font-semibold text-gray-600">{text}</span>
          <hr className={cn("flex-1 border-1 border-input", className)} />
        </>
      ) : (
        <hr className={cn("w-full border-1 border-input", className)} />
      )}
    </div>
  );
}

export { Seperator };
