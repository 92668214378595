import React from "react";
import Layout from "../../Layout";
import ProspectSearch from "../../containers/ProspectSearch";
import ProfileContainer from "../../containers/Profile";

export default function Profile() {
  return (
    <Layout leftNav={false} className="bg-gray-50">
      <ProfileContainer />
    </Layout>
  );
}
