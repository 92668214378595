import React, { useEffect, useState } from "react";
import {
  createApiKey,
  deleteApiKey,
  getApiKeys,
  rotateApiKey,
  updateApiKey,
} from "../../../apis/workspace/security";
import useRoleCheck from "../../../hooks/useRoleCheck";
import { useMutation, useQuery, useQueryClient } from "react-query";
import NoData from "../../NoData";
import { Button } from "../../ui/button";
import Image from "../../Tags/Image";
import Text from "../../Tags/Text";
import { ArrowDownIcon, ArrowUpIcon, PlusIcon } from "lucide-react";
import { Badge } from "../../ui/badge";
import TableLoader from "../../Loaders/TableLoader";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import { FormikProvider, useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import FormError from "../../Tags/FormError";
import Input from "../../Tags/Input";
import PrintDate from "../../Tags/PrintDate";
import { Tooltip } from "react-tooltip";
import ConfirmModal from "../../Modals/ConfirmModal";
import { paginationConfig } from "../../../axios/constant";
import { isEmptyObject, returnArray } from "../../../utils/GenericFn";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import EditColumn from "../../EditColumn";
import Filter from "../../Filter";
import Pagination from "../../Pagination";

export default function ApiKeys() {
  const queryClient = useQueryClient();
  const { activeWorkspace } = useRoleCheck();
  const [currentToken, setCurrentToken] = useState("");
  const [currentKey, setCurrentKey] = useState({});
  const [currentKeyId, setCurrentKeyId] = useState("");
  const [edit, setEdit] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [keys, setKeys] = useState([]);

  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  const getApiKeysFn = useQuery(
    "get_api_keys",
    () => {
      return getApiKeys({
        query: filterQuery,
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        setApiResponse(data);
        setKeys(data?.data);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetApiKeys = () => {
    getApiKeysFn.refetch();
  };

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetApiKeys();
    }
  }, [activeWorkspace, filterQuery]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    //console.log(page);
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetRole = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    //console.log(columns);
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => a.order - b.order);
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // create key modal
  const { isOpen: isOpenToken, openModal: openModalToken, closeModal: closeModalToken } = useModal();
  const { isOpen, openModal, closeModal } = useModal();

  // handle create group
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter API key name")
        .max(100, "API key name can not be more than 100 characters"),
    }),
    onSubmit: (values) => {
      const payload = {
        name: values.name,
      };

      if (edit) {
        updateApiKeyFn.mutate({
          data: payload,
          id: currentKey && currentKey.id,
          options: {
            headers: { "X-Workspace-Id": activeWorkspace?.id },
          },
        });
      } else {
        createApiKeyFn.mutate({
          data: payload,
          options: {
            headers: { "X-Workspace-Id": activeWorkspace?.id },
          },
        });
      }
    },
  });

  const createApiKeyFn = useMutation("create_apikey", createApiKey, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleModalClose();
      handleGetApiKeys();
      formik.resetForm();
      formik.setSubmitting(false);
      setCurrentToken(data?.token);
      openModalToken();
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  const updateApiKeyFn = useMutation("update_apikey", updateApiKey, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleModalClose();
      handleGetApiKeys();
      formik.resetForm();
      formik.setSubmitting(false);
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  const handleModalClose = () => {
    formik.resetForm();
    closeModal();
    setEdit(false);
    setCurrentKey({});
  };

  // handle open confirm
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteApiKey();
    }
    if (data && data.confirm && data.type && data.type === "rotate") {
      handleRotateApiKey(currentKeyId);
    }
  };

  const deleteApiKeyFn = useMutation("delete_apikey", deleteApiKey, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleGetApiKeys();
    },
  });
  const handleDeleteApiKey = () => {
    deleteApiKeyFn.mutate({
      id: currentKeyId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // refresh token

  const rotateApiKeyFn = useMutation("rotate_apikey", rotateApiKey, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleGetApiKeys();
      setCurrentToken(data?.token);
      openModalToken();
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  const handleRotateApiKey = (id) => {
    rotateApiKeyFn.mutate({
      id: id,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // handle copy
  const [isCopied, setIsCopied] = useState(false);
  const copyText = (event, data) => {
    event.stopPropagation();
    event.preventDefault();
    let input = document.createElement("input");
    input.setAttribute("value", data);
    document.body.appendChild(input);
    input.select();
    let result = document.execCommand("copy");
    document.body.removeChild(input);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    return result;
  };

  return (
    <>
      <div className="">
        <div className="flex items-center">
          <div className="sm:flex-auto">
            <Text size="lg" className="flex items-center">
              <span>API Keys</span>
              {keys && keys.length > 0 && (
                <Badge radius={"sm"} variant={"outline"} className={"ml-2"}>
                  {keys.length} active {keys.length === 1 ? "key" : "keys"}
                </Badge>
              )}
            </Text>
            <Text size="p" className="mt-1">
              API keys help access {process.env.REACT_APP_APP_NAME} functionality across other applications.
            </Text>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Button
              type="button"
              onClick={() => {
                openModal();
                setEdit(false);
              }}
            >
              <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              Create key
            </Button>
          </div>
        </div>

        {apiResponse && apiResponse.data && (apiResponse.data.length > 0 || !isEmptyObject(filters)) && (
          <div className="mt-7 flex justify-between items-center">
            <div className="space-x-3 inline-flex items-center">
              {apiResponse && apiResponse.filterMetadata && (
                <>
                  <span>Filters:</span>
                  <Filter filterMetadata={apiResponse.filterMetadata} getFiltersObject={getFiltersObject} />
                </>
              )}
            </div>
            <div className="space-x-3">
              {apiResponse.columnMetadata && (
                <EditColumn columnMetadata={apiResponse.columnMetadata} getColumns={getColumns} />
              )}
            </div>
          </div>
        )}

        {apiResponse && apiResponse.data && apiResponse.data.length > 0 && getApiKeysFn.isSuccess && (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border border-border rounded-[--radius]">
                  <table className="min-w-full divide-y divide-border">
                    <thead className="bg-background">
                      <tr>
                        {returnArray(sortedVisibleColumns).map((column, index) => (
                          <th
                            key={index}
                            scope="col"
                            className={`px-3 py-3.5 text-left text-sm font-normal   ${
                              column.sortable ? "cursor-pointer" : ""
                            }`}
                            onClick={() => (column.sortable ? handleSetRole(column.key) : void 0)}
                          >
                            <span className={"inline-flex items-center"}>
                              {column.display_name}
                              {column.sortable && (
                                <>
                                  {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                    <Image
                                      src="/images/icons/dual-arrow.svg"
                                      width="7"
                                      height="7"
                                      className="inline-block ml-1"
                                    />
                                  ) : (
                                    <>
                                      {sort === column.key && (
                                        <ArrowDownIcon width={12} className="inline-block ml-1" />
                                      )}
                                      {sort === `-${column.key}` && (
                                        <ArrowUpIcon width={12} className="inline-block ml-1" />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </span>
                          </th>
                        ))}
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold "></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-border bg-background">
                      {keys &&
                        returnArray(keys).map((key, index) => (
                          <tr key={key.id} className={index % 2 !== 0 ? "bg-smoke" : ""}>
                            {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                              const colKey =
                                columns &&
                                Object.keys(columns).find((key) => columns[key].order === column.order);

                              if (colKey === "member_since") {
                                return (
                                  <td
                                    key={colIndex}
                                    className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                  >
                                    <PrintDate date={key?.created_at} />
                                  </td>
                                );
                              }
                              if (colKey === "last_used_at") {
                                return (
                                  <td
                                    key={colIndex}
                                    className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                  >
                                    {key?.last_used_at ? <PrintDate date={key?.last_used_at} /> : "-"}
                                  </td>
                                );
                              }

                              return (
                                <td
                                  key={colIndex}
                                  className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                >
                                  {key[colKey] ? key[colKey] : "-"}
                                </td>
                              );
                            })}
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <div className="flex justify-end w-full">
                                <Button
                                  data-tooltip-id="in_table_actions"
                                  data-tooltip-content="Edit"
                                  variant="ghost"
                                  onClick={() => {
                                    setEdit(true);
                                    setCurrentKey(key);
                                    openModal();
                                    formik.setFieldValue("name", key.name);
                                  }}
                                >
                                  <Image
                                    src="/images/icons/untitledui/edit.svg"
                                    width="16"
                                    height="16"
                                    className="inline-block"
                                  />
                                </Button>
                                <Button
                                  data-tooltip-id="in_table_actions"
                                  data-tooltip-content="Regenerate API Key"
                                  variant="ghost"
                                  onClick={() => {
                                    if (rotateApiKeyFn.isLoading) {
                                      return;
                                    }
                                    setCurrentKey(key);
                                    setCurrentKeyId(key?.id);
                                    setOpenConfirmModal(true);
                                    setOpenConfirmModalType("rotate");
                                  }}
                                >
                                  {rotateApiKeyFn.isLoading && currentKeyId === key?.id ? (
                                    <div className="inline-block loader_circle"></div>
                                  ) : (
                                    <Image
                                      src="/images/icons/untitledui/refresh.svg"
                                      width="16"
                                      height="16"
                                      className="inline-block"
                                    />
                                  )}
                                </Button>
                                <Button
                                  data-tooltip-id="in_table_actions"
                                  data-tooltip-content="Delete"
                                  variant="ghost"
                                  onClick={() => {
                                    setCurrentKeyId(key?.id);
                                    setOpenConfirmModal(true);
                                    setOpenConfirmModalType("delete");
                                  }}
                                >
                                  <Image
                                    src="/images/icons/untitledui/trash.svg"
                                    width="16"
                                    height="16"
                                    className="inline-block"
                                  />
                                </Button>
                                <Tooltip id="in_table_actions" />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {getApiKeysFn.isLoading && <TableLoader />}

        {getApiKeysFn.isSuccess &&
          apiResponse &&
          apiResponse.data &&
          apiResponse.data.length === 0 &&
          isEmptyObject(filters) && <NoData heading={"No keys found!"} />}
        {getApiKeysFn.isSuccess &&
          apiResponse &&
          apiResponse.data &&
          apiResponse.data.length === 0 &&
          !isEmptyObject(filters) && (
            <div className="p-10 border rounded-lg mt-8">
              <NoData heading={"No keys found!"} filterMode={true} />
            </div>
          )}
        <div className="flex items-center justify-between  rounded-[--radius] py-3">
          <div className="flex-1">
            {apiResponse && apiResponse.pagination && apiResponse.pagination.total > 0 && (
              <Pagination data={apiResponse.pagination} getPageNumber={getPageNumber} />
            )}
          </div>
        </div>
      </div>
      <GenericModal
        isOpen={isOpen}
        closeModal={() => {
          handleModalClose();
        }}
      >
        <div className="mb-1">
          <div className="inline-flex justify-center border border-border rounded-[--radius] p-2 bg-background">
            <Image src="/images/icons/untitledui/key.svg" width="20" className="inline-block" />
          </div>
          <div className="mt-4">
            <h2 className="text-base font-semibold leading-7 ">{edit ? "Update" : "Create"} API Key</h2>
            {!edit && <p className="text-paragraph">Please enter a name for this API key.</p>}
          </div>
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="py-3">
              <div className="mb-5">
                <label htmlFor="name" className="block mb-2 text-sm font-medium leading-6">
                  API Key name
                </label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  placeholder="e.g. Secrets"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />
                <FormError field="name" />
              </div>
            </div>
            <div className="mt-0 space-x-2 flex">
              <Button
                className="flex-1"
                onClick={(e) => {
                  e.preventDefault();
                  handleModalClose();
                }}
                variant="outline"
              >
                Cancel
              </Button>
              <Button type="submit" disabled={formik.isSubmitting || !formik.isValid} className="flex-1">
                {edit ? "Update" : "Create"}
                {formik.isSubmitting ? <div className="loader_circle ml-3"></div> : ""}
              </Button>
            </div>
          </form>
        </FormikProvider>
      </GenericModal>
      <ConfirmModal
        openModal={openConfirmModal}
        modalType={openConfirmModalType}
        confirmModalState={confirmModalState}
        title={
          openConfirmModalType === "rotate"
            ? "Are you sure you want to rotate your API key?"
            : "Are you sure you want to delete your API key?"
        }
        subText={
          "This will make any application using your older API key non-functional until new API key is entered."
        }
      />

      <GenericModal
        isOpen={isOpenToken}
        closeModal={() => {
          closeModalToken();
        }}
        dismiss={false}
        width="w-[700px]"
      >
        <div className="mb-1">
          <div className="inline-flex justify-center border border-border rounded-[--radius] p-2 bg-background">
            <Image src="/images/icons/untitledui/key.svg" width="20" className="inline-block" />
          </div>
          <div className="mt-4">
            <h2 className="text-base font-semibold leading-7 ">Save your API key</h2>
            <p className="text-paragraph">
              Keep a record of the key below. You won't be able to view it again.
            </p>
          </div>
        </div>
        <div className="pt-3">
          <div className="flex relative rounded-[--radius] border border-input">
            <span className="bg-[#F9FAFB] dark:bg-smoke h-11 inline-flex items-center rounded-l-[--radius] px-[14px] text-text-600 border-r border-border flex-1">
              {currentToken}
            </span>
            <span
              className="inline-flex items-center text-sm space-x-1 px-4 cursor-pointer w-[100px]"
              onClick={(e) => copyText(e, currentToken)}
            >
              {isCopied ? (
                <Image src="/images/icons/untitledui/green-check.svg" width="16" className="inline-flex" />
              ) : (
                <Image src="/images/icons/untitledui/copy.svg" width="16" className="inline-flex" />
              )}
              <span>{isCopied ? "Copied" : "Copy"}</span>
            </span>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              onClick={(e) => {
                e.preventDefault();
                closeModalToken();
                setCurrentToken("");
              }}
              variant="outline"
            >
              Close
            </Button>
          </div>
        </div>
      </GenericModal>
    </>
  );
}
