import React from "react";
import EnrichLeftNav from "../../containers/Enrich/EnrichLeftNav";
import Layout from "../../Layout";
import EnrichPeople from "../../containers/EnrichPeople";

export default function EnrichPeoplePage() {
  return (
    <Layout leftNav={<EnrichLeftNav />}>
      <EnrichPeople />
    </Layout>
  );
}
