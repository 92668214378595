import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { FormikProvider, useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import FormError from "../../Tags/FormError";
import { createGroup, updateGroup } from "../../../apis/workspace/groups";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import Image from "../../Tags/Image";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";
import { Switch } from "../../ui/switch";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function GroupModal({
  closeModal,
  edit = false,
  data = {},
  activeWorkspace,
  refetch = () => javascript.void(0),
}) {
  const queryClient = useQueryClient();

  const handleModalClose = () => {
    formik.resetForm();
    closeModal();
  };

  // handle create group
  const formik = useFormik({
    initialValues: {
      name: "",
      csv_enrichment_allowed: true,
      max_monthly_enrichment_credits: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter group name")
        .max(100, "Group can not be more than 100 characters"),
      max_monthly_enrichment_credits: Yup.number()
        .required("Please enter max monthly limit")
        .typeError("Please enter a valid number")
        .moreThan(0, "Value must be greater than 0")
        .max(1000000, "Value cannot exceed 1,000,000"),
    }),
    onSubmit: (values) => {
      //console.log(values);
      const payload = {
        name: values.name,
        preferences: {
          csv_enrichment_allowed: values.csv_enrichment_allowed,
          max_monthly_enrichment_credits: values.max_monthly_enrichment_credits,
        },
      };
      if (edit) {
        updateGroupFn.mutate({
          data: payload,
          groupId: data && data.id,
          options: {
            headers: { "X-Workspace-Id": activeWorkspace?.id },
          },
        });
      } else {
        createGroupFn.mutate({
          data: payload,
          options: {
            headers: { "X-Workspace-Id": activeWorkspace?.id },
          },
        });
      }
    },
  });

  const createGroupFn = useMutation("create_group", createGroup, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleModalClose();
      queryClient.invalidateQueries("get_groups");
      formik.resetForm();
      formik.isSubmitting = false;
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  const updateGroupFn = useMutation("create_group", updateGroup, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleModalClose();
      queryClient.invalidateQueries("get_groups");
      formik.resetForm();
      formik.isSubmitting = false;
      if (refetch) {
        refetch();
      }
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  // handle edit case
  const prefillForm = () => {
    formik.setFieldValue("name", data.name);
    formik.setFieldValue("csv_enrichment_allowed", data?.preferences?.csv_enrichment_allowed);
    formik.setFieldValue("max_monthly_enrichment_credits", data?.preferences?.max_monthly_enrichment_credits);
  };
  useEffect(() => {
    if (edit) {
      prefillForm();
    }
  }, [edit]);
  const captureEvent = useCaptureEvent();
  return (
    <>
      <div className="mb-1">
        <div className="inline-flex justify-center border border-border rounded-[--radius] p-2 bg-background">
          <Image src="/images/icons/untitledui/group.svg" width="20" className="inline-block" />
        </div>
        <div className="mt-4">
          <h2 className="text-base font-semibold leading-7 ">{edit ? "Update" : "Create"} group</h2>
          <p className="text-paragraph">Please enter a name for this group.</p>
        </div>
      </div>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="py-3">
            <div className="mb-5">
              <label htmlFor="name" className="block mb-2 text-sm font-medium leading-6">
                Group name
              </label>
              <Input
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                placeholder="e.g. BDR Sales"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default behavior of Enter key
                    formik.handleSubmit(); // Manually submit the form
                  }
                }}
              />
              <FormError field="name" />
            </div>
            <div className="mb-5">
              <label
                htmlFor="max_monthly_enrichment_credits"
                className="block mb-2 text-sm font-medium leading-6"
              >
                Max monthly limit
              </label>
              <Input
                type="number"
                name="max_monthly_enrichment_credits"
                id="max_monthly_enrichment_credits"
                autoComplete="max_monthly_enrichment_credits"
                placeholder="Ex - 1000"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.max_monthly_enrichment_credits}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default behavior of Enter key
                    formik.handleSubmit(); // Manually submit the form
                  }
                }}
              />
              <FormError field="max_monthly_enrichment_credits" />
              <p className="text-paragraph mt-2">Contact reveal credit usage limit</p>
            </div>
            <div className="mb-5">
              <div className="flex space-x-4">
                <Switch
                  name="csv_enrichment_allowed"
                  checked={formik.values.csv_enrichment_allowed}
                  onCheckedChange={(checked) => {
                    formik.setFieldValue("csv_enrichment_allowed", checked);
                    if (edit) {
                      captureEvent("SET_GroupsColoumnEditModal_CSVEnrichmentBtn", { state: checked });
                    } else {
                      captureEvent("SET_GroupsCreateGroupModal_CSVEnrichmentBtn", { state: checked });
                      console.log(checked);
                    }
                  }}
                />
                <label className="block mb-2 text-sm font-bold leading-6 -mt-1">
                  CSV Enrichment
                  <span className="block text-paragraph font-normal">
                    CSV Enrichment is enabled for group
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="mt-0 space-x-2 flex">
            <Button
              className="flex-1"
              onClick={(e) => {
                e.preventDefault();
                handleModalClose();
                if (edit) {
                  captureEvent("SET_GroupsColoumnEditModal_CancelBtn");
                } else {
                  captureEvent("SET_GroupsCreateGroupModal_CancelBtn");
                }
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
              className="flex-1"
              onClick={() => {
                if (edit) {
                  captureEvent("SET_GroupsColoumnEditModal_UpdateGroupBtn");
                } else {
                  captureEvent("SET_CreateGroupModal_CreateGroupBtn");
                }
              }}
            >
              {edit ? "Update Group" : "Create Group"}
              {formik.isSubmitting ? <div className="loader_circle ml-3"></div> : ""}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}
