import { ChevronDown } from "lucide-react";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addItemsToList, getListsAttributes, removeItemsFromList } from "../../../../apis/workspace/lists";
import { ReactComponent as TrashIcon } from "../../../../static/media/icons/trash-detail-icon.svg";
import ConfirmModal from "../../../Modals/ConfirmModal";
import TableRowActionDropdown from "../../../TableRowActionDropdown";
import { Button } from "../../../ui/button";

export default function TableMultiSelectOptions({
  workspaceId,
  currentListId,
  selectedRows = [],
  invalidateQuery = "",
}) {
  const queryClient = useQueryClient();
  const [openConfirmModal, setOpenConfirmModal] = useState({ open: false, type: null });
  const [addToListItems, setAddToListItems] = useState([]);
  const query = useQuery({
    queryKey: "list-items",
    queryFn: () => getListsAttributes({ options: { headers: { "X-Workspace-Id": workspaceId } } }),
    enabled: !!workspaceId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setAddToListItems(
        data.map(({ id, name }) => ({
          action: "add",
          label: name,
          data: { id, name },
        }))
      );
    },
  });

  const addItemToListMutation = useMutation({
    mutationFn: addItemsToList,
    onSuccess(data) {
      toast.success(data?.message);
    },
  });

  const deleteListMutation = useMutation({
    mutationFn: removeItemsFromList,
    onSuccess(data) {
      queryClient.invalidateQueries(invalidateQuery);
      toast.success(data?.message);
    },
  });

  const onHandleRowActionClick = useCallback(
    (actionType, data) => {
      switch (actionType) {
        case "add":
          addItemToListMutation.mutate({
            data: selectedRows.map(({ item_id, item_type }) => ({ item_id, item_type })),
            options: { headers: { "X-Workspace-Id": workspaceId } },
            listId: data.id,
          });
          break;
        default:
          break;
      }
    },
    [setOpenConfirmModal]
  );

  const confirmModalState = async (data) => {
    if (data) {
      if (data.confirm && data.type && data.type === "delete") {
        if (currentListId)
          deleteListMutation.mutate({
            options: {
              headers: {
                "X-Workspace-Id": workspaceId,
              },
            },
            data: selectedRows.map(({ item_id }) => item_id),
            listId: currentListId,
          });
      }
      setOpenConfirmModal({ open: false, type: null });
    }
  };

  return (
    <div className="flex items-center gap-3">
      <TableRowActionDropdown
        rowActionItems={addToListItems}
        isItemsLoaded={!query.isLoading}
        onHandleRowActionClick={onHandleRowActionClick}
        isGroupingEnabled={false}
      >
        <Button type="button" variant="gray-outline">
          Add to list
          <ChevronDown className="h-4 w-4 ml-1" />
        </Button>
      </TableRowActionDropdown>
      <Button
        onClick={() => setOpenConfirmModal({ open: true, type: "delete" })}
        type="button"
        variant="danger-outline"
        size="icon"
        className="rounded-lg dark:bg-transparent dark:hover:bg-transparent dark:border-red-600 dark:hover:border-red-400 group"
      >
        <TrashIcon height="20" width="20" className="stroke-red-600 dark:group-hover:stroke-red-400" />
      </Button>
      <ConfirmModal
        openModal={openConfirmModal.open}
        modalType={openConfirmModal.type}
        confirmModalState={confirmModalState}
        subText="All the selected items will be removed from this list. Please confirm."
      />
    </div>
  );
}
