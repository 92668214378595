import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Image from "../../Tags/Image";
import Text from "../../Tags/Text";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="flex justify-between items-center mt-8 w-7/12 mx-auto">
      <ChevronLeft width={20} onClick={() => previous()} />
      <ChevronRight width={20} onClick={() => next()} />
    </div>
  );
};

export default function Slides() {
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={5000}
      arrows={false}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
      showDots={true}
      renderDotsOutside={true}
    >
      <div className="flex h-full flex-col justify-around">
        <Image src={"/images/register-slide-1.svg"} className="w-full no-invert" />
        <div className=" mt-3 text-center">
          <Text size="lg">Uncover your best customers.</Text>
          <p className="text-base text-gray-900 mt-2 ">
            Leverage AI to identify your best customers and create highly accurate Ideal Customer Profiles
            with 100s of signals
          </p>
        </div>
      </div>
      <div className="flex h-full flex-col justify-around">
        <Image src={"/images/register-slide-2.svg"} className="w-full no-invert" />
        <div className=" mt-3 text-center">
          <Text size="lg">Unreliable pipeline? Auto-prospect to the rescue.</Text>
          <p className="text-base text-gray-900 mt-2 ">
            Build your dream list of companies and leads with AI
          </p>
        </div>
      </div>
      <div className="flex h-full flex-col justify-around">
        <Image src={"/images/register-slide-3.svg"} className="w-full no-invert" />
        <div className=" mt-3 text-center">
          <Text size="lg">Target right with scoring</Text>
          <p className="text-base text-gray-900 mt-2 ">
            Analyze company and person fitment with AI scores and prioritize your efforts
          </p>
        </div>
      </div>
      <div className="flex h-full flex-col justify-around">
        <Image src={"/images/register-slide-4.svg"} className="w-full no-invert" />
        <div className=" mt-3 text-center">
          <Text size="lg">Save costs while increasing contact coverage</Text>
          <p className="text-base text-gray-900 mt-2 ">
            Multiple provider waterfall enrichment and automatic contact verification. Reduce number of seats
            on providers saving you $$
          </p>
        </div>
      </div>
      <div className="flex h-full flex-col justify-around">
        <Image src={"/images/register-slide-5.svg"} className="w-full no-invert" />
        <div className=" mt-3 text-center">
          <Text size="lg">Personalized Engagement at Scale</Text>
          <p className="text-base text-gray-900 mt-2 ">
            Create hyper-personalized messages based on signals and send seamlessly with your favorite mailing
            platform.
          </p>
        </div>
      </div>
    </Carousel>
  );
}
