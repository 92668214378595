import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteList, getLists } from "../../../../apis/workspace/lists";
import { paginationConfig } from "../../../../axios/constant";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import useUpdateQuery from "../../../../hooks/useUpdateQuery";
import { isEmptyObject, isNullableValue, returnArray } from "../../../../utils/GenericFn";
import EditColumn from "../../../EditColumn";
import Filter from "../../../Filter";
import TableLoader from "../../../Loaders/TableLoader";
import ConfirmModal from "../../../Modals/ConfirmModal";
import NoData from "../../../NoData";
import Pagination from "../../../Pagination";
import TableRowActionDropdown from "../../../TableRowActionDropdown";
import Image from "../../../Tags/Image";
import PrintDate from "../../../Tags/PrintDate";
import { Badge } from "../../../ui/badge";
import { Button } from "../../../ui/button";
import { rowActionItemsProps } from "./tableRowActionConfig";

export default function CompanyAndPeopleTable({ handleOpenEditListModal }) {
  const { activeWorkspace } = useRoleCheck();
  const setLocationSearchParam = useUpdateQuery();
  const queryClient = useQueryClient();
  const refMount = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const filterQuery = searchQuery.toString();
  const [openConfirmModal, setOpenConfirmModal] = useState({ open: false, type: null });
  const [lists, setLists] = useState({});
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: searchQuery.get("page") || paginationConfig.page,
    perPage: searchQuery.get("per_page") || paginationConfig.perPage,
  });
  const [sort, setSort] = useState(searchQuery.get("sort") || "");
  const [columns, setColumns] = useState({});
  const queryParamsForLoaded = useMemo(() => {
    const params = new URLSearchParams();
    params.set("page", pagination.page);
    params.set("per_page", pagination.perPage);
    if (sort) params.set("sort", sort);
    Object.entries(filters).forEach(([key, { selectedScope, value }]) => {
      if (selectedScope && value) {
        const parsedValue = value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.set(`filter[${selectedScope}]`, parsedValue);
      }
    });
    return params.toString();
  }, [pagination, filters, sort]);

  const listsApiQuery = useQuery(
    "get_lists",
    () => getLists({ query: filterQuery, options: { headers: { "X-Workspace-Id": activeWorkspace?.id } } }),
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setLists(data);
        refMount.current = true;
      },
      onError: console.error,
    }
  );

  const deleteListMutation = useMutation({
    mutationFn: deleteList,
    onSuccess(data) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_lists");
    },
  });

  useEffect(() => {
    if (activeWorkspace?.id) listsApiQuery.refetch();
  }, [activeWorkspace, filterQuery]);

  useEffect(() => {
    if (refMount.current) setLocationSearchParam(queryParamsForLoaded);
  }, [queryParamsForLoaded]);

  const confirmModalState = async (data) => {
    if (data) {
      if (data.confirm && data.type === "delete") {
        deleteListMutation.mutate({
          options: {
            headers: {
              "X-Workspace-Id": activeWorkspace?.id,
            },
          },
          listId: openConfirmModal.data?.id,
        });
      }
      setOpenConfirmModal({ open: false, type: null });
    }
  };

  const getColumns = (columns) => setColumns(columns);
  const getPageNumber = (page) => setPagination((prev) => ({ ...prev, page }));
  const handleSetSort = (column) => setSort(sort === column ? `-${column}` : column);
  const navigateToLocation = (slug) => navigate(`${location.pathname}/list/${slug}`);
  const getFiltersObject = (data) => {
    if (!isEmptyObject(data)) setPagination(paginationConfig);
    setFilters(data);
  };

  const sortedVisibleColumns = useMemo(
    () =>
      Object.values(columns)
        .filter((item) => item.visible)
        .sort((a, b) => a.order - b.order),
    [columns]
  );

  const getAlignedProp = (column) =>
    column.hasOwnProperty("align")
      ? column.align === 0
        ? "center"
        : column.align === 1
          ? "right"
          : "left"
      : undefined;

  const renderCellContent = (columnKey, item, metaData) => {
    const data = !isNullableValue(item[columnKey])
      ? item[columnKey]
      : metaData[columnKey]?.hasOwnProperty("nullValue")
        ? metaData[columnKey]["nullValue"]
        : "-";

    switch (columnKey) {
      case "updated_by":
      case "created_by":
        return (
          <div className="flex items-center">
            <div className="h-9 w-9 flex-shrink-0">
              <Image nobaseurl className="h-9 w-9 rounded-full" src={data?.profile_photo_url} alt="" />
            </div>
            <div className="ml-4">
              <div className="font-medium ">{data?.name}</div>
            </div>
          </div>
        );
      case "updated_at":
        return <PrintDate date={data ?? new Date().toISOString()} />;
      case "name":
        return (
          <div className="group flex items-center gap-2">
            {item?.creation_source !== "User" && <Badge radius="full" size="dot" variant="success-dot" />}
            <span
              className="group-hover:underline group-hover:underline-offset-2 group-hover:cursor-pointer"
              onClick={() => navigateToLocation(item.slug)}
            >
              {data}
            </span>
            {item?.creation_source !== "User" && (
              <Badge radius="full" size="sm" variant="gray">
                {item.creation_source} Generated
              </Badge>
            )}
          </div>
        );
      default:
        return data;
    }
  };

  const onHandleRowActionClick = (actionType, list) => {
    switch (actionType) {
      case "info":
        navigateToLocation(list.slug);
        break;
      case "edit":
        handleOpenEditListModal("edit", list);
        break;
      case "duplicate":
        handleOpenEditListModal("duplicate", list);
        break;
      case "delete":
        setOpenConfirmModal({ open: true, type: "delete", data: list });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div>
        {lists && lists.data && (lists.data.length > 0 || !isEmptyObject(filters)) && (
          <div className="mt-7 flex justify-between items-center">
            <div className="space-x-3 inline-flex items-center">
              {lists.filterMetadata && (
                <>
                  <span>Filters:</span>
                  <Filter filterMetadata={lists.filterMetadata} getFiltersObject={getFiltersObject} />
                </>
              )}
            </div>
            <div className="space-x-3">
              {lists.columnMetadata && (
                <EditColumn columnMetadata={lists.columnMetadata} getColumns={getColumns} />
              )}
            </div>
          </div>
        )}
        <div>
          {lists && lists.data && lists.data.length > 0 && listsApiQuery.isSuccess && (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-border rounded-[--radius]">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="bg-background">
                        <tr>
                          {returnArray(sortedVisibleColumns).map((column) => (
                            <th
                              align={getAlignedProp(column)}
                              key={column.key}
                              scope="col"
                              className={`px-3 py-3.5 text-sm font-normal ${
                                column.sortable ? "cursor-pointer" : ""
                              }`}
                              onClick={() => column.sortable && handleSetSort(column.key)}
                            >
                              <span className={"inline-flex items-center"}>
                                {column.display_name}
                                {column.sortable && (
                                  <>
                                    {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                      <Image
                                        src="/images/icons/dual-arrow.svg"
                                        width="7"
                                        height="7"
                                        className="inline-block ml-1"
                                      />
                                    ) : sort === column.key ? (
                                      <ArrowDownIcon width={16} height={16} className="inline-block ml-1" />
                                    ) : (
                                      <ArrowUpIcon width={16} height={16} className="inline-block ml-1" />
                                    )}
                                  </>
                                )}
                              </span>
                            </th>
                          ))}
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold "></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border bg-background">
                        {lists &&
                          returnArray(lists.data).map((list, index) => (
                            <tr
                              key={list.id}
                              className={index % 2 !== 0 ? "bg-smoke" : ""}
                              onDoubleClick={() => navigateToLocation(list.slug)}
                            >
                              {returnArray(sortedVisibleColumns).map(({ key: colKey }) => {
                                return (
                                  <td
                                    align={getAlignedProp(lists.columnMetadata[colKey])}
                                    key={colKey}
                                    className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                  >
                                    {renderCellContent(colKey, list, lists.columnMetadata)}
                                  </td>
                                );
                              })}
                              <td className="whitespace-nowrap px-3 py-4">
                                <TableRowActionDropdown
                                  rowActionItems={rowActionItemsProps.items}
                                  excludedActions={
                                    list["creation_source"] === "ICP"
                                      ? ["edit", "duplicate", "delete"]
                                      : undefined
                                  }
                                  onHandleRowActionClick={(action) => onHandleRowActionClick(action, list)}
                                >
                                  <Button
                                    className={`bg-transparent hover:bg-transparent`}
                                    size="icon"
                                    variant="white"
                                  >
                                    <Image
                                      src="/images/icons/untitledui/dots-vertical.svg"
                                      height="20"
                                      width="20"
                                      aria-hidden="true"
                                    />
                                  </Button>
                                </TableRowActionDropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {listsApiQuery.isLoading && <TableLoader />}
          {listsApiQuery.isSuccess &&
            lists &&
            lists.data &&
            lists.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No Lists found!"} />}
          {listsApiQuery.isSuccess &&
            lists &&
            lists.data &&
            lists.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No Lists found!"} filterMode={true} />
              </div>
            )}
          <div className="flex items-center justify-between  rounded-[--radius] py-3">
            <div className="flex-1">
              {lists && lists.pagination && lists.pagination.total > 0 && (
                <Pagination data={lists.pagination} getPageNumber={getPageNumber} />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        openModal={openConfirmModal.open}
        modalType={openConfirmModal.type}
        confirmModalState={confirmModalState}
      />
    </div>
  );
}
