export const apps = {
  lambdatestinternal: {
    app_name: "Prospecto",
    logo: "/images/apps/lambdatestinternal/logo.svg",
    favicon: `${process.env.REACT_APP_BASE_URL}/app/images/apps/lambdatestinternal/favicon.ico`,
    title: "Prospecto Stage",
  },
  lambdatest: {
    app_name: "Prospecto",
    logo: "/images/apps/lambdatest/logo.svg",
    favicon: `${process.env.REACT_APP_BASE_URL}/app/images/apps/lambdatest/favicon.ico`,
    title: "Prospecto",
  },
  primerole: {
    app_name: "PrimeRole",
    logo: "/images/apps/primerole/logo.svg",
    favicon: `${process.env.REACT_APP_BASE_URL}/app/images/apps/primerole/favicon.ico`,
    title: "PrimeRole",
  },
  default: {
    app_name: "PrimeRole",
    logo: "/images/apps/primerole/logo.svg",
    favicon: `${process.env.REACT_APP_BASE_URL}/app/images/apps/primerole/favicon.ico`,
    title: "PrimeRole",
  },
};
