import { useContext, useCallback } from "react";
import posthog from "posthog-js";
import SharedDataContext from "../SharedDataContext";
import { useTheme } from "../components/ThemeProvider";

export const useCaptureEvent = () => {
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const { theme, setTheme } = useTheme();

  return useCallback(
    (eventName, properties = {}) => {
      if (!eventName) {
        console.warn("Event name is required for PostHog capture.");
        return;
      }

      // Conditionally add workspace properties
      const workspaceProperties = activeWorkspace
        ? {
            workspace_name: activeWorkspace.name,
            workspace_id: activeWorkspace.id,
          }
        : {};

      console.log("Capture event");
      posthog.capture(eventName, {
        ...properties,
        ...workspaceProperties, // Merge workspace properties only if available
        theme: theme,
      });
    },
    [activeWorkspace] // Function updates only if `activeWorkspace` changes
  );
};
