import React, { useContext, useEffect, useState } from "react";
import SharedDataContext from "../../../SharedDataContext";
import { paginationConfig } from "../../../axios/constant";
import { useMutation } from "react-query";
import { getAuditLogs } from "../../../apis/workspace/security";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import {
  getVariant,
  isEmptyObject,
  returnArray,
} from "../../../utils/GenericFn";
import EditColumn from "../../EditColumn";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  FolderIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { Tooltip } from "react-tooltip";
import NoData from "../../NoData";
import TableLoader from "../../Loaders/TableLoader";
import Pagination from "../../Pagination";
import Filter from "../../Filter";
import Image from "../../Tags/Image";
import AuditLogsModal from "./AuditLogsModal";
import useRoleCheck from "../../../hooks/useRoleCheck";
import PrintDate from "../../Tags/PrintDate";
import { Badge } from "../../ui/badge";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import Text from "../../Tags/Text";
export default function Security() {
  const { activeWorkspace } = useRoleCheck();
  const [logs, setLogs] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  const getAuditLogsFn = useMutation("get_audit_logs", getAuditLogs, {
    onSuccess(data, variables, context) {
      //console.log(data);
      setLogs(data);
    },
  });
  const handleGetAuditLogs = () => {
    getAuditLogsFn.mutate({
      query: filterQuery,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    if (activeWorkspace?.id && filterQuery !== "") {
      handleGetAuditLogs();
    }
  }, [activeWorkspace, filterQuery]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [
        `page=${pagination.page}`,
        `per_page=${pagination.perPage}`,
        `sort=${sort}`,
      ];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    //console.log(page);
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetSort = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    //console.log(columns);
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter(
      (column) => column.visible
    );
    const sortedVisibleColumns = visibleColumns.sort(
      (a, b) => a.order - b.order
    );
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // handle log change show hide
  const [currentLog, setCurrentLog] = useState({});

  const { isOpen, openModal, closeModal } = useModal();
  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Text size="lg">Audit Logs</Text>
          <Text size="p" className="mt-1">
            Check Audit Logs
          </Text>
        </div>
      </div>
      <div className="border-b border-border my-5"></div>

      <div>
        {logs &&
          logs.data &&
          (logs.data.length > 0 || !isEmptyObject(filters)) && (
            <div className="mt-7 flex justify-between items-center">
              <div className="space-x-3 inline-flex items-center">
                {logs && logs.filterMetadata && (
                  <>
                    <span>Filters:</span>
                    <Filter
                      filterMetadata={logs.filterMetadata}
                      getFiltersObject={getFiltersObject}
                    />
                  </>
                )}
              </div>
              <div className="space-x-3">
                {logs.columnMetadata && (
                  <EditColumn
                    columnMetadata={logs.columnMetadata}
                    getColumns={getColumns}
                  />
                )}
              </div>
            </div>
          )}
        <div>
          {logs &&
            logs.data &&
            logs.data.length > 0 &&
            getAuditLogsFn.isSuccess && (
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border border-border rounded-[--radius]">
                      <table className="min-w-full divide-y divide-border">
                        <thead className="bg-background">
                          <tr>
                            {returnArray(sortedVisibleColumns).map(
                              (column, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className={`px-3 py-3.5 text-left  font-normal   ${column.sortable ? "cursor-pointer" : ""
                                    }`}
                                  onClick={() =>
                                    column.sortable
                                      ? handleSetSort(column.key)
                                      : void 0
                                  }
                                >
                                  <span className={"inline-flex items-center"}>
                                    {column.display_name}
                                    {column.sortable && (
                                      <>
                                        {sort === "" ||
                                          (sort !== column.key &&
                                            sort !== `-${column.key}`) ? (
                                          <Image
                                            src="/images/icons/dual-arrow.svg"
                                            width="7"
                                            height="7"
                                            className="inline-block ml-1"
                                          />
                                        ) : (
                                          <>
                                            {sort === column.key && (
                                              <ArrowDownIcon
                                                width={12}
                                                className="inline-block ml-1"
                                              />
                                            )}
                                            {sort === `-${column.key}` && (
                                              <ArrowUpIcon
                                                width={12}
                                                className="inline-block ml-1"
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </span>
                                </th>
                              )
                            )}
                            {/* <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            ></th> */}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-border bg-background">
                          {logs &&
                            returnArray(logs.data).map((item, index) => (
                              <>
                                <tr
                                  key={item.id}
                                  className={`cursor-pointer text-paragraph ${index % 2 !== 0 ? "bg-smoke" : ""
                                    }`}
                                  onClick={() => {
                                    openModal();
                                    setCurrentLog(item);
                                  }}
                                >
                                  {returnArray(sortedVisibleColumns).map(
                                    (column, colIndex) => {
                                      const colKey =
                                        columns &&
                                        Object.keys(columns).find(
                                          (key) =>
                                            columns[key].order === column.order
                                        );

                                      if (colKey === "user_id") {
                                        return (
                                          <td
                                            key={colIndex}
                                            className="whitespace-nowrap px-3 py-4"
                                          >
                                            <div className="flex items-center">
                                              <div className="h-9 w-9 flex-shrink-0">
                                                {item.user?.profile_photo_url && (
                                                  <Image
                                                    nobaseurl
                                                    className="h-9 w-9 rounded-full"
                                                    src={item.user.profile_photo_url}
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                              <div className="ml-4">
                                                <div className="font-medium ">
                                                  {item.user?.name}
                                                </div>
                                                <div className="mt-0 text-paragraph">
                                                  {item.user?.email}
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        );
                                      }
                                      if (colKey === "created_at") {
                                        return (
                                          <td
                                            key={colIndex}
                                            className="whitespace-nowrap px-3 py-4"
                                          >
                                            <PrintDate date={item.created_at} />
                                          </td>
                                        );
                                      }
                                      if (colKey === "event") {
                                        return (
                                          <td
                                            key={colIndex}
                                            className="whitespace-nowrap px-3 py-4"
                                          >
                                            {item[colKey] ? (
                                              <>
                                                <Badge
                                                  variant={getVariant(
                                                    item[colKey]
                                                  )}
                                                  className={"capitalize"}
                                                >
                                                  {item[colKey]}
                                                </Badge>
                                              </>
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                        );
                                      }

                                      return (
                                        <td
                                          key={colIndex}
                                          className="whitespace-nowrap px-3 py-4"
                                        >
                                          {item[colKey] ? item[colKey] : "-"}
                                        </td>
                                      );
                                    }
                                  )}
                                  {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="See Logs"
                                      onClick={() => {
                                        toggleCurrentLogId(item.id);
                                      }}
                                      type="button"
                                      className="rounded items-center inline-flex bg-gray-50 px-2 py-1 text-xs font-normal text-gray-600 shadow-sm hover:bg-gray-100"
                                    >
                                      <FolderIcon width={16} height={16} />
                                    </button>
                                    <Tooltip id="in_table_actions" />
                                  </td> */}
                                </tr>
                                {/* {item.id === currentLogId && (
                                  <tr className="bg-yellow-50">
                                    <td
                                      colSpan={sortedVisibleColumns.length + 1}
                                    >
                                      <div className="p-3 -top-1 z-10 relative bg-yellow-50">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Beatae officiis sit
                                        accusantium aperiam eaque neque
                                        consectetur placeat tenetur laboriosam
                                        esse aliquid excepturi autem ratione
                                        voluptates nulla deserunt quis, repellat
                                        similique.
                                      </div>
                                    </td>
                                  </tr>
                                )} */}
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {getAuditLogsFn.isLoading && <TableLoader />}

          {getAuditLogsFn.isSuccess &&
            logs &&
            logs.data &&
            logs.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No Logs found!"} />}
          {getAuditLogsFn.isSuccess &&
            logs &&
            logs.data &&
            logs.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No Logs found!"} filterMode={true} />
              </div>
            )}
          <div className="flex items-center justify-between  rounded-[--radius] py-3">
            <div className="flex-1">
              {logs && logs.pagination && logs.pagination.total > 0 && (
                <Pagination
                  data={logs.pagination}
                  getPageNumber={getPageNumber}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <GenericModal isOpen={isOpen} closeModal={closeModal} drawer={true}>
        <AuditLogsModal log={currentLog} />
      </GenericModal>
    </>
  );
}
