import React from "react";
import Layout from "../../Layout";
import NewWorkspace from "../../containers/NewWorkspace";

export default function NewWorkspacePage() {
  return (
    <Layout leftNav={false}>
      <NewWorkspace />
    </Layout>
  );
}
