import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import useRoleCheck from "../../hooks/useRoleCheck";
import { returnArray } from "../../utils/GenericFn";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

function RenderMenuItem({ item, excludedActions, onHandleRowActionClick }) {
  const { activeWorkspace } = useRoleCheck();
  const [isLoading, setIsLoading] = useState(!!item.props?.queryFn && !item.props.isItemsLoaded);
  const [subItems, setSubItems] = useState(item.items ?? []);
  const query = useQuery({
    queryKey: item.action,
    queryFn: () => item.props.queryFn(activeWorkspace?.id, item),
    enabled: !!item.props.queryFn,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const parsedData = item.props.onSuccess(data);
      setSubItems(parsedData);
      setIsLoading(false);
    },
  });

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger {...(item?.itemProps ?? {})}>
        <div
          className={`flex items-center gap-2 font-medium focus:font-semibold p-0 ${item.className ?? ""}`}
        >
          <span className="stroke-gray-500 dark:stroke-gray-400">{item.icon}</span>
          {item.label}
        </div>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent className="w-36">
          {isLoading ? (
            <DropdownMenuItem className="flex items-center justify-center font-medium h-8">
              <div className="loader_circle ml-3 !border-l-brand-700 dark:!border-l-brand-400" />
            </DropdownMenuItem>
          ) : query.isError || !Array.isArray(subItems) || subItems.length === 0 ? (
            <DropdownMenuLabel>Error Loading...</DropdownMenuLabel>
          ) : (
            <RenderMenuItems
              items={subItems}
              excludedActions={excludedActions}
              onHandleRowActionClick={onHandleRowActionClick}
            />
          )}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
}

function RenderMenuItems({ items, excludedActions, onHandleRowActionClick }) {
  return returnArray(items).map((item, index) => {
    if (excludedActions.includes(item.action)) return null;
    if (Array.isArray(item?.items))
      return (
        <RenderMenuItem
          key={index}
          item={item}
          excludedActions={excludedActions}
          onHandleRowActionClick={onHandleRowActionClick}
        />
      );
    return (
      <DropdownMenuItem
        key={index}
        onSelect={() => onHandleRowActionClick(item.action, item?.data)}
        className={`flex items-center gap-2 font-medium focus:font-semibold ${item.className ?? ""}`}
      >
        <span className="stroke-gray-500 dark:stroke-gray-400">{item.icon}</span>
        {item.label}
      </DropdownMenuItem>
    );
  });
}

export default function TableRowActionDropdown({
  rowActionItems,
  excludedActions = [],
  onHandleRowActionClick = () => null,
  isItemsLoaded = true,
  isGroupingEnabled = true,
  className = "",
  children,
}) {
  const renderGroups = (groups) => {
    if (!isGroupingEnabled)
      return (
        <RenderMenuItems
          items={groups}
          excludedActions={excludedActions}
          onHandleRowActionClick={onHandleRowActionClick}
        />
      );

    return groups.map((group, index) => (
      <div key={group.group}>
        {index > 0 && <DropdownMenuSeparator />}
        <DropdownMenuGroup>
          <RenderMenuItems
            items={group.items}
            excludedActions={excludedActions}
            onHandleRowActionClick={onHandleRowActionClick}
          />
        </DropdownMenuGroup>
      </div>
    ));
  };

  const memoizedRenderGroups = useMemo(() => renderGroups(rowActionItems), [rowActionItems, excludedActions]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className={`w-40 ${className}`}>
        <DropdownMenuGroup>
          {isItemsLoaded ? (
            memoizedRenderGroups
          ) : (
            <DropdownMenuItem className="flex items-center justify-center font-medium h-8">
              <div className="loader_circle ml-3 !border-l-brand-700 dark:!border-l-brand-400" />
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
