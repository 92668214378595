import { Link } from "react-router-dom";
import SharedDataContext from "../../SharedDataContext";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

import React, { useCallback, useContext } from "react";

const BreadcrumbComponent = React.memo(({ loader, item }) => {
  if (item.current) {
    return (
      <BreadcrumbPage>
        <span className={`text-sm font-medium ${item.className} text-brand-700`}>
          {item.icon} {item.isLoading ? (loader ? loader : "Loading...") : item.label}
        </span>
      </BreadcrumbPage>
    );
  } else {
    return (
      <Link to={item.routeTo} className="transition-colors hover:text-foreground">
        <span className={`text-sm font-medium ${item.className}`}>
          {item.icon} {item.isLoading ? "Loading..." : item.label}
        </span>
      </Link>
    );
  }
});

export function ListBreadcrumb({
  crumbItems = [],
  separator = <BreadcrumbSeparator />,
  showAll = false,
  loader = <div className="loader_circle ml-3 !border-l-brand-700 dark:!border-l-brand-400" />,
}) {
  const workspaceSlug = useContext(SharedDataContext)?.activeWorkspace?.slug;

  if (crumbItems.length === 0) return null;

  const processedCrumbItems = crumbItems.map((item) => ({
    ...item,
    routeTo: `/${workspaceSlug}${item.routeTo}`,
  }));

  const handleClick = useCallback((routeTo) => {
    window.location.href = routeTo;
  }, []);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {showAll ? (
          processedCrumbItems.map((item, index) => (
            <React.Fragment key={item.order}>
              <BreadcrumbItem>
                <BreadcrumbComponent item={item} loader={loader} />
              </BreadcrumbItem>
              {index < processedCrumbItems.length - 1 && separator}
            </React.Fragment>
          ))
        ) : (
          <>
            <BreadcrumbItem>
              <BreadcrumbComponent item={processedCrumbItems[0]} loader={loader} />
            </BreadcrumbItem>
            {processedCrumbItems.length > 1 && separator}

            {/* Conditionally display the dropdown for middle items if more than three items */}
            {processedCrumbItems.length > 3 && (
              <>
                <BreadcrumbItem>
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex items-center gap-1">
                      <BreadcrumbEllipsis className="h-4 w-4" />
                      <span className="sr-only">Toggle menu</span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                      {processedCrumbItems.slice(1, -2).map((item) => (
                        <DropdownMenuItem key={item.order} onClick={() => handleClick(item.routeTo)}>
                          <BreadcrumbComponent item={item} loader={loader} />
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </BreadcrumbItem>
                {separator}
              </>
            )}

            {/* Display the second last and last items */}
            {processedCrumbItems.slice(-2).map((item, index) => (
              <React.Fragment key={item.order}>
                <BreadcrumbItem>
                  <BreadcrumbComponent item={item} loader={loader} />
                </BreadcrumbItem>
                {index < 1 && separator}
              </React.Fragment>
            ))}
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
