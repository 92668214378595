import Layout from "../../Layout";
import ProspectSearchList from "../../containers/ProspectSearchList";

export default function ProspectSearchListPage() {
  return (
    <Layout leftNav={false}>
      <ProspectSearchList />
    </Layout>
  );
}
