import React, { useCallback, useEffect, useRef, useState } from "react";
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import { sendInvitations } from "../../../apis/workspace/invitations";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { getGroups } from "../../../apis/workspace/groups";
import Image from "../../Tags/Image";
import { Button } from "../../ui/button";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
import { useLocation } from "react-router-dom";
export default function InviteModal({ showModal, closeModal, activeWorkspace, onboarding = false }) {
  const location = useLocation(); // Get current location
  const path = location.pathname; // Extract the path
  // console.log("path",path);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);
  const handleModalClose = () => {
    setOpen(false);
    closeModal();
  };

  const tagify = useRef();
  const [role, setRole] = useState("Member");
  const [group, setGroup] = useState("");
  const [emails, setEmails] = useState([]);
  const [emailsValid, setEmailsValid] = useState(true);
  // on tag add/edit/remove
  const onChange = useCallback((e) => {
    const emailArray = e.detail.tagify.value.map((each) => {
      return each.value;
    });
    setEmails(emailArray);
    console.log("tagify", tagify);
    setEmailsValid(tagify.current.getTagElms().length === tagify.current.value.length);
  }, []);
  // Tagify settings object
  const baseTagifySettings = {
    placeholder: "Invite multiple users. You can paste multiple emails seperated by comma or space",
    dropdown: {
      enabled: 0, // a;ways show suggestions dropdown
    },
    delimiters: ",| ",
    pattern: /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/,
    keepInvalidTags: true,
    editTags: 1,
  };

  const onTagRemove = (e) => {
    setEmailsValid(tagify.current.getTagElms().length === tagify.current.value.length);
    // TO DO
  };

  const onTagInvalid = (e) => {
    setEmailsValid(tagify.current.getTagElms().length === tagify.current.value.length);
  };

  // handle api call
  const sendInvitationsFn = useMutation("send_invitations", sendInvitations, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      setRole("Member");
      setEmails([]);
      setEmailsValid(false);
      handleModalClose();
      queryClient.invalidateQueries("get_invitations");
    },
  });
  const handleSendInvitations = () => {
    let data = [];
    if (group !== "") {
      data = emails.map((email) => ({
        email: email.toLowerCase(),
        role: role,
        group_id: group,
      }));
    } else {
      data = emails.map((email) => ({
        email: email.toLowerCase(),
        role: role,
      }));
    }
    if (data && data.length > 0) {
      sendInvitationsFn.mutate({
        data,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    } else {
      setEmailsValid(false);
    }
  };

  // check groups
  const [groups, setGroups] = useState([]);

  const getGroupsFn = useMutation("get_groups", getGroups, {
    onSuccess(data, variables, context) {
      setGroups(data.data);
      if (data && data.data && data.data.length === 0) {
        setTimeout(() => {
          const groupFilterEl = document.querySelector(".filter__group");
          if (groupFilterEl) {
            //console.log("groups hide");
            groupFilterEl.style.display = "none";
          }
        }, 100);
      }
    },
  });
  const handleGetGroups = () => {
    getGroupsFn.mutate({
      query: `page=1&per_page=100`,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id) {
      handleGetGroups();
    }
  }, [activeWorkspace]);
  const captureEvent = useCaptureEvent();
  return (
    <>
      {!onboarding && (
        <div className="flex items-center space-x-4">
          <div className="inline-flex justify-center border border-border rounded-[--radius] p-2 bg-background">
            <Image src="/images/icons/untitledui/group.svg" width="20" className="inline-block" />
          </div>
          <div>
            <h2 className="text-base font-semibold leading-7 ">Invite members</h2>
            <p className="text-paragraph">Invite your colleagues to join your workspace</p>
          </div>
        </div>
      )}
      <div className="mt-5">
        <label htmlFor="tagify_el" className="block mt-5 mb-2 text-sm font-medium leading-6">
          Enter Emails
        </label>
        <Tags
          id="tagify_el"
          onChange={onChange}
          settings={baseTagifySettings}
          tagifyRef={tagify}
          onRemove={onTagRemove}
          onInvalid={(e) => {
            setEmailsValid(false)
          }}
          autoFocus
          defaultValue={emails}
        />
        {!emailsValid && <div className="flex text-red-500 text-[12px] mt-6">Please enter valid emails</div>}
        <div className="border-b border-border my-5"></div>
        <div className="flex space-x-2 mobile:block mobile:space-x-0 mobile:space-y-4">
          <div className="w-6/12 mobile:w-full">
            <label htmlFor="role" className="block text-sm font-medium leading-6">
              Select Role
            </label>
            <select
              id="role"
              name="location"
              className="mt-2 h-11 px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
              value={role}
              defaultValue={role}
              onChange={(e) => {
                setRole(e.target.value); 
                captureEvent("HOM_InviteMemberModal_SelectRoleDropdown", {
                  SelectRoleDropdown: e.target.value, 
                });
              }}
              onClick={() => {
                captureEvent("HOM_InviteMemberModal_SelectRoleDropdown");
              }}
            >
              <option>Admin</option>
              <option>Member</option>
              <option>Guest</option>
            </select>
            <p className="text-xs mt-2 text-paragraph">All invited users will be given the selected role</p>
          </div>
          <div className="w-6/12 mobile:w-full">
            {groups && groups.length > 0 && (
              <>
                <label htmlFor="role" className="block text-sm font-medium leading-6">
                  Select Group
                </label>
                <select
                  id="role"
                  name="location"
                  className="mt-2 h-11 px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
                  value={group}
                  defaultValue={group}
                  onChange={(e) =>{ setGroup(e.target.value)
                    captureEvent("HOM_InviteMemberModal_SelectGroupDropdown",{SelectGroupDropdown: e.target.value});
                  }}
                  onClick={() => {
                    captureEvent("HOM_InviteMemberModal_SelectGroupDropdown");
                  }}
                >
                  <option value={""}>Select a Group</option>
                  {groups.map((group) => (
                    <option value={group.id} key={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>

                <p className="text-xs mt-2 text-paragraph">All users will be added to the selected group</p>
              </>
            )}
          </div>
        </div>
      </div>
      {onboarding ? <div className="my-5"></div> : <div className="border-b border-border my-5"></div>}
      <div className={`mt-5 flex space-x-2 ${onboarding ? "justify-center" : ""}`}>
        {!onboarding && (
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault();
              handleModalClose(false);
              if(path.includes("/settings/members")){
                captureEvent("SET_MembersInviteMembersModal_CancelBtn");
              }else{
                captureEvent("HOM_InviteMemberModal_CancelBtn");
              }
              
            }}
            className="flex-1"
          >
            Cancel
          </Button>
        )}
        <Button
          type="button"
          disabled={sendInvitationsFn.isLoading || emails.length === 0 || !emailsValid}
          onClick={() => {
            handleSendInvitations();
            if(path.includes("/settings/members")){
              captureEvent("SET_MembersInviteMembersModal_SendInviteBtn");
            }else{
              captureEvent("HOM_InviteMemberModal_SendInviteBtn");
            }
          }}
          className={`${onboarding ? "w-[200px]" : "flex-1"}`}
        >
          Send Invite {sendInvitationsFn.isLoading && <div className="loader_circle ml-2"></div>}
        </Button>
      </div>
    </>
  );
}
