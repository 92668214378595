import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import Image from "../Tags/Image";
import Cookies from "js-cookie";
import { logOutUser } from "../../apis/user";
import { useMutation } from "react-query";
import { NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { fetchCsrfCookie } from "../../apis/csrf";
import { useCaptureEvent } from "../../hooks/useCaptureEvent";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileDropdown({ data }) {
  // const logoutUrl = `${process.env.REACT_APP_AUTH_DOMAIN_BASE_URL}/api/logout`;
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const logOutUserFn = useMutation("logout", logOutUser, {
    onSuccess(data, variables, context) {
      // toast.success(data?.message);
      Cookies.remove(process.env.REACT_APP_PR_ACCESS_TOKEN);
      Cookies.remove(process.env.REACT_APP_PR_APP_SESSION_COOKIE);
      navigate("/login");
    },
  });

  const handleLogout = () => {
    fetchCsrfCookie()
      .then((xsrfToken) => {
        logOutUserFn.mutate({});
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      });
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          onClick={() => {
            captureEvent("TOP_UserProfileBtn");
          }}
        >
          <Image nobaseurl src={data?.icon} width={32} height={32} className={"rounded-full"} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-background shadow-lg ring-1 ring-border ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              <span className="block w-full px-4 py-3 pb-4 text-left border-b border-border mb-1">
                <span className="text-size-xs block mb-1 text-foreground/60">Signed in as</span>
                {data?.name}
                <span className="block text-[12px] text-gray-500 break-all">{data?.email}</span>
              </span>
            </Menu.Item>
            <Menu.Item>
              <NavLink
                to={`/profile`}
                className={"flex items-center space-x-2 w-full px-4 py-2 text-left"}
                onClick={() => {
                  captureEvent("TOP_UserProfileDropdown_ProfileBtn");
                }}
              >
                Profile
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(active ? "bg-secondary" : "", "block w-full px-4 py-2 text-left")}
                  // onClick={handleLogout}
                  onClick={() => {
                    handleLogout();
                    captureEvent("TOP_ThemeBtnTOP_UserProfileDropdown_LogOutBtn");
                  }}
                >
                  Log out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
