import React, { useContext, useEffect, useState } from "react";
import { paginationConfig } from "../../../axios/constant";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import { classNames, isEmptyObject, returnArray } from "../../../utils/GenericFn";
import EditColumn from "../../EditColumn";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "react-tooltip";
import NoData from "../../NoData";
import TableLoader from "../../Loaders/TableLoader";
import Pagination from "../../Pagination";
import Filter from "../../Filter";
import Image from "../../Tags/Image";
import PrintDate from "../../Tags/PrintDate";
import {
  getEnrichmentDetails,
  downloadEnrichListCSV,
  downloadSummarizedEnrichListCSV,
  getEnrichmentListRecords,
} from "../../../apis/enrich";
import { useParams } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import useModal from "../../GenericModal/useModal";
import { JsonView, collapseAllNested, darkStyles } from "react-json-view-lite";
import { toast } from "react-hot-toast";
import {
  BriefcaseIcon,
  CalendarIcon,
  ChevronRightIcon,
  ArrowPathRoundedSquareIcon,
  ArrowDownTrayIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../ui/breadcrumb";
import NavAnchor from "../../Tags/NavAnchor";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import TooltipWrapper from "../../TooltipWrapper";
import SharedDataContext from "../../../SharedDataContext";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
import GenericModal from "../../GenericModal";
export default function EnrichDetails() {
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const { enrichListId } = useParams();
  const [records, setRecords] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  const getEnrichmentListRecordsFn = useMutation("get_enrich_records", getEnrichmentListRecords, {
    onSuccess(data, variables, context) {
      //console.log(data);
      setRecords(data);
    },
  });
  const handleGetEnrichmentListRecords = () => {
    getEnrichmentListRecordsFn.mutate({
      query: filterQuery,
      listId: enrichListId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    if (activeWorkspace?.id && filterQuery !== "") {
      handleGetEnrichmentListRecords();
    }
  }, [activeWorkspace, filterQuery]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    //console.log(page);
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetSort = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    //console.log(columns);
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => a.order - b.order);
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // get enrichment details
  const [enrichData, setEnrichData] = useState({});
  const getEnrichmentDetailsFn = useMutation("get_enrich_details", getEnrichmentDetails, {
    onSuccess(data, variables, context) {
      console.log("enrichData", enrichData);
      setEnrichData(data);
    },
  });
  const handleGetEnrichmentDetails = () => {
    getEnrichmentDetailsFn.mutate({
      listId: enrichListId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  const [currentEnrichmentId, setCurrentEnrichmentId] = useState("");

  // download enrich list csv - summarized
  const downloadSummarizedEnrichListCSVFn = useMutation("download_enrich", downloadSummarizedEnrichListCSV, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_enrichment_list");
    },
  });
  const handleDownloadSummarizedEnrichListCSV = (item) => {
    setCurrentEnrichmentId(item.id);
    downloadSummarizedEnrichListCSVFn.mutate(
      {
        listId: item.id,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      },
      {
        onSuccess: (data) => {
          // Sanitize the item name to be used as the file name
          const sanitizedFileName = sanitizeFilename("Summarized-" + item.name) + ".csv";

          // Create a URL for the blob
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          // Create a temporary anchor tag
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", sanitizedFileName); // Set the sanitized file name for download
          document.body.appendChild(fileLink);

          // Programmatically click the link to start download
          fileLink.click();

          // Clean up
          fileLink.remove();
          window.URL.revokeObjectURL(fileURL);
        },
      }
    );
  };

  // download enrich list csv
  const downloadEnrichListCSVFn = useMutation("download_enrich", downloadEnrichListCSV, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_enrichment_list");
    },
  });
  const handleDownloadEnrichListCSV = (item) => {
    setCurrentEnrichmentId(item.id);
    downloadEnrichListCSVFn.mutate(
      {
        listId: item.id,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      },
      {
        onSuccess: (data) => {
          // Sanitize the item name to be used as the file name
          const sanitizedFileName = sanitizeFilename(item.name) + ".csv";

          // Create a URL for the blob
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          // Create a temporary anchor tag
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", sanitizedFileName); // Set the sanitized file name for download
          document.body.appendChild(fileLink);

          // Programmatically click the link to start download
          fileLink.click();

          // Clean up
          fileLink.remove();
          window.URL.revokeObjectURL(fileURL);
        },
      }
    );
  };

  function sanitizeFilename(name) {
    // Remove invalid characters
    const invalidChars = /[\\/:*?"<>|\x00-\x1F\x80-\x9F]/g; // Regex for invalid characters
    let sanitized = name.replace(invalidChars, "");

    // Prepend 'Enriched-' to the filename
    sanitized = "Enriched-" + sanitized;

    // Truncate the filename if it's too long (200 characters is a common maximum)
    const maxLength = 200;
    sanitized = sanitized.substring(0, maxLength);

    // Avoid filenames that are reserved in Windows
    const reservedNames = [
      "CON",
      "PRN",
      "AUX",
      "NUL",
      "COM1",
      "COM2",
      "COM3",
      "COM4",
      "COM5",
      "COM6",
      "COM7",
      "COM8",
      "COM9",
      "LPT1",
      "LPT2",
      "LPT3",
      "LPT4",
      "LPT5",
      "LPT6",
      "LPT7",
      "LPT8",
      "LPT9",
    ];
    if (reservedNames.includes(sanitized.toUpperCase())) {
      sanitized = "_" + sanitized;
    }

    // Ensure the filename is not empty
    if (sanitized.trim() === "") {
      sanitized = "unnamed_file";
    }

    return sanitized;
  }
  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetEnrichmentDetails();
    }
  }, [activeWorkspace?.id]);

  const { isOpen, openModal, closeModal } = useModal(false);
  const [timeline, setTimeline] = useState([]);

  const returnBadge = (status) => {
    if (status === "Cancelled" || status === "Failed") {
      return <Badge variant={"withdrawn"}>{status}</Badge>;
    } else if (status === "Completed" || status === "Enrichment Completed") {
      return <Badge>{status}</Badge>;
    } else if (
      status === "Finalizing" ||
      status === "Processing" ||
      status === "Retrying" ||
      status === "Pending"
    ) {
      return <Badge variant={"pending"}>{status}</Badge>;
    } else if (status === "No Match" || status === "Paused" || !status) {
      return <Badge variant={"outline"}>{status}</Badge>;
    } else if (status === "Verification Completed") {
      return <Badge variant={"success"}>{status}</Badge>;
    } else {
      return <Badge variant={"outline"}>{status}</Badge>;
    }
  };

  const returnBadgeTable = (status) => {
    if (status === "Success") {
      return <Badge variant={"accepted"}>{status}</Badge>;
    } else if (status === "Failed" || status === "Cancelled" || status === "Disqualified") {
      return <Badge variant={"withdrawn"}>{status}</Badge>;
    } else if (status === "No Match (Final)" || !status) {
      return <Badge variant={"outline"}>{status}</Badge>;
    } else {
      return <Badge variant={"outline"}>{status}</Badge>;
    }
  };

  return (
    <div className="px-4 mobile:px-0">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <NavAnchor to={`/`}>
                  <Image src="/images/icons/untitledui/home.svg" width="16" height="16" />
                </NavAnchor>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <NavAnchor to={`/enrich/list/`}>CSV Enrichment</NavAnchor>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage> {enrichData?.name}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="sm:flex sm:items-center my-6">
            <div className="sm:flex-auto">
              <h2 className="text-lg font-semibold leading-7 text-text-900">{enrichData?.name}</h2>
              <p className="text-text-600 mt-2 flex items-center space-x-3">
                <span className="leading-none">
                  {enrichData?.enrichment_mode === "Email" && (
                    <Image src={"/images/icons/untitledui/email-2.svg"} />
                  )}
                  {enrichData?.enrichment_mode === "Phone" && (
                    <Image src={"/images/icons/untitledui/phone-2.svg"} />
                  )}
                  {(enrichData?.enrichment_mode === "Email and Phone" ||
                    enrichData?.enrichment_mode === "Email or Phone") && (
                    <TooltipWrapper
                      className="inline-flex space-x-2 items-center"
                      content={enrichData?.enrichment_mode}
                    >
                      <Image src={"/images/icons/untitledui/phone-2.svg"} />
                      <Image src={"/images/icons/untitledui/email-2.svg"} />
                    </TooltipWrapper>
                  )}
                </span>
                <span className="h-1 w-1 inline-block rounded-full bg-text-900"></span>

                {returnBadge(enrichData?.status)}
                <span className="h-1 w-1 inline-block rounded-full bg-text-900"></span>
                <span>{enrichData?.created_by?.name} </span>
                <span className="h-1 w-1 inline-block rounded-full bg-text-900"></span>
                <span className="inline-flex items-center leading-none">
                  <Image src={"/images/icons/untitledui/calendar.svg"} className="mr-1" />
                  <PrintDate date={enrichData?.created_at} />{" "}
                </span>
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              {enrichData.status === "Completed" && (
                <span className="space-x-3">
                  {enrichData.enrichment_type === 'People' && <Button
                    disabled={downloadEnrichListCSVFn.isLoading && currentEnrichmentId === enrichData.id}
                    onClick={() => {
                      handleDownloadEnrichListCSV(enrichData);
                      captureEvent("BLE_ListDetail_FullCSVbtn");
                    }}
                    variant="purple_outline"
                  >
                    <ArrowDownTrayIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                    Full CSV
                    {downloadEnrichListCSVFn.isLoading && currentEnrichmentId === enrichData.id && (
                      <div className="loader_circle ml-2"></div>
                    )}
                  </Button>}
                  <Button
                    disabled={
                      downloadSummarizedEnrichListCSVFn.isLoading && currentEnrichmentId === enrichData.id
                    }
                    onClick={() => {
                      handleDownloadSummarizedEnrichListCSV(enrichData);
                      captureEvent("BLE_ListDetail_SummarizedCSVbtn");
                    }}
                  >
                    <ArrowDownTrayIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                    Summarized CSV
                    {downloadSummarizedEnrichListCSVFn.isLoading && currentEnrichmentId === enrichData.id && (
                      <div className="loader_circle ml-2"></div>
                    )}
                  </Button>
                </span>
              )}
            </div>
          </div>

          <div className="border-b border-border"></div>
        </div>
      </div>

      <div className="">
        {records && records.data && (records.data.length > 0 || !isEmptyObject(filters)) && (
          <div className="mt-7 flex justify-between items-center">
            <div className="space-x-3 inline-flex items-center">
              {records && records.filterMetadata && (
                <>
                  {" "}
                  {/* <span>Filters:</span> */}
                  <Filter filterMetadata={records.filterMetadata} getFiltersObject={getFiltersObject} />
                </>
              )}
            </div>
            <div className="space-x-3">
              {records.columnMetadata && (
                <EditColumn columnMetadata={records.columnMetadata} getColumns={getColumns} />
              )}
            </div>
          </div>
        )}
        <div>
          {records && records.data && records.data.length > 0 && getEnrichmentListRecordsFn.isSuccess && (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-border rounded-[--radius]">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="">
                        <tr>
                          {returnArray(sortedVisibleColumns).map((column, index) => (
                            <th
                              key={index}
                              scope="col"
                              className={`px-3 py-3.5 text-left text-xs font-medium text-text-900  ${
                                column.sortable ? "cursor-pointer" : ""
                              }`}
                              onClick={() => (column.sortable ? handleSetSort(column.key) : void 0)}
                            >
                              <span className={"inline-flex items-center"}>
                                {column.display_name}
                                {column.sortable && (
                                  <>
                                    {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                      <Image
                                        src="/images/icons/dual-arrow.svg"
                                        width="7"
                                        height="7"
                                        className="inline-block ml-1"
                                      />
                                    ) : (
                                      <>
                                        {sort === column.key && (
                                          <ArrowDownIcon width={12} className="inline-block ml-1" />
                                        )}
                                        {sort === `-${column.key}` && (
                                          <ArrowUpIcon width={12} className="inline-block ml-1" />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </span>
                            </th>
                          ))}
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border bg-background">
                        {records &&
                          returnArray(records.data).map((item, index) => (
                            <>
                              <tr key={item.id} className={` text-text-600`}>
                                {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                                  const colKey =
                                    columns &&
                                    Object.keys(columns).find((key) => columns[key].order === column.order);

                                  // if (colKey === "full_name") {
                                  //   return (
                                  //     <td
                                  //       key={colIndex}
                                  //       className="whitespace-nowrap px-3 py-4 text-sm "
                                  //     >
                                  //       {item?.first_name} {item?.last_name}
                                  //     </td>
                                  //   );
                                  // }

                                  if (colKey === "status") {
                                    return (
                                      <td key={colIndex} className="whitespace-nowrap px-3 py-4 text-sm ">
                                        {/* <Badge>{item.task && item.task?.status}</Badge> */}
                                        {item.task &&
                                          item.task?.status &&
                                          returnBadgeTable(item.task?.status)}
                                      </td>
                                    );
                                  }

                                  return (
                                    <td key={colIndex} className="whitespace-nowrap px-3 py-4 text-sm ">
                                      {item[colKey] ? item[colKey] : "-"}
                                    </td>
                                  );
                                })}
                                <td className="whitespace-nowrap px-3 py-4 text-sm ">
                                  {item.task && item.task.events && item.task.events.length > 0 && (
                                    <Button
                                      onClick={() => {
                                        openModal();
                                        setTimeline(item.task.events);
                                      }}
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="View events timeline"
                                    >
                                      <ClockIcon width={16} height={16} />
                                    </Button>
                                  )}
                                  <Tooltip id="in_table_actions" />
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {getEnrichmentListRecordsFn.isLoading && <TableLoader />}

          {getEnrichmentListRecordsFn.isSuccess &&
            records &&
            records.data &&
            records.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No Record found!"} />}
          {getEnrichmentListRecordsFn.isSuccess &&
            records &&
            records.data &&
            records.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No Record found!"} filterMode={true} />
              </div>
            )}
          <div className="flex items-center justify-between mt-2  py-3">
            <div className="flex-1">
              {records && records.pagination && records.pagination.total > 0 && (
                <Pagination data={records.pagination} getPageNumber={getPageNumber} />
              )}
            </div>
          </div>
        </div>
      </div>
      <GenericModal isOpen={isOpen} closeModal={closeModal} drawer={true}>
        <div className="flow-root p-4 mt-2">
        <h3 className="text-[18px] block font-semibold mb-6 pl-2">Events Timeline</h3>
          <ul role="list" className="space-y-6">
            {timeline &&
              timeline.length > 0 &&
              timeline.map((event, eventIdx) => (
                <li key={event.id} className="relative flex gap-x-4">
                  <div
                    className={classNames(
                      eventIdx === timeline.length - 1 ? "h-6" : "-bottom-6",
                      "absolute left-0 top-0 flex w-6 justify-center"
                    )}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    </div>
                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                      <p className="flex items-center">
                        {event.config && event.config.integration ? (
                          <Image
                            src={`/images/logos/integrations/${event.config.integration?.key}.svg`}
                            className="h-[24px]"
                          />
                        ) : (
                          <Image src={`/images/logos/integrations/integration.png`} className="h-[24px]" />
                        )}{" "}
                        <span className="ml-2 text-black font-semibold">
                          {event.config && event.config?.name}
                        </span>
                      </p>
                      <p className="text-gray-900 mt-2 font-semibold">
                        {event?.event_type}:{" "}
                        <span className="text-gray-600 font-normal">{event?.message}</span>
                      </p>
                      {event.metadata && (
                        <div className="p-5 rounded-lg bg-[#002b36] mt-2">
                          <JsonView
                            data={event.metadata}
                            shouldExpandNode={collapseAllNested}
                            style={darkStyles}
                          />
                        </div>
                      )}
                    </p>
                    <span className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                      <PrintDate date={event?.occurred_at} />
                    </span>
                  </>
                </li>
              ))}
          </ul>
        </div>
      </GenericModal>
    </div>
  );
}
