import React from "react";
import Layout from "../../Layout";
import ProspectSearch from "../../containers/ProspectSearch";

export default function ProspectSearchPage() {
  return (
    <Layout leftNav={false}>
      <ProspectSearch />
    </Layout>
  );
}
