import { PlusIcon } from "lucide-react";
import { useState } from "react";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import Text from "../../Tags/Text";
import { Button } from "../../ui/button";
import CompanyAndPeopleTable from "./CompanyAndPeopleTable";
import CreateOrEditListModal from "./CreateOrEditListModal";

export default function ProspectSearch() {
  const { isOpen, openModal, closeModal } = useModal(false);
  const [listModalMode, setListModalMode] = useState({ mode: "create", listData: null });

  const handleOpenEditListModal = (action, listData) => {
    setListModalMode({ mode: action, listData });
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setListModalMode({ mode: "create", listData: null });
  };

  return (
    <div
      className="bg-gray-25 dark:bg-gray-dark-50 -mx-8 -my-8 p-8 border-t border-border"
      style={{ minHeight: "calc(100vh - 72px)" }}
    >
      <div className="space-y-4 relative">
        <div className="flex justify-between items-center">
          <div className="flex-auto">
            <Text size="h6">Prospect</Text>
            <Text size="md-base" className="mt-1">
              Insights for companies and people. Save and manage lists.
            </Text>
          </div>
          <Button
            onClick={openModal}
            type="button"
            variant="purple_outline"
            className="border-brand-300 dark:border-brand-600"
          >
            <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            New List
          </Button>
        </div>
        <CompanyAndPeopleTable handleOpenEditListModal={handleOpenEditListModal} />
      </div>
      <GenericModal isOpen={isOpen} closeModal={handleCloseModal}>
        <CreateOrEditListModal closeModal={handleCloseModal} {...listModalMode} />
      </GenericModal>
    </div>
  );
}
