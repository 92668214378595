export default function NoData({ className, icon, heading, filterMode = false, description, children }) {
  return (
    <div className="text-center">
      <div
        className={`text-center dark:bg-blend-overlay bg__nodata p-36 inline-block mobile:p-10 ${
          className ? className : ""
        }`}
      >
        {icon ? (
          icon
        ) : (
          <svg
            className="mx-auto h-12 w-12 text-paragraph"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
        )}

        {filterMode ? (
          <h3 className="text-lg font-semibold leading-7 mt-4">{heading ? heading : "No Data found!"}</h3>
        ) : (
          <h3 className="text-lg font-semibold leading-7 mt-4">{heading ? heading : "No Data found!"}</h3>
        )}
        {filterMode && (
          <p className="mt-1 text-paragraph">
            {description ? description : "No data found with applied filters"}
          </p>
        )}
        {!filterMode && description && <p className="mt-1 text-paragraph">{description}</p>}
        <div className="mt-5">{children}</div>
      </div>
    </div>
  );
}
