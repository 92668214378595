import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";

import { useContext, useEffect, useState } from "react";
import SharedDataContext from "../../../SharedDataContext";
import { paginationConfig } from "../../../axios/constant";
import { useMutation, useQuery } from "react-query";
import { isEmptyObject, returnArray } from "../../../utils/GenericFn";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import Pagination from "../../Pagination";
import EditColumn from "../../EditColumn";
import Filter from "../../Filter";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import TableLoader from "../../Loaders/TableLoader";
import NoData from "../../NoData";
import { toast } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import ConfirmModal from "../../Modals/ConfirmModal";
import { deleteGroup, getGroups } from "../../../apis/workspace/groups";
import GroupModal from "./GroupModal";
import NavAnchor from "../../Tags/NavAnchor";
import Image from "../../Tags/Image";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import useRoleCheck from "../../../hooks/useRoleCheck";
import PrintDate from "../../Tags/PrintDate";
import { Button } from "../../ui/button";
import { PlusIcon } from "lucide-react";
import Text from "../../Tags/Text";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function Groups() {
  const captureEvent = useCaptureEvent();
  const { activeWorkspace } = useRoleCheck();
  const [groups, setGroups] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  const getGroupsFn = useQuery(
    "get_groups",
    () => {
      return getGroups({
        query: filterQuery,
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        setGroups(data);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetGroups = () => {
    getGroupsFn.refetch();
  };

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    //console.log("filterQuery", filterQuery);
    if (activeWorkspace?.id) {
      handleGetGroups();
      //console.log("filterQuery", filterQuery);
    }
    return () => setFilterQuery("");
  }, [activeWorkspace, filterQuery]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [
        `page=${pagination.page}`,
        `per_page=${pagination.perPage}`,
        `sort=${sort}`,
      ];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    //console.log(page);
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetSort = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    //console.log(columns);
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter(
      (column) => column.visible
    );
    const sortedVisibleColumns = visibleColumns.sort(
      (a, b) => a.order - b.order
    );
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // handle edit group
  const [groupEditMode, setGroupEditMode] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  // handle delete group
  const [currentGroupId, setCurrentGroupId] = useState("");
  const deleteGroupFn = useMutation("delete_group", deleteGroup, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleGetGroups();
    },
  });
  const handleDeleteGroup = () => {
    deleteGroupFn.mutate({
      groupId: currentGroupId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // handle open confirm
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    //console.log(data);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteGroup();
    }
  };

  // handle open group modal
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Text size="lg">Groups</Text>
          <Text size="p" className="mt-1">
            Manage members
          </Text>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button 
          onClick={() => {
            openModal();
            captureEvent("SET_Groups_CreateGroupBtn");
          }} 
          type="button">
            <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            Create Group
          </Button>
        </div>
      </div>
      <div className="border-b border-border my-5"></div>
      <div>
        {groups &&
          groups.data &&
          (groups.data.length > 0 || !isEmptyObject(filters)) && (
            <div className="mt-7 flex justify-between items-center">
              <div className="space-x-3 inline-flex items-center">
                {groups && groups.filterMetadata && (
                  <>
                    <span>Filters:</span>
                    <Filter
                      filterMetadata={groups.filterMetadata}
                      getFiltersObject={getFiltersObject}
                    />
                  </>
                )}
              </div>
              <div className="space-x-3">
                {groups.columnMetadata && (
                  <EditColumn
                    columnMetadata={groups.columnMetadata}
                    getColumns={getColumns}
                  />
                )}
              </div>
            </div>
          )}
        <div>
          {groups &&
            groups.data &&
            groups.data.length > 0 &&
            getGroupsFn.isSuccess && (
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border border-border rounded-[--radius]">
                      <table className="min-w-full divide-y divide-border">
                        <thead className="bg-background">
                          <tr>
                            {returnArray(sortedVisibleColumns).map(
                              (column, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className={`px-3 py-3.5 text-left text-sm font-normal  ${
                                    column.sortable ? "cursor-pointer" : ""
                                  }`}
                                  onClick={() =>
                                    column.sortable
                                      ? handleSetSort(column.key)
                                      : void 0
                                  }
                                >
                                  <span className={"inline-flex items-center"}>
                                    {column.display_name}
                                    {column.sortable && (
                                      <>
                                        {sort === "" ||
                                        (sort !== column.key &&
                                          sort !== `-${column.key}`) ? (
                                          <Image
                                            src="/images/icons/dual-arrow.svg"
                                            width="7"
                                            height="7"
                                            className="inline-block ml-1"
                                          />
                                        ) : (
                                          <>
                                            {sort === column.key && (
                                              <ArrowDownIcon
                                                width={12}
                                                className="inline-block ml-1"
                                              />
                                            )}
                                            {sort === `-${column.key}` && (
                                              <ArrowUpIcon
                                                width={12}
                                                className="inline-block ml-1"
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </span>
                                </th>
                              )
                            )}
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-normal"
                            ></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-border bg-background">
                          {groups &&
                            returnArray(groups.data).map((item, index) => (
                              <tr
                                key={item.id}
                                className={`${
                                  index % 2 !== 0 ? "bg-smoke" : ""
                                }`}
                              >
                                {returnArray(sortedVisibleColumns).map(
                                  (column, colIndex) => {
                                    const colKey =
                                      columns &&
                                      Object.keys(columns).find(
                                        (key) =>
                                          columns[key].order === column.order
                                      );
                                    if (colKey === "name") {
                                      return (
                                        <td
                                          key={colIndex}
                                          className="whitespace-nowrap px-3 py-4 text-brand-700"
                                        >
                                          <NavAnchor
                                            to={`/settings/groups/${item.id}`}
                                            className={
                                              "hover:text-brand-800 hover:underline"
                                            }
                                          >
                                            {item[colKey]}
                                          </NavAnchor>
                                        </td>
                                      );
                                    }
                                    if (colKey === "created_by") {
                                      return (
                                        <td
                                          key={colIndex}
                                          className="whitespace-nowrap px-3 py-4   text-paragraph"
                                        >
                                          <div className="flex items-center">
                                            <div className="h-9 w-9 flex-shrink-0">
                                              <Image
                                                nobaseurl
                                                className="h-9 w-9 rounded-full"
                                                src={
                                                  item[colKey]
                                                    ?.profile_photo_url
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="ml-4">
                                              <div className="font-medium">
                                                {item[colKey]?.name}
                                              </div>
                                              <div className="mt-0 text-paragraph">
                                                {item[colKey]?.email}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      );
                                    }
                                    if (colKey === "created_at") {
                                      return (
                                        <td
                                          key={colIndex}
                                          className="whitespace-nowrap px-3 py-4 text-paragraph"
                                        >
                                          <PrintDate date={item.created_at} />
                                        </td>
                                      );
                                    }
                                    return (
                                      <td
                                        key={colIndex}
                                        className="whitespace-nowrap px-3 py-4 text-paragraph"
                                      >
                                        {item[colKey] ? item[colKey] : "-"}
                                      </td>
                                    );
                                  }
                                )}
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Button
                                    data-tooltip-id="in_table_actions"
                                    data-tooltip-content="Edit Group"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCurrentGroup(item);
                                      setGroupEditMode(true);
                                      openModal();
                                      captureEvent("SET_GroupsColoumn_EditGroupBtn");
                                    }}
                                    variant="ghost"
                                  >
                                    <Image
                                      src="/images/icons/untitledui/edit.svg"
                                      width="16"
                                      height="16"
                                      className="inline-block"
                                    />
                                  </Button>
                                  <Button
                                    data-tooltip-id="in_table_actions"
                                    data-tooltip-content="Delete Group"
                                    disabled={
                                      deleteGroupFn.isLoading &&
                                      currentGroupId === item.id
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCurrentGroupId(item.id);
                                      setOpenConfirmModal(true);
                                      setOpenConfirmModalType("delete");
                                      captureEvent("SET_GroupsColoumn_DeleteBtn");
                                    }}
                                    variant="ghost"
                                  >
                                    <Image
                                      src="/images/icons/untitledui/trash.svg"
                                      width="16"
                                      height="16"
                                      className="inline-block"
                                    />
                                    {deleteGroupFn.isLoading &&
                                      currentGroupId === item.id && (
                                        <div className="loader_circle ml-2"></div>
                                      )}
                                  </Button>
                                  <Tooltip id="in_table_actions" />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {getGroupsFn.isLoading && <TableLoader />}

          {getGroupsFn.isSuccess &&
            groups &&
            groups.data &&
            groups.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No Group found!"} />}
          {getGroupsFn.isSuccess &&
            groups &&
            groups.data &&
            groups.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No Group found!"} filterMode={true} />
              </div>
            )}
          <div className="flex items-center justify-between  rounded-[--radius] py-3">
            <div className="flex-1">
              {groups && groups.pagination && groups.pagination.total > 0 && (
                <Pagination
                  data={groups.pagination}
                  getPageNumber={getPageNumber}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        openModal={openConfirmModal}
        modalType={openConfirmModalType}
        confirmModalState={confirmModalState}
      />
      <GenericModal
        isOpen={isOpen}
        closeModal={() => {
          closeModal();
          setGroupEditMode(false);
        }}
      >
        <GroupModal
          closeModal={() => {
            closeModal();
            setGroupEditMode(false);
          }}
          activeWorkspace={activeWorkspace}
          edit={groupEditMode}
          data={currentGroup}
        />
      </GenericModal>
    </>
  );
}
