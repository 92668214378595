const LoadingRows = new Array(10).fill(0);
const LoadingCells = new Array(7).fill(0);
const TableLoader = ({ marginTop = "mt-8 " }) => {
  return (
    <div className={`flow-root ${marginTop}`}>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border border-border rounded-[--radius]">
            <table className="min-w-full divide-y divide-border">
              <thead className="bg-background">
                <tr>
                  {LoadingCells.map((cell, index) => (
                    <th
                      key={`th_${index}`}
                      scope="col"
                      className={`px-3 py-3.5 text-left text-sm font-normal`}
                    >
                      <div className="placeholder-content" style={{ height: "20px" }}></div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-border bg-background">
                {LoadingRows.map((row, index) => (
                  <tr key={`tr_${index}`}>
                    {LoadingCells.map((cell, index) => (
                      <td key={`td_${index}`} className="whitespace-nowrap px-3 py-4 text-sm">
                        <div className="placeholder-content" style={{ height: "20px" }}></div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableLoader;
