import React from "react";
import Layout from "../../Layout";
import ApiKeys from "../../containers/ApiKeys";

export default function ApiKeysPage() {
  return (
    <Layout>
      <ApiKeys />
    </Layout>
  );
}
