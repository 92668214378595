import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { createOrPersistCompany } from "../../../../apis/workspace/companies";
import { ReactComponent as BuildingIcon } from "../../../../static/media/icons/building-detail-icon.svg";
import { ReactComponent as LinkHorizontalIcon } from "../../../../static/media/icons/link-horizontal-icon.svg";
import linkedinIcon from "../../../../static/media/images/linkedin-logo.png";
import FormError from "../../../Tags/FormError";
import Input from "../../../Tags/Input";
import Text from "../../../Tags/Text";
import { Button } from "../../../ui/button";
import { Seperator } from "../../../ui/seperator";

export default function AddCompanyModal({ activeWorkspace, closeModal, currentListData }) {
  const queryClient = useQueryClient();
  const [selectedForm, setSelectedForm] = useState("linkedin");

  const formikLinkedinURL = useFormik({
    initialValues: {
      linkedin_url: "",
    },
    validationSchema: Yup.object({
      linkedin_url: Yup.string()
        .required("Linkedin company URL is required")
        .matches(/^(https?:\/\/)?(www.)?linkedin.com\/company\/[a-zA-Z0-9_-]+\/?$/, {
          message: "Provided URL is not valid linkedin company URL",
        }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const formikWebsiteURL = useFormik({
    initialValues: {
      website_url: "",
    },
    validationSchema: Yup.object({
      website_url: Yup.string()
        .required("Website or domain input is required")
        .matches(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/, {
          message: "Provided URL is not valid website or domain",
        }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleModalClose = () => {
    formikLinkedinURL.resetForm();
    formikWebsiteURL.resetForm();
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedForm === "linkedin") {
      formikLinkedinURL.submitForm();
    } else {
      formikWebsiteURL.submitForm();
    }
  };

  // TODO:Kamal
  const listMutation = useMutation({
    mutationFn: createOrPersistCompany,
    onSuccess(data) {
      toast.success(data?.message);
      // TODO:Kamal: Invalidate Query
      handleModalClose();
    },
  });

  return (
    <div className="p-6 flex flex-col h-full">
      <div className="flex items-center gap-4">
        <div className="h-[40px] w-[40px] items-center flex items-center justify-center border rounded-lg inline-block border-gray-300 dark:border-gray-100">
          <BuildingIcon className="stroke-gray-600" height="20" width="20" />
        </div>
        <Text size="lg">Add Company</Text>
      </div>
      <div className="h-6 w-full" />
      <div className="h-full flex flex-col justify-between">
        <div className="flex flex-col gap-6">
          <FormikProvider value={formikLinkedinURL}>
            <form onSubmit={formikLinkedinURL.handleSubmit} onFocus={() => setSelectedForm("linkedin")}>
              <label htmlFor="linkedin_url" className="block mb-1.5 text-sm font-medium leading-6">
                LinkedIn URL
              </label>
              <Input
                icon={<img src={linkedinIcon} height="20" width="20" />}
                autoFocus
                type="text"
                name="linkedin_url"
                id="linkedin_url"
                autoComplete="url"
                placeholder="https://linkedin.com/company/google"
                onChange={formikLinkedinURL.handleChange}
                value={formikLinkedinURL.values.linkedin_url}
                onBlur={formikLinkedinURL.handleBlur}
              />
              {selectedForm === "linkedin" && <FormError field="linkedin_url" className="mt-1.5" />}
            </form>
          </FormikProvider>
          <Seperator text="or" />
          <FormikProvider value={formikWebsiteURL}>
            <form onSubmit={formikWebsiteURL.handleSubmit} onFocus={() => setSelectedForm("website")}>
              <label htmlFor="website_url" className="block mb-1.5 text-sm font-medium leading-6">
                Website URL or Domain
              </label>
              <Input
                icon={<LinkHorizontalIcon className="stroke-gray-900" height="20" width="20" />}
                type="text"
                name="website_url"
                id="website_url"
                autoComplete="url"
                placeholder="www.google.com"
                onChange={formikWebsiteURL.handleChange}
                value={formikWebsiteURL.values.website_url}
                onBlur={formikWebsiteURL.handleBlur}
              />
              {selectedForm === "website" && <FormError field="website_url" className="mt-1.5" />}
            </form>
          </FormikProvider>
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleModalClose();
            }}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={selectedForm === "linkedin" ? !formikLinkedinURL.isValid : !formikWebsiteURL.isValid}
          >
            Save
            {formikLinkedinURL.isSubmitting || formikWebsiteURL.isSubmitting ? (
              <div className="loader_circle ml-3"></div>
            ) : (
              ""
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
