import React from "react";

import { integrationFormsMetaData } from "./integrationFormsMeta";
import DynamicForm from "./DynamicForm";
import ZoomInfoForm from "./ZoomInfoForm";

export default function InstallIntegration({
  edit = false,
  formData,
  closeModal,
  integrationKey,
  logo,
  onboarding = false,
}) {
  return (
    <>
      {integrationKey &&
        (integrationKey === "zoom_info" ? (
          <div className="p-6">
            <ZoomInfoForm
              logo={logo}
              edit={edit}
              closeForm={closeModal}
              zoomInfoformData={formData}
              onboarding={onboarding}
            />
          </div>
        ) : (
          <div className="p-6">
            <DynamicForm
              integrationKey={integrationKey}
              integrationFormsMetaData={integrationFormsMetaData}
              logo={logo}
              closeForm={closeModal}
              edit={edit}
              formData={formData}
              onboarding={onboarding}
            />
          </div>
        ))}
    </>
  );
}
