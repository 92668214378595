import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
import Image from "../../Tags/Image";
import { Button } from "../../ui/button";
import { useState } from "react";
import usePageAttributes from "../../../hooks/usePageAttributes";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../ThemeProvider";
import { Sun } from "lucide-react";

const surveySteps = [
  {
    question: `What was your main reason for uninstalling ${process.env.REACT_APP_APP_NAME}?`,
    options: [
      "Found a better alternative",
      "Too expensive for the value provided",
      "Technical issues/bugs",
      "Missing key features I need",
      "No longer need this type of tool",
    ],
  },
  // {
  //   question: `How easy was it for you to use ${process.env.REACT_APP_APP_NAME}'s features?`,
  //   options: [
  //     "Very easy - I could use everything without help",
  //     "Somewhat easy - I figured most things out with minimal guide",
  //     "Neutral - Some features were easy, others were difficult",
  //     "Somewhat difficult - I often needed help or documentation",
  //     "Very difficult - I struggled to use most features",
  //   ],
  // },
  // {
  //   question: " Which feature did you use most frequently?",
  //   options: [
  //     "Contact enrichment",
  //     "Company intelligence/analysis",
  //     "LinkedIn prospecting",
  //     "AI message generation",
  //     "Didn't use it enough to say",
  //   ],
  // },
  // {
  //   question: `What was your biggest challenge while using ${process.env.REACT_APP_APP_NAME}?`,
  //   options: [
  //     "Data accuracy/quality issues",
  //     "Complex user interface",
  //     "Slow performance",
  //     "Integration problems with CRM",
  //     "Poor customer support",
  //   ],
  // },
  // {
  //   question: `What would have convinced you to keep using ${process.env.REACT_APP_APP_NAME}?`,
  //   options: [
  //     "Lower pricing",
  //     "Better data coverage",
  //     "More advanced features",
  //     "Improved user interface",
  //     "Better customer support",
  //   ],
  // },
];

const OffBoarding = () => {
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent(); 
  const { logo, appname, setTitle } = usePageAttributes();


  const [step, setStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelect = (option, step) => {
    setSelectedOption(option.answer);
    setSelectedOptions((prev) => {
      const updatedOptions = [...prev];
      updatedOptions[step] = option; // Replace option at the given step index
      return updatedOptions;
    });
    handleNext();
  };

  const handleNext = () => {
    if (step < surveySteps.length - 1) {
      setStep(step + 1);
      setSelectedOption(null);
    }
  };
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = () => {
    setSubmitted(true)
    captureEvent("Chrome_Extension_Uninstall_Feedback", {feedback: selectedOptions})
    setTimeout(() => {
      navigate(`/`);
    }, 3000);
  };

  const { theme, setTheme } = useTheme();

  return (
    <>
      <div className="min-h-screen">
        <header className="border-b border-border bg-white dark:bg-gray-25 py-5 mobile:w-full">
          <nav className="mx-auto flex items-center  px-6 lg:px-8" aria-label="Global">
            <div className="flex items-center gap-x-2 w-4/12 mobile:w-4/12">
              <a
                href={`/${process.env.REACT_APP_APP_HOME}/`}
                className="-m-1.5 p-1.5 w-[264px]"
              >
                {appname === "PrimeRole" ? (
                  <>
                    {theme === "dark" ? (
                      <Image
                        src="/images/apps/primerole/logo-dark.svg"
                        className="logo no-invert w-[170px]"
                        alt="Logo"
                      />
                    ) : (
                      <Image src={logo} className="logo w-[170px]" alt="Logo" />
                    )}
                  </>
                ) : (
                  <Image src={logo} className="logo w-[170px]" alt="Logo" />
                )}
              </a>
            </div>
            <div className="w-4/12 text-center">
              {/* <div className="flex justify-center items-center flex-col gap-1 mobile:flex mobile:items-end">
                <p className="text-sm text-gray-500 text-center">
                  {" "}
                  Question {step + 1} of {surveySteps.length}
                </p>

                <div className="flex space-x-3 mobile:hidden ">
                  {surveySteps.map((_, index) => (
                    <div
                      key={index}
                      className={`h-1 w-6 rounded-full ${index <= step ? "bg-black dark:bg-white" : "bg-gray-300"}`}
                    />
                  ))}
                </div>
              </div> */}
            </div>
            <div className="w-4/12 text-right">
              <div className="inline-flex items-center">
                {theme === "light" && (
                  <span
                    className="cursor-pointer transition-all text-text-700"
                    onClick={() => {
                      setTheme("dark");
                      captureEvent("TOP_ThemeBtn");
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3333 13.2035C17.2388 13.6985 16.0239 13.974 14.7446 13.974C9.92941 13.974 6.02597 10.0706 6.02597 5.25544C6.02597 3.97612 6.30151 2.76115 6.79648 1.66666C3.77147 3.03468 1.66667 6.07891 1.66667 9.61473C1.66667 14.4299 5.57012 18.3333 10.3853 18.3333C13.9211 18.3333 16.9653 16.2285 18.3333 13.2035Z"
                        stroke="currentColor"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                )}
                {theme === "dark" && (
                  <Sun
                    onClick={() => {
                      setTheme("light");
                      captureEvent("TOP_ThemeBtn");
                    }}
                    className="h-[1.25rem] w-[1.25rem] cursor-pointer transition-all"
                  />
                )}
              </div>
            </div>
          </nav>
        </header>

        <div
          className="mx-auto justify-between bg__gradient__onboarding"
          style={{ minHeight: "calc(100vh - 73px)" }}
        >
          <div className="flex w-full container__layout mobile:w-full">
            <div className="flex-1 justify-center items-center inline-flex overflow-y-scroll">
              <div className="flex justify-center items-center h-full mobile:w-full mobile:px-4">
                <div className={`bg-white dark:bg-gray-50 rounded-xl p-10 ${step === 4 ? 'pb-7' : 'pb-10'} shadow w-[768px]  mx-auto mobile:w-full`}>
                  <div className="flex justify-center items-center mb-6">
                    <Image src="/images/icons/featured-icon.svg" alt="Logo" className="no-invert" />
                  </div>

                {!submitted ? <div>
                    {/* Survey Question */}
                    <div className="flex justify-center items-center flex-col mb-6 mobile:w-full mobile:flex mobile:items-center">
                      <h1 className="text-lg font-semibold text-gray-900 mobile:text-center mobile:mb-1">
                        {surveySteps[step].question}
                      </h1>
 
                    </div>
                    {/* Survey Options */}
                    <div className={`flex justify-center items-center flex-col ${step === 4 ? 'mb-4' : 'mb-6'} mobile:w-full`}>
                      {surveySteps[step].options.map((option) => (
                        <div
                          className="flex px-3 py-4 w-full border border-solid rounded-xl items-center gap-4 mb-2 cursor-pointer hover:bg-purple-50 dark:hover:bg-black"
                          key={option}
                          onClick={() => handleSelect({question: surveySteps[step].question, answer: option}, step)}
                        >
                          {selectedOption === option ? (
                            <Image src="/images/icons/checked-icon.svg" className="no-invert" />
                          ) : (
                            <Image src="/images/icons/unchecked-icon.svg" className="no-invert" />
                          )}
                          <p className="text-gray-900 text-md">{option}</p>
                        </div>
                      ))}
                    </div>

                    {/* Skip Button */}
                    <div className="flex justify-center items-center">
                      {step < surveySteps.length - 1 ? (
                        <button onClick={() => {
                          handleSelect({question: surveySteps[step].question, answer: 'skipped'}, step)
                          handleNext()
                        }} className="w-full text-sm font-semibold text-gray-600">
                          Skip →
                        </button>
                      ) : (
                        <Button
                          disabled={!selectedOption}
                          onClick={handleSubmit}
                          className="bg-white text-brand-700 border  border-brand-700 hover:bg-white hover:text-brand-700"
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                 </div> : <div className="text-center">
                      <h3 className="text-center font-bold text-lg">We hope to bring you back to {process.env.REACT_APP_APP_NAME} soon!</h3>
                      <Image src="/images/falling-from-sky.svg" alt="Falling" className="inline-block mt-10" />
                      <p className="mt-10 text-xs">Redirecting to Dashboard...</p>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffBoarding;
