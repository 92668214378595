import React from "react";
import Layout from "../../Layout";
import Security from "../../containers/Security";

export default function SecurityPage() {
  return (
    <Layout>
      <Security />
    </Layout>
  );
}
