import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function SuccessAlert({
  heading,
  description,
  timer = 5,
  isVisible,
  onTimerEnd,
}) {
  const [secondsRemaining, setSecondsRemaining] = useState(timer);
  const [showAlert, setShowAlert] = useState(isVisible);

  useEffect(() => {
    let interval;

    if (showAlert && secondsRemaining > 0) {
      interval = setInterval(() => {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (secondsRemaining === 0) {
      clearInterval(interval);
      if (onTimerEnd) onTimerEnd();
    }

    // Cleanup the interval on unmount or when the alert is not shown.
    return () => {
      clearInterval(interval);
    };
  }, [showAlert, secondsRemaining, onTimerEnd]);

  useEffect(() => {
    setShowAlert(isVisible);
  }, [isVisible]);

  return showAlert ? (
    <div>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {heading ? heading : "Success!"}
        </h3>
        <div className="mt-2">
          {description && (
            <p className="text-sm text-gray-500">{description}</p>
          )}
          {timer > 0 && (
            <p className="text-xs text-gray-500 mt-5">
              {secondsRemaining} seconds
            </p>
          )}
        </div>
      </div>
    </div>
  ) : null;
}
