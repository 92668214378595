import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./index.css";
import {
  Pagination as PaginationUI,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../ui/pagination";

function Pagination({ data, getPageNumber }) {
  const [pagination, setPagination] = useState({
    currentPage: null,
    lastPage: null,
    perPage: null,
    total: null,
  });

  const [totalPages, setTotalPages] = useState(0);

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    getPageNumber(selected);
  };

  useEffect(() => {
    if (data) {
      setPagination((prev) => ({
        ...prev,
        currentPage: data.currentPage,
        lastPage: data.lastPage,
        perPage: data.perPage,
        total: data.total,
      }));
      setTotalPages(Math.ceil(data.total / data.perPage));
    }
  }, [data]);

  return (
    <>
      <div className="flex items-center justify-between w-full">
        {pagination && pagination.total <= pagination.perPage && (
          <div>
            <p className="text-sm text-paragraph">
              Showing {data.total} results
            </p>
          </div>
        )}
        {pagination && pagination.total > pagination.perPage && (
          <>
            <div>
              <p className="text-sm text-paragraph">
                Showing{" "}
                <span className="font-medium">
                  {pagination.currentPage === 1
                    ? pagination.currentPage
                    : (pagination.currentPage - 1) * pagination.perPage}
                </span>{" "}
                to{" "}
                <span className="font-medium">
                  {pagination.currentPage === 1
                    ? pagination.perPage
                    : pagination.currentPage * pagination.perPage >
                      pagination.total
                    ? pagination.total
                    : pagination.currentPage * pagination.perPage}
                </span>{" "}
                of <span className="font-medium">{pagination.total}</span>{" "}
                results
              </p>
            </div>
            <div className="paginationWrapper">
              <ReactPaginate
                previousLabel={
                  <div className="inline-flex text-paragraph items-center">
                    {" "}
                    <ChevronLeftIcon
                      className="h-4 w-4 mr-1"
                      aria-hidden="true"
                    />{" "}
                    <span>Previous</span>
                  </div>
                }
                nextLabel={
                  <div className="inline-flex text-paragraph  items-center">
                    <span>Next</span>
                    <ChevronRightIcon
                      className="ml-1 h-4 w-4"
                      aria-hidden="true"
                    />
                  </div>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages} // Total number of pages
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={pagination.currentPage - 1} // Subtracting 1 as react-paginate starts from 0
              />
              {/* <PaginationUI>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious href="#" />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#" isActive>
                      2
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationEllipsis />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationNext href="#" />
                  </PaginationItem>
                </PaginationContent>
              </PaginationUI> */}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Pagination;
