import { DELETE, GET, PATCH, POST } from "../../axios/apiService";

export const getMembers = async ({ query, options }) => {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/api/v1/workspace-settings/members${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const changeMemberRole = async ({ data, options, memberId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/members/${memberId}`;
  return PATCH(url, data, options);
};

export const deleteMember = async ({ memberId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/members/${memberId}`;
  return DELETE(url, options);
};

export const getMembershipRequests = async ({ query, options }) => {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/api/v1/workspace-settings/membership-requests${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const createMembershipRequest = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/membership-requests`;
  return POST(url, data);
};

export const deleteMembershipRequest = async ({ data, options }) => {
  console.log("data", data);
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/membership-requests/decline`;
  return POST(url, data, options);
};

export const approveMembershipRequest = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/membership-requests`;
  return POST(url, data, options);
};

export const joinOpenWorkspace = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/join`;
  return POST(url, data);
};
export const acceptInvite = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/invitation/accept`;
  return POST(url, data);
};

export const getInviteData = async ({ inviteId, inviteToken }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/invitation/${inviteId}/${inviteToken}`;
  return GET(url);
};

export const declineInvite = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/invitation/decline`;
  return POST(url, data);
};

export const withdrawWorkspaceRequest = async ({ requestId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/membership-requests/${requestId}/withdraw`;
  return POST(url, {}, options);
};
