import React, { useEffect, useState } from "react";
import Image from "../../Tags/Image";
import Text from "../../Tags/Text";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import FormError from "../../Tags/FormError";
import { resendOtp, resetPassword } from "../../../apis/user";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { fetchCsrfCookie } from "../../../apis/csrf";
import OtpInput from "react-otp-input";
import { Check } from "lucide-react";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function ForgetPassword() {
  const captureEvent = useCaptureEvent();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email address"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Submitted values:", values);
      setEmail(values?.email);
      resendOtpFn.mutate({
        email: values?.email,
        action: "reset-password",
      });
    },
  });

  //  reset password
  const [passwordResetDone, setPasswordResetDone] = useState(false);
  const validationSchemaReset = yup.object({
    password: yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formikReset = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchemaReset,
    onSubmit: (values) => {
      let payload = {
        otp: otp,
        token: token,
        email: email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      };

      resetPasswordFn.mutate(payload);
    },
  });

  const resetPasswordFn = useMutation("reset_password", resetPassword, {
    onSuccess(data, variables, context) {
      toast.success(data.message);
      formik.setSubmitting(false);
      formikReset.setSubmitting(false);
      formik.resetForm();
      setPasswordResetDone(true);
      setTimeout(() => {
        window.location.href = `/app/login`;
      }, 2000);
    },
    onError(error) {
      console.log(error);
      formik.setSubmitting(false);
      formikReset.setSubmitting(false);
    },
  });

  //

  useEffect(() => {
    fetchCsrfCookie()
      .then((xsrfToken) => {
        console.log("CSRF");
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      });
  }, []);

  // reset password

  const resendOtpFn = useMutation("resend_otp", resendOtp, {
    onSuccess(data, variables, context) {
      toast.success(data.message);
      setToken(data.token);
      formik.setSubmitting(false);
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  // show reset form

  return (
    <div className="bg-brand-50 dark:bg-gray-25 min-h-screen pt-28 mobile:pt-10 mobile:px-4">
      <div className="max-w-[440px] mx-auto py-8 px-10 rounded-xl shadow bg-white dark:bg-gray-50 mobile:px-4">
        <div className="text-center">
          <Image src="/images/apps/primerole/favicon.ico" width={48} height={48} className="inline-block" />
        </div>
        <div className="text-center mt-6">
          <Text size="h6">Reset password</Text>
        </div>
        {token ? (
          <>
            {passwordResetDone ? (
              <div className="text-center mt-10">
                <span className="mb-6 bg-green-100 h-20 w-20 inline-flex items-center justify-center rounded-full">
                  <Check className="text-green-600  box-border w-10 h-10" />
                </span>
                <Text size="md-base">Password has been reset successfully</Text>

                <Text size="md-base" className="mt-2 flex items-center justify-center space-x-2">
                  <span>Redirecting to login... </span>
                </Text>
              </div>
            ) : (
              <FormikProvider value={formikReset}>
                <form
                    onSubmit={formikReset.handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); 
                        formikReset.handleSubmit(); 
                      }
                    }}
                  >
                  <div className="mt-6">
                    <div className="mb-5">
                      <p className="mb-2">Email Id</p>
                      <Input
                        className={"w-full bg-gray-200"}
                        placeholder="Email"
                        value={email}
                        disabled={true}
                      />
                      <FormError field="password" />
                      <div className="flex justify-end">
                        <Button
                          onClick={() => {
                            setToken("");
                            setOtp("")
                            formikReset.resetForm();
                          }}
                          variant="link"
                          className="text-brand-700 px-1 dark:text-brand-600"
                        >
                          Use a Different Email
                        </Button>
                      </div>
                    </div>
                    <div className="mb-5">
                      <p className="mb-2">Enter 6 Digit OTP</p>
                      <OtpInput
                        inputType="number"
                        containerStyle={"w-full space-x-3"}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={""}
                        placeholder={"000000"}
                        inputStyle={
                          "remove-arrow h-[50px] w-[50px] border border-border rounded-xl text-2xl text-center placeholder-gray-300 dark:text-black font-bold dark:placeholder-gray-700"
                        }
                        skipDefaultStyles={true}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="mb-5">
                      <p className="mb-2">New Password</p>
                      <Input
                        className={"w-full"}
                        placeholder="New Password"
                        name="password"
                        onChange={formikReset.handleChange}
                        onBlur={formikReset.handleBlur}
                        value={formikReset.values.password}
                        type="password"
                      />
                      <FormError field="password" />
                    </div>
                    <div>
                      <p className="mb-2">Confirm Password</p>
                      <Input
                        className={"w-full"}
                        placeholder="Confirm Password"
                        name="password_confirmation"
                        onChange={formikReset.handleChange}
                        onBlur={formikReset.handleBlur}
                        value={formikReset.values.password_confirmation}
                        type="password"
                      />
                      <FormError field="password_confirmation" />
                    </div>

                    <div className="mt-5 mb-2">
                      <Button
                        className="w-full"
                        type="submit"
                        disabled={!otp || otp?.length < 6 || formikReset.isSubmitting || !formikReset.isValid}
                      >
                        Submit
                        {formikReset.isSubmitting && <div className="loader_circle ml-2"></div>}
                      </Button>
                    </div>
                    <div className="mb-2">
                      Didn't receive OTP?{" "}
                      <Button
                        variant="link"
                        className="text-brand-700 px-1 dark:text-brand-600"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                        disabled={formik.isSubmitting}
                      >
                        Resend OTP{" "}
                        {formik.isSubmitting && <div className="loader_circle ml-2 inline-block"></div>}
                      </Button>
                    </div>
                  </div>
                </form>
              </FormikProvider>
            )}
          </>
        ) : (
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-6">
                <Input
                  className={"w-full"}
                  placeholder="Enter your email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormError field="email" />

                <div className="mt-5">
                  <Button
                    className="w-full"
                    type="submit"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => {
                      captureEvent("ONB_LoginForgotPassword_SubmitBtn");
                    }}
                  >
                    Submit
                    {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
                  </Button>
                </div>
              </div>
            </form>
          </FormikProvider>
        )}
      </div>
    </div>
  );
}
