import { ArrowDownIcon, ArrowUpIcon, ExternalLink, PlusIcon, Search } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import { getList } from "../../../../apis/workspace/lists";
import { paginationConfig } from "../../../../axios/constant";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import useUpdateQuery from "../../../../hooks/useUpdateQuery";
import { ReactComponent as BuildingIcon } from "../../../../static/media/icons/building-detail-icon.svg";
import LinkedinIcon from "../../../../static/media/icons/linkedin-icon.svg";
import { isEmptyObject, returnArray } from "../../../../utils/GenericFn";
import EditColumn from "../../../EditColumn";
import Filter from "../../../Filter";
import TableLoader from "../../../Loaders/TableLoader";
import NoData from "../../../NoData";
import Pagination from "../../../Pagination";
import Image from "../../../Tags/Image";
import Input from "../../../Tags/Input";
import PrintDate from "../../../Tags/PrintDate";
import TooltipWrapper from "../../../TooltipWrapper";
import { Button } from "../../../ui/button";
import { Checkbox } from "../../../ui/checkbox";
import TableDropdownRowOptions from "../TableDropdownRowOptions/tableDropdownRowOptions";
import TableMultiSelectOptions from "../TableMultiSelectOptions";

function CompaniesNoDataInserted() {
  return (
    <NoData
      icon={
        <div className="rounded-full inline-block bg-smoke">
          <div className={"bg-brand-100 text-brand-700 rounded-full inline-block p-4"}>
            <BuildingIcon height="28" width="28" className="stroke-brand-600" />
          </div>
        </div>
      }
      heading={"Companies"}
      description={"Add new companies manually or through the chrome extension on LinkedIn"}
      filterMode={false}
    >
      <div className="flex flex-col justify-stretch md:flex-row md:justify-center gap-2">
        <Button
          onClick={() => console.log("open company")}
          type="button"
          variant="purple_outline"
          className="border-brand-300 dark:border-brand-600 group"
        >
          <PlusIcon className="mr-2" aria-hidden="true" height="20" width="20" />
          Add Company Manually
        </Button>
        <Button type="button" variant="gray-outline">
          <Image src={LinkedinIcon} height="20" width="20" nobaseurl={true} className="no-invert mr-1.5" />
          Prospect on LinkedIn
          <ExternalLink className="ml-1.5 h-5 w-5" />
        </Button>
      </div>
    </NoData>
  );
}

export default function CompaniesTab({ onHandleChangeCurrentPageProps, handleOpenEditListModal }) {
  const { activeWorkspace } = useRoleCheck();
  const setLocationSearchParam = useUpdateQuery();
  const { listNameSlug } = useParams();
  const refMount = useRef(false);
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const filterQuery = searchQuery.toString();
  const [searchString, setSearchString] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [list, setList] = useState({});
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: searchQuery.get("page") || paginationConfig.page,
    perPage: 20,
  });
  const [sort, setSort] = useState(searchQuery.get("sort") || "");
  const [columns, setColumns] = useState({});
  const queryParamsForLoaded = useMemo(() => {
    const params = new URLSearchParams();
    params.set("scope", searchQuery.get("scope"));
    params.set("page", pagination.page);
    params.set("per_page", pagination.perPage);
    if (sort) params.set("sort", sort);
    Object.entries(filters).forEach(([key, { selectedScope, value }]) => {
      if (selectedScope && value) {
        const parsedValue = value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.set(`filter[${selectedScope}]`, parsedValue);
      }
    });
    return params.toString();
  }, [pagination, filters, sort]);

  const companyApiQuery = useQuery(
    "get_company_lists",
    () => {
      const apiQuery = searchQuery;
      apiQuery.delete("scope");
      apiQuery.set("item_type", "company");
      return getList({
        query: apiQuery,
        options: { headers: { "X-Workspace-Id": activeWorkspace?.id } },
        listId: listNameSlug,
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setList(data);
        onHandleChangeCurrentPageProps(false, data.data);
        refMount.current = true;
      },
      onError: console.error,
    }
  );

  useEffect(() => {
    if (activeWorkspace?.id) companyApiQuery.refetch();
  }, [activeWorkspace, filterQuery]);

  useEffect(() => {
    if (refMount.current) setLocationSearchParam(queryParamsForLoaded);
  }, [queryParamsForLoaded]);

  const getColumns = (columns) => setColumns(columns);
  const getPageNumber = (page) => setPagination((prev) => ({ ...prev, page }));
  const handleSetSort = (column) => setSort(sort === column ? `-${column}` : column);
  const getFiltersObject = (data) => {
    if (!isEmptyObject(data)) setPagination(paginationConfig);
    setFilters(data);
  };

  const sortedVisibleColumns = useMemo(
    () =>
      Object.values(columns)
        .filter((item) => item.visible)
        .sort((a, b) => a.order - b.order),
    [columns]
  );

  const selectedRowsDetailed = useMemo(
    () => (list?.data?.items ?? []).filter(({ item_id }) => selectedRows.includes(item_id)),
    [selectedRows, list]
  );

  const getAlignedProp = (column) =>
    column.hasOwnProperty("align")
      ? column.align === 0
        ? "center"
        : column.align === 1
          ? "right"
          : "left"
      : undefined;

  const handleSelectAll = () =>
    setSelectedRows(
      selectedRows.length === list.data.items.length ? [] : list.data.items.map((item) => item.item_id)
    );
  const handleSelectRow = (isChecked, id) =>
    setSelectedRows((prev) => (isChecked ? [...prev, id] : prev.filter((rowId) => rowId !== id)));

  const renderCellContent = (columnKey, item, metaData) => {
    switch (columnKey) {
      case "created_at":
        return <PrintDate date={item[columnKey] ?? new Date().toISOString()} />;
      case "name":
        return (
          <div className="flex items-center">
            <div className="h-9 w-9 flex-shrink-0">
              <Image
                nobaseurl
                className="h-9 w-9 rounded-full object-cover"
                src="https://images.ctfassets.net/hrltx12pl8hq/3MiLYuOESJbXrwoeXUI012/ccba97119444af2f25aa9cfddaf844bf/05-logos_548082964.jpg"
                alt=""
              />
            </div>
            <div className="ml-4 flex flex-col">
              <div className="flex items-center">
                <span className="font-medium ">{item?.["input"]?.name ?? "No Name"}</span>
                <TooltipWrapper content={item?.["input"]?.website}>
                  <Link to={item?.["input"]?.website} target="_blank">
                    <ExternalLink className="h-4 w-4 ml-1.5" />
                  </Link>
                </TooltipWrapper>
                <TooltipWrapper content={item?.["input"]?.linkedin_url}>
                  <Link to={item?.["input"]?.linkedin_url} target="_blank">
                    <Image src={LinkedinIcon} nobaseurl className="no-invert ml-1 h-5 max-w-5" />
                  </Link>
                </TooltipWrapper>
              </div>
              <p className="text-sm mt-0.5 max-w-[200px] !whitespace-normal">
                {item?.["input"]?.description ?? "No description found"}
              </p>
            </div>
          </div>
        );
      default:
        return (
          item?.["input"]?.[columnKey] ??
          item?.[columnKey] ??
          (metaData[columnKey]?.hasOwnProperty("nullValue") ? metaData[columnKey]["nullValue"] : "-")
        );
    }
  };

  return (
    <div>
      {list?.data?.items && (list.data.items.length > 0 || !isEmptyObject(filters)) && (
        <div className="flex justify-between items-center">
          <div className="space-x-3 inline-flex items-center">
            <Input
              icon={<Search className="h-4 w-4" />}
              type="text"
              name="search"
              id="search"
              autoComplete="search"
              placeholder="Search"
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
            />
            {list.filterMetadata && (
              <>
                <span>Filters:</span>
                <Filter filterMetadata={list.filterMetadata} getFiltersObject={getFiltersObject} />
              </>
            )}
          </div>
          <div className="space-x-3">
            {selectedRows.length > 0 ? (
              <TableMultiSelectOptions
                currentListId={list?.data?.id}
                workspaceId={activeWorkspace?.id}
                selectedRows={selectedRowsDetailed}
                invalidateQuery="get_company_lists"
              />
            ) : (
              list.columnMetadata && (
                <EditColumn columnMetadata={list.columnMetadata} getColumns={getColumns} />
              )
            )}
          </div>
        </div>
      )}
      <div>
        {list?.data?.items && list.data.items.length > 0 && companyApiQuery.isSuccess && (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border border-border rounded-[--radius]">
                  <table className="min-w-full divide-y divide-border">
                    <thead className="bg-background">
                      <tr>
                        <th scope="col" role="checkbox" className="h-4 w-4 !pr-0">
                          <Checkbox
                            className="h-4 w-4"
                            aria-checked={selectedRows.length === list.data.items.length}
                            indeterminate={
                              selectedRows.length > 0 && selectedRows.length < list.data.items.length
                            }
                            checked={selectedRows.length === list.data.items.length}
                            onCheckedChange={handleSelectAll}
                          />
                        </th>
                        {returnArray(sortedVisibleColumns).map((column) => (
                          <th
                            align={getAlignedProp(column)}
                            key={column.key}
                            scope="col"
                            className={`!px-3 text-sm font-normal ${column.sortable ? "cursor-pointer" : ""}`}
                            onClick={() => column.sortable && handleSetSort(column.key)}
                          >
                            <span className={"inline-flex items-center"}>
                              {column.display_name}
                              {column.sortable && (
                                <>
                                  {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                    <Image
                                      src="/images/icons/dual-arrow.svg"
                                      width="7"
                                      height="7"
                                      className="inline-block ml-1"
                                    />
                                  ) : sort === column.key ? (
                                    <ArrowDownIcon width={16} height={16} className="inline-block ml-1" />
                                  ) : (
                                    <ArrowUpIcon width={16} height={16} className="inline-block ml-1" />
                                  )}
                                </>
                              )}
                            </span>
                          </th>
                        ))}
                        <th scope="col" className="!px-3 !py-4 text-left text-sm font-semibold" />
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-border bg-background">
                      {list?.data?.items &&
                        returnArray(list.data.items).map((item, index) => (
                          <tr key={item.item_id} className={index % 2 !== 0 ? "bg-smoke" : ""}>
                            <td role="checkbox" className="h-4 w-4 !pr-0">
                              <Checkbox
                                className="h-4 w-4"
                                aria-checked={selectedRows.includes(item.item_id)}
                                checked={selectedRows.includes(item.item_id)}
                                onCheckedChange={(isChecked) => handleSelectRow(isChecked, item.item_id)}
                              />
                            </td>
                            {returnArray(sortedVisibleColumns).map(({ key: colKey }) => {
                              return (
                                <td
                                  align={getAlignedProp(list.columnMetadata[colKey])}
                                  key={colKey}
                                  className="whitespace-nowrap !px-3 !py-4 text-sm text-paragraph"
                                >
                                  {renderCellContent(colKey, item.item, list.columnMetadata)}
                                </td>
                              );
                            })}
                            <td className="whitespace-nowrap !px-3 !py-4">
                              <TableDropdownRowOptions
                                row={item}
                                workspaceId={activeWorkspace?.id}
                                invalidateQuery="get_company_lists"
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {companyApiQuery.isLoading && <TableLoader />}
        {companyApiQuery.isSuccess &&
          list?.data?.items &&
          list.data.items.length === 0 &&
          isEmptyObject(filters) && <CompaniesNoDataInserted />}
        {companyApiQuery.isSuccess &&
          list?.data?.items &&
          list.data.items.length === 0 &&
          !isEmptyObject(filters) && (
            <div className="p-10 border rounded-lg mt-8">
              <NoData heading={"No Companies found!"} filterMode={true} />
            </div>
          )}
        <div className="flex items-center justify-between  rounded-[--radius] py-3">
          <div className="flex-1">
            {list && list.pagination && list.pagination.total > 0 && (
              <Pagination data={list.pagination} getPageNumber={getPageNumber} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
