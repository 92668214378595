import * as React from "react"
import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils"

const badgeVariants = cva(
  "inline-flex items-center border  px-2.5 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-brand-200 bg-brand-50 text-brand-700", 
        pending:
          "border-yellow-500/30 bg-yellow-500/10 text-yellow-700",
        accepted:
          "border-green-500/30 bg-green-500/10 text-green-700",
        withdrawn:
          "border-red-500/30 bg-red-500/10 text-red-700",
        declined:
          "border-indigo-500/30 bg-indigo-500/10 text-indigo-700",
        expired:
          "border-gray-500/30 bg-gray-500/10 text-gray-700",
        created:
          "border-blue-300/30 bg-blue-300/10 text-blue-500",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground", 
        "gray": "border-gray-200 text-gray-700 bg-gray-50 ",
        "brand": "border-brand-200 text-brand-700 bg-brand-50 ",
        "error": "border-error-200 text-error-700 bg-error-50 ",
        "warning": "border-warning-200 text-warning-700 bg-warning-50 ",
        "success": "border-success-200 text-success-700 bg-success-50 ",
        "gray-blue": "border-gray-blue-200 text-gray-blue-700 bg-gray-blue-50 ",
        "blue-light": "border-blue-light-200 text-blue-light-700 bg-blue-light-50 ",
        "blue": "border-blue-200 text-blue-700 bg-blue-50 ",
        "indigo": "border-indigo-200 text-indigo-700 bg-indigo-50 ",
        "purple": "border-purple-200 text-purple-700 bg-purple-50 ",
        "pink": "border-pink-200 text-pink-700 bg-pink-50 ",
        "orange": "border-orange-200 text-orange-700 bg-orange-50 ",

        "gray-outline": "border-gray-600 text-gray-700 bg-transparent ",
        "brand-outline": "border-brand-600 text-brand-700 bg-transparent ",
        "error-outline": "border-error-600 text-error-700 bg-transparent ",
        "warning-outline": "border-warning-600 text-warning-700 bg-transparent ",
        "success-outline": "border-success-600 text-success-700 bg-transparent ",
        "gray-blue-outline": "border-gray-blue-600 text-gray-blue-700 bg-transparent ",
        "blue-light-outline": "border-blue-light-600 text-blue-light-700 bg-transparent ",
        "blue-outline": "border-blue-600 text-blue-700 bg-transparent ",
        "indigo-outline": "border-indigo-600 text-indigo-700 bg-transparent ",
        "purple-outline": "border-purple-600 text-purple-700 bg-transparent ",
        "pink-outline": "border-pink-600 text-pink-700 bg-transparent ",
        "orange-outline": "border-orange-600 text-orange-700 bg-transparent ",

       
        "gray-dot": "bg-gray-500",
        "brand-dot": "bg-gray-500 ",
        "error-dot": "bg-error-500",
        "warning-dot": "bg-warning-500",
        "success-dot": "bg-success-500",
        "gray-blue-dot": "bg-gray-blue-500",
        "blue-light-dot": "bg-blue-light-500",
        "blue-dot": "bg-blue-500 ",
        "indigo-dot": "bg-indigo-500",
        "purple-dot": "bg-purple-500",
        "pink-dot": "bg-pink-500",
        "orange-dot": "bg-orange-500 ",
      },
      size: {
        default: "h-[22px] px-[8px] py-[2px]  text-xs",
        sm: "h-[22px] px-[8px] py-[2px]  text-xs",
        md: "h-[24px] px-[10px] py-[2px]  text-sm",
        lg: "h-[28px] px-[12px] py-[4px]  text-sm",
        dot: "w-2 h-2 max-w-2 max-h-2 p-0 border-none"
      },
      radius: {
        default: "rounded-[9999px]", 
        full: "rounded-[9999px]",
        sm: "rounded-[6px]",
        circle: "rounded-[50%]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      radius: "default",
    },
  }
)

function Badge({
  className,
  size,
  radius,
  variant,
  ...props
}) {
  return (<div  className={cn(badgeVariants({ variant, size, radius }), className)} {...props} />);
}

export { Badge, badgeVariants }
