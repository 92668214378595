import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import { paginationConfig } from "../../../../axios/constant";
import {
  getInvitations,
  resendInvitations,
  withdrawInvitations,
} from "../../../../apis/workspace/invitations";
import toast from "react-hot-toast";
import { getVariant, isEmptyObject, returnArray } from "../../../../utils/GenericFn";
import useUpdateQuery from "../../../../hooks/useUpdateQuery";
import Filter from "../../../Filter";
import EditColumn from "../../../EditColumn";
import Image from "../../../Tags/Image";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import PrintDate from "../../../Tags/PrintDate";
import { Badge } from "../../../ui/badge";
import { Button } from "../../../ui/button";
import { Tooltip } from "react-tooltip";
import TableLoader from "../../../Loaders/TableLoader";
import NoData from "../../../NoData";

import { useCaptureEvent } from "../../../../hooks/useCaptureEvent";
import Pagination from "../../../Pagination";
export default function Invitations({ getCount }) {
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const { activeWorkspace } = useRoleCheck();
  const [invitations, setInvitations] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("-created_at");

  const getInvitationsFn = useQuery(
    ["get_invitations", filterQuery],
    () => {
      return getInvitations({
        query: filterQuery,
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        setInvitations(data);
        getCount(data?.data?.length);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetInvitations = () => {
    getInvitationsFn.refetch();
  };
  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);
  useEffect(() => {
    if (activeWorkspace?.id && filterQuery) {
      handleGetInvitations();
    }
  }, [activeWorkspace, filterQuery]);

  const [currentInviteId, setCurrentInviteId] = useState(null);

  const resendInvitationsFn = useMutation("resend_invitations", resendInvitations, {
    onSuccess(data, variables, context) {
      //console.log(data);
      toast.success(data?.message);
    },
  });
  const handleResendInvitations = (id) => {
    setCurrentInviteId(id);
    resendInvitationsFn.mutate({
      inviteId: id,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  const withdrawInvitationsFn = useMutation("withdraw_invitations", withdrawInvitations, {
    onSuccess(data, variables, context) {
      //console.log(data);
      toast.success(data?.message);
      queryClient.invalidateQueries("get_invitations");
    },
  });
  const handleWithdrawInvitations = (id) => {
    setCurrentInviteId(id);
    withdrawInvitationsFn.mutate({
      inviteId: id,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("-created_at");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };

  const getPageNumber = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };
  const handleSetRole = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };

  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => a.order - b.order);
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);
  return (
    <>
      <div>
        {invitations && invitations.data && (invitations.data.length > 0 || !isEmptyObject(filters)) && (
          <div className="mt-6 flex justify-between items-center">
            <div className="space-x-3 inline-flex items-center">
              {invitations && invitations.filterMetadata && (
                <>
                  <span>Filters:</span>
                  <Filter filterMetadata={invitations.filterMetadata} getFiltersObject={getFiltersObject} />
                </>
              )}
            </div>
            <div className="space-x-3">
              {invitations.columnMetadata && (
                <EditColumn columnMetadata={invitations.columnMetadata} getColumns={getColumns} />
              )}
            </div>
          </div>
        )}
        <div>
          {invitations && invitations.data && invitations.data.length > 0 && getInvitationsFn.isSuccess && (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-border rounded-[--radius]">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="bg-background">
                        <tr>
                          {returnArray(sortedVisibleColumns).map((column, index) => (
                            <th
                              key={index}
                              scope="col"
                              className={`px-3 py-3.5 text-left text-sm font-normal   ${
                                column.sortable ? "cursor-pointer" : ""
                              }`}
                              onClick={() => (column.sortable ? handleSetRole(column.key) : void 0)}
                            >
                              <span className={"inline-flex items-center"}>
                                {column.display_name}
                                {column.sortable && (
                                  <>
                                    {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                      <Image
                                        src="/images/icons/dual-arrow.svg"
                                        width="7"
                                        height="7"
                                        className="inline-block ml-1"
                                      />
                                    ) : (
                                      <>
                                        {sort === column.key && (
                                          <ArrowDownIcon width={12} className="inline-block ml-1" />
                                        )}
                                        {sort === `-${column.key}` && (
                                          <ArrowUpIcon width={12} className="inline-block ml-1" />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </span>
                            </th>
                          ))}
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal"></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border bg-background">
                        {invitations &&
                          returnArray(invitations.data).map((person, index) => (
                            <tr
                              key={person.id}
                              className={`group text-paragraph ${index % 2 !== 0 ? "bg-smoke" : ""}`}
                            >
                              {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                                const colKey =
                                  columns &&
                                  Object.keys(columns).find((key) => columns[key].order === column.order);

                                if (colKey === "sent_by") {
                                  return (
                                    <td key={colIndex} className="whitespace-nowrap px-3 py-4">
                                      <div className="flex items-center">
                                        <div className="h-9 w-9 flex-shrink-0">
                                          <Image
                                            nobaseurl
                                            className="h-9 w-9 rounded-full"
                                            src={person[colKey].profile_photo_url}
                                            alt=""
                                          />
                                        </div>
                                        <div className="ml-4">
                                          <div className="font-medium">{person[colKey].name}</div>
                                          <div className="mt-0">{person[colKey].email}</div>
                                        </div>
                                      </div>
                                    </td>
                                  );
                                }
                                if (colKey === "created_at") {
                                  return (
                                    <td className="whitespace-nowrap px-3 py-4" key={colIndex}>
                                      <PrintDate date={person.sent_at} />
                                    </td>
                                  );
                                }
                                if (colKey === "expires_at") {
                                  return (
                                    <td className="whitespace-nowrap px-3 py-4" key={colIndex}>
                                      <PrintDate date={person.expires_at} />
                                    </td>
                                  );
                                }
                                if (colKey === "accepted_at") {
                                  return (
                                    <td className="whitespace-nowrap px-3 py-4" key={colIndex}>
                                      <PrintDate date={person.accepted_at} />
                                    </td>
                                  );
                                }

                                if (colKey === "status") {
                                  return (
                                    <td key={colIndex} className="whitespace-nowrap px-3 py-4 ">
                                      {person.status && (
                                        <Badge variant={getVariant(person?.status)} className={"capitalize"}>
                                          {person.status}
                                        </Badge>
                                      )}
                                    </td>
                                  );
                                }

                                return (
                                  <td key={colIndex} className="whitespace-nowrap px-3 py-4">
                                    {person[colKey] ? person[colKey] : "-"}
                                  </td>
                                );
                              })}
                              <td className="whitespace-nowrap px-3 py-4 ">
                                {person.status === "Pending" && (
                                  <div className="inline-flex">
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Resend Invite"
                                      disabled={
                                        resendInvitationsFn.isLoading && currentInviteId === person.id
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleResendInvitations(person.id);
                                        captureEvent("SET_MembersInvitations_ResendBtn");
                                      }}
                                      variant="ghost"
                                    >
                                      <Image
                                        src="/images/icons/untitledui/resend.svg"
                                        width="16"
                                        height="16"
                                        className="inline-block"
                                      />
                                      {resendInvitationsFn.isLoading && currentInviteId === person.id && (
                                        <div className="loader_circle ml-2"></div>
                                      )}
                                    </Button>
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Withdraw Invite"
                                      disabled={
                                        withdrawInvitationsFn.isLoading && currentInviteId === person.id
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleWithdrawInvitations(person.id);
                                        captureEvent("SET_MembersInvitations_DeteleBtn");
                                      }}
                                      variant="ghost"
                                    >
                                      <Image
                                        src="/images/icons/untitledui/trash.svg"
                                        width="16"
                                        height="16"
                                        className="inline-block"
                                      />
                                      {withdrawInvitationsFn.isLoading && currentInviteId === person.id && (
                                        <div className="loader_circle ml-2"></div>
                                      )}
                                    </Button>
                                    <Tooltip id="in_table_actions" />
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {getInvitationsFn.isLoading && <TableLoader />}

          {getInvitationsFn.isSuccess &&
            invitations &&
            invitations.data &&
            invitations.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No Invitation found!"} />}
          {getInvitationsFn.isSuccess &&
            invitations &&
            invitations.data &&
            invitations.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No Invitation found!"} filterMode={true} />
              </div>
            )}

          <div className="flex items-center justify-between  rounded-[--radius] py-3">
            <div className="flex-1">
              {invitations && invitations.pagination && invitations.pagination.total > 0 && (
                <>
                  <Pagination data={invitations.pagination} getPageNumber={getPageNumber} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
