import React from "react";
import Layout from "../../Layout";
import Integrations from "../../containers/Integrations";

export default function IntegrationsPage() {
  return (
    <Layout>
      <Integrations />
    </Layout>
  );
}
