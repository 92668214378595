import React from "react";
import Layout from "../../Layout";
import EnrichList from "../../containers/EnrichList";
import EnrichLeftNav from "../../containers/Enrich/EnrichLeftNav";

export default function EnrichListPage() {
  return (
    <Layout
      leftNav={<EnrichLeftNav />}
      // leftNav={false}
    >
      <EnrichList />
    </Layout>
  );
}
