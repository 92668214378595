import { useMemo } from "react";
import { ReactComponent as HomeIcon } from "../../../../static/media/icons/home-icon.svg";
import { ListBreadcrumb } from "../../../ListBreadcrumb";

const breadcrumbItems = {
  home: {
    order: 1,
    routeTo: "/",
    icon: <HomeIcon height="20" width="20" className="stroke-current" />,
    className: "inline-block flex items-center gap-2",
    current: false,
    isLoading: false,
  },
  prospect: {
    order: 2,
    routeTo: "/prospect-search",
    label: "Prospect",
    className: "inline-block flex items-center gap-2",
    current: false,
    isLoading: false,
  },
  current: {
    order: 3,
    className: "inline-block flex items-center gap-2",
    current: true,
    isLoading: true,
  },
};

export default function ProspectListBreadcrumbs({
  currentPageProps: { isLoading = false, label = null } = {},
}) {
  const crumbItems = useMemo(
    () => ({ ...breadcrumbItems, current: { ...breadcrumbItems["current"], isLoading, label } }),
    [isLoading, label]
  );
  return <ListBreadcrumb crumbItems={Object.values(crumbItems)} />;
}
