import { DELETE, GET, PATCH, POST } from "../../axios/apiService";

export const getGroups = async ({ query, options }) => {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/api/v1/workspace-settings/groups${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const createGroup = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/groups`;
  return POST(url, data, options);
};

export const updateGroup = async ({ data, options, groupId }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/groups/${groupId}`;
  return POST(url, data, options);
};

export const deleteGroup = async ({ groupId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/groups/${groupId}`;
  return DELETE(url, options);
};

export const viewGroup = async ({ groupId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/groups/${groupId}`;
  return GET(url, options);
};

export const addMembersToGroup = async ({ data, groupId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/groups/${groupId}/members`;
  return POST(url, data, options);
};

export const deleteMembersToGroup = async ({ groupId, memberId, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/groups/${groupId}/members/${memberId}`;
  return DELETE(url, options);
};
