import React from "react";
import Layout from "../../Layout";
import EnrichDetails from "../../containers/EnrichDetails";
import EnrichLeftNav from "../../containers/Enrich/EnrichLeftNav";

export default function EnrichListDetailsPage() {
  return (
    <Layout
      leftNav={<EnrichLeftNav />}
      // leftNav={false}
    >
      <EnrichDetails />
    </Layout>
  );
}
