import React from "react";
import Layout from "../../Layout";
import Developers from "../../containers/Developers";

export default function DevelopersPage() {
  return (
    <Layout>
      <Developers />
    </Layout>
  );
}
