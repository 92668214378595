import { Checkbox } from "../../ui/checkbox";

export default function CheckboxInput({ id, label, labelClassName, children, ...props }) {
  return (
    <div className={`flex ${children ? "items-top" : "items-center"} space-x-2`}>
      <Checkbox id={id} {...props} />
      <div className={`${children ? "grid gap-1.5" : ""} leading-none`}>
        <label
          htmlFor={id}
          className={`text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${labelClassName}`}
        >
          {label}
        </label>
        {children && <div className="text-sm text-muted-foreground">{children}</div>}
      </div>
    </div>
  );
}
