import React, { useContext, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { requestIntegration } from "../../../apis/workspace/integrations";
import SharedDataContext from "../../../SharedDataContext";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import SuccessAlert from "../../Alerts/Timer/SuccessAlert";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function RequestIntegration({ closeModal }) {
  const captureEvent = useCaptureEvent();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => {
      //console.log("Submitted values:", values);
      requestIntegrationFn.mutate({
        data: {
          integration_name: values.name,
          additional_notes: values.description,
        },
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    },
  });
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  // handle form submit function
  const requestIntegrationFn = useMutation("request_integration", requestIntegration, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      setIsTimerVisible(true);
    },
    onError(error) {
      formik.setSubmitting(false);
    },
  });

  const onTimerEnd = () => {
    closeModal();
    formik.setSubmitting(false);
    formik.resetForm();
  };

  return (
    <>
      <div className="mt-5">
        {isTimerVisible ? (
          <SuccessAlert
            onTimerEnd={onTimerEnd}
            isVisible={isTimerVisible}
            heading={"We will get back to you soon!"}
            description={"Your request has been made successfully. We will check and get back to you."}
          />
        ) : (
          <form onSubmit={formik.handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name of the product you want to integrate
              </label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name && (
                <p className="mt-1 text-red-500 text-sm">{formik.errors.name}</p>
              )}
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium mb-2">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                className="px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
                rows="3"
              />
              {formik.touched.description && formik.errors.description && (
                <p className="mt-1 text-red-500 text-sm">{formik.errors.description}</p>
              )}
            </div>

            <div>
              <Button type="submit" disabled={formik.isSubmitting || !formik.isValid}
              onClick={() => {
                captureEvent("SET_RequestIntegrationModal_SubmitBtn");
                }}
              >
                Submit
                {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
