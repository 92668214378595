import React, { useContext, useEffect } from "react";
import { Button } from "../../ui/button";
import Input from "../../Tags/Input";
import SharedDataContext from "../../../SharedDataContext";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { setJobFunction, updatePassword } from "../../../apis/user";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import FormError from "../../Tags/FormError";
import { fetchCsrfCookie } from "../../../apis/csrf";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function ProfileContainer() {
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Submitted values:", values);
      updateProfileFn.mutate({
        data: { name: values.name },
      });
    },
  });

  const updateProfileFn = useMutation("update_profile", setJobFunction, {
    onSuccess(data, variables, context) {
      toast.success("Profile updated successfully");
      queryClient.invalidateQueries("get_user");
      formik.setSubmitting(false);
    },
    onError(error) {
      console.log(error);
      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (userData?.name) {
      formik.setFieldValue("name", userData.name);
    }
  }, [userData?.name]);

  // change password

  const validationSchemaReset = yup.object({
    current_password: yup
      .string()
      .required("Current password is required")
      .min(8, "Password must be at least 8 characters")
      .max(64, "Password must not exceed 64 characters"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .max(64, "Password must not exceed 64 characters")
      .required("Password is required")
      .notOneOf([yup.ref("current_password")], "New password cannot be the same as the current password"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formikReset = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchemaReset,
    onSubmit: (values) => {
      let payload = {
        current_password: values.current_password,
        password: values.password,
        password_confirmation: values.password_confirmation,
      };

      console.log(payload);

      updatePasswordFn.mutate({ ...payload });
    },
  });

  const updatePasswordFn = useMutation("update_password", updatePassword, {
    onSuccess(data, variables, context) {
      formikReset.resetForm();
      toast.success("Password changed successfully.");
      formikReset.setSubmitting(false);
    },
    onError(err) {
      formikReset.setSubmitting(false);
    },
  });

  useEffect(() => {
    fetchCsrfCookie()
      .then((xsrfToken) => {
        console.log("CSRF");
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      });
  }, []);

  return (
    <div className="container max-w-[1200px] mx-auto p-8  bg-background rounded-xl mobile:p-4">
      <div className="sm:flex-auto">
        <h2 className="text-lg font-semibold leading-7 text-text-900">Personal info</h2>
        <p className="text-text-600 mt-1">Update your photo and personal details here.</p>

        <div className="p-6 border border-border rounded-lg mt-6">
          <form onSubmit={formik.handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium mb-2">
                Name
              </label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name && (
                <p className="mt-1 text-red-500 text-sm">{formik.errors.name}</p>
              )}
            </div>

            <div>
              <label htmlFor="name" className="block text-sm font-medium mb-2">
                Email Id
              </label>
              <Input type="text" value={userData?.email} disabled={true} className={"bg-gray-50"} />
            </div>

            <div>
              <Button
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                onClick={() => {
                  captureEvent("TOP_ProfilePage_UpdateBtn");
                }}
              >
                Update
                {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="sm:flex-auto mt-10">
        <h2 className="text-lg font-semibold leading-7 text-text-900">Change Password</h2>
        <p className="text-text-600 mt-1">
          Enter your old password and your new password to confirm the change.
        </p>

        <div className="p-6 border border-border rounded-lg mt-6">
          <FormikProvider value={formikReset}>
            <form onSubmit={formikReset.handleSubmit}>
              <div className="">
                <div className="mb-5">
                  <p className="mb-2">Current Password</p>
                  <Input
                    type="password"
                    className={"w-full"}
                    placeholder="Current Password"
                    name="current_password"
                    onChange={formikReset.handleChange}
                    onBlur={formikReset.handleBlur}
                    value={formikReset.values.current_password}
                  />
                  <FormError field="current_password" />
                </div>
                <div className="mb-5">
                  <p className="mb-2">New Password</p>
                  <Input
                    type="password"
                    className={"w-full"}
                    placeholder="New Password"
                    name="password"
                    onChange={formikReset.handleChange}
                    onBlur={formikReset.handleBlur}
                    value={formikReset.values.password}
                  />
                  <FormError field="password" />
                </div>
                <div>
                  <p className="mb-2">Confirm Password</p>
                  <Input
                    type="password"
                    className={"w-full"}
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    onChange={formikReset.handleChange}
                    onBlur={formikReset.handleBlur}
                    value={formikReset.values.password_confirmation}
                  />
                  <FormError field="password_confirmation" />
                </div>

                <div className="mt-5">
                  <Button
                    type="submit"
                    disabled={formikReset.isSubmitting || !formikReset.isValid}
                    onClick={() => {
                      captureEvent("TOP_ProfilePage_ChangePasswordBtn");
                    }}
                  >
                    Change password
                    {formikReset.isSubmitting && <div className="loader_circle ml-2"></div>}
                  </Button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
}
