import * as YupValid from "yup";

YupValid.addMethod(YupValid.string, "sequence", function (funcList) {
  return this.test("sequence", "Sequence validation failed", async (value, context) => {
    try {
      for (const func of funcList) {
        await func().validate(value);
      }
    } catch ({ message }) {
      return context.createError({ message });
    }
    return true;
  });
});

YupValid.addMethod(
  YupValid.string,
  "uniqueName",
  function (message, workspaceId, shouldValidate, mutationApi, extraDataVars = {}) {
    return this.test("uniqueName", message, async (value) => {
      if (!shouldValidate) return true;

      try {
        const payload = {
          data: { name: value, ...extraDataVars },
          options: { headers: { "X-Workspace-Id": workspaceId } },
        };
        const responseData = await mutationApi.mutateAsync(payload);
        return responseData.is_available;
      } catch (error) {
        return false;
      }
    });
  }
);

export default YupValid;
