import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import GenericModal from "../GenericModal";
import useModal from "../GenericModal/useModal";
import { Button } from "../ui/button";

export default function ConfirmModal({
  openModal: showModal,
  modalType,
  confirmModalState,
  closeOnConfirm = true,
  title = "Confirmation Required!",
  subText = "You are about to perform a critical action. Please confirm.",
  confirmTitle = "Confirm",
  cancelTitle = "Cancel",
}) {
  const { isOpen, openModal, closeModal } = useModal(false);

  useEffect(() => {
    if (showModal) openModal();
    else closeModal();
    return () => closeModal();
  }, [showModal, openModal, closeModal]);

  const handleClose = () => {
    confirmModalState({ confirm: false, type: modalType });
    closeModal();
  };

  const handleConfirm = () => {
    confirmModalState({ confirm: true, type: modalType });
    if (closeOnConfirm) closeModal();
  };

  return (
    <GenericModal isOpen={isOpen} closeModal={handleClose}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 ">
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-paragraph">{subText}</p>
          </div>
        </div>
      </div>

      <div className="mt-5 flex space-x-2 justify-end">
        <Button onClick={handleClose} variant="outline">
          {cancelTitle}
        </Button>
        <Button onClick={handleConfirm}>{confirmTitle}</Button>
      </div>
    </GenericModal>
  );
}
