import SharedDataContext from "../../SharedDataContext";
import { getEnrichmentPriorityConfig, setEnrichmentPriorityConfig } from "../../apis/workspace/integrations";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Skeleton } from "../ui/skeleton";
import { Button } from "../ui/button";
import Sortable from "sortablejs";
import toast from "react-hot-toast";

export default function EnrichmentPriority({ closeModal, logos }) {
  const queryClient = useQueryClient();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const [priorityList, setPriorityList] = useState([]);
  const listRef = useRef(null);
  const [priorityOrder, setPriorityOrder] = useState([]);

  const getEnrichmentPriorityConfigFn = useQuery(
    ["get_enrichment_priority_config", activeWorkspace?.id],
    () =>
      getEnrichmentPriorityConfig({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      }),
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      staleTime: Infinity, // never mark as stale
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      onSuccess: (data) => {
        console.log(data);
        setPriorityList(data.data);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id) {
      getEnrichmentPriorityConfigFn.refetch();
      console.log(logos);
    }
  }, [activeWorkspace]);

  const setEnrichmentPriorityConfigFn = useMutation(
    "set_enrichment_priority_config",
    setEnrichmentPriorityConfig,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);
      },
      onError(error) {},
    }
  );

  const handleUpdatePriority = () => {
    setEnrichmentPriorityConfigFn.mutate({
      data: { integrationConfigIds: priorityOrder },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (listRef.current) {
      Sortable.create(listRef.current, {
        animation: 150,
        chosenClass: "bg-gray-100",
        ghostClass: "bg-gray-100",
        onEnd: (event) => {
          console.log(event);
          const { newIndex } = event;
          const children = listRef.current.children;
          const order = Array.from(children).map((child) => +child.getAttribute("data-priority"));
          console.log("New order:", order);
          setPriorityOrder(order);
        },
      });
    }
  }, []);
  return (
    <div className="p-6">
      <h3 className="text-[18px] block font-semibold mb-1">Customise enrichment priority</h3>
      <p className="text-paragraph">Click and drag to change the priority order</p>
      <div className="mt-6">
        {getEnrichmentPriorityConfigFn.isFetching && (
          <div className="space-y-3">
            <Skeleton className={"rounded-2xl h-16 w-full"} />
            <Skeleton className={"rounded-2xl h-16 w-full"} />
            <Skeleton className={"rounded-2xl h-16 w-full"} />
            <Skeleton className={"rounded-2xl h-16 w-full"} />
            <Skeleton className={"rounded-2xl h-16 w-full"} />
          </div>
        )}
        {getEnrichmentPriorityConfigFn.isFetched &&
          !getEnrichmentPriorityConfigFn.isFetching &&
          priorityList &&
          priorityList.length === 0 && (
            <div className="flex flex-col items-center mt-40">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 6.5C12.5523 6.5 13 6.05228 13 5.5C13 4.94772 12.5523 4.5 12 4.5C11.4477 4.5 11 4.94772 11 5.5C11 6.05228 11.4477 6.5 12 6.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 13.5C12.5523 13.5 13 13.0523 13 12.5C13 11.9477 12.5523 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5C11 13.0523 11.4477 13.5 12 13.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 20.5C12.5523 20.5 13 20.0523 13 19.5C13 18.9477 12.5523 18.5 12 18.5C11.4477 18.5 11 18.9477 11 19.5C11 20.0523 11.4477 20.5 12 20.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19 6.5C19.5523 6.5 20 6.05228 20 5.5C20 4.94772 19.5523 4.5 19 4.5C18.4477 4.5 18 4.94772 18 5.5C18 6.05228 18.4477 6.5 19 6.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19 13.5C19.5523 13.5 20 13.0523 20 12.5C20 11.9477 19.5523 11.5 19 11.5C18.4477 11.5 18 11.9477 18 12.5C18 13.0523 18.4477 13.5 19 13.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19 20.5C19.5523 20.5 20 20.0523 20 19.5C20 18.9477 19.5523 18.5 19 18.5C18.4477 18.5 18 18.9477 18 19.5C18 20.0523 18.4477 20.5 19 20.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 6.5C5.55228 6.5 6 6.05228 6 5.5C6 4.94772 5.55228 4.5 5 4.5C4.44772 4.5 4 4.94772 4 5.5C4 6.05228 4.44772 6.5 5 6.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 13.5C5.55228 13.5 6 13.0523 6 12.5C6 11.9477 5.55228 11.5 5 11.5C4.44772 11.5 4 11.9477 4 12.5C4 13.0523 4.44772 13.5 5 13.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 20.5C5.55228 20.5 6 20.0523 6 19.5C6 18.9477 5.55228 18.5 5 18.5C4.44772 18.5 4 18.9477 4 19.5C4 20.0523 4.44772 20.5 5 20.5Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h3 className="text-lg font-bold mt-4">No Data to Customize</h3>
              <p>There is no data available to set enrichment priorities.</p>
            </div>
          )}
        <div ref={listRef} className="space-y-3">
          {priorityList &&
            priorityList.length > 0 &&
            priorityList.map((list) => (
              <div
                className="rounded-2xl  border-brand-600 border-2 py-3 px-4 flex justify-between items-center"
                data-priority={list?.integrationConfig?.id}
              >
                <span className="inline-flex items-center">
                  {logos[list?.integrationConfig?.integration?.key] && (
                    <span
                      className="svg__32 mr-3 inline-block rounded-[--radius] p-1 bg-white"
                      dangerouslySetInnerHTML={{
                        __html: logos[list?.integrationConfig?.integration?.key],
                      }}
                    />
                  )}
                  <span>{list?.integrationConfig?.integration?.display_name}</span>
                </span>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6.5C12.5523 6.5 13 6.05228 13 5.5C13 4.94772 12.5523 4.5 12 4.5C11.4477 4.5 11 4.94772 11 5.5C11 6.05228 11.4477 6.5 12 6.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 13.5C12.5523 13.5 13 13.0523 13 12.5C13 11.9477 12.5523 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5C11 13.0523 11.4477 13.5 12 13.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 20.5C12.5523 20.5 13 20.0523 13 19.5C13 18.9477 12.5523 18.5 12 18.5C11.4477 18.5 11 18.9477 11 19.5C11 20.0523 11.4477 20.5 12 20.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 6.5C19.5523 6.5 20 6.05228 20 5.5C20 4.94772 19.5523 4.5 19 4.5C18.4477 4.5 18 4.94772 18 5.5C18 6.05228 18.4477 6.5 19 6.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 13.5C19.5523 13.5 20 13.0523 20 12.5C20 11.9477 19.5523 11.5 19 11.5C18.4477 11.5 18 11.9477 18 12.5C18 13.0523 18.4477 13.5 19 13.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 20.5C19.5523 20.5 20 20.0523 20 19.5C20 18.9477 19.5523 18.5 19 18.5C18.4477 18.5 18 18.9477 18 19.5C18 20.0523 18.4477 20.5 19 20.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 6.5C5.55228 6.5 6 6.05228 6 5.5C6 4.94772 5.55228 4.5 5 4.5C4.44772 4.5 4 4.94772 4 5.5C4 6.05228 4.44772 6.5 5 6.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 13.5C5.55228 13.5 6 13.0523 6 12.5C6 11.9477 5.55228 11.5 5 11.5C4.44772 11.5 4 11.9477 4 12.5C4 13.0523 4.44772 13.5 5 13.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 20.5C5.55228 20.5 6 20.0523 6 19.5C6 18.9477 5.55228 18.5 5 18.5C4.44772 18.5 4 18.9477 4 19.5C4 20.0523 4.44772 20.5 5 20.5Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ))}
        </div>
      </div>
      {priorityList && priorityList.length > 0 && (
        <Button
          className="mt-5 w-full"
          onClick={(e) => {
            e.preventDefault();
            handleUpdatePriority();
          }}
          disabled={priorityOrder && priorityOrder.length === 0 ? true : false}
        >
          Save
        </Button>
      )}
    </div>
  );
}
