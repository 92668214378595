export default function Input({ type = "text", pretext = "", className, icon, ...rest }) {
  return (
    <>
      {pretext ? (
        <div className="flex relative rounded-[--radius] border border-input">
          <span className="bg-smoke inline-flex items-center rounded-l-[--radius] px-[14px] text-text-600 border-r border-border">
            {pretext}
          </span>
          <input
            type={type}
            className={`h-11 px-[14px] py-[10px] text-sm flex-1 rounded-r-[--radius] border-none bg-background text-text-900 ${className}`}
            {...rest}
          />
        </div>
      ) : icon ? (
        <div className="flex items-center relative rounded-[--radius] border border-input">
          <div className="flex items-center justify-center pointer-events-none w-5 h-5 absolute top-1/2 transform -translate-y-1/2 left-3">
            {icon}
          </div>
          <input
            type={type}
            className={`h-11 pl-[42px] pr-[14px] py-[10px] text-sm w-full rounded-[--radius] border-none bg-background text-text-900 ${className}`}
            {...rest}
          />
        </div>
      ) : (
        <input
          type={type}
          className={`h-11 px-[14px] py-[10px] text-sm w-full rounded-[--radius] border border-input bg-background text-text-900 ${className}`}
          {...rest}
        />
      )}
    </>
  );
}
