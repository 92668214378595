"use client"

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";
import { ReactComponent as CheckIcon } from "../../static/media/icons/check-icon.svg";
import { ReactComponent as MinusIcon } from "../../static/media/icons/minus-icon.svg";

import { cn } from "../../lib/utils";

const Checkbox = React.forwardRef(({ className, checked, indeterminate, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "flex justify-center items-center peer h-4 w-4 shrink-0 rounded-sm border border-gray-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-brand-700 data-[state=checked]:text-primary-foreground data-[state=checked]:border-brand-600",
      className
    )}
    checked={checked || !!indeterminate}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current h-3 w-3")}>
      {!!indeterminate ? (
        <MinusIcon className="h-3 w-3 stroke-current" />
      ) : (
        <CheckIcon className="h-3 w-3 stroke-current" />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

