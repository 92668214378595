import React, { useEffect } from "react";
import Image from "../../Tags/Image";
import { ChevronLeft, ChevronRight, Mailbox } from "lucide-react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import Text from "../../Tags/Text";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";
import { useNavigate } from "react-router-dom";
import { register } from "../../../apis/user";
import { useMutation } from "react-query";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import NavAnchor from "../../Tags/NavAnchor";
import FormError from "../../Tags/FormError";
import Slides from "./Slides";
import { fetchCsrfCookie } from "../../../apis/csrf";
import usePageAttributes from "../../../hooks/usePageAttributes";
import { useTheme } from "../../ThemeProvider";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";

export default function Register() {
  const captureEvent = useCaptureEvent();
  const navigate = useNavigate();
  const registerFn = useMutation("register", register, {
    onSuccess(data, variables, context) {
      console.log(data);
      formik.resetForm();
      // toast.success(data?.message);
      // Cookies.set(process.env.REACT_APP_PR_ACCESS_TOKEN, data?.token);
      window.location.href = "/app/";
    },
    onError(err) {
      formik.setSubmitting(false);
    },
  });

  const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email address"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(64, "Password must not exceed 64 characters"),
    company: yup.string().required("Company is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      company: "",
    },
    validationSchema,
    onSubmit: (values) => {
      let payload = values;
      payload.password_confirmation = values.password;
      payload.timezone = getUserTimezone();
      payload.email = values.email.toLowerCase();
      console.log("payload", payload);
      registerFn.mutate(payload);
    },
  });

  useEffect(() => {
    const cookieName = process.env.REACT_APP_PR_ACCESS_TOKEN
      ? process.env.REACT_APP_PR_ACCESS_TOKEN
      : "accessToken";

    const token = Cookies.get(cookieName);
    // Redirect only if on the root path "/"
    if (token) {
      navigate(`/`);
    }
  }, [navigate]);

  useEffect(() => {
    fetchCsrfCookie()
      .then((xsrfToken) => {
        console.log("CSRF");
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      });
  }, []);

  const { logo, appname, setTitle } = usePageAttributes();
  const { theme, setTheme } = useTheme();

  return (
    <div className="flex  min-h-screen">
      <div className="w-6/12 bg-white dark:bg-gray-50 mobile:w-full">
        <div className="p-8 flex h-full flex-col justify-between">
          {appname === "PrimeRole" ? (
            <>
              {theme === "dark" ? (
                <Image src="/images/apps/primerole/logo-dark.svg" width={166} className="logo no-invert" />
              ) : (
                <Image src={logo} width={166} className="logo" />
              )}
            </>
          ) : (
            <Image src={logo} width={166} className="logo" />
          )}
          <div className="w-[360px] mx-auto py-4  mobile:w-full">
            <div className=" mt-6">
              <Text size="h6">Get Started</Text>
              <p className="text-base text-gray-600 mt-1">Create free account to start using {appname}</p>
            </div>
            <div className="mt-8">
              <a href={`${process.env.REACT_APP_API_BASE_URL}/login/google`}>
                <Button
                  className="w-full mt-4"
                  variant="outline"
                  type="button"
                  onClick={() => {
                    captureEvent("ONB_Register_SignInWithBtn", { sign_in_type: "Google" });
                  }}
                >
                  <Image
                    src="/images/icons/untitledui/social-google.svg"
                    width={24}
                    className="mr-3 no-invert"
                  />
                  <span>Sign up with Google</span>
                </Button>
              </a>
              <a href={`${process.env.REACT_APP_API_BASE_URL}/login/slack`}>
                <Button
                  className="w-full mt-3"
                  variant="outline"
                  type="button"
                  onClick={() => {
                    captureEvent("ONB_Register_SignInWithBtn", { sign_in_type: "Slack" });
                  }}
                >
                  <Image
                    src="/images/icons/untitledui/social-slack.svg"
                    width={20}
                    className="mr-3 no-invert"
                  />
                  <span>Sign up with Slack</span>
                </Button>
              </a>
            </div>
            <div className="relative my-6 text-center">
              <span className="inline-block bg-white dark:bg-gray-200 p-2 rounded text-sm text-gray-600 relative z-10">
                Or, continue with email
              </span>
              <div className="border-b border-border w-full absolute top-[50%]"></div>
            </div>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <div className="mt-6">
                  <div className="space-y-3">
                    <div>
                      <label htmlFor="" className="block text-sm font-medium leading-6 mb-1">
                        Name*
                      </label>
                      <Input
                        className={"w-full"}
                        placeholder="Enter your name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      <FormError field="name" />
                    </div>
                    <div>
                      <label htmlFor="" className="block text-sm font-medium leading-6 mb-1">
                        Business Email*
                      </label>
                      <Input
                        className={"w-full"}
                        placeholder="Enter your email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      <FormError field="email" />
                    </div>
                    <div>
                      <label htmlFor="" className="block text-sm font-medium leading-6 mb-1">
                        Company*
                      </label>
                      <Input
                        className={"w-full"}
                        placeholder="Enter your company name"
                        name="company"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company}
                      />
                      <FormError field="company" />
                    </div>
                    <div>
                      <label htmlFor="" className="block text-sm font-medium leading-6 mb-1">
                        Password*
                      </label>
                      <Input
                        className={"w-full"}
                        placeholder="Create a password"
                        type="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <FormError field="password" />
                    </div>
                  </div>
                  <div className="mt-6">
                    <Button
                      className="w-full"
                      type="submit"
                      disabled={formik.isSubmitting || !formik.isValid}
                      onClick={() => {
                        captureEvent("ONB_Register_StartForFreeBtn");
                      }}
                    >
                      Start for Free
                      {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
                    </Button>
                  </div>
                  <p className="text-center text-xs text-gray-500 mt-2">
                    By signing up, you agree to our terms and privacy.
                  </p>
                  <div className="mt-6 text-center">
                    <span className="text-gray-600">Already have an account?</span>{" "}
                    <NavAnchor
                      slug={false}
                      to={"/login"}
                      className="text-brand-700 font-bold"
                      onClick={() => {
                        captureEvent("ONB_Register_LoginBtn");
                      }}
                    >
                      Login
                    </NavAnchor>
                  </div>
                </div>
              </form>
            </FormikProvider>
          </div>
          <div className="flex justify-between">
            <span className="text-sm">© PrimeRole {new Date().getFullYear()}</span>
            <span className="text-sm flex items-center">
              <EnvelopeIcon width={20} className="mr-2" />{" "}
              <a href="mailto:support@primerole.com">support@primerole.com</a>
            </span>
          </div>
        </div>
      </div>
      <div className="w-6/12 bg-brand-50 dark:bg-gray-25 mobile:hidden">
        <div className="px-[107px] pt-3 register__slider relative">
          <Slides />
        </div>
      </div>
    </div>
  );
}
