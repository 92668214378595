import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import { ReactComponent as PersonIcon } from "../../../../static/media/icons/person-light-icon.svg";
import linkedinIcon from "../../../../static/media/images/linkedin-logo.png";
import CheckboxInput from "../../../Tags/CheckboxInput";
import FormError from "../../../Tags/FormError";
import Input from "../../../Tags/Input";
import Text from "../../../Tags/Text";
import { Button } from "../../../ui/button";
import { PhoneInput } from "../../../ui/phoneInput";
import { Seperator } from "../../../ui/seperator";

export default function AddContactModal({ activeWorkspace, closeModal, currentListData }) {
  const queryClient = useQueryClient();
  const [selectedForm, setSelectedForm] = useState("auto_contact");

  const formikAutoContactURL = useFormik({
    initialValues: {
      linkedin_url: "",
      auto_fetch_contact: false,
    },
    validationSchema: Yup.object({
      linkedin_url: Yup.string()
        .required("Linkedin company URL is required")
        .matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/, {
          message: "Provided URL is not valid linkedin profile URL",
        }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const formikManualContact = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      job_title: "",
      phone_number: "+1",
      company_name: "",
      emails: [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required").min(3, "Provided first name is invalid"),
      last_name: Yup.string().required("Last name is required").min(3, "Provided last name is invalid"),
      job_title: Yup.string().required("Job title is required").min(3, "Provided title is invalid"),
      phone_number: Yup.string()
        .required()
        .test("isValidPhoneNumber", "Provided phone number is not valid", (value) => {
          console.log(value, isValidPhoneNumber(value));
          return isValidPhoneNumber(value);
        }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleModalClose = () => {
    formikAutoContactURL.resetForm();
    formikManualContact.resetForm();
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedForm === "auto_contact") {
      formikAutoContactURL.submitForm();
    } else {
      formikManualContact.submitForm();
    }
  };

  // TODO:Kamal
  // const listMutation = useMutation({
  //   mutationFn: null,
  //   onSuccess(data) {
  //     toast.success(data?.message);
  //     queryClient.invalidateQueries("get_lists");
  //     handleModalClose();
  //   },
  // });

  return (
    <div className="p-6 flex flex-col h-full">
      <div className="flex items-center gap-4">
        <div className="h-[40px] w-[40px] items-center flex items-center justify-center border rounded-lg inline-block border-gray-300 dark:border-gray-100">
          <PersonIcon className="stroke-gray-600" height="20" width="20" />
        </div>
        <Text size="lg">Add New Person</Text>
      </div>
      <div className="h-6 w-full" />
      <div className="h-full flex flex-col justify-between">
        <div className="flex flex-col gap-6">
          <FormikProvider value={formikAutoContactURL}>
            <form
              onSubmit={formikAutoContactURL.handleSubmit}
              onFocus={() => setSelectedForm("auto_contact")}
              className="flex flex-col gap-4"
            >
              <div className="flex flex-col gap-1.5">
                <label htmlFor="linkedin_url" className="block m-0 text-sm font-medium leading-6">
                  LinkedIn URL
                </label>
                <Input
                  icon={<img src={linkedinIcon} height="20" width="20" />}
                  autoFocus
                  type="text"
                  name="linkedin_url"
                  id="linkedin_url"
                  autoComplete="url"
                  placeholder="https://linkedin.com/in/fun_name"
                  onChange={formikAutoContactURL.handleChange}
                  value={formikAutoContactURL.values.linkedin_url}
                  onBlur={formikAutoContactURL.handleBlur}
                />
                {selectedForm === "auto_contact" && <FormError field="linkedin_url" className="!m-0" />}
              </div>
              <CheckboxInput
                label="Fetch contact details"
                id="auto_fetch_contact"
                name="auto_fetch_contact"
                checked={formikAutoContactURL.values.auto_fetch_contact}
                onCheckedChange={(checked) =>
                  formikAutoContactURL.setFieldValue("auto_fetch_contact", checked)
                }
              >
                This will cost 1 contact credit
              </CheckboxInput>
            </form>
          </FormikProvider>
          <Seperator text="Or, add manually" />
          <FormikProvider value={formikManualContact}>
            <form
              onSubmit={formikManualContact.handleSubmit}
              onFocus={() => setSelectedForm("manual")}
              className="flex flex-col gap-4"
            >
              <div className="flex items-center gap-4">
                <div className="flex flex-col gap-1.5">
                  <label htmlFor="first_name" className="block text-sm font-medium leading-6">
                    First name*
                  </label>
                  <Input
                    autoFocus
                    type="text"
                    name="first_name"
                    id="first_name"
                    autoComplete="given-name"
                    placeholder="John"
                    onChange={formikManualContact.handleChange}
                    value={formikManualContact.values.first_name}
                    onBlur={formikManualContact.handleBlur}
                  />
                  {selectedForm === "manual" && <FormError field="first_name" className="!m-0" />}
                </div>
                <div className="flex flex-col gap-1.5">
                  <label htmlFor="last_name" className="block text-sm font-medium leading-6">
                    Last name*
                  </label>
                  <Input
                    autoFocus
                    type="text"
                    name="last_name"
                    id="last_name"
                    autoComplete="family-name"
                    placeholder="Doe"
                    onChange={formikManualContact.handleChange}
                    value={formikManualContact.values.last_name}
                    onBlur={formikManualContact.handleBlur}
                  />
                  {selectedForm === "manual" && <FormError field="last_name" className="!m-0" />}
                </div>
              </div>
              <div className="flex flex-col gap-1.5">
                <label htmlFor="job_title" className="block text-sm font-medium leading-6">
                  Job Title
                </label>
                <Input
                  autoFocus
                  type="text"
                  name="job_title"
                  id="job_title"
                  autoComplete="title"
                  placeholder="Chief Fun Officer"
                  onChange={formikManualContact.handleChange}
                  value={formikManualContact.values.job_title}
                  onBlur={formikManualContact.handleBlur}
                />
                {selectedForm === "manual" && <FormError field="job_title" className="!m-0" />}
              </div>
              <div className="flex flex-col gap-1.5">
                <label htmlFor="phone_number" className="block m-0 text-sm font-medium leading-6">
                  Phone Number
                </label>
                <PhoneInput
                  defaultCountry="US"
                  placeholder="Enter a phone number"
                  id="phone_number"
                  name="phone_number"
                  value={formikManualContact.values.phone_number}
                  onChange={(phone) => formikManualContact.setFieldValue("phone_number", phone)}
                  onBlur={formikManualContact.handleBlur}
                />
                {selectedForm === "manual" && <FormError field="phone_number" className="!m-0" />}
              </div>
            </form>
          </FormikProvider>
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleModalClose();
            }}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              selectedForm === "auto_contact" ? !formikAutoContactURL.isValid : !formikManualContact.isValid
            }
          >
            Save
            {formikAutoContactURL.isSubmitting || formikManualContact.isSubmitting ? (
              <div className="loader_circle ml-3"></div>
            ) : (
              ""
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
