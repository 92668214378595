import { useQuery } from "react-query";
import { NavLink, useLocation } from "react-router-dom";
import { getUser } from "../../../apis/user";
import { useEffect, useState } from "react";
import Image from "../../Tags/Image";

export default function PageNotFoundPage() {
  const getUserData = useQuery("get_user", getUser);
  const [userData, setUserData] = useState({});
  const isOnAppHome = () => {
    if (
      window.location.pathname === "/app" ||
      window.location.pathname === "/app/"
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (getUserData?.data) {
      setUserData(getUserData?.data?.data);
      if (isOnAppHome()) {
        window.location.href = `${window.location.origin}/app/${getUserData?.data?.data.activeWorkspace.slug}/`;
      }
    }
  }, [getUserData?.data]);
  return (
    <>
      {isOnAppHome() ? (
        <div className="h-full min-h-screen w-full flex justify-center items-center">
          <Image
            className=""
            src="/images/loader.gif"
            width="150"
            height="150"
            alt=""
          />
        </div>
      ) : (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-indigo-600">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Page not found
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            {userData &&
              userData.activeWorkspace &&
              userData.activeWorkspace.slug && (
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <NavLink
                    to={`/${userData.activeWorkspace.slug}/`}
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Go back home
                  </NavLink>
                </div>
              )}
          </div>
        </main>
      )}
    </>
  );
}
