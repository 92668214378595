import React, { useContext, useEffect, useState } from "react";
import Image from "../../../components/Tags/Image";
import { Button } from "../../ui/button";
import Text from "../../Tags/Text";
import { ArrowRight, PlusIcon } from "lucide-react";
import { ResponsivePie, ResponsivePieCanvas } from "@nivo/pie";
import useModal from "../../GenericModal/useModal";
import SharedDataContext from "../../../SharedDataContext";
import GenericModal from "../../GenericModal";
import InviteModal from "../Invitations/InviteModal";
import { useQuery } from "react-query";
import { getIntegrations } from "../../../apis/workspace/integrations";
import InstallIntegration from "../Integrations/InstallIntegration";
import NavAnchor from "../../Tags/NavAnchor";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Badge } from "../../ui/badge";
import { Skeleton } from "../../ui/skeleton";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
const boxes = [
  {
    title: "Uncover Company Insights",
    description: "Gain deep intelligence on target accounts to fuel strategic sales approaches",
    img: "company-insights.svg",
    id: "company_insights",
  },
  {
    title: "Find People Contact Details",
    description: "Access verified decision-maker information for precise, effective outreach",
    img: "contact-details.svg",
    id: "contact_details",
  },
  {
    title: "Bulk enrich people lists",
    description: "Scale your outreach efficiently with mass data enrichment and verification",
    img: "bulk-people.svg",
    id: "people_list",
  },
  {
    title: "CRM Integration",
    description: "Seamlessly sync and leverage enriched data within your existing CRM workflow",
    img: "crm-integration.svg",
    id: "crm_integration",
  },
];

const modalData = {
  company_insights: {
    slides: [
      {
        heading: "Detailed Company Data",
        description: "Find NAICS and SIC codes, funding and financial information, investors and specialties",
        img: "company-insights-1.svg",
      },
      {
        heading: "Analyze Website Insights",
        description: "Monthly visitors, Ad spend and ad rank and search engine performance all at a glance.",
        img: "company-insights-2.svg",
      },
      {
        heading: "Product and Platforms",
        description:
          "Understand the products & services a company is providing and their available platforms",
        img: "company-insights-3.svg",
      },
      {
        heading: "Technology Stack",
        description: "Peer into the technologies and products a company uses to qualify and tune your pitch",
        img: "company-insights-4.svg",
      },
      {
        heading: "Product and Company Reviews",
        description: "Glassdoor and G2 rating to gauge the product and personnel ",
        img: "company-insights-5.svg",
      },
    ],
  },
  contact_details: {
    slides: [
      {
        heading: "Never Miss Another Contact",
        description: "Get the highest possible contact coverage with multiple provider waterfall enrichment",
        img: "contact-details-1.svg",
      },
      {
        heading: "Verification has your back",
        description: "Contacts are auto-verified with multi-step verification process ensuring data accuracy",
        img: "contact-details-2.svg",
      },
      {
        heading: "Huge Cost Savings",
        description: "With intelligent waterfall and provider seat reduction, save thousands of dollars",
        img: "contact-details-3.svg",
      },
      {
        heading: "Every provider you’ll ever need",
        description: "Access multiple major contact data providers through a single integrated platform. ",
        img: "contact-details-4.svg",
      },
      {
        heading: "No More Extension Bloat",
        description: "Install one browser extension for all your contact info replacing all others",
        img: "contact-details-5.svg",
      },
    ],
  },
  people_list: {
    slides: [
      {
        heading: "Save Time With a Simplified Workflow",
        description:
          "Simply upload your list of people to find contact information instead of doing it manually",
        img: "people-list-1.svg",
      },
      {
        heading: "Enrich Just What You Need",
        description: "Choose between just email addresses, just phones or all available information",
        img: "people-list-2.svg",
      },
      {
        heading: "Customize Provider Priority",
        description: "Change the order of provider usage based on your needs",
        img: "people-list-3.svg",
      },
      {
        heading: "Push Directly to CRM",
        coming_soon: true,
        description: "Push people as leads or contacts directly to CRM after bulk enrichment",
        img: "people-list-4.svg",
      },
    ],
  },
  crm_integration: {
    slides: [
      {
        heading: "Instant Information",
        description:
          "See if a person or company exists in your CRM and associated record directly on LinkedIn",
        img: "crm-integration-1.svg",
      },
      {
        heading: "Create Records With Ease",
        description:
          "Push leads/contacts and accounts into your CRM directly from LinkedIn with the latest data",
        img: "crm-integration-2.svg",
      },
      {
        heading: "Update Outdated Records",
        description:
          "If a CRM record has outdated information, update the record or create a new record easily",
        img: "crm-integration-3.svg",
      },
      {
        heading: "Custom Objects are Welcome Here",
        description: "Map people and company objects to non-standard CRM objects with ease",
        img: "crm-integration-4.svg",
      },
    ],
  },
};

export default function Dashboard() {
  const captureEvent = useCaptureEvent();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  // handle invite modal
  const { isOpen, openModal, closeModal } = useModal(false);

  const [activeBox, setActiveBox] = useState("");

  // handle invite modal
  const {
    isOpen: isOpenBoxModal,
    openModal: openModalBoxModal,
    closeModal: closeModalBoxModal,
  } = useModal(false);
  const [sliderLoading, setSliderLoading] = useState(true);

  useEffect(() => {
    if (sliderLoading) {
      setTimeout(() => {
        setSliderLoading(false);
      }, 1000);
    }
  }, [sliderLoading]);

  // const [currentIntegrationKey, setCurrentIntegrationKey] = useState("");
  // const [currentIntegrationLogo, setCurrentIntegrationLogo] = useState("");
  // const [integrations, setIntegrations] = useState({});
  // const getIntegrationsFn = useQuery(
  //   "get_integrations",
  //   () => {
  //     return getIntegrations({
  //       options: {
  //         headers: {
  //           "X-Workspace-Id": activeWorkspace?.id,
  //         },
  //       },
  //     });
  //   },
  //   {
  //     enabled: !!activeWorkspace,
  //     refetchOnWindowFocus: false,
  //     manual: true,
  //     onSuccess: (data) => {
  //       console.log(data);
  //       setIntegrations(data);
  //     },
  //     onError: (error) => {
  //       console.error(error);
  //     },
  //   }
  // );

  // const handleGetIntegrations = () => {
  //   getIntegrationsFn.refetch();
  // };

  // useEffect(() => {
  //   if (activeWorkspace?.id) {
  //     handleGetIntegrations();
  //   }
  // }, [activeWorkspace]);

  const extensionFeatures = [
    "Reveal contacts on LinkedIn",
    "120+ company insights on any website",
    "Personalized outreach messages",
    "Push records to CRM",
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div
        className="carousel-button-group mb-4  gap-4 flex justify-between mt-8 
            items-center absolute w-full bottom-[100px] left-0"
      >
        <button onClick={() => previous()} className="w-14 h-14 flex justify-center items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 8H1M1 8L8 15M1 8L8 1"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button onClick={() => next()} className="w-14 h-14 flex justify-center items-center">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 8H15M15 8L8 1M15 8L8 15"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <>
      <div
        className="relative bg-gray-50 -mx-8 -my-8 px-[112px] py-8 border-t border-border"
        style={{ minHeight: "calc(100vh - 72px)" }}
      >
        <div className="w-full mx-auto mobile:w-full">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <Text size="h6">Welcome, {userData?.name}</Text>
              <Text size="p" className="mt-1">
                Prospect, enrich, track - people and companies.
              </Text>
            </div>
            {activeWorkspace && (activeWorkspace.role === "Owner" || activeWorkspace.role === "Admin") && (
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <Button
                  onClick={() => {
                    openModal();
                    captureEvent("HOM_InviteMemberBtn");
                  }}
                  type="button"
                >
                  <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Invite Members
                </Button>
              </div>
            )}
          </div>
          <div className="border-b border-border mt-6 mb-3"></div>
          {/* <div className="flex items-center space-x-6">
          {boxes.map((box) => (
            <div className="flex-1 border border-border rounded-xl px-4 py-5 bg-white dark:bg-gray-100">
              <h3 className="text-primary-900 font-semibold">{box.title}</h3>
              <p className="text-gray-600 mt-2 min-h-[40px]">{box.description}</p>
              <div
                className="h-[136px] mt-4 rounded-xl"
                style={{ background: `linear-gradient(${box.img})` }}
              ></div>
            </div>
          ))}
        </div> */}
          <div className="flex space-x-6 mobile:block mobile:space-x-0">
            <div className="w-8/12 space-y-6 mobile:w-full">
              <div className="flex flex-wrap -mx-3 mobile:block">
                {boxes.map((box) => (
                  <div className="w-6/12 px-3 mt-6 mobile:w-full">
                    <div
                      className="border border-border rounded-xl px-4 py-5 bg-white dark:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        setActiveBox(box.id);
                        openModalBoxModal();
                        setSliderLoading(true);
                        captureEvent("HOM_UsagesBtn");
                      }}
                    >
                      <h3 className="text-primary-900 font-semibold">{box.title}</h3>
                      <p className="text-gray-600 mt-2 min-h-[40px]">{box.description}</p>

                      <div className="h-[136px] mt-4 rounded-xl justify-center flex items-center">
                        <Image src={`/images/icons/untitledui/dashboard/${box.img}`} className="no-invert" />
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="rounded-xl px-4 py-5 bg-white border border-border flex-1 dark:bg-gray-100">
                <div className="flex items-center space-x-3">
                  <Image src={"/images/icons/untitledui/phone.svg"} />
                  <Text size="md">Contact data coverage (Phone)</Text>
                </div>
                <div className="flex justify-between items-end mt-6">
                  <div className="flex space-x-2">
                    <Text size="h4" className="pt-2 leading-none">
                      85%
                    </Text>
                    <div className="inline-flex space-x-2 items-start">
                      <Image
                        src={"/images/icons/untitledui/trend-down.svg"}
                        width="22"
                        className="inline-block"
                      />
                      <span className="text-error-600 font-bold">10%</span>
                    </div>
                  </div>
                  <Image src={"/images/icons/untitledui/trend-down-chart.svg"} className="no-invert" />
                </div>
              </div>
              <div className="rounded-xl px-4 py-5 bg-white border border-border flex-1 dark:bg-gray-100">
                <div className="flex items-center space-x-3">
                  <Image src={"/images/icons/untitledui/email.svg"} />
                  <Text size="md">Contact data coverage (Email)</Text>
                </div>
                <div className="flex justify-between items-end mt-6">
                  <div className="flex space-x-2">
                    <Text size="h4" className="pt-2 leading-none">
                      100%
                    </Text>
                    <div className="inline-flex space-x-2 items-start">
                      <Image
                        src={"/images/icons/untitledui/trend-up.svg"}
                        width="22"
                        className="inline-block"
                      />
                      <span className="text-green-600 font-bold">100%</span>
                    </div>
                  </div>
                  <Image src={"/images/icons/untitledui/trend-up-chart.svg"} className="no-invert" />
                </div>
              </div> */}
              </div>
              {/* <div className="flex space-x-6 ">
              <div className="rounded-xl px-4 py-5 bg-white border border-border flex-1 dark:bg-gray-100">
                <Text size="md">Contact credit usage</Text>
                <div className="h-80">
                  <ResponsivePieCanvas
                    data={data}
                    margin={{ top: 40, right: 200, bottom: 0, left: 0 }}
                    innerRadius={0.5}
                    padAngle={0}
                    cornerRadius={0}
                    colors={{ datum: "data.color" }}
                    activeOuterRadiusOffset={8}
                    borderWidth={0}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    arcLinkLabelsSkipAngle={360} // Setting a high skip angle effectively hides all link labels
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={0} // Set thickness to 0 to hide the lines
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    tooltip={() => null} // Disable tooltips
                    legends={[
                      {
                        anchor: "right",
                        direction: "column",
                        justify: false,
                        translateX: 130,
                        translateY: 0,
                        itemsSpacing: 10,
                        itemWidth: 100,
                        itemHeight: 10,
                        itemTextColor: "#999",
                        itemDirection: "left-to-right",
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: "circle",
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemTextColor: "#000",
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="rounded-xl px-4 py-5 bg-white border border-border flex-1 dark:bg-gray-100">
                <Text size="md">Contact provider usage</Text>
                <div className="h-80">
                  <ResponsivePieCanvas
                    data={data2}
                    margin={{ top: 40, right: 200, bottom: 0, left: 0 }}
                    innerRadius={0.5}
                    padAngle={0}
                    cornerRadius={0}
                    colors={{ datum: "data.color" }}
                    activeOuterRadiusOffset={8}
                    borderWidth={0}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    arcLinkLabelsSkipAngle={360} // Setting a high skip angle effectively hides all link labels
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={0} // Set thickness to 0 to hide the lines
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    tooltip={() => null} // Disable tooltips
                    legends={[
                      {
                        anchor: "right",
                        direction: "column",
                        justify: false,
                        translateX: 130,
                        translateY: 0,
                        itemsSpacing: 10,
                        itemWidth: 100,
                        itemHeight: 10,
                        itemTextColor: "#999",
                        itemDirection: "left-to-right",
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: "circle",
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemTextColor: "#000",
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </div>
            </div> */}
            </div>
            <div className="w-4/12  mobile:w-full">
              <div className="border border-border rounded-xl px-4 py-5 bg-white dark:bg-gray-100 mt-6">
                <h3 className="text-primary-900 font-semibold text-base">Install the Browser Extension</h3>
                <p className="text-gray-600 mt-2 min-h-[40px]">
                  Enrich companies and people anywhere on the web
                </p>
                <div className="mt-4">
                  <div className="space-y-4">
                    {extensionFeatures.map((item) => (
                      <div className="flex items-center space-x-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                            fill="#DCFAE6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.0964 7.39016L9.93641 14.3002L8.03641 12.2702C7.68641 11.9402 7.13641 11.9202 6.73641 12.2002C6.34641 12.4902 6.23641 13.0002 6.47641 13.4102L8.72641 17.0702C8.94641 17.4102 9.32641 17.6202 9.75641 17.6202C10.1664 17.6202 10.5564 17.4102 10.7764 17.0702C11.1364 16.6002 18.0064 8.41016 18.0064 8.41016C18.9064 7.49016 17.8164 6.68016 17.0964 7.38016V7.39016Z"
                            fill="#079455"
                          />
                        </svg>
                        <span>{item}</span>
                      </div>
                    ))}
                    <div className="flex justify-center">
                      <Image src={"/images/chrome-web-store.svg"} className="no-invert rounded-xl" />
                    </div>
                    <Button
                      className="w-full"
                      asChild
                      onClick={() => {
                        captureEvent("HOM_InstallBtn");
                      }}
                    >
                      <a href={process.env.REACT_APP_CHROME_EXTENSION} target="_blank">
                        <Image
                          src={"/images/icons/untitledui/google-chrome.svg"}
                          className="mr-2 no-invert"
                        />{" "}
                        Install
                      </a>
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="mt-6">
              {integrations &&
                integrations.available &&
                integrations.available.length > 0 &&
                integrations.available.map((integration) => (
                  <div
                    onClick={() => {
                      setCurrentIntegrationKey(integration.key);
                      setCurrentIntegrationLogo(integration.logo);
                      openModalIntegration();
                    }}
                    className={`inline-flex  mr-3 mb-3 justify-center items-center border border-border rounded-2xl py-4 px-3   
                              ${
                                integrations.installed &&
                                integrations.installed.length > 0 &&
                                integrations.installed.filter((installInt) =>
                                  installInt.integration.key
                                    .toLowerCase()
                                    .includes(integration.key.toLowerCase())
                                ).length > 0
                                  ? "border-green-500 bg-green-50 cursor-default"
                                  : "bg-white cursor-pointer dark:bg-gray-200"
                              }
                              `}
                  >
                    <span
                      className="svg__32"
                      dangerouslySetInnerHTML={{
                        __html: integration.logo,
                      }}
                    />
                    <span className="text-md font-bold text-gray-600 ml-3">{integration.display_name}</span>
                  </div>
                ))}
              <div className="text-center mt-6">
                <NavAnchor to={`/settings/integrations/`}>
                  <Button variant="outline">View all</Button>
                </NavAnchor>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <GenericModal isOpen={isOpen} closeModal={closeModal}>
        <InviteModal closeModal={closeModal} activeWorkspace={activeWorkspace} />
      </GenericModal>
      <GenericModal isOpen={isOpenBoxModal} closeModal={closeModalBoxModal} width={"w-[800px]"}>
        {activeBox && (
          <>
            <div className="mt-7">
              {sliderLoading ? (
                <div>
                  <div className="bg__gradient w-full h-[450px] rounded-xl"></div>
                  <Skeleton className={"h-20 mt-6"} />
                </div>
              ) : (
                <Carousel
                  showDots={true}
                  removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                  autoPlaySpeed={3000}
                  ssr={true}
                  infinite={true}
                  autoPlay={true}
                  partialVisible
                  responsive={responsive}
                  dotListClass="absolute bottom-0 left-0"
                  arrows={true}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup />}
                >
                  {modalData[activeBox] &&
                    modalData[activeBox].slides &&
                    modalData[activeBox].slides.length > 0 &&
                    modalData[activeBox].slides.map((slide) => (
                      <div className="">
                        <div className="bg__gradient rounded-xl px-12 flex justify-center mobile:px-3">
                          <Image src={`/images/dashboard/${slide?.img}`} className="h-[450px] no-invert" />
                        </div>
                        <div className="text-center p-6">
                          <h3 className="text-primary-900 font-semibold text-lg flex items-center space-x-2 justify-center">
                            <span>{slide?.heading}</span>{" "}
                            {slide?.coming_soon && <Badge variant={"gray"}>Coming Soon</Badge>}
                          </h3>
                          <p className="text-gray-600 mt-1">{slide?.description}</p>
                        </div>
                      </div>
                    ))}
                </Carousel>
              )}
            </div>

            {activeBox && (activeBox === "company_insights" || activeBox === "contact_details") && (
              <div className="flex items-center space-x-4 mt-8 mobile:block mobile:space-x-0">
                <Button className="w-full flex-1 mobile:mb-2" variant="outline" asChild>
                  <a href={process.env.REACT_APP_CHROME_EXTENSION} target="_blank">
                    <svg
                      className="mr-2"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 7.16675C8.65906 7.16675 7.16667 8.65913 7.16667 10.5001C7.16667 12.341 8.65906 13.8334 10.5 13.8334C12.341 13.8334 13.8333 12.341 13.8333 10.5001C13.8333 8.65913 12.341 7.16675 10.5 7.16675ZM10.5 7.16675H18.1417M3.79167 5.55008L7.61667 12.1667M9.56667 18.7834L13.3833 12.1667M18.8333 10.5001C18.8333 15.1025 15.1024 18.8334 10.5 18.8334C5.89763 18.8334 2.16667 15.1025 2.16667 10.5001C2.16667 5.89771 5.89763 2.16675 10.5 2.16675C15.1024 2.16675 18.8333 5.89771 18.8333 10.5001Z"
                        stroke="#344054"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Install Browser Extension
                  </a>
                </Button>
                {activeBox === "company_insights" && (
                  <Button className="w-full flex-1" asChild>
                    <a href="https://www.linkedin.com/company/stripe/" target="_blank">
                      Go to LinkedIn
                      <svg
                        className="ml-2"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 8.00001L18 3.00001M18 3.00001H13M18 3.00001L10.5 10.5M8.83333 3H7C5.59987 3 4.8998 3 4.36502 3.27248C3.89462 3.51217 3.51217 3.89462 3.27248 4.36502C3 4.8998 3 5.59987 3 7V14C3 15.4001 3 16.1002 3.27248 16.635C3.51217 17.1054 3.89462 17.4878 4.36502 17.7275C4.8998 18 5.59987 18 7 18H14C15.4001 18 16.1002 18 16.635 17.7275C17.1054 17.4878 17.4878 17.1054 17.7275 16.635C18 16.1002 18 15.4001 18 14V12.1667"
                          stroke="white"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </Button>
                )}
                {activeBox === "contact_details" && (
                  <Button className="w-full flex-1" asChild>
                    <a href="https://www.linkedin.com/in/williamhgates/" target="_blank">
                      Go to LinkedIn
                      <svg
                        className="ml-2"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 8.00001L18 3.00001M18 3.00001H13M18 3.00001L10.5 10.5M8.83333 3H7C5.59987 3 4.8998 3 4.36502 3.27248C3.89462 3.51217 3.51217 3.89462 3.27248 4.36502C3 4.8998 3 5.59987 3 7V14C3 15.4001 3 16.1002 3.27248 16.635C3.51217 17.1054 3.89462 17.4878 4.36502 17.7275C4.8998 18 5.59987 18 7 18H14C15.4001 18 16.1002 18 16.635 17.7275C17.1054 17.4878 17.4878 17.1054 17.7275 16.635C18 16.1002 18 15.4001 18 14V12.1667"
                          stroke="white"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </Button>
                )}
              </div>
            )}
            {activeBox && (activeBox === "people_list" || activeBox === "crm_integration") && (
              <div className="flex items-center space-x-4 mt-8">
                <Button className="w-full flex-1" variant="outline" onClick={closeModalBoxModal}>
                  Got it!
                </Button>
                {activeBox === "people_list" && (
                  <Button className="w-full flex-1" asChild>
                    <NavAnchor slug={true} to={"/enrich/list"}>
                      Let’s Enrich
                      <ArrowRight width={16} height={16} className="ml-1" />
                    </NavAnchor>
                  </Button>
                )}
                {activeBox === "crm_integration" && (
                  <Button className="w-full flex-1" asChild>
                    <NavAnchor slug={true} to={"/settings/integrations"}>
                      Connect CRM
                      <ArrowRight width={16} height={16} className="ml-1" />
                    </NavAnchor>
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </GenericModal>
      {/* <GenericModal isOpen={isOpenIntegration} closeModal={closeModalIntegration} drawer={true}>
        <InstallIntegration
          closeModal={closeModalIntegration}
          integrationKey={currentIntegrationKey}
          logo={currentIntegrationLogo}
          edit={false}
          formData={{
            name: currentIntegrationKey === "zoom_info" ? "ZoomInfo" : "",
            configId: "",
          }}
        />
      </GenericModal> */}
    </>
  );
}
