import React, { useEffect, useState } from "react";
import { updateIntegration } from "../../../../apis/workspace/integrations";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { Button } from "../../../ui/button";
import Image from "../../../Tags/Image";

export default function GeneralInfo({
  onSuccess,
  onError,
  currentIntegration = {},
  activeWorkspace,
  isSubmitting,
  isLoading,
}) {
  const { salesforceConfigId } = useParams();
  // update config name
  const [connectionName, setConnectionName] = useState("");
  const updateIntegrationFn = useMutation("install_integration", updateIntegration, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      onSuccess();
      isLoading(false);
    },
    onError(error) {
      onError();
      isLoading(false);
    },
  });
  const handleUpdateIntegrationName = () => {
    isLoading(true);
    if (updateIntegrationFn.isLoading) {
      return;
    }
    updateIntegrationFn.mutate({
      data: { name: connectionName },
      configId: salesforceConfigId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  useEffect(() => {
    if (isSubmitting) {
      handleUpdateIntegrationName();
    }
  }, [isSubmitting]);

  return (
    <>
      <div className="">
        <p className="text-sm text-gray-900 font-semibold mb-2">Connection name</p>
        <input
          onChange={(e) => {
            setConnectionName(e.target.value);
          }}
          defaultValue={currentIntegration?.name}
          placeholder="Connection name"
          className="h-11 px-[14px] py-[10px] text-sm w-4/12 rounded-[--radius] border border-input bg-background text-text-900 "
        />
      </div>

      <div className="border-y my-6 py-6">
        <p className="text-sm text-gray-900 font-semibold mb-2">Salesforce URL</p>
        <p className="text-sm text-gray-600 font-normal mt-1">{currentIntegration?.instance_url}</p>
      </div>
      <div className="border-b pb-6">
        <p className="text-sm text-gray-900 font-semibold mb-2">Connected Salesforce User</p>
        <p className="text-sm text-gray-600 font-normal mt-1">
          {currentIntegration?.sf_user_name} ({currentIntegration?.sf_user_email})
        </p>
      </div>
      <div className="py-6">
        <p className="text-sm text-gray-900 font-semibold mb-2">Authentication</p>
        <Button variant="outline" className="inline-flex items-center space-x-2" onClick={() => {
          window.location.href = `${process.env.REACT_APP_API_BASE_URL}/workspace-settings/integrations/salesforce/reauthorize/${salesforceConfigId}`;
        }}>
          <Image  src="/images/icons/untitledui/re-auth.svg" />
          <span>Re-authentication</span>
        </Button>
      </div>
    </>
  );
}
