import { useState } from "react";

import InviteModal from "../Invitations/InviteModal";
import useRoleCheck from "../../../hooks/useRoleCheck";
import { Button } from "../../ui/button";
import { PlusIcon } from "lucide-react";
import { Badge } from "../../ui/badge";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import MembershipRequests from "./MembershipRequests";
import Text from "../../Tags/Text";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import ActiveMembers from "./ActiveMembers";
import Invitations from "./Invitations";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent"
export default function Members() {
  const { activeWorkspace } = useRoleCheck();
  const captureEvent = useCaptureEvent();
  // handle invite modal
  const { isOpen, openModal, closeModal } = useModal(false);

  // get count of members
  const [membersCount, setMembersCount] = useState();
  const getMembersCount = (count) => {
    setMembersCount(count);
  };

  // get count of requests
  const [requestCount, setRequestCount] = useState();
  const getRequestCount = (count) => {
    setRequestCount(count);
  };

  // get count of invitations
  const [invitationCount, setInvitationCount] = useState();
  const getInvitationCount = (count) => {
    setInvitationCount(count);
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Text size="lg">Members</Text>
          <Text size="p" className="mt-1">
            Manage workspace users and requests
          </Text>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button
            onClick={() => {
              openModal()
              captureEvent("SET_Members_InviteMembers");
            }} 
            type="button">
            <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            Invite Members
          </Button>
        </div>
      </div>
      <Tabs defaultValue="active" className="w-full mt-6">
        <TabsList className="w-full">
          <TabsTrigger value="active"
           onClick={() => {
            captureEvent("SET_Members_ActiveBtn");
          }}
          >
            Active{" "}
            {/* {membersCount >= 0 && (
              <Badge className="ml-2">{membersCount}</Badge>
            )} */}
          </TabsTrigger>
          <TabsTrigger value="request"
           onClick={() => {
            captureEvent("SET_Members_RequestBtn");
          }}
          >
            Requests{" "}
            {requestCount >= 0 && (
              <Badge variant="warning" className="ml-2">
                {requestCount}
              </Badge>
            )}
          </TabsTrigger>
          <TabsTrigger value="invitation"
          onClick={() => {
            captureEvent("SET_Members_InvitationsBtn");
          }}
          >
            Invitations
            {/* {invitationCount >= 0 && (
              <Badge className="ml-2">{invitationCount}</Badge>
            )} */}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="active">
          <ActiveMembers getCount={getMembersCount} />
        </TabsContent>
        <TabsContent value="request">
          <MembershipRequests getCount={getRequestCount} />
        </TabsContent>
        <TabsContent value="invitation">
          <Invitations getCount={getInvitationCount} />
        </TabsContent>
      </Tabs>

      <GenericModal isOpen={isOpen} closeModal={closeModal}>
        <InviteModal
          closeModal={closeModal}
          activeWorkspace={activeWorkspace}
        />
      </GenericModal>
    </>
  );
}
