import React from "react";
import Layout from "../../Layout";
import GroupDetails from "../../containers/GroupDetails";

export default function GroupDetailsPage() {
  return (
    <Layout>
      <GroupDetails />
    </Layout>
  );
}
