import axios from "axios";
import Cookies from "js-cookie";

// Configure Axios
const baseURL = process.env.REACT_APP_API_BASE_URL;
const api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

// Exported function that directly returns the promise with .then() and .catch()
export function fetchCsrfCookie() {
  return api
    .get("/sanctum/csrf-cookie")
    .then((response) => {
      console.log("CSRF cookie fetched successfully:", response);
      //   const xsrfToken =  document.cookie
      //     .split("; ")
      //     .find((row) => row.startsWith("XSRF-TOKEN="))
      //     .split("=")[1];
      const xsrfToken = Cookies.get("XSRF-TOKEN");
      return xsrfToken; // return the token for further processing
    })
    .catch((error) => {
      console.error("Error fetching CSRF cookie:", error);
      throw error; // This will allow the calling function to handle the error
    });
}
