import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.headers["accept"] = "application/json";
axios.defaults.headers["content-type"] = "application/json";

const cookieName = process.env.REACT_APP_PR_ACCESS_TOKEN
  ? process.env.REACT_APP_PR_ACCESS_TOKEN
  : "accessToken";

const token = Cookies.get(cookieName);

if (token) {
  axios.defaults.headers["Authorization"] = "Bearer " + token;
}

const showAlert = (type, message) => {
  return toast.error(message, { id: "error" });
};


const errorResponseHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      if (`${process.env.REACT_APP_API_BASE_URL}/api/v1/user` === error.response?.request?.responseURL) {
        Cookies.remove(process.env.REACT_APP_PR_ACCESS_TOKEN, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        Cookies.remove(process.env.REACT_APP_PR_APP_SESSION_COOKIE, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        window.location.href = `/app/login`;
        const newevent = new CustomEvent("user_unauthenticated", {
          detail: true,
        });
        window.dispatchEvent(newevent);
      }
    }
    if (error.response.status === 429) {
      return showAlert("error", "You have exceeded request limit. Please try again in sometime.");
    } else if (error.response.status === 404) {
      if (error.response?.data?.message && typeof error.response?.data?.message === "string") {
        showAlert("error", error.response?.data?.message);
      } else {
        showAlert("error", "Error 404! We could not find the resource you are accessing.");
      }
    } else if (error.response.status === 419) {
      showAlert("error", error.response.data.message);
      // showAlert("error", "Session expired! Please try refreshing the page.");
    } else if (error.response.status === 422) {
      if (error.response?.data?.message && typeof error.response.data.message === "string") {
        showAlert("error", error.response.data.message);
      }
    } else if (error.response.status >= 400) {
      const error_type = "error";

      if (typeof error.response?.data?.message === "string") {
        const error_message = error.response?.data?.message;
        if (error.response.status !== 401) {
          showAlert(error_type, error_message);
        }
      }
    }
  }

  return Promise.reject(error);
};

axios.interceptors.response.use((response) => response, errorResponseHandler);

export default axios;
