import { ReactComponent as DocumentDuplicateIcon } from "../../../../static/media/icons/document-duplicate-icon.svg";
import { ReactComponent as InfoIcon } from "../../../../static/media/icons/info-icon.svg";
import { ReactComponent as PencilEditIcon } from "../../../../static/media/icons/pencil-edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../static/media/icons/trash-detail-icon.svg";

const items = [
  {
    group: "group_1",
    items: [
      {
        action: "info",
        label: "Info",
        icon: <InfoIcon height="16" width="16" />,
      },
      {
        action: "edit",
        label: "Edit",
        icon: <PencilEditIcon height="16" width="16" />,
      },
      {
        action: "duplicate",
        label: "Duplicate",
        icon: <DocumentDuplicateIcon height="16" width="16" />,
      },
    ],
  },
  {
    group: "group_2",
    items: [
      {
        action: "delete",
        label: "Delete",
        icon: <TrashIcon height="16" width="16" className="stroke-red-600" />,
        className: "text-red-600 focus:text-red-600",
      },
    ],
  },
];

export const rowActionItemsProps = {
  props: {
    isItemsLoaded: true,
  },
  items,
};
