import Header from "./Header";
import LeftNav from "./LeftNav";

export default function Layout({ leftNav = <LeftNav />, className = "", children }) {
  return (
    <div className="min-h-screen">
      <Header />
      <div className="mx-auto justify-between">
        <div className="flex w-full container__layout">
          {leftNav ? (
            <>
              <div className="border-r border-border bg-white dark:bg-gray-25">{leftNav}</div>
              <div
                className={`px-8 py-7 flex-1 bg-gray-25 dark:bg-gray-dark-50 overflow-y-auto mobile:pl-3 mobile:pr-5 mobile:py-4 ${className}`}
                style={{ scrollPaddingTop: 40 }}
              >
                <div className="">{children}</div>
              </div>
            </>
          ) : (
            <div className="flex-1">
              <div className={`px-8 py-7 ${className}`}>{children}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
