import React from "react";
import Layout from "../../Layout";
import CreditConsumptionLog from "../../containers/CreditConsumptionLog";

export default function CreditConsumptionLogPage() {
  return (
    <Layout>
      <CreditConsumptionLog />
    </Layout>
  );
}
