import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logOutUser } from "../../../apis/user";
import { useMutation } from "react-query";
import Cookies from "js-cookie";
import { fetchCsrfCookie } from "../../../apis/csrf";

export default function Logout() {
  const navigate = useNavigate();

  const logOutUserFn = useMutation("logout", logOutUser, {
    onSuccess(data, variables, context) {
      Cookies.remove(process.env.REACT_APP_PR_ACCESS_TOKEN);
      Cookies.remove(process.env.REACT_APP_PR_APP_SESSION_COOKIE);
      navigate("/login");
    },
  });

  const handleLogout = () => {
    fetchCsrfCookie()
      .then((xsrfToken) => {
        logOutUserFn.mutate({});
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      });
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div className="flex h-screen w-full justify-center items-center">
      <div className="flex justify-center flex-col items-center">
        <div className="loader_circle loader_loading"></div>
        <p className="text-base font-semibold text-gray-500 mt-3">Logging you out...</p>
      </div>
    </div>
  );
}
