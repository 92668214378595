import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  addMembersToGroup,
  deleteMembersToGroup,
  updateGroup,
  viewGroup,
} from "../../../apis/workspace/groups";
import { useMutation } from "react-query";
import SharedDataContext from "../../../SharedDataContext";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ArrowDownIcon, ArrowUpIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import GroupModal from "../Groups/GroupModal";

import AddTeamMembers from "./AddTeamMembers";
import { getMembers } from "../../../apis/workspace/members";
import { Tooltip } from "react-tooltip";
import ConfirmModal from "../../Modals/ConfirmModal";
import { membersRoleOptions, paginationConfig } from "../../../axios/constant";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import { isEmptyObject, returnArray } from "../../../utils/GenericFn";
import Filter from "../../Filter";
import EditColumn from "../../EditColumn";
import Pagination from "../../Pagination";
import NoData from "../../NoData";
import TableLoader from "../../Loaders/TableLoader";
import GroupList from "../Members/GroupList";
import Image from "../../Tags/Image";
import NavAnchor from "../../Tags/NavAnchor";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import PrintDate from "../../Tags/PrintDate";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../ui/breadcrumb";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import Input from "../../Tags/Input";
import { Switch } from "../../ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function GroupDetails() {
  const captureEvent = useCaptureEvent();
  const [csvEnrichmentAllowed, setCsvEnrichmentAllowed] = useState();
  const [maxMonthlyEnrichmentCredits, setMaxMonthlyEnrichmentCredits] = useState();
  const [maxMonthlyEnrichmentCreditsError, setMaxMonthlyEnrichmentCreditsError] = useState();
  const [pageMounted, setPageMounted] = useState(false);
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const { groupId } = useParams();
  const [group, setGroup] = useState({});
  const viewGroupFn = useMutation("view_group", viewGroup, {
    onSuccess(data, variables, context) {
      setGroup(data.data);
      setMaxMonthlyEnrichmentCredits(data.data?.preferences?.max_monthly_enrichment_credits);
      setCsvEnrichmentAllowed(data.data?.preferences?.csv_enrichment_allowed);
    },
  });
  const handleViewGroup = () => {
    viewGroupFn.mutate({
      groupId: groupId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  useEffect(() => {
    if (activeWorkspace?.id) {
      handleViewGroup();
    }
  }, [activeWorkspace, groupId]);

  // page mount
  useEffect(() => {
    setPageMounted(true);
  }, []);

  // edit group modal
  const { isOpen, openModal, closeModal } = useModal();
  const refetchData = () => {
    handleViewGroup();
  };
  // handle add members

  const { isOpen: isOpen2, openModal: openModal2, closeModal: closeModal2 } = useModal();
  const [members, setMembers] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  const getMembersFn = useMutation("get_members", getMembers, {
    onSuccess(data, variables, context) {
      setMembers((prevMembers) => data);
    },
  });
  const handleGetMembers = () => {
    getMembersFn.mutate({
      query: filterQuery,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    //console.log("filterQuery out", filterQuery);
    if (activeWorkspace?.id && groupId !== "" && filterQuery !== "") {
      handleGetMembers();
    }
  }, [activeWorkspace, filterQuery, groupId]);

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort, groupId]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    params.push(`filter[group]=${groupId}`);
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetSort = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => a.order - b.order);
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const addMembersToGroupFn = useMutation("view_group", addMembersToGroup, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleViewGroup();
      handleGetMembers();
      closeModal2();
    },
  });
  const handleAddMembersToGroup = (arr) => {
    addMembersToGroupFn.mutate({
      groupId: groupId,
      data: { members: arr },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  const getSelectedMembers = (arr) => {
    //console.log(arr);
    handleAddMembersToGroup(arr);
  };

  // delete members from group
  const [currentMemberId, setCurrentMemberId] = useState("");
  const deleteMembersToGroupFn = useMutation("view_group", deleteMembersToGroup, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleViewGroup();
      handleGetMembers();
    },
  });
  const handleDeleteMembersToGroup = () => {
    deleteMembersToGroupFn.mutate({
      groupId: groupId,
      memberId: currentMemberId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // handle open confirm
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteMembersToGroup();
    }
  };

  const setQueryString = useUpdateQuery();

  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // check group exists
  const doesGroupIdExist = (groups = [], idToCheck) => {
    return groups.some((group) => group.id === idToCheck);
  };

  useEffect(() => {
    if (maxMonthlyEnrichmentCredits === "" || maxMonthlyEnrichmentCredits === null) {
      setMaxMonthlyEnrichmentCreditsError(true);
    } else {
      setMaxMonthlyEnrichmentCreditsError(false);
    }
  }, [maxMonthlyEnrichmentCredits]);

  const updateGroupFn = useMutation("create_group", updateGroup, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      refetchData();
    },
    onError(error) {},
  });

  const handleUpdateGroup = (toggleMode = "") => {
    if (group && group.id) {
      let preferences = {};
      if (toggleMode) {
        preferences = {
          csv_enrichment_allowed: toggleMode === "true" ? true : false,
          max_monthly_enrichment_credits: group?.preferences?.max_monthly_enrichment_credits,
        };
      } else {
        preferences = {
          csv_enrichment_allowed: group?.preferences?.csv_enrichment_allowed,
          max_monthly_enrichment_credits: maxMonthlyEnrichmentCredits,
        };
      }
      const payload = {
        name: group.name,
        preferences: preferences,
      };
      updateGroupFn.mutate({
        data: payload,
        groupId: group.id,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    }
  };

  return (
    <>
      {group && group.id && (
        <>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <NavAnchor to={`/`}>
                  <Image src="/images/icons/untitledui/home.svg" width="16" height="16" />
                </NavAnchor>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <NavAnchor to={`/settings/groups/`}>Groups</NavAnchor>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Group Details</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="sm:flex sm:items-center mt-1">
            <div className="sm:flex-auto">
              <h2 className="text-base font-semibold leading-7 flex items-center">
                {group.name}
                <Button onClick={openModal} variant="link">
                  <PencilIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                </Button>
              </h2>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              {group.members && group.members.length > 0 && (
                <Button
                  onClick={() => {
                    openModal2();
                    captureEvent("SET_GroupDetailActive_AddMembersBtn");
                  }}
                  type="button"
                >
                  <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Add Members
                </Button>
              )}
            </div>
          </div>

          <Tabs defaultValue="members" className="w-full mt-6">
            <TabsList className="w-full">
              <TabsTrigger
                value="members"
                onClick={() => {
                  captureEvent("SET_GroupDetail_ActiveBtn");
                }}
              >
                Active {group.members ? <Badge className="ml-2">{group.members.length}</Badge> : null}
              </TabsTrigger>
              <TabsTrigger
                value="usage"
                onClick={() => {
                  captureEvent("SET_GroupDetail_UsageBtn");
                }}
              >
                Usage
              </TabsTrigger>
              <TabsTrigger
                value="access_control"
                onClick={() => {
                  captureEvent("SET_GroupDetail_AccessControlBtn");
                }}
              >
                Access Control
              </TabsTrigger>
            </TabsList>
            <TabsContent value="members">
              <div>
                {group.members && group.members.length === 0 && (
                  <NoData
                    icon={
                      <div className="rounded-full inline-block bg-smoke">
                        <div className={"bg-brand-100 text-brand-700 rounded-full inline-block p-4"}>
                          <Image src="/images/icons/untitledui/users-plus.svg" width="20" height="20" />
                        </div>
                      </div>
                    }
                    heading={"Add members to group"}
                    description={"You haven’t added any members to your this group yet."}
                    filterMode={false}
                  >
                    <Button
                      onClick={() => {
                        openModal2();
                        captureEvent("SET_GroupDetailActive_AddMembersBtn");
                      }}
                      type="button"
                    >
                      <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                      Add Members
                    </Button>
                  </NoData>
                )}

                <div>
                  {members && members.data && (members.data.length > 0 || !isEmptyObject(filters)) && (
                    <div className="mt-7 flex justify-between items-center">
                      <div className={`space-x-3 group__details__filter inline-flex items-center`}>
                        {members && members.filterMetadata && (
                          <>
                            <span>Filters:</span>
                            <Filter
                              filterMetadata={members.filterMetadata}
                              getFiltersObject={getFiltersObject}
                            />
                          </>
                        )}
                      </div>
                      <div className="space-x-3">
                        {members.columnMetadata && (
                          <EditColumn columnMetadata={members.columnMetadata} getColumns={getColumns} />
                        )}
                      </div>
                    </div>
                  )}
                  <div>
                    {members && members.data && members.data.length > 0 && getMembersFn.isSuccess && (
                      <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden border border-border rounded-[--radius]">
                              <table className="min-w-full divide-y divide-border">
                                <thead className="bg-background">
                                  <tr>
                                    {returnArray(sortedVisibleColumns).map((column, index) => (
                                      <th
                                        key={index}
                                        scope="col"
                                        className={`px-3 py-3.5 text-left text-sm font-semibold  ${
                                          column.sortable ? "cursor-pointer" : ""
                                        }`}
                                        onClick={() => (column.sortable ? handleSetSort(column.key) : void 0)}
                                      >
                                        <span className={"inline-flex items-center"}>
                                          {column.display_name}
                                          {column.sortable && (
                                            <>
                                              {sort === "" ||
                                              (sort !== column.key && sort !== `-${column.key}`) ? (
                                                <Image
                                                  src="/images/icons/dual-arrow.svg"
                                                  width="7"
                                                  height="7"
                                                  className="inline-block ml-1"
                                                />
                                              ) : (
                                                <>
                                                  {sort === column.key && (
                                                    <ArrowDownIcon width={12} className="inline-block ml-1" />
                                                  )}
                                                  {sort === `-${column.key}` && (
                                                    <ArrowUpIcon width={12} className="inline-block ml-1" />
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </span>
                                      </th>
                                    ))}
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold "
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-border bg-background">
                                  {members &&
                                    returnArray(members.data).map((person, index) => (
                                      <tr key={person.id}>
                                        {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                                          const colKey =
                                            columns &&
                                            Object.keys(columns).find(
                                              (key) => columns[key].order === column.order
                                            );

                                          if (colKey === "name") {
                                            return (
                                              <td
                                                key={colIndex}
                                                className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                              >
                                                <div className="flex items-center">
                                                  <div className="h-9 w-9 flex-shrink-0">
                                                    <Image
                                                      nobaseurl
                                                      className="h-9 w-9 rounded-full"
                                                      src={person.profile_photo_url}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="ml-4">
                                                    <div className="font-medium text-paragraph">
                                                      {person.name}
                                                    </div>
                                                    {/* <div className="mt-0 text-gray-500">
                                              {person.email}
                                            </div> */}
                                                  </div>
                                                </div>
                                              </td>
                                            );
                                          }
                                          if (colKey === "role") {
                                            return (
                                              <td
                                                key={colIndex}
                                                className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                              >
                                                {person[colKey]}
                                              </td>
                                            );
                                          }

                                          if (colKey === "group") {
                                            return (
                                              <td
                                                key={colIndex}
                                                className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                              >
                                                {person["groups"] && person["groups"].length > 0 ? (
                                                  <GroupList groups={person["groups"]} />
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                            );
                                          }

                                          if (colKey === "member_since") {
                                            return (
                                              <td
                                                key={colIndex}
                                                className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                              >
                                                <PrintDate date={person.member_since} />
                                              </td>
                                            );
                                          }

                                          return (
                                            <td
                                              key={colIndex}
                                              className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                            >
                                              {person[colKey] ? person[colKey] : "-"}
                                            </td>
                                          );
                                        })}
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                          {doesGroupIdExist(person.groups, groupId) && (
                                            <>
                                              <Button
                                                data-tooltip-id="in_table_actions"
                                                data-tooltip-content={`Remove this member from "${group.name}" group`}
                                                disabled={
                                                  deleteMembersToGroupFn.isLoading &&
                                                  currentMemberId === person.id
                                                }
                                                onClick={() => {
                                                  setCurrentMemberId(person.id);
                                                  setOpenConfirmModal(true);
                                                  setOpenConfirmModalType("delete");
                                                }}
                                                variant="ghost"
                                              >
                                                <Image
                                                  src="/images/icons/untitledui/trash.svg"
                                                  width="16"
                                                  height="16"
                                                  className="inline-block"
                                                />
                                                {deleteMembersToGroupFn.isLoading &&
                                                  currentMemberId === person.id && (
                                                    <div className="loader_circle ml-2"></div>
                                                  )}
                                              </Button>
                                              <Tooltip id="in_table_actions" />
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {getMembersFn.isLoading && <TableLoader />}

                    {getMembersFn.isSuccess &&
                      members &&
                      members.data &&
                      members.data.length === 0 &&
                      !isEmptyObject(filters) && (
                        <div className="p-10 border rounded-lg mt-8">
                          <NoData heading={"No Member found!"} filterMode={true} />
                        </div>
                      )}
                    <div className="flex items-center justify-between mt-2 py-3">
                      <div className="flex-1">
                        {members && members.pagination && members.pagination.total > 0 && (
                          <Pagination data={members.pagination} getPageNumber={getPageNumber} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="usage">
              <div>
                <div className="flex">
                  <div className="w-9/12 mobile:w-full">
                    <div className="flex py-6 items-center mobile:block mobile:space-y-4">
                      <div className="w-4/12 mobile:w-full">
                        <h3 className="font-bold">Current monthly usage</h3>
                        <p className="text-paragraph">Contact reveal credit usage in this month</p>
                      </div>
                      <div className="w-8/12 mobile:w-full">
                        <h3 className="font-bold text-base">Current monthly usage</h3>
                        <p className="text-green-600">48% limit remaining</p>
                      </div>
                    </div>
                    <div className="border-b border-border"></div>
                    <div className="flex py-6 items-center mobile:block mobile:space-y-4">
                      <div className="w-4/12 mobile:w-full">
                        <h3 className="font-bold">Max monthly limit</h3>
                        <p className="text-paragraph">Contact reveal credit usage limit</p>
                      </div>
                      <div className="w-8/12 mobile:w-full">
                        <div className="inline-block">
                          <Input
                            type="number"
                            name="max_monthly_enrichment_credits"
                            id="max_monthly_enrichment_credits"
                            autoComplete="max_monthly_enrichment_credits"
                            placeholder="Ex - 1000"
                            onChange={(e) => {
                              setMaxMonthlyEnrichmentCredits(e.target.value);
                              captureEvent("SET_GroupDetailUsage_MaxMonthlyLimit");
                            }}
                            onClick={() => {
                              captureEvent("SET_GroupDetailUsage_MaxMonthlyLimit");
                            }}
                            defaultValue={group?.preferences?.max_monthly_enrichment_credits}
                          />
                          {maxMonthlyEnrichmentCreditsError && (
                            <p className="text-red-700 text-size-10 mt-2">Please enter max monthly limit</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-4/12 mobile:hidden"></div>
                      <div>
                        <Button
                          disabled={maxMonthlyEnrichmentCreditsError || updateGroupFn.isLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdateGroup();
                            captureEvent("SET_GroupDetailUsage_Save");
                          }}
                        >
                          Save {updateGroupFn.isLoading ? <div className="loader_circle ml-3"></div> : ""}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="access_control">
              <div>
                <div className="flex mobile:block">
                  <div className="w-9/12 mobile:w-full">
                    <div className="flex py-6 items-center mobile:block">
                      <div className="w-4/12 mobile:w-full">
                        <h3 className="font-bold">CSV Enrichment</h3>
                        <p className="text-paragraph">Enable CSV Enrichment for group</p>
                      </div>
                      <div className="w-8/12 mobile:w-full mobile:mt-4">
                        <div className="inline-flex items-center">
                          <Switch
                            checked={csvEnrichmentAllowed}
                            onCheckedChange={(checked) => {
                              setCsvEnrichmentAllowed(checked);
                              handleUpdateGroup(checked ? "true" : "false");
                              captureEvent("SET_GroupDetailAccessControl_CSVEnrichmentBtn");
                            }}
                          />
                          <span className="ml-3">{csvEnrichmentAllowed ? "Enabled" : "Disabled"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>

          <GenericModal isOpen={isOpen2} closeModal={closeModal2} title={"Add Members"} overflow="">
            <AddTeamMembers
              excludeMembers={group.members}
              getSelectedMembers={getSelectedMembers}
              isSubmitting={addMembersToGroupFn.isLoading}
              modal={true}
              closeModal={closeModal2}
            />
          </GenericModal>

          <GenericModal isOpen={isOpen} closeModal={closeModal}>
            <GroupModal
              closeModal={closeModal}
              activeWorkspace={activeWorkspace}
              edit={true}
              data={group}
              refetch={refetchData}
            />
          </GenericModal>
          <ConfirmModal
            openModal={openConfirmModal}
            modalType={openConfirmModalType}
            confirmModalState={confirmModalState}
          />
        </>
      )}
    </>
  );
}
