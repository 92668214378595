import { PlusIcon } from "lucide-react";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRoleCheck from "../../../hooks/useRoleCheck";
import { ReactComponent as BuildingIcon } from "../../../static/media/icons/building-detail-icon.svg";
import { ReactComponent as PersonIcon } from "../../../static/media/icons/person-light-icon.svg";

import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import Text from "../../Tags/Text";
import { Button } from "../../ui/button";
import { Skeleton } from "../../ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import AddCompanyModal from "./AddCompanyModal";
import AddContactModal from "./AddContactModal";
import CompaniesTab from "./CompaniesTab";
import PeopleTab from "./PeopleTab";
import ProspectListBreadcrumbs from "./ProspectListBreadcrumbs.js";

export default function ProspectSearchList() {
  const { activeWorkspace } = useRoleCheck();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const activeTab = query.get("scope") || "people";
  const onHandleTabChange = (value) => navigate(`${location.pathname}?scope=${value}`);
  const { isOpen, openModal, closeModal } = useModal(false);
  const [modalType, setModalType] = useState();
  const [list, setList] = useState();
  const [crumbCurrentPageProps, setCrumbCurrentPageProps] = useState({
    isLoading: true,
    label: "loading...",
    description: "No Description",
  });

  const onHandleChangeCurrentPageProps = useCallback((isLoading, listData) => {
    setCrumbCurrentPageProps({
      isLoading,
      label: listData?.name ?? "N/A",
      description: listData?.description ?? "No Description",
    });
    setList(listData);
  }, []);

  const onHandleOpenModal = (type) => {
    setModalType(type);
    openModal();
  };

  return (
    <div
      className="bg-gray-25 dark:bg-gray-dark-50 -mx-8 -my-8 p-8 border-t border-border"
      style={{ minHeight: "calc(100vh - 72px)" }}
    >
      <div className="space-y-4 relative">
        <ProspectListBreadcrumbs currentPageProps={crumbCurrentPageProps} />
        <div className="flex justify-between items-center">
          <div className="flex-auto mt-2">
            {crumbCurrentPageProps.isLoading ? (
              <Skeleton style={{ height: "24px", width: "300px" }} />
            ) : (
              <Text size="h6">{crumbCurrentPageProps.label}</Text>
            )}
            {crumbCurrentPageProps.isLoading ? (
              <Skeleton style={{ height: "24px", width: "380px" }} className="mt-2" />
            ) : (
              <Text size="md-base" className="mt-1">
                {crumbCurrentPageProps.description}
              </Text>
            )}
          </div>
          <div className="flex flex-col justify-stretch md:flex-row md:justify-center gap-3">
            <Button
              onClick={() => onHandleOpenModal("person")}
              type="button"
              variant="purple_outline"
              className="border-brand-300 dark:border-brand-600 group"
            >
              <PlusIcon className="mr-2" aria-hidden="true" height="20" width="20" />
              Add Person
              <PersonIcon height="20" width="20" className="stroke-brand-600 group-hover:stroke-white ml-2" />
            </Button>
            <Button
              onClick={() => onHandleOpenModal("company")}
              type="button"
              variant="purple_outline"
              className="border-brand-300 dark:border-brand-600 group"
            >
              <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              Add Company
              <BuildingIcon
                height="20"
                width="20"
                className="stroke-brand-600 group-hover:stroke-white ml-2"
              />
            </Button>
          </div>
        </div>
        <Tabs value={activeTab} className="w-full mt-6" onValueChange={onHandleTabChange}>
          <TabsList className="w-full">
            <TabsTrigger value="people">People</TabsTrigger>
            <TabsTrigger value="companies">Companies</TabsTrigger>
          </TabsList>
          {activeWorkspace && activeWorkspace.id && (
            <>
              <TabsContent value="companies" className="py-7">
                <CompaniesTab onHandleChangeCurrentPageProps={onHandleChangeCurrentPageProps} />
              </TabsContent>
              <TabsContent value="people" className="py-7">
                <PeopleTab onHandleChangeCurrentPageProps={onHandleChangeCurrentPageProps} />
              </TabsContent>
            </>
          )}
        </Tabs>
        <GenericModal
          isOpen={isOpen}
          closeModal={closeModal}
          drawer={true}
          loading={crumbCurrentPageProps.isLoading}
        >
          {modalType === "person" ? (
            <AddContactModal
              closeModal={closeModal}
              activeWorkspace={activeWorkspace}
              currentListData={list}
            />
          ) : modalType === "company" ? (
            <AddCompanyModal
              closeModal={closeModal}
              activeWorkspace={activeWorkspace}
              currentListData={list}
            />
          ) : null}
        </GenericModal>
      </div>
    </div>
  );
}
