import React, { useEffect, useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import { cn } from "../../../../lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
import { Button } from "../../../ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../ui/command";

export default function SelectField({
  mapping = [],
  data = [],
  selected = "",
  salesforceObjectRequiredFields = [],
  salesforceObjectNonRequiredFields = [],
  getCurrent,
  type,
}) {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState({});
  useEffect(() => {
    if (mapping && mapping.length > 0 && selected) {
      const obj = mapping.find((obj) => obj.salesforce_field_api_name === selected);
      setCurrent(obj);
      // console.log("current", obj);
    }
  }, [data && data.length && selected, mapping && mapping.length]);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen} className="w-[400px]">
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between h-11 w-[290px] rounded-[8px] font-semibold pr-2.5"
          >
            {/* {value
                              ? frameworks.find((framework) => framework.value === value)?.label
                              : "Save"} */}
            {current && current?.salesforce_field_label && current?.salesforce_field_api_name === selected
              ? current?.salesforce_field_label
              : "--Select--"}
            <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className=" p-0" align="start">
          <Command>
            <div className="p-2">
              <div className="border  rounded-[8px] border-b-0 overflow-hidden">
                <CommandInput placeholder="Search" />
              </div>
            </div>
            <CommandList>
              <CommandEmpty>No List found.</CommandEmpty>
              {salesforceObjectRequiredFields && salesforceObjectRequiredFields.length > 0 && (
                <CommandGroup className="py-0">
                  {salesforceObjectRequiredFields &&
                    salesforceObjectRequiredFields.length > 0 &&
                    salesforceObjectRequiredFields.map((item) => {
                      const newItem = {
                        ...item,
                        value: item.salesforce_field_api_name,
                        label: item.salesforce_field_label,
                        type: type,
                      };

                      return (
                        <CommandItem
                          key={newItem.salesforce_field_api_name}
                          value={newItem.label}
                          onSelect={() => {
                            setCurrent(newItem);
                            getCurrent(newItem);
                            setOpen(false);
                          }}
                          className="min-h-[40px]"
                        >
                          <span className="inline-flex items-center">
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                mapping.find(
                                  (obj) => obj.salesforce_field_api_name === newItem.salesforce_field_api_name
                                )
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            <span>{newItem.label}</span>
                          </span>
                          <span className="text-red-600 ml-2">*</span>
                        </CommandItem>
                      );
                    })}
                </CommandGroup>
              )}
              {salesforceObjectNonRequiredFields && salesforceObjectNonRequiredFields.length > 0 && (
                <CommandGroup>
                  {salesforceObjectNonRequiredFields &&
                    salesforceObjectNonRequiredFields.length > 0 &&
                    salesforceObjectNonRequiredFields.map((item) => {
                      const newItem = {
                        ...item,
                        value: item.salesforce_field_api_name,
                        label: item.salesforce_field_label,
                        type: type,
                      };

                      return (
                        <CommandItem
                          key={newItem.salesforce_field_api_name}
                          value={newItem.label}
                          onSelect={() => {
                            setCurrent(newItem);
                            getCurrent(newItem);
                            setOpen(false);
                          }}
                          className="min-h-[40px]"
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              mapping.find(
                                (obj) => obj.salesforce_field_api_name === newItem.salesforce_field_api_name
                              )
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {newItem.label}
                        </CommandItem>
                      );
                    })}
                </CommandGroup>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
