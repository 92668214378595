import React, { useEffect, useState } from "react";
import Image from "../../../Tags/Image";
import { Skeleton } from "../../../ui/skeleton";
import SearchDropdown from "../../../SearchDropdown";
import { Button } from "../../../ui/button";
import {
  deleteSalesforceObjectsConfig,
  getSalesforceObjectList,
  getSavedSalesforceConfig,
  saveSalesforceObjectsConfig,
  updateSalesforceObjectsConfig,
} from "../../../../apis/workspace/integrations";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function ObjectSettings({
  onSuccess,
  onError,
  currentIntegration = {},
  activeWorkspace,
  isSubmitting,
  isLoading,
  getSavedConfig,
  getCurrentConfig,
  getCompaniesArr,
}) {
  const { salesforceConfigId } = useParams();
  const [objectSettingsArr, setObjectSettingsArr] = useState([
    {
      id: "init_1",
      salesforce_object_api_name: "Account",
      salesforce_object_label: "Account",
      type: "company",
      permission: {
        create: true,
        update: true,
        view: true,
      },
      name: "Company",
    },
    {
      id: "init_2",
      salesforce_object_api_name: "Lead",
      salesforce_object_label: "Lead",
      type: "person",
      permission: {
        create: true,
        update: true,
        view: true,
      },
      name: "People - Lead",
    },
    {
      id: "init_3",
      salesforce_object_api_name: "Contact",
      salesforce_object_label: "Contact",
      type: "person",
      permission: {
        create: true,
        update: true,
        view: true,
      },
      name: "People - Contact",
    },
  ]);

  const [salesforceObjects, setSalesforceObjects] = useState([]);

  // useEffect(() => {
  //   console.log("objectSettingsArr", { objectSettingsArr, currentObject, savedConfig });
  // }, [objectSettingsArr]);

  // get salesforce object list
  const getSalesforceObjectListFn = useMutation("salesforce_object_list", getSalesforceObjectList, {
    onSuccess(data, variables, context) {
      // console.log(data);
      setSalesforceObjects(data);
    },
  });
  const handleGetSalesforceObjectList = () => {
    getSalesforceObjectListFn.mutate({
      salesforceConfigId: salesforceConfigId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id && salesforceConfigId) {
      handleGetSalesforceObjectList();
    }
  }, [activeWorkspace, salesforceConfigId]);

  const [savedConfig, setSavedConfig] = useState([]);
  const [currentConfig, setCurrentConfig] = useState(null);
  const [companiesArr, setCompaniesArr] = useState([]);

  // get salesforce object list
  const getSavedSalesforceConfigFn = useMutation("get_saved_salesforce_object", getSavedSalesforceConfig, {
    onSuccess(data, variables, context) {
      // console.log("savedConfig", data);
      if (data && data.data && data.data.length > 0) {
        const objCompany = data?.data.find((obj) => obj.type === "company");
        const objPeople = data?.data.find((obj) => obj.type === "person");

        const arr = data?.data.filter((obj) => obj.type === "company");
        setCompaniesArr(arr);
        getCompaniesArr(arr);
        console.log("saveed config", data?.data);
        setSavedConfig(data?.data);
        getSavedConfig(data?.data);

        // if (!mapActiveTabApi) {
        //   setMapActiveTabApi(objCompany?.salesforce_object_api_name);
        // }

        setCurrentConfig(objCompany);
        getCurrentConfig(objCompany);

        const sortedArr = data.data
          .sort((a, b) => a.type.localeCompare(b.type)) // Sort by type ('company' first, then 'person')
          .map((item, index, arr) => {
            let name = "";

            if (item.type === "company") {
              name = "Company";
            } else if (item.type === "person") {
              const personIndex = arr.slice(0, index).filter((obj) => obj.type === "person").length;

              if (personIndex === 0) {
                name = "People - Lead";
              } else if (personIndex === 1) {
                name = "People - Contact";
              } else {
                name = "People";
              }
            }

            return {
              id: item.id,
              salesforce_object_api_name: item.salesforce_object_api_name,
              salesforce_object_label: item.salesforce_object_label,
              type: item.type,
              permission: item.permission,
              name: name, // Add the name key with the calculated value
            };
          });

        const remainingArr = [];
        objectSettingsArr.forEach((item) => {
          const obj = sortedArr.find((el) => el.name === item.name);
          if (!obj) {
            remainingArr.push({ ...item, reinit: true });
          }
        });
        const finalArr = [...sortedArr, ...remainingArr];
        finalArr.sort((a, b) => a.type.localeCompare(b.type));
        setObjectSettingsArr(finalArr);
      }
    },
  });

  const handleGetSavedSalesforceConfig = () => {
    getSavedSalesforceConfigFn.mutate({
      salesforceConfigId: salesforceConfigId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id && salesforceConfigId) {
      handleGetSavedSalesforceConfig();
    }
  }, [activeWorkspace, salesforceConfigId]);

  const [currentObject, setCurrentObject] = useState(null);
  const [currentObjectConfigId, setCurrentObjectConfigId] = useState(null);
  const saveSalesforceObjectsConfigFn = useMutation(
    "save_salesforce_object_config",
    saveSalesforceObjectsConfig,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);
        setCurrentObjectConfigId(null);
        handleGetSavedSalesforceConfig();
      },
      onError(error) {
        setCurrentObjectConfigId(null);
      },
    }
  );

  const initSaveSalesforceObjectsConfigFn = useMutation(
    "init_save_salesforce_object_config",
    saveSalesforceObjectsConfig,
    {
      onSuccess(data, variables, context) {
        handleGetSavedSalesforceConfig();
      },
      onError(error) {},
    }
  );

  const handleSaveSalesforceObjectsConfig = (objectConfigId, remove = false) => {
    setCurrentObjectConfigId(objectConfigId);

    if (remove) {
      deleteSalesforceObjectsConfigFn.mutate({
        salesforceConfigId: salesforceConfigId,
        objectId: objectConfigId,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    }

    let obj = {};
    const arr = objectSettingsArr.filter((item) => item.id === objectConfigId);
    obj = arr && arr.length > 0 && arr[0];

    const payload = {
      salesforce_object_api_name: obj?.salesforce_object_api_name,
      salesforce_object_label: obj?.salesforce_object_label,
      type: obj?.type,
      permission: obj?.permission,
    };

    if (obj?.salesforce_object_api_name) {
      saveSalesforceObjectsConfigFn.mutate({
        salesforceConfigId: salesforceConfigId,
        data: { ...payload },
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    }
  };

  // delete salesforce object config
  const deleteSalesforceObjectsConfigFn = useMutation(
    "delete_salesforce_object_config",
    deleteSalesforceObjectsConfig,
    {
      onSuccess(data, variables, context) {
        if (currentObject && currentObject.salesforce_object_api_name === "") {
          toast.success("Salesforce object unmapped successfully");
          setCurrentObject({});
        }
      },
    }
  );

  const getCurrent = (obj, id) => {
    setCurrentObject({ ...obj, id: id });
    setObjectSettingsArr((prevState) =>
      prevState.map((item) =>
        item.id === id
          ? {
              ...item,
              salesforce_object_api_name: obj.salesforce_object_api_name,
              salesforce_object_label: obj.salesforce_object_label,
            }
          : item
      )
    );
  };

  const updatePermission = (event, id, obj, permissionType) => {
    const { checked } = event.target;
    setCurrentObject({
      ...obj,
      id: id,
      permission: {
        ...obj.permission,
        [permissionType]: checked,
      },
    });
    setObjectSettingsArr((prevState) =>
      prevState.map((item) =>
        item.id === id
          ? {
              ...item,
              permission: {
                ...item.permission,
                [permissionType]: checked,
              },
            }
          : item
      )
    );
  };

  // send default payload for object settings

  useEffect(() => {
    if (
      !getSavedSalesforceConfigFn.isLoading &&
      savedConfig.length === 0 &&
      !getSalesforceObjectListFn.isLoading &&
      salesforceObjects.length > 0
    ) {
      const accountObject = salesforceObjects.find((item) => item.salesforce_object_api_name === "Account");
      const leadObject = salesforceObjects.find((item) => item.salesforce_object_api_name === "Lead");
      const contactObject = salesforceObjects.find((item) => item.salesforce_object_api_name === "Contact");

      let accountObjectPayload = {};
      let leadObjectPayload = {};
      let contactObjectPayload = {};

      const saveObjects = (payload) => {
        initSaveSalesforceObjectsConfigFn.mutate({
          salesforceConfigId: salesforceConfigId,
          data: { ...payload },
          options: {
            headers: { "X-Workspace-Id": activeWorkspace?.id },
          },
        });
      };

      if (accountObject && accountObject.salesforce_object_api_name) {
        accountObjectPayload = {
          salesforce_object_api_name: accountObject?.salesforce_object_api_name,
          salesforce_object_label: accountObject?.salesforce_object_label,
          type: "company",
          permission: {
            create: true,
            update: true,
            view: true,
          },
        };
        saveObjects(accountObjectPayload);
      }
      if (leadObject && leadObject.salesforce_object_api_name) {
        leadObjectPayload = {
          salesforce_object_api_name: leadObject?.salesforce_object_api_name,
          salesforce_object_label: leadObject?.salesforce_object_label,
          type: "person",
          permission: {
            create: true,
            update: true,
            view: true,
          },
        };
        saveObjects(leadObjectPayload);
      }
      if (contactObject && contactObject.salesforce_object_api_name) {
        contactObjectPayload = {
          salesforce_object_api_name: contactObject?.salesforce_object_api_name,
          salesforce_object_label: contactObject?.salesforce_object_label,
          type: "person",
          permission: {
            create: true,
            update: true,
            view: true,
          },
        };
        saveObjects(contactObjectPayload);
      }
    }
  }, [
    savedConfig,
    getSavedSalesforceConfigFn.isLoading,
    salesforceObjects,
    getSalesforceObjectListFn.isLoading,
  ]);

  const shouldMapButtonBeDisabled = (item) => {
    const existingObj = savedConfig.find(
      (obj) => obj.salesforce_object_api_name === item.salesforce_object_api_name
    );

    if (
      currentObject &&
      existingObj &&
      currentObject.permission === existingObj.permission &&
      currentObject.id === item.id
    ) {
      return true;
    } else if (
      currentObject &&
      existingObj &&
      currentObject.permission !== existingObj.permission &&
      currentObject.id === item.id
    ) {
      return false;
    } else if (saveSalesforceObjectsConfigFn.isLoading && item.id === currentObjectConfigId) {
      return true;
    } else if (getSalesforceObjectListFn.isLoading) {
      return true;
    } else if (existingObj) {
      return true;
    } else {
      false;
    }
  };

  const updateSalesforceObjectsConfigFn = useMutation(
    "update_salesforce_object_config",
    updateSalesforceObjectsConfig,
    {
      onSuccess(data, variables, context) {
        handleGetSavedSalesforceConfig();
        toast.success("Salesforce object updated successfully");
      },
      onError(error) {},
    }
  );

  const handleUpdateSalesforceObjectsConfig = (id, payload) => {
    updateSalesforceObjectsConfigFn.mutate({
      salesforceConfigId: salesforceConfigId,
      objectId: id,
      data: { ...payload },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  return (
    <>
      <div className="space-y-4">
        {objectSettingsArr.map((item) => (
          <div
            key={item.value}
            className="w-full rounded-[--radius] border border-input bg-background text-text-900 py-4 px-6"
          >
            <div className="flex justify-between">
              <div className="inline-flex items-center">
                <span className="text-lg font-bold capitalize">{item.name}</span>
              </div>
            </div>
            <div className="pt-6 px-6 border-t flex items-center mt-4 mb-2 -mx-6">
              <Image src="/images/logos/integrations/salesforce.svg" width={24} className="no-invert" />
              <span className="text-[14px] text-paragraph leading-relaxed inline-flex ml-2">
                Salesforce Object
              </span>

              <div className="ml-6">
                {getSalesforceObjectListFn.isLoading ? (
                  <Skeleton className={"rounded-lg h-11 w-[290px]"} />
                ) : (
                  <SearchDropdown
                    data={salesforceObjects}
                    selected={!item.reinit && item.salesforce_object_api_name}
                    getCurrent={(obj) => {
                      getCurrent(obj, item.id);
                    }}
                    type={item.type}
                  />
                )}
              </div>

              <Button
                className="ml-2"
                disabled={shouldMapButtonBeDisabled(item)}
                onClick={() => {
                  const objInit =
                    savedConfig &&
                    savedConfig.length > 0 &&
                    savedConfig.find(
                      (obj) => obj.salesforce_object_api_name === item.salesforce_object_api_name
                    );
                  const objUpdated =
                    objectSettingsArr &&
                    objectSettingsArr.length > 0 &&
                    objectSettingsArr.find(
                      (obj) => obj.salesforce_object_api_name === item.salesforce_object_api_name
                    );
                  if (objInit && objUpdated && objInit.permission !== objUpdated.permission) {
                    handleUpdateSalesforceObjectsConfig(objUpdated.id, {
                      salesforce_object_api_name: objUpdated?.salesforce_object_api_name,
                      salesforce_object_label: objUpdated?.salesforce_object_label,
                      type: objUpdated?.type,
                      permission: objUpdated?.permission,
                    });
                  } else {
                    if (item.id.includes("init")) {
                      handleSaveSalesforceObjectsConfig(item.id);
                    } else {
                      handleSaveSalesforceObjectsConfig(item.id, true);
                    }
                  }
                }}
              >
                Map
                {saveSalesforceObjectsConfigFn.isLoading && item.id === currentObjectConfigId && (
                  <div className="loader_circle inline-block ml-1"></div>
                )}
              </Button>
            </div>
            <div className="">
              <div className="border-t my-6"></div>
            </div>
            <div>
              <span className="text-base font-bold">Manage Permissions</span>
              <div className="flex space-x-3 items-center mt-4">
                <input
                  checked={item.permission?.create}
                  type="checkbox"
                  className="form-checkbox text-brand-700 rounded cursor-pointer dark:text-brand-200"
                  onChange={(e) => {
                    updatePermission(e, item.id, item, "create");
                  }}
                />{" "}
                <span className="text-[14px] text-paragraph leading-relaxed">Create records</span>
              </div>
              <div className="flex space-x-3 items-center mt-4">
                <input
                  checked={item.permission?.update}
                  type="checkbox"
                  className="form-checkbox text-brand-700 rounded cursor-pointer dark:text-brand-200"
                  onChange={(e) => {
                    updatePermission(e, item.id, item, "update");
                  }}
                />{" "}
                <span className="text-[14px] text-paragraph leading-relaxed">Update records</span>
              </div>
              <div className="flex space-x-3  items-center mt-4">
                <input
                  checked
                  type="checkbox"
                  className="form-checkbox text-gray-iron-300 rounded cursor-not-allowed dark:text-brand-200"
                  disabled
                />{" "}
                <span className="text-[14px] text-paragraph leading-relaxed">View records</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
