import { useState, useEffect, useMemo } from "react";
import posthog from "posthog-js";

const useFeatureFlag = (flagName) => {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (!flagName) return;

    const checkFeature = () => {
      setIsEnabled(posthog.isFeatureEnabled(flagName));
    };

    checkFeature();

    const unsubscribe = posthog.onFeatureFlags(() => {
        checkFeature();
      });
    return () => {
        unsubscribe();
    };
  }, [flagName]);

  return useMemo(() => isEnabled, [isEnabled]);
};

export default useFeatureFlag;
