import React, { useEffect, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
import { cn } from "../../../../lib/utils";
import { Button } from "../../../ui/button";
import { Check, ChevronDown } from "lucide-react";

export default function UserSelect({ data = [], selected = "", getCurrent }) {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState({});
  useEffect(() => {
    if (data && data.length > 0 && selected) {
      const obj = data.find((obj) => obj.salesforce_user_id === selected);
      setCurrent(obj);
      // console.log("current", obj);
    }
  }, [data && data.length && selected]);
  return (
    <Popover open={open} onOpenChange={setOpen} className="w-[400px]">
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between h-11 w-[290px] rounded-[8px] font-semibold pr-2.5"
        >
          {current && current?.salesforce_user_name ? current?.salesforce_user_name : "--Select--"}
          <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className=" p-0" align="start">
        <Command>
          <div className="p-2">
            <div className="border  rounded-[8px] border-b-0 overflow-hidden">
              <CommandInput placeholder="Search" />
            </div>
          </div>
          <CommandList>
            <CommandEmpty>No List found.</CommandEmpty>
            <CommandGroup>
              {data &&
                data.length > 0 &&
                data.map((item) => {
                  const newItem = {
                    ...item,
                    value: item.salesforce_user_id,
                    label: item.salesforce_user_name,
                  };

                  return (
                    <CommandItem
                      key={newItem.salesforce_user_id}
                      value={newItem.label}
                      onSelect={() => {
                        if (newItem.salesforce_user_id === current?.salesforce_user_id) {
                          setCurrent({
                            ...newItem,
                            salesforce_user_id: "",
                            salesforce_user_name: "",
                          });
                          getCurrent({
                            ...newItem,
                            salesforce_user_id: "",
                            salesforce_user_name: "",
                          });
                          setOpen(false);
                        } else {
                          setCurrent(newItem);
                          getCurrent(newItem);
                          setOpen(false);
                        }
                      }}
                      className="min-h-[40px]"
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          current?.salesforce_user_id === newItem.salesforce_user_id
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {newItem.label}
                    </CommandItem>
                  );
                })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
