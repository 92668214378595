import { ChevronLeftIcon, MinusCircleIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { isEmptyObject } from "../../utils/GenericFn";
import { useLocation } from "react-router-dom";
import useUpdateQuery from "../../hooks/useUpdateQuery";
import useClickOutside from "../../hooks/useClickOutside";
import { Cross, CrossIcon, LucideXCircle, PlusIcon, XCircle } from "lucide-react";
import { Button } from "../ui/button";
import Input from "../Tags/Input";
import Image from "../Tags/Image";

const formatScopeForDisplay = (scope, type = null) => {
  if (scope.includes("_condition_")) {
    const extracted = scope.split("_condition_")[1];
    return extracted
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return type === "text" ? "Contains" : "";
  }
};

const FilterDropdown = ({
  setFilterValuesForDropdown,
  filterKey,
  type,
  scopes,
  options,
  displayName,
  setOpenFilter,
  filterValues = {},
}) => {
  const [selectedScope, setSelectedScope] = useState(
    filterValues.selectedScope || (type === "text" ? filterKey : scopes ? scopes[0] : "")
  );
  const [inputValue, setInputValue] = useState(filterValues.inputValue || "");
  const [inputValue2, setInputValue2] = useState(filterValues.inputValue2 || "");
  const [selectedOptions, setSelectedOptions] = useState(filterValues.selectedOptions || {});

  const handleScopeChange = (e) => {
    const newScope = e.target.value;
    setSelectedScope(newScope);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  // Similarly update other handlers:
  const handleInputChange2 = (e) => {
    const newValue = e.target.value;
    setInputValue2(newValue);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const newSelectedOptions = { ...selectedOptions, [value]: checked };
    setSelectedOptions(newSelectedOptions);
    setSelectedScope(filterKey);
  };

  const handleApply = () => {
    const appliedFilters = {
      selectedScope,
      inputValue,
      inputValue2,
      selectedOptions,
    };

    setFilterValuesForDropdown(filterKey, appliedFilters);
    setOpenFilter("");
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleApply();
    }
  };

  return (
    <div className="bg-background p-4 rounded shadow-lg mt-3 absolute top-[100%] left-0 w-[250px] z-10">
      {type === "text" && (
        <div className="space-y-2">
          {scopes && scopes.length > 0 ? (
            <select
              className="h-11 px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
              value={selectedScope}
              onChange={handleScopeChange}
            >
              <option key={`${filterKey}`} value={`${filterKey}`}>
                Contains
              </option>
              {scopes.map((scope) => (
                <option key={scope} value={scope}>
                  {formatScopeForDisplay(scope)}
                </option>
              ))}
            </select>
          ) : (
            <label>Contains:</label>
          )}
          <Input
            className="block w-full p-2 rounded border"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={``}
            onKeyDown={handleEnterKeyPress}
          />
        </div>
      )}

      {type === "date" && (
        <div className="space-y-2">
          <select
            className="h-11 px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
            value={selectedScope}
            onChange={handleScopeChange}
          >
            {scopes.map((scope) => (
              <option key={scope} value={scope}>
                {formatScopeForDisplay(scope)}
              </option>
            ))}
          </select>
          <Input
            className="block w-full p-2 rounded border"
            type="date"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleEnterKeyPress}
          />
          {selectedScope === `${filterKey}_condition_between` && (
            <Input
              className="block w-full p-2 rounded border mt-2"
              type="date"
              value={inputValue2}
              onChange={handleInputChange2}
              onKeyDown={handleEnterKeyPress}
            />
          )}
        </div>
      )}

      {type === "select" && (
        <div className="space-y-2">
          {Array.isArray(options)
            ? options.map((option) => (
                <label key={option} className="flex items-center space-x-2 text-xs">
                  <input
                    className="form-checkbox text-brand-700 rounded"
                    type="checkbox"
                    value={option}
                    checked={selectedOptions[option] || false}
                    onChange={handleCheckboxChange}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <span>{option}</span>
                </label>
              ))
            : Object.keys(options).map((optionKey) => (
                <label key={optionKey} className="flex items-center space-x-2 text-xs">
                  <input
                    className="form-checkbox text-brand-700 rounded dark:text-brand-200"
                    type="checkbox"
                    value={optionKey}
                    checked={selectedOptions[optionKey] || false}
                    onChange={handleCheckboxChange}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <span>{options[optionKey]}</span>
                </label>
              ))}
        </div>
      )}

      <Button
        className="w-full mt-3"
        onClick={(e) => {
          e.preventDefault();
          handleApply();
        }}
      >
        Apply
      </Button>
    </div>
  );
};

const Filter = ({ filterMetadata, getFiltersObject, defaultFilterCount = 20 }) => {
  const setQueryString = useUpdateQuery();
  const [openFilter, setOpenFilter] = useState("");
  const [showSecondaryFilters, setShowSecondaryFilters] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const toggleDropdown = (filterKey) => {
    if (openFilter === filterKey) {
      setOpenFilter("");
    } else {
      setOpenFilter(filterKey);
    }
    if (openFilterMore) {
      setOpenFilterMore(false);
      setFilterMoreKey("");
    }
  };

  const [openFilterMore, setOpenFilterMore] = useState("");
  const [filterMoreKey, setFilterMoreKey] = useState("");
  const toggleDropdownMore = (filterKey) => {
    setFilterMoreKey(filterKey);
    if (openFilterMore === filterKey) {
      setOpenFilterMore("");
    } else {
      setOpenFilterMore(filterKey);
    }
    if (filterKey !== "") {
      setOpenFilter(false);
    }
  };

  const formatFiltersForApi = () => {
    let formattedFilters = {};

    Object.entries(filterValues).forEach(([key, values]) => {
      const filterData = filterMetadata[key]; // extract data from filterMetadata
      let formattedData = {
        displayName: filterData.display_name,
        type: filterData.type,
        selectedScope: values.selectedScope || "", // Capture the selected scope from filterValues
      };

      switch (filterData.type) {
        case "text":
          formattedData.value = values.inputValue || "";
          break;

        case "date":
          formattedData.value = values.inputValue || "";
          if (values.selectedScope === `${key}_condition_between` && values.inputValue2) {
            formattedData.value += `|${values.inputValue2}`;
          }
          break;

        case "select":
          formattedData.value = Object.keys(values.selectedOptions || {})
            .filter((optionKey) => values.selectedOptions[optionKey])
            .join(", ");
          break;

        default:
          break;
      }

      formattedFilters[key] = formattedData;
    });

    return formattedFilters;
  };

  const [filtersToSubmit, setFiltersToSubmit] = useState({});

  useEffect(() => {
    setFiltersToSubmit(formatFiltersForApi());
  }, [filterValues]);
  useEffect(() => {
    getFiltersObject(filtersToSubmit);
  }, [filtersToSubmit]);

  const constructResult = (locationSearch, metadata) => {
    // Remove '?' prefix if it exists
    const query = decodeURIComponent(locationSearch).replace(/^\?/, "");
    // Only consider 'filter[*]' parameters
    const entries = query.split("&").filter((entry) => entry.startsWith("filter["));

    let result = {};

    for (let entry of entries) {
      const [key, value] = entry.split("=");
      const innerKey = key.slice(7, -1); // Removes 'filter[' prefix and ']' suffix
      const mainKey = innerKey.split("_condition_")[0]; // Get the main key before any '_condition_'
      const conditionKey = innerKey.split("_condition_")[1]; // Get the condition key after '_condition_'

      if (metadata[mainKey]) {
        if (!result[mainKey]) {
          result[mainKey] = {
            inputValue: "",
            inputValue2: "",
            selectedOptions: {},
            selectedScope: "",
          };
        }

        result[mainKey].selectedScope = innerKey;

        switch (metadata[mainKey].type) {
          case "text":
          case "date":
            if (conditionKey === "between") {
              const [firstValue, secondValue] = value.split("|");
              result[mainKey].inputValue = firstValue;
              result[mainKey].inputValue2 = secondValue || "";
            } else {
              result[mainKey].inputValue = value;
            }
            break;
          case "select":
            const options = value.split("|");
            for (let option of options) {
              result[mainKey].selectedOptions[option] = true;
            }
            break;
          default:
            break;
        }
      }
    }

    return result;
  };

  const location = useLocation();

  // handle filter query
  useEffect(() => {
    if (filterMetadata && location.search) {
      const finalResult = constructResult(location.search, filterMetadata);
      setFilterValues(finalResult);
      //console.log("finalResult", finalResult);
    }
  }, [filterMetadata]);

  useClickOutside("filter_dropdown", [setOpenFilter]);
  useClickOutside("filter_dropdown", [setOpenFilterMore]);

  const getSelectedLabels = (selectedValues, options) => {
    const selectedKeys = selectedValues.split(",").map((key) => key.trim());
    const selectedLabels = selectedKeys.filter((key) => key in options).map((key) => options[key]);

    return selectedLabels.join(", ");
  };

  // handle more filters and default filter limit
  // Filter the filters that have values
  const filtersWithValues = Object.entries(filterMetadata)
    .filter(([key]) => filtersToSubmit[key] && filtersToSubmit[key].value)
    .map(([key]) => key);

  // Determine the number of filters to show (default + filters with values)
  const filtersToShowCount = Math.min(
    defaultFilterCount + filtersWithValues.length,
    Object.entries(filterMetadata).length
  );

  // Get the initially shown default filters
  const defaultFilters = Object.entries(filterMetadata)
    .filter(([key, data]) => data.primary && filtersToShowCount > 0)
    .slice(0, defaultFilterCount);

  // Include default filters in filtersToDisplay, if not already applied
  const filtersToDisplayArray = [
    ...defaultFilters
      .filter(([key]) => !filtersWithValues.some(([valueKey]) => valueKey === key))
      .map(([key]) => key),
    ...filtersWithValues,
  ];
  const filtersToDisplay = [...new Set(filtersToDisplayArray)];

  // Determine the filters to show in "more filters" section
  const filtersToShowInMoreFilters = Object.entries(filterMetadata).filter(
    ([key, data]) =>
      !filtersWithValues.includes(key) && // Exclude filters that are already in filtersWithValues
      !defaultFilters.some(([defaultKey]) => defaultKey === key) && // Exclude filters that are in defaultFilters
      (!filtersToSubmit[key] || !filtersToSubmit[key].value) // Exclude filters that have a value in filtersToSubmit
  );

  const allFiltersDisplayed = filtersToDisplay.length === Object.entries(filterMetadata).length;

  return (
    <div className="space-x-2 inline-flex items-center">
      {Object.entries(filterMetadata).map(([key, data]) => {
        const hasValues = filtersToSubmit[key] && filtersToSubmit[key].value;
        if (filtersToDisplay.includes(key)) {
          return (
            <div key={key} className={`relative inline-block filter_dropdown filter__${key}`}>
              <span
                onClick={() => toggleDropdown(key)}
                className={`cursor-pointer inline-flex items-center rounded-[--radius] bg-gray-50/2 px-2 py-2 text-xs  font-medium border  box-border border-border bg-background whitespace-pre`}
              >
                {hasValues ? (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFilterValues((prev) => ({ ...prev, [key]: {} }));
                      setOpenFilter("");
                    }}
                    className="mr-1"
                  >
                    <Image src="/images/icons/untitledui/close.svg" width="16" height="18" />
                  </span>
                ) : (
                  <span className="group relative mr-1 h-4 w-4 rounded-sm">
                    {/* <PlusIcon width={16} height={16} /> */}
                    <Image src="/images/icons/untitledui/filter.svg" width="16" height="18" />
                  </span>
                )}
                {data.display_name}
                {hasValues && filtersToSubmit[key].type === "text" ? " | " : ""}
                {hasValues ? (
                  <span className="text-brand-700 ml-1">
                    {" "}
                    {filtersToSubmit[key].selectedScope && (
                      <>
                        {formatScopeForDisplay(filtersToSubmit[key].selectedScope, filtersToSubmit[key].type)}{" "}
                      </>
                    )}{" "}
                    |{" "}
                    {filtersToSubmit[key].type === "select" ? (
                      <>
                        {Array.isArray(data.options) ? (
                          filtersToSubmit[key].value
                        ) : (
                          <>{getSelectedLabels(filtersToSubmit[key].value, data.options)}</>
                        )}
                      </>
                    ) : (
                      filtersToSubmit[key].value
                    )}{" "}
                  </span>
                ) : (
                  ""
                )}
              </span>

              {openFilter === key && (
                <FilterDropdown
                  filterKey={key}
                  type={data.type}
                  scopes={data.scopes}
                  options={data.options}
                  displayName={data.display_name}
                  setOpenFilter={setOpenFilter}
                  filterValues={filterValues[key] || {}}
                  setFilterValuesForDropdown={(dropdownKey, values) => {
                    setFilterValues((prev) => ({
                      ...prev,
                      [dropdownKey]: values,
                    }));
                  }}
                />
              )}
            </div>
          );
        }
      })}

      {!allFiltersDisplayed && (
        <div className={`relative inline-block filter_dropdown filter__more`}>
          <span
            onClick={() => toggleDropdown("more_filter")}
            className="inline-flex items-center rounded-[--radius] bg-background px-2 py-2 text-xs font-medium  border border-border box-border cursor-pointer whitespace-pre"
          >
            <PlusIcon width={16} height={16} className="mr-1" />
            More Filters
          </span>
          {openFilterMore === filterMoreKey &&
            filterMetadata[filterMoreKey] &&
            filterMetadata[filterMoreKey].type &&
            filterMetadata[filterMoreKey].type !== "" && (
              <div className="absolute top-[100%] left-0 ">
                <span className="font-semibold flex items-center -mb-6 relative z-20 px-4 py-2  w-[250px] shadow-inner bg-background">
                  <button
                    onClick={() => {
                      toggleDropdown("more_filter");
                      setOpenFilterMore("");
                    }}
                    className="border p-1 rounded mr-2"
                  >
                    <ChevronLeftIcon height={20} width={20} />
                  </button>{" "}
                  Filter by: {filterMetadata[filterMoreKey].display_name}
                </span>
                <FilterDropdown
                  filterKey={filterMoreKey}
                  type={filterMetadata[filterMoreKey].type}
                  scopes={filterMetadata[filterMoreKey].scopes}
                  options={filterMetadata[filterMoreKey].options}
                  displayName={filterMetadata[filterMoreKey].display_name}
                  setOpenFilter={setOpenFilterMore}
                  filterValues={filterValues[filterMoreKey] || {}}
                  setFilterValuesForDropdown={(dropdownKey, values) => {
                    setFilterValues((prev) => ({
                      ...prev,
                      [dropdownKey]: values,
                    }));
                  }}
                />
              </div>
            )}

          {openFilter === "more_filter" && (
            <div className="bg-background p-4 py-2 rounded shadow-lg mt-2 absolute top-[100%] left-0 w-[250px] z-10">
              {filtersToShowInMoreFilters.map(([key, data]) => {
                return (
                  <div key={key} className={`relative my-2 block filter_dropdown filter__${key}`}>
                    <span
                      onClick={() => toggleDropdownMore(key)}
                      className="cursor-pointer inline-flex items-center gap-x-0.5  text-[12px]  font-medium text-brand-700"
                    >
                      <span className="group relative mr-1 h-4 w-4 rounded-sm">
                        {/* <PlusIcon width={16} height={16} /> */}
                        <Image src="/images/icons/untitledui/filter.svg" width="16" height="18" />
                      </span>
                      {data.display_name}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {filtersWithValues && filtersWithValues.length > 0 && (
        <span
          className="text-brand-700 cursor-pointer"
          onClick={() => {
            setFilterValues({});
            setQueryString("");
          }}
        >
          Clear Filters
        </span>
      )}
    </div>
  );
};

export default Filter;
