export const integrationFormsMetaData = {
  // zoom_info: {
  //   display_name: "Zoom Info",
  //   fields: [
  //     {
  //       name: "name",
  //       type: "text",
  //       label: "Connection Name",
  //       placeholder: "Connection Name",
  //       required: true,
  //     },
  //     {
  //       name: "username",
  //       type: "text",
  //       label: "ZoomInfo Username",
  //       placeholder: "ZoomInfo Username",
  //       required: true,
  //     },
  //     {
  //       name: "password",
  //       type: "password",
  //       label: "ZoomInfo Password",
  //       placeholder: "ZoomInfo Password",
  //       required: true,
  //     },
  //   ],
  // },
  lusha: {
    display_name: "Lusha",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "Lusha API Key",
        placeholder: "Lusha API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Contact your Lusha account manager to ask for an API Key and enter it
        here. Note: An annual contract is required to obtain a Lusha API key.
      </span>
    ),
  },
  apollo: {
    display_name: "Apollo",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "Apollo API Key",
        placeholder: "Apollo API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Get Apollo API Key from{" "}
        <a href="https://developer.apollo.io/keys/" target="_blank">
          https://developer.apollo.io/keys/
        </a>
      </span>
    ),
  },
  cognism: {
    display_name: "Cognism",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiToken",
        type: "password",
        label: "Cognism API Token",
        placeholder: "Cognism API Token",
        required: true,
      },
    ],
    info: (
      <span>
        Learn how to get Cognism API Token:&nbsp;
        <a
          href="https://help.cognism.com/hc/en-gb/articles/4444293398802-How-to-generate-a-new-API-Token"
          target="_blank"
        >
          https://help.cognism.com/hc/en-gb/articles/4444293398802-How-to-generate-a-new-API-Token
        </a>
      </span>
    ),
  },
  explorium: {
    display_name: "Explorium",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "Explorium API Key",
        placeholder: "Explorium API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Get API Key:&nbsp;
        <a
          href="https://app.explorium.ai/api-usage"
          target="_blank"
        >
          https://app.explorium.ai/api-usage
        </a>
      </span>
    ),
  },
  full_contact: {
    display_name: "Full Contact",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "Full Contact API Key",
        placeholder: "Full Contact API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Get API Key:&nbsp;
        <a
          href="https://www.fullcontact.com/contact"
          target="_blank"
        >
          https://www.fullcontact.com/contact
        </a>
      </span>
    ),
  },
  wiza: {
    display_name: "Wiza",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "Full Contact API Key",
        placeholder: "Full Contact API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Get API Key:&nbsp;
        <a
          href="https://help.wiza.co/en/articles/8392662-wiza-s-api-everything-you-need-to-know"
          target="_blank"
        >
          https://help.wiza.co/en/articles/8392662-wiza-s-api-everything-you-need-to-know
        </a>
      </span>
    ),
  },
  clearout: {
    display_name: "Clearout",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "Clearout API Key",
        placeholder: "Clearout API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Get Clearout API Key:&nbsp;
        <a
          href="https://app.clearout.io/dashboard/apps"
          target="_blank"
        >
          https://app.clearout.io/dashboard/apps
        </a>
      </span>
    ),
  },
  zerobounce: {
    display_name: "ZeroBounce",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "ZeroBounce API Key",
        placeholder: "ZeroBounce API Key",
        required: true,
      },
    ],
    info: (
      <span>
        How to get ZeroBounce API Key:&nbsp;
        <a
          href="https://www.zerobounce.net/docs/api-dashboard/key-management/"
          target="_blank"
        >
          https://www.zerobounce.net/docs/api-dashboard/key-management/
        </a>
      </span>
    ),
  },
  clearbit_enrichment: {
    display_name: "Clearbit Enrichment",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "Clearbit Enrichment API Key",
        placeholder: "Clearbit Enrichment API Key",
        required: true,
      },
    ],
  },
  get_prospect: {
    display_name: "GetProspect",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "GetProspect API Key",
        placeholder: "GetProspect API Key",
        required: true,
      },
    ],
  },
  peopledatalabs: {
    display_name: "People Data Labs",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "People Data Labs API Key",
        placeholder: "People Data Labs API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Get People Data Labs API Key:&nbsp;
        <a
          href="https://dashboard.peopledatalabs.com/api-keys"
          target="_blank"
        >
          https://dashboard.peopledatalabs.com/api-keys
        </a>
      </span>
    ),
  },
  easyleadz: {
    display_name: "EasyLeadz",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
      {
        name: "apiKey",
        type: "password",
        label: "EasyLeadz API Key",
        placeholder: "EasyLeadz API Key",
        required: true,
      },
    ],
    info: (
      <span>
        Contact your EasyLeadz account manager to ask for an API Key and enter it here.
      </span>
    ),
  },
  advanced_phone_verification: {
    display_name: "PrimeVerify",
    multiple_connections: false,
    editable_connections: false,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Connection Name",
        placeholder: "Connection Name",
        required: true,
      },
    ],
  },
};
