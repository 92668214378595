import React, { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { getMembers } from "../../../apis/workspace/members";
import { useMutation } from "react-query";
import SharedDataContext from "../../../SharedDataContext";
import { Button } from "../../ui/button";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
// Debounce function
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const excludeObjects = (source, exclude) => {
  // Create a Set containing all the IDs from exclude for quick lookup
  const idsInExclude = new Set(exclude.map((item) => item.id));

  // Filter out objects from arr1 whose value exists in the idsInExclude Set
  const filteredArr = source.filter((item) => !idsInExclude.has(item.value));

  return filteredArr;
};

const AddTeamMembers = ({
  excludeMembers,
  getSelectedMembers,
  isSubmitting,
  modal = false,
  closeModal = () => javascript.void(0),
}) => {
  // autocomplete members
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};
  const [members, setMembers] = useState({});
  const [autocompleteMembers, setAutocompleteMembers] = useState({});
  const [autocompleteFilterQuery, setAutocompleteFilterQuery] = useState("");

  const [tagOptions, setTagOptions] = useState([]);

  const getAutoCompleteMembersFn = useMutation("get_members", getMembers, {
    onSuccess(data, variables, context) {
      setAutocompleteMembers((prevMembers) => data);
      const arr = data.data.map(({ id, name, email }) => ({
        id,
        name,
        email,
      }));
      setMembers(arr);
      let tags = arr.map((member) => ({
        value: member.id,
        label: `${member.name} (${member.email})`,
      }));
      setTagOptions(excludeObjects(tags, excludeMembers));
    },
  });
  const handleGetAutoCompleteMembersFn = () => {
    getAutoCompleteMembersFn.mutate({
      query: autocompleteFilterQuery,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetAutoCompleteMembersFn();
    }
  }, [activeWorkspace, autocompleteFilterQuery, excludeMembers]);

  const [selectedTags, setSelectedTags] = useState([]);

  const debouncedApiCall = debounce((query) => {
    // Call your API here with the query
    //console.log(`API called with query: ${query}`);
    setAutocompleteFilterQuery(`filter[name]=${query}`);
  }, 500);

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
    setAutocompleteFilterQuery("");
  };

  const customFilterOption = (option, rawInput) => {
    const input = rawInput.toLowerCase();
    const label = option.label.toLowerCase();
    return label.includes(input);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: "left", // Align text to the left in the menu
    }),
    control: (provided) => ({
      ...provided,
      textAlign: "left", // Align text to the left in the input
    }),
  };

  const handleAddToGroup = () => {
    const arr = selectedTags.map((item) => item.value);
    //console.log(arr);
    getSelectedMembers(arr);
  };

  const handleInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      debouncedApiCall(inputValue);
    }
  };

  useEffect(() => {
    //console.log(excludeMembers);
  }, []);
  const captureEvent = useCaptureEvent();
  return (
    <div className={modal ? "w-full mt-5" : "flex items-start flex-1"}>
      <div className={modal ? "w-full" : "flex-1"} style={{color: '#000'}}>
        <Select
          isMulti
          options={tagOptions}
          value={selectedTags}
          onChange={handleTagChange}
          placeholder="Select members"
          filterOption={customFilterOption}
          closeMenuOnSelect={false}
          styles={customStyles} // Apply custom styles to both menu and control
          onInputChange={handleInputChange}
        />
      </div>
      {modal ? (
        <div className="mt-5 flex justify-end items-center space-x-2">
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
              captureEvent("SET_GroupDetailActiveAddMembersModal_CancelBtn");
            }}
            className="flex-1"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              handleAddToGroup();
              captureEvent("SET_GroupDetailActiveAddMembersModal_AddToGroupBtn");
            }}
            className="flex-1"
          >
            Add to Group
            {isSubmitting && <div className="loader_circle ml-2"></div>}
          </Button>
        </div>
      ) : (
        <Button
          disabled={isSubmitting}
          onClick={(e) => {
            e.preventDefault();
            handleAddToGroup();
            captureEvent("SET_GroupDetailActiveAddMembersModal_AddToGroupBtn");
          }}
          className="flex-1"
        >
          Add to Group
          {isSubmitting && <div className="loader_circle ml-2"></div>}
        </Button>
      )}
    </div>
  );
};

export default AddTeamMembers;
