import { DELETE, GET, PATCH, POST, PUT } from "../axios/apiService";

export const getUser = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user`;
  return GET(url);
};

export const logOutUser = async ({ options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/logout`;
  return POST(url, {}, { withCredentials: true, withXSRFToken: true });
};

export const createWorkspace = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/create`;
  return POST(url, data);
};

export const checkSlugAvailability = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/check-slug-availability`;
  return POST(url, data);
};

export const setActiveWorkspace = async (id) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/set-active-workspace/${id}`;
  return PUT(url);
};

export const updateWorkspace = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/${data.id}`;
  return PUT(url, { name: data.name, slug: data.slug });
};

export const patchWorkspace = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/${data.id}`;
  return PATCH(url, { name: data.name, slug: data.slug });
};

export const deleteWorkspace = async (id) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/${id}`;
  return DELETE(url);
};

export const updateWorkspaceIcon = async ({ id, icon }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/${id}/icon`;
  return POST(url, icon);
};

export const updateDiscovery = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/${data.id}`;
  return PATCH(url, {
    visibility: data.visibility,
    whitelisted_domains: data.whitelisted_domains,
  });
};

export const login = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/login`;
  return POST(url, data, {
    withCredentials: true,
    withXSRFToken: true,
  });
};

export const register = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/register`;
  return POST(url, data, {
    withCredentials: true,
    withXSRFToken: true,
  });
};

export const setJobFunction = async ({ data }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile`;
  return PATCH(url, data);
};

export const verifyEmail = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/email/verify`;
  return POST(url, data, {
    withCredentials: true,
    withXSRFToken: true,
  });
};

export const resendOtp = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/otp/send`;
  return POST(url, data, {
    withCredentials: true,
    withXSRFToken: true,
  });
};

export const resetPassword = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/reset-password`;
  return POST(url, data, {
    withCredentials: true,
    withXSRFToken: true,
  });
};

export const getUserOnboardingStatus = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/onboarding`;
  return GET(url);
};

export const updateOnboardingStatus = async ({ data }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/onboarding`;
  return PATCH(url, data);
};

export const setPreferences = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/preferences`;
  return PATCH(url, data, options);
};

export const updatePassword = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/user/password`;
  return PUT(
    url,
    { ...data },
    {
      withCredentials: true,
      withXSRFToken: true,
    }
  );
};
