import React, { useEffect, useState } from "react";
import { Skeleton } from "../../../ui/skeleton";
import Image from "../../../Tags/Image";
import { getCRMUserMapping, saveCRMUserMapping } from "../../../../apis/workspace/integrations";
import { useMutation } from "react-query";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import UserSelect from "./UserSelect";
import toast from "react-hot-toast";

export default function UserMapping({
  configId,
  activeWorkspace,
  onSuccess,
  onError,
  isSubmitting,
  saveDisabled,
  isLoading,
}) {
  const [mappings, setMappings] = useState([]);
  const [userMappingData, setUserMappingData] = useState({});
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const getCRMUserMappingFn = useMutation("get_crm_user_mapping", getCRMUserMapping, {
    onSuccess(data, variables, context) {
      //   console.log(data);
      setUserMappingData(data);
      if (data && data.workspace_users && data.workspace_users.length > 0) {
        setWorkspaceUsers(data.workspace_users);
      }
    },
  });
  const handleGetCRMUserMapping = () => {
    getCRMUserMappingFn.mutate({
      type: "organizations",
      salesforceConfigId: configId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id && configId) {
      handleGetCRMUserMapping();
    }
  }, []);

  function generateMappings(users) {
    return users
      .filter((user) => user.is_being_mapped)
      .map((user) => ({
        user_id: user.id,
        salesforce_user_id: user?.salesforce_user?.id ? user?.salesforce_user?.id : "",
      }));
  }

  const updateKeysOfSpecificObject = (id, salesforceUser) => {
    const newarr = workspaceUsers.map((item) => {
      if (item?.id === id && salesforceUser.name) {
        const updatedItem = {
          ...item,
          is_being_mapped: true,
          salesforce_user: salesforceUser,
        };

        return updatedItem;
      } else if (item?.id === id && salesforceUser.name === "") {
        const updatedItem = {
          ...item,
          is_being_mapped: true,
          salesforce_user: null,
        };

        return updatedItem;
      } else {
        return item;
      }
    });
    // console.log(newarr);
    setMappings(generateMappings(newarr));

    setWorkspaceUsers(newarr);
  };

  // save user mapping

  const saveCRMUserMappingFn = useMutation("save_crm_user_mapping", saveCRMUserMapping, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      onSuccess();
      isLoading(false);
      setMappings([]);
    },
    onError(error) {
      onError();
      isLoading(false);
    },
  });
  const handleSaveCRMUserMapping = () => {
    isLoading(true);
    if (saveCRMUserMappingFn.isLoading) {
      return;
    }
    saveCRMUserMappingFn.mutate({
      data: { mappings: mappings },
      salesforceConfigId: configId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  useEffect(() => {
    if (isSubmitting) {
      handleSaveCRMUserMapping();
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (mappings && mappings.length === 0) {
      saveDisabled(true);
    } else {
      saveDisabled(false);
    }
  }, [mappings.length]);

  return (
    <div>
      <div className="mt-6 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-border rounded-[--radius]">
              <table className="min-w-full divide-y divide-border">
                <thead className="bg-background">
                  <tr>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal`}>
                      <span className="inline-flex items-center space-x-2">
                        <Image src={"/favicon.ico"} width={20} height={20} className="inline-block" />{" "}
                        <span>System User</span>
                      </span>
                    </th>
                    <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal `}>
                      <span className="inline-flex items-center space-x-2">
                        <Image src={"/favicon.ico"} width={20} height={20} className="inline-block" />{" "}
                        <span>System Email Address</span>
                      </span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal ">
                      <span className="inline-flex items-center space-x-2">
                        <Image
                          src="/images/logos/integrations/salesforce.svg"
                          className="no-invert"
                          width={24}
                        />
                        <span>Salesforce User</span>
                      </span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal ">
                      <span className="inline-flex items-center space-x-2">
                        <Image
                          src="/images/logos/integrations/salesforce.svg"
                          className="no-invert"
                          width={24}
                        />
                        <span>Salesforce Email Address</span>
                      </span>
                    </th>

                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal ">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-border bg-background">
                  {!getCRMUserMappingFn.isLoading &&
                    workspaceUsers &&
                    workspaceUsers.length > 0 &&
                    workspaceUsers.map((user, index) => (
                      <tr className={index % 2 !== 0 ? "bg-smoke" : ""} key={user?.id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph">{user?.name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph ">{user?.email}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 ">
                          {userMappingData &&
                            userMappingData.salesforce_users &&
                            userMappingData.salesforce_users.length > 0 && (
                              <UserSelect
                                data={userMappingData.salesforce_users}
                                getCurrent={(value) => {
                                  updateKeysOfSpecificObject(user?.id, {
                                    email: value?.salesforce_user_email,
                                    id: value?.value,
                                    name: value?.salesforce_user_name,
                                    username: value?.salesforce_user_username,
                                  });
                                }}
                                selected={user?.salesforce_user?.id}
                              />
                            )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph ">
                          {user?.salesforce_user?.email ? user?.salesforce_user?.email : "-"}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 ">
                          {!user?.salesforce_user?.email ? (
                            <CrossCircledIcon width={20} height={20} className="text-gray-600" />
                          ) : (
                            <Image
                              src="/images/icons/untitledui/check-purple.svg"
                              width="20"
                              className="inline-block no-invert"
                            />
                          )}
                        </td>
                      </tr>
                    ))}

                  {getCRMUserMappingFn.isLoading && (
                    <>
                      <tr>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                        <td>
                          <Skeleton className="h-10" />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
