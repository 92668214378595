import { GET, POST } from "../../axios/apiService";

export const getInvitations = async ({ query, options }) => {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/api/v1/workspace-settings/invitations${query ? `?${query}` : ""}`;
  return GET(url, options);
};

export const sendInvitations = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/invitations`;
  return POST(url, data.data, data.options);
};

export const resendInvitations = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/invitations/${data.inviteId}/resend`;
  return POST(url, undefined, data.options);
};

export const withdrawInvitations = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspace-settings/invitations/${data.inviteId}/withdraw`;
  return POST(url, undefined, data.options);
};

export const getWorkspaces = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/workspaces/available`;
  return GET(url);
};
