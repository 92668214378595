import React, { useEffect } from "react";
import Image from "../../Tags/Image";
import Text from "../../Tags/Text";
import Input from "../../Tags/Input";
import { Button } from "../../ui/button";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import FormError from "../../Tags/FormError";
import { login } from "../../../apis/user";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import NavAnchor from "../../Tags/NavAnchor";
import { fetchCsrfCookie } from "../../../apis/csrf";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";

export default function Login({ invite = false, inviteData={} }) {
  const captureEvent = useCaptureEvent();
  const navigate = useNavigate();
  const loginFn = useMutation("login", login, {
    onSuccess(data, variables, context) {
      formik.resetForm();
      // toast.success(data?.message);
      // Cookies.set("prStageAccessToken", data?.token);
      if (!invite) {
        window.location.href = "/app/";
      }
      if (invite) {
        window.location.reload();
      }
    },
    onError(err) {
      formik.setSubmitting(false);
    },
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email address"),
    password: yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Submitted values:", values);
      loginFn.mutate(formik.values);
    },
  });

  useEffect(() => {
    const cookieName = process.env.REACT_APP_PR_ACCESS_TOKEN
      ? process.env.REACT_APP_PR_ACCESS_TOKEN
      : "accessToken";

    const token = Cookies.get(cookieName);
    // Redirect only if on the root path "/"
    if (token) {
      navigate(`/`);
    }
  }, [navigate]);

  useEffect(() => {
    fetchCsrfCookie()
      .then((xsrfToken) => {
        console.log("CSRF");
      })
      .catch((error) => {
        console.error("Failed to fetch CSRF token:", error);
      });
  }, []);

  const createOauthRedirectUrl = (oauth='google') => {
    let baseUrl = "";
    if(oauth === 'google') {
      baseUrl = `${process.env.REACT_APP_API_BASE_URL}/login/google`;
    }
    if(oauth === 'slack') {
      baseUrl = `${process.env.REACT_APP_API_BASE_URL}/login/slack`;
    }
    if(invite && inviteData) {
      return `${baseUrl}?redirect_url=/invitation/accept/${inviteData?.id}/${inviteData?.token}`
    } else {
      return baseUrl;
    }
  }

  return (
    <div className="bg-brand-50 dark:bg-gray-25 min-h-screen pt-28 mobile:pt-10 mobile:px-4">
      <div className="max-w-[440px] mx-auto py-8 px-10 rounded-xl shadow bg-white dark:bg-gray-50 mobile:px-4">
        <div className="text-center">
          <Image src="/images/apps/primerole/favicon.ico" width={48} height={48} className="inline-block" />
        </div>
        <div className="text-center mt-6">
          <Text size="h6">{invite ? "Accept Invitation" : "Welcome back"}</Text>
          <p className="text-base text-gray-600 mt-1">Please enter your details.</p>
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-6">
              <Input
                className={"w-full"}
                placeholder="Enter your email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormError field="email" />
              <Input
                className={"w-full mt-3"}
                placeholder="•••••"
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <FormError field="password" />
              <div className="flex justify-between items-center py-4">
                <div className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="rounded border border-input mr-2 dark:text-brand-200"
                    onClick={() => {
                      captureEvent("ONB_Login_RememberThisDeviceBtn");
                    }}
                  ></input>
                  <span className="text-gray-700">Remember this device</span>
                </div>

                <NavAnchor
                  slug={false}
                  to={"/reset-password"}
                  className="text-brand-700 font-bold"
                  onClick={() => {
                    captureEvent("ONB_Login_ForgotPassword");
                  }}
                >
                  Forgot password
                </NavAnchor>
              </div>
              <div className="">
                <Button
                  className="w-full"
                  type="submit"
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={() => {
                    captureEvent("ONB_Login_SignInBtn");
                  }}
                >
                  Sign in
                  {formik.isSubmitting && <div className="loader_circle ml-2"></div>}
                </Button>

                <a href={createOauthRedirectUrl('google')}>
                  <Button
                    className="w-full mt-4"
                    variant="outline"
                    type="button"
                    onClick={() => {
                      captureEvent("ONB_SignInWithBtn", { sign_in_type: "Google" });
                    }}
                  >
                    <Image
                      src="/images/icons/untitledui/social-google.svg"
                      width={24}
                      className="mr-3 no-invert"
                    />
                    <span>Sign in with Google</span>
                  </Button>
                </a>
                <a href={createOauthRedirectUrl('slack')}>
                  <Button
                    className="w-full mt-3"
                    variant="outline"
                    type="button"
                    onClick={() => {
                      captureEvent("ONB_SignInWithBtn", { sign_in_type: "Slack" });
                    }}
                  >
                    <Image
                      src="/images/icons/untitledui/social-slack.svg"
                      width={20}
                      className="mr-3 no-invert"
                    />
                    <span>Sign in with Slack</span>
                  </Button>
                </a>
              </div>
              {!invite && (
                <div className="mt-6 text-center">
                  <span className="text-gray-600">Don’t have an account?</span>{" "}
                  <NavAnchor
                    slug={false}
                    to={"/register"}
                    className="text-brand-700 font-bold"
                    onClick={() => {
                      captureEvent("ONB_SignUp");
                    }}
                  >
                    Sign up
                  </NavAnchor>
                </div>
              )}
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  );
}
