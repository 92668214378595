import React, { useContext } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import SharedDataContext from "../../../SharedDataContext";

export default function NavAnchor({
  to,
  activeClassName,
  className,
  children,
  slug = true,
  activePaths = [],
  ...rest
}) {
  // const { workspaceSlug } = useParams();
  const userData = useContext(SharedDataContext);
  const workspaceSlug =
    userData && userData.activeWorkspace && userData.activeWorkspace.slug;
  const location = useLocation();
  const navTo = slug ? `/${workspaceSlug}${to}` : `${to}`;
  const removeTrailingSlash = (inputString) => {
    return inputString.replace(/\/$/, "");
  };
  const mapActivePathsWithSlug = (slug, array) => {
    return array.map((path) =>
      slug
        ? path === "/"
          ? `/${workspaceSlug}`
          : `/${workspaceSlug}${path}`
        : `${path}`
    );
  };
  const isPathMatching = (mappedActivePaths, path) => {
    return mappedActivePaths.some((pattern) => {
      let regexPattern = pattern.replace(/[.+?^${}()|[\]\\]/g, "\\$&");
      regexPattern = regexPattern.replace(/\*/g, ".*");
      const regex = new RegExp("^" + regexPattern + "$");
      return regex.test(path);
    });
  };
  const mappedActivePaths = mapActivePathsWithSlug(slug, activePaths);
  return (
    <NavLink
      to={navTo}
      {...rest}
      className={
        isPathMatching(mappedActivePaths, location.pathname)
          ? `${activeClassName}`
          : `${className}`
      }
    >
      {children}
    </NavLink>
  );
}
