import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import SharedDataContext from "../SharedDataContext";

const useRoleCheck = () => {
  const navigate = useNavigate();
  const userData = useContext(SharedDataContext);
  const { activeWorkspace } = userData || {};

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.role) {
      if (
        activeWorkspace.role !== "Owner" &&
        activeWorkspace.role !== "Admin"
      ) {
        navigate(`/${activeWorkspace.slug}/404`);
      }
    }
  }, [activeWorkspace, navigate]);

  return { activeWorkspace }; // You can return any other variables or functions as needed
};

export default useRoleCheck;
