import React, { useEffect, useState } from "react";
import GenericModal from "../../../GenericModal";
import useModal from "../../../GenericModal/useModal";
import { RadioGroup, RadioGroupItem } from "../../../ui/radio-group";
import Text from "../../../Tags/Text";
import { Button } from "../../../ui/button";

export default function AdvanceCountry({ formats = [], onConfirm, advanced_mapping = {} }) {
  const { isOpen, openModal, closeModal } = useModal(false);

  const [currentValue, setCurrentValue] = useState("full");
  const [currentFormat, setCurrentFormat] = useState("");
  const radios = [
    {
      name: "Full country name (Eg: United States)",
      value: "full",
    },
    {
      name: "Country abbreviations ",
      value: "abbr",
    },
  ];
  useEffect(() => {
    if (formats && formats.length > 0) {
      setCurrentFormat(formats[0]);
    }
  }, [formats && formats.length]);

  useEffect(() => {
    console.log("advanced_mapping", advanced_mapping);
    if (advanced_mapping && advanced_mapping.value) {
      setCurrentValue(advanced_mapping.value);
    }
    if (advanced_mapping && advanced_mapping.format) {
      setCurrentFormat(advanced_mapping.format);
    }
  }, [advanced_mapping]);
  useEffect(() => {
    return () => {
      setCurrentValue("full");
      setCurrentFormat("");
    };
  }, []);

  return (
    <>
      <span className="text-[#6941C6] font-bold text-sm cursor-pointer" onClick={openModal}>
        Advanced
      </span>
      <GenericModal isOpen={isOpen} closeModal={closeModal} width="w-[500px]">
        <h3 className="text-[18px] block font-semibold mb-1 text-text-900">Country</h3>
        <p className="text-text-600 mb-7">Please choose your mapping preferences for country field</p>
        <RadioGroup
          defaultValue={currentValue}
          onValueChange={(value) => {
            setCurrentValue(value);
          }}
        >
          {radios &&
            radios.length > 0 &&
            radios.map((radio) => (
              <div className={`flex items-start space-x-3 mb-2`}>
                <div className="inline-flex items-center justify-center">
                  <RadioGroupItem value={radio.value} id={radio.value} />
                </div>
                <div>
                  <label htmlFor={radio.value}>
                    <span size="sm" className="text-gray-dark-700 font-light leading-tight">
                      {radio.name}
                    </span>
                  </label>
                  {radio.value === "abbr" && (
                    <select
                      value={currentFormat}
                      onChange={(e) => {
                        setCurrentFormat(e.target.value);
                      }}
                      disabled={currentValue === "abbr" ? false : true}
                      className="mt-3 h-10 px-4 py-1 text-sm w-9/12 rounded-[--radius] border border-border bg-background text-text-900"
                    >
                      {formats &&
                        formats.length > 0 &&
                        formats.map((format) => <option value={format}>{format}</option>)}
                    </select>
                  )}
                </div>
              </div>
            ))}
        </RadioGroup>
        <div className="flex space-x-3 mt-6">
          <Button variant="outline" className="flex-1" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="flex-1"
            onClick={() => {
              onConfirm(
                currentValue === "abbr"
                  ? {
                      value: currentValue,
                      type: "country",
                      format: currentFormat,
                    }
                  : {
                      value: currentValue,
                      type: "country",
                    }
              );
              closeModal();
            }}
          >
            Confirm
          </Button>
        </div>
      </GenericModal>
    </>
  );
}
