import React, { useContext, useEffect, useState } from "react";
import SharedDataContext from "../../../SharedDataContext";
import {
  deleteIntegration,
  disableConfig,
  enableConfig,
  getIntegrations,
  installIntegration,
} from "../../../apis/workspace/integrations";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Image from "../../Tags/Image";
import {
  ArrowPathIcon,
  Cog6ToothIcon,
  PencilIcon,
  PlayCircleIcon,
  PlayIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";
import InstallIntegration from "./InstallIntegration";
import { Tooltip } from "react-tooltip";
import ConfirmModal from "../../Modals/ConfirmModal";
import RequestIntegration from "./RequestIntegration";
import GenericModal from "../../GenericModal";
import useModal from "../../GenericModal/useModal";
import useRoleCheck from "../../../hooks/useRoleCheck";
import PrintDate from "../../Tags/PrintDate";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import { useLocation, useNavigate } from "react-router-dom";
import NoData from "../../NoData";
import { Button } from "../../ui/button";
import { GearIcon } from "@radix-ui/react-icons";
import { GridIcon, PlusIcon, Terminal } from "lucide-react";
import { Badge } from "../../ui/badge";
import Input from "../../Tags/Input";
import { Switch } from "../../ui/switch";
import EnrichmentPriority from "../../EnrichmentPriority";
import Text from "../../Tags/Text";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { HoverCardContent, HoverCardTrigger, HoverCard, HoverCardArrow } from "../../ui/hover-card";
import { Alert, AlertDescription, AlertTitle } from "../../ui/alert";
import AlertTag from "../../Alerts/Alert";
import SalesforceDrawer from "./Salesforce/SalesforceDrawer";
import { setPreferences } from "../../../apis/user";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
import useFeatureFlag from "../../../hooks/useFeatureFlag";
import { restrictedIntegrations } from "../../../axios/constant";
export default function Integrations() {
  const isPremiumProvidersEnabled = useFeatureFlag("enable-premium-providers");
  const isAdvancePhoneVerificationEnabled = useFeatureFlag("advanced-phone-verification");
  const captureEvent = useCaptureEvent();
  const userData = useContext(SharedDataContext);
  const navigate = useNavigate();
  const setQueryString = useUpdateQuery();
  const queryClient = useQueryClient();
  const { activeWorkspace } = useRoleCheck();
  const [integrations, setIntegrations] = useState({});
  const [installedIntegrations, setInstalledIntegrations] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [logos, setLogos] = useState({});
  const [category, setCategory] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("View all");
  const [currentTab, setCurrentTab] = useState("integrations");
  
  const getIntegrationsFn = useQuery(
    "get_integrations",
    () => {
      return getIntegrations({
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (intdata) => {
        let data = intdata;
        if(data.available && data.available.length > 0) {
         const availableInt =  data.available.filter((integration) => {
            if (restrictedIntegrations.includes(integration.key)) {
              return isPremiumProvidersEnabled;
            }
            if (integration.key === "advanced_phone_verification") {
              return isAdvancePhoneVerificationEnabled;
            }
            return true;
          })
          data.available = availableInt;
        }
        setIntegrations(data);
        setInstalledIntegrations(data.installed);
        if (data && data.installed && data.installed.length === 0) {
          setCurrentTab("integrations");
        }
        if (data && data.installed && data.installed.length > 0) {
          const notifications = data.installed.reduce((acc, obj) => {
            if (obj.notifications && obj.notifications.length > 0) {
              let newobj = {
                integration: obj?.integration,
                ...obj.notifications[0],
              };
              // console.log(newobj);
              acc.push(newobj);
            }
            return acc;
          }, []);
          console.log(notifications);
          setNotifications(notifications);
        }

        if (data && data.available && data.available.length > 0) {
          const mergedCategories = data.available.flatMap((obj) => obj.categories);
          const uniqueCategories = [...new Set(mergedCategories)];
          const finalCategories = ["View all", ...uniqueCategories];
          setCategory(finalCategories);

          const keylogos = data.available.reduce((acc, curr) => {
            acc[curr.key] = curr?.logo;
            return acc;
          }, {});
          setLogos(keylogos);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleGetIntegrations = () => {
    getIntegrationsFn.refetch();
  };

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetIntegrations();
    }
  }, [activeWorkspace]);

  
  const handleCurrentTab = (tabname) => {
    setCurrentTab(tabname);
  };

  // add logo in table for integration
  const addLogoToInstalled = ({ available, installed }) => {
    // Map through the installed array and add the logo field where keys match
    const updatedInstalled = installed.map((installedItem) => {
      // Find the corresponding available item
      const availableItem = available.find((availItem) => availItem.key === installedItem.integration.key);

      // If found, add the logo to the installed item
      if (availableItem) {
        return {
          ...installedItem,
          logo: availableItem.logo,
        };
      }

      // If not found, return the original installed item
      return installedItem;
    });

    return updatedInstalled;
  };

  // install integration code
  const [currentIntegrationKey, setCurrentIntegrationKey] = useState("");
  const [currentIntegrationLogo, setCurrentIntegrationLogo] = useState("");
  const [integrationFormEditMode, setIntegrationFormEditMode] = useState(false);
  const [currentIntegrationEditName, setCurrentIntegrationEditName] = useState("");

  const { isOpen, openModal, closeModal } = useModal();

  // handle delete integration
  const [currentIntegrationId, setCurrentIntegrationId] = useState("");
  const deleteIntegrationFn = useMutation("change_Integration_role", deleteIntegration, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_integrations");
      if(installedIntegrations && installedIntegrations.length === 1) {
        navigate(`?tab=integrations`, { replace: true });
      }
    },
  });
  const handleDeleteIntegration = () => {
    deleteIntegrationFn.mutate({
      configId: currentIntegrationId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // handle open confirm
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    //console.log(data);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteIntegration();
      //console.log("test");
    }
  };

  // handle request integration
  const { isOpen: isOpen2, openModal: openModal2, closeModal: closeModal2 } = useModal();

  const location = useLocation();
  const [queryData, setQueryData] = useState({ key: "", tab: "" });
 

  useEffect(() => {
    if(location){
      const searchParams = new URLSearchParams(location?.search);
      const tab = searchParams.get("tab");
      const manage = searchParams.get("manage");
      const connect = searchParams.get("connect");
      const category = searchParams.get("category") || "View all"; 
      if (tab === "integrations" && category) {
        setCurrentCategory(category);
      } else if(tab === "installed" && installedIntegrations && installedIntegrations.length > 0) {
          setCurrentTab(tab);
        } else {
          setCurrentTab("integrations");
        }
      if(manage && manage === 'salesforce' && installedIntegrations && installedIntegrations.length > 0 && integrations && integrations.available && integrations.available.length > 0) {
        const salesforceEl = integrations.available.find((int) => int.key === manage);
        setCurrentIntegration(salesforceEl)
        openModalManage();
      }
      if(manage && installedIntegrations && installedIntegrations.length > 0 && integrations && integrations.available && integrations.available.length > 0) {
        const intEl = integrations.available.find((int) => int.key === manage);
        setCurrentIntegration(intEl)
        openModalManage();
      }
      if(connect && connect === 'salesforce' && integrations && integrations.available && integrations.available.length > 0) {
        const salesforceint = integrations.available.find((int) => int.key === connect);
        console.log("salesforceint", salesforceint)
        openModalSalesforce();
        setCurrentIntegrationKey(salesforceint.key);
        setCurrentIntegrationLogo(salesforceint.logo);
      }
      if(connect && integrations && integrations.available && integrations.available.length > 0) {
        const int = integrations.available.find((int) => int.key === connect);
        console.log("int", int)
        openModal();
        setCurrentIntegrationKey(int.key);
        setCurrentIntegrationLogo(int.logo);
      }
      
    }
  }, [category, installedIntegrations, integrations]);
 
  

  const handleChangeKey = (event) => {
    setQueryData((prevQueryData) => ({
      ...prevQueryData,
      key: event.target.value,
    }));
  };

  const isKeyFound = (categories, activeTab) => {
    if (activeTab === "View all") {
      return true;
    }

    return categories.includes(activeTab) || categories.includes("View all");
  };

  // enable and disable config

  const enableConfigFn = useMutation("enable_config", enableConfig, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_user");
      queryClient.invalidateQueries("get_integrations");
    },
  });
  const disableConfigFn = useMutation("disable_config", disableConfig, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      queryClient.invalidateQueries("get_user");
      queryClient.invalidateQueries("get_integrations");
    },
  });
  const handleEnableDisableConfig = (checked, id) => {
    if (checked) {
      enableConfigFn.mutate({
        id: id,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    } else {
      disableConfigFn.mutate({
        id: id,
        options: {
          headers: { "X-Workspace-Id": activeWorkspace?.id },
        },
      });
    }
  };

  // change priorityn
  const { isOpen: isOpenPriority, openModal: openModalPriority, closeModal: closeModalPriority } = useModal();

  // manage integration
  const { isOpen: isOpenManage, openModal: openModalManage, closeModal: closeModalMange } = useModal();

  const [currentIntegration, setCurrentIntegration] = useState({});
  const returnErrorIntegrationNames = () => {
    const displayNames = notifications.map((notification) => notification.integration.display_name);

    const formattedDisplayNames =
      displayNames.length > 1
        ? displayNames.slice(0, -1).join(", ") + " and " + displayNames.slice(-1)
        : displayNames[0];
    return formattedDisplayNames;
  };

  const findIntegrationWithError = (key) => {
    return notifications.find((notification) => notification.integration.key === key);
  };

  // install salesforce

  const {
    isOpen: isOpenSalesforce,
    openModal: openModalSalesforce,
    closeModal: closeModalSalesforce,
  } = useModal();

  const salesforceFeatures = [
    `Check if existence of Accounts, Contacts and Leads from within ${process.env.REACT_APP_APP_NAME}`,
    `Export data from ${process.env.REACT_APP_APP_NAME} to Salesforce`,
    "Configurable permissions according to your needs",
    "Support for custom objects and fields",
  ];

  // handle salesforce integrations
  const setPreferencesFn = useMutation("set_pref", setPreferences, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries("get_user");
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleSetPreferences = (value) => {
    if (setPreferencesFn.isLoading) {
      return;
    } else {
      setPreferencesFn.mutate({
        data: {
          preferences: {
            active_crm_workspace_integration_config_id: value,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (integrations && integrations.installed && integrations.installed.length > 0) {
      let filteredArray = [];
      filteredArray = integrations.installed.filter(
        (item) => item.integration.key === "salesforce" && item.is_enabled === true
      );

      const activatedSalesforce = filteredArray.filter(
        (item) => item.id === userData?.preferences?.active_crm_workspace_integration_config_id
      );

      console.log("activatedSalesforce", activatedSalesforce);

      if (activatedSalesforce && activatedSalesforce.length > 0) {
        console.log("salesforce connection is already there.");
      } else {
        if (
          filteredArray &&
          filteredArray.length === 1 &&
          userData?.preferences?.active_crm_workspace_integration_config_id &&
          filteredArray[0].id === userData?.preferences?.active_crm_workspace_integration_config_id
        ) {
          console.log("salesforce connection is already there.");
        }
        if (
          filteredArray &&
          filteredArray.length === 1 &&
          !userData?.preferences?.active_crm_workspace_integration_config_id
        ) {
          handleSetPreferences(filteredArray[0].id);
        }
        if (
          filteredArray &&
          filteredArray.length === 0 &&
          userData?.preferences?.active_crm_workspace_integration_config_id
        ) {
          handleSetPreferences("");
        }
        if (
          filteredArray &&
          filteredArray.length > 1 &&
          userData?.preferences?.active_crm_workspace_integration_config_id
        ) {
          handleSetPreferences("");
        }
      }
    }
  }, [userData?.preferences, integrations]);

  return (
    <>
      <div>
        {notifications && notifications.length === 1 && (
          <AlertTag heading={notifications[0].title} description={notifications[0].message} />
        )}
        {notifications && notifications.length > 1 && (
          <AlertTag
            heading={"There are issues with multiple integrations."}
            description={`There is a problem with ${returnErrorIntegrationNames()} affecting system efficiency. Fix immediately to continue`}
          />
        )}
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <Text size="lg">Integrations and connected apps</Text>
            <Text size="p" className="mt-1">
              Increase your contact coverage and boost your platform efficiency.
            </Text>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Button onClick={() => {
              openModal2();
              captureEvent("SET_Integrations_RequestIntegrationBtn");
              }} type="button" variant="purple-outline">
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M16.0833 6.06478L8.99997 9.99996M8.99997 9.99996L1.91664 6.06478M8.99997 9.99996L9 17.9167M10.6667 17.4074L9.64753 17.9736C9.41119 18.1049 9.29302 18.1705 9.16788 18.1963C9.05712 18.2191 8.94288 18.2191 8.83213 18.1963C8.70698 18.1705 8.58881 18.1049 8.35248 17.9736L2.18581 14.5477C1.93621 14.409 1.8114 14.3397 1.72053 14.241C1.64013 14.1538 1.57929 14.0504 1.54207 13.9378C1.5 13.8104 1.5 13.6677 1.5 13.3821V6.61786C1.5 6.33233 1.5 6.18956 1.54207 6.06223C1.57929 5.94958 1.64013 5.84618 1.72053 5.75894C1.8114 5.66032 1.93621 5.59099 2.18581 5.45232L8.35248 2.02639C8.58881 1.8951 8.70698 1.82944 8.83213 1.80371C8.94288 1.78093 9.05712 1.78093 9.16788 1.80371C9.29302 1.82944 9.41119 1.89509 9.64753 2.02639L15.8142 5.45232C16.0638 5.59099 16.1886 5.66032 16.2795 5.75893C16.3599 5.84618 16.4207 5.94958 16.4579 6.06222C16.5 6.18956 16.5 6.33232 16.5 6.61786L16.5 10.4167M5.25 3.74999L12.75 7.91665M14.8333 17.5V12.5M12.3333 15H17.3333"
                  stroke="currentColor"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Request Integration
            </Button>
          </div>
        </div>

        <Tabs  
        value={currentTab}
        onValueChange={(val) => {
          setCurrentTab(val); 
          navigate(`?tab=${val}`, { replace: true });
        }} className="w-full mt-6">
          <TabsList className="w-full">
            <TabsTrigger value="integrations"
            onClick={() => {
              navigate(`?tab=integrations`, { replace: true });
              captureEvent("SET_Integrations_AllIntegrationsBtn");
              }}
            >
              All Integrations{" "}
              {integrations && integrations.available && integrations.available.length > 0 && (
                <Badge className="ml-2">{integrations.available.length}</Badge>
              )}
            </TabsTrigger>
            {integrations && integrations.installed && integrations.installed.length > 0 && (
              <TabsTrigger value="installed"
              onClick={() => {
                navigate(`?tab=installed`, { replace: true });
                captureEvent("SET_Integrations_InstalledBtn");
                }}
              >
                Installed{" "}
                <Badge variant="gray" className="ml-2">
                  {integrations.installed.length}
                </Badge>
              </TabsTrigger>
            )}
          </TabsList>
          <TabsContent value="integrations">
            <div className="border-b border-border mb-6 space-x-2 mt-6 mobile:space-x-0">
              {category &&
                category.length > 0 &&
                category.map((el) => (
                  <Button
                    className={`font-semibold mobile:w-6/12 mobile:ml-0 text-text-500 focus:bg-none px-5 rounded-none border-t-0 border-x-0 shadow-none ${
                      el === currentCategory
                        ? "bg-brand-100 text-brand-700 pointer-events-none border-b-2 border-brand-600"
                        : "border-b-2 border-transparent"
                    }`}
                    variant={el === currentCategory ? "purple-outline" : "ghost"}
                    onClick={(e) => {
                      e.preventDefault(); 
                      navigate(`?tab=integrations&category=${el}`);
                      setCurrentCategory(el);
                      captureEvent("SET_Integrations_"+el+"Btn");
                    }}
                  >
                    {el}
                  </Button>
                ))}
            </div>
            <div className="flex flex-wrap -mx-3 mobile:block">
              {getIntegrationsFn.isLoading && (
                <>
                  <div className="w-4/12 px-3 mb-6 mobile:w-full">
                    <div className="rounded-lg overflow-hidden">
                      <div className="placeholder-content" style={{ height: "400px" }}></div>
                    </div>
                  </div>
                  <div className="w-4/12 px-3 mb-6">
                    <div className="rounded-lg overflow-hidden">
                      <div className="placeholder-content" style={{ height: "400px" }}></div>
                    </div>
                  </div>
                  <div className="w-4/12 px-3 mb-6">
                    <div className="rounded-lg overflow-hidden">
                      <div className="placeholder-content" style={{ height: "400px" }}></div>
                    </div>
                  </div>
                  <div className="w-4/12 px-3">
                    <div className="rounded-lg overflow-hidden">
                      <div className="placeholder-content" style={{ height: "400px" }}></div>
                    </div>
                  </div>
                  <div className="w-4/12 px-3">
                    <div className="rounded-lg overflow-hidden">
                      <div className="placeholder-content" style={{ height: "400px" }}></div>
                    </div>
                  </div>
                  <div className="w-4/12 px-3">
                    <div className="rounded-lg overflow-hidden">
                      <div className="placeholder-content" style={{ height: "400px" }}></div>
                    </div>
                  </div>
                </>
              )}
              {integrations &&
                integrations.available &&
                integrations.available.length > 0 &&
                integrations.available.filter((integration) => {
                  if (restrictedIntegrations.includes(integration.key)) {
                    return isPremiumProvidersEnabled;
                  }
                  if (integration.key === "advanced_phone_verification") {
                    return isAdvancePhoneVerificationEnabled;
                  }
                  return true;
                }).map((integration) => (
                  <div
                    className={`w-4/12 px-3 slide-in mobile:w-full ${
                      isKeyFound(integration.categories, currentCategory) ? "" : "hidden"
                    }`}
                  >
                    <div
                      key={integration.key}
                      className={`mb-6 border rounded-[--radius] p-6  overflow-hidden relative bg-background shadow-sm 
                      ${
                        findIntegrationWithError(integration.key) &&
                        findIntegrationWithError(integration.key).type === "error"
                          ? "border-error-600"
                          : "border-border "
                      }

                      ${
                        findIntegrationWithError(integration.key) &&
                        findIntegrationWithError(integration.key).type === "warning"
                          ? "border-warning-600"
                          : "border-border "
                      }
                      
                      `}
                    >
                      <div className="flex items-center mb-9 justify-between w-full">
                        <div className="flex items-center">
                          <div className="inline-flex justify-center border border-border rounded-[--radius] p-1 bg-white">
                            <span
                              className="svg__48"
                              dangerouslySetInnerHTML={{
                                __html: integration.logo,
                              }}
                            />
                          </div>
                          <h3 className="text-[16px] text-center font-semibold ml-3 text-text-900">
                            {" "}
                            {integration.display_name}
                          </h3>
                        </div>
                        <div>
                          {findIntegrationWithError(integration.key) &&
                            findIntegrationWithError(integration.key).type && (
                              <HoverCard>
                                <HoverCardTrigger>
                                  {findIntegrationWithError(integration.key).type === "warning" && (
                                    <svg
                                      className="inline-flex"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.9998 9.00023V13.0002M11.9998 17.0002H12.0098M10.6151 3.89195L2.39019 18.0986C1.93398 18.8866 1.70588 19.2806 1.73959 19.6039C1.769 19.886 1.91677 20.1423 2.14613 20.309C2.40908 20.5002 2.86435 20.5002 3.77487 20.5002H20.2246C21.1352 20.5002 21.5904 20.5002 21.8534 20.309C22.0827 20.1423 22.2305 19.886 22.2599 19.6039C22.2936 19.2806 22.0655 18.8866 21.6093 18.0986L13.3844 3.89195C12.9299 3.10679 12.7026 2.71421 12.4061 2.58235C12.1474 2.46734 11.8521 2.46734 11.5935 2.58235C11.2969 2.71421 11.0696 3.10679 10.6151 3.89195Z"
                                        stroke="#F79009"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                  {findIntegrationWithError(integration.key).type === "error" && (
                                    <svg
                                      className="inline-flex"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#D92D20"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className={`${
                                    findIntegrationWithError(integration.key).type === "error" &&
                                    "bg-error-500"
                                  } ${
                                    findIntegrationWithError(integration.key).type === "warning" &&
                                    "bg-warning-500"
                                  } text-white border-none shadow-none`}
                                >
                                  <HoverCardArrow
                                    className={`${
                                      findIntegrationWithError(integration.key).type === "error" &&
                                      "tick__bg-red-600"
                                    } ${
                                      findIntegrationWithError(integration.key).type === "warning" &&
                                      "tick__bg-warning-600"
                                    } `}
                                  />
                                  <div className="whitespace-pre-wrap text-center">
                                    <Text size="xs" className="text-white">
                                      {findIntegrationWithError(integration.key)?.title}
                                    </Text>
                                    <Text size="xs" className="font-light mt-1 text-white">
                                      {findIntegrationWithError(integration.key)?.message}
                                    </Text>
                                  </div>
                                </HoverCardContent>
                              </HoverCard>
                            )}
                        </div>
                      </div>

                      <p className="text-[14px] leading-relaxed min-h-[115px] text-text-600">
                        {integration.description}
                      </p>
                      <div className=" w-full flex mt-9 justify-center">
                        {["clearbit_enrichment", "get_prospect"].includes(integration.key) ? (
                          <span className="p-4 text-center flex-1 inline-flex items-center justify-center font-bold text-[16px]">
                            <span className="ml-2">Coming soon</span>
                          </span>
                        ) : (
                          <>
                            {integrations.installed &&
                            integrations.installed.length > 0 &&
                            integrations.installed.filter((installInt) =>
                              installInt.integration.key.toLowerCase().includes(integration.key.toLowerCase())
                            ).length > 0 ? (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  openModalManage();
                                  setCurrentIntegration(integration);
                                  captureEvent("SET_Integrations_ManageBtn", { integration_type: integration.key });
                                }}
                                variant="purple_outline"
                              >
                                <GearIcon className="mr-2" />
                                Manage
                              </Button>
                            ) : (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (integration.key.toLowerCase() === "salesforce") {
                                    openModalSalesforce();
                                  } else {
                                    openModal();
                                    setIntegrationFormEditMode(false);
                                  }

                                  setCurrentIntegrationKey(integration.key);
                                  setCurrentIntegrationLogo(integration.logo);
                                  captureEvent("SET_Integrations_ConnectBtn");
                                }}
                              >
                                Connect
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </TabsContent>
          <TabsContent value="installed">
            <div className="flex items-center justify-between mt-6 mobile:block">
              <div className="inline-flex w-[370px] mobile:w-full mobile:mb-3">
                <Input
                  placeholder="Search"
                  defaultValue={queryData?.key}
                  type="text"
                  className=""
                  onChange={handleChangeKey}
                />
              </div>
              <Button onClick={() => {openModalPriority()
                captureEvent("SET_Integrations_CustomizeEnrichmentPriorityBtn");
              }} variant="purple-outline">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path
                    d="M4.83333 14.6668V1.3335M4.83333 1.3335L1.5 4.66683M4.83333 1.3335L8.16667 4.66683M13.1667 14.6668V5.50016M13.1667 5.50016L9.83333 8.8335M13.1667 5.50016L16.5 8.8335"
                    stroke="currentColor"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Customize enrichment priority
              </Button>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-border rounded-[--radius]">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="bg-background">
                        <tr>
                          <th scope="col" className="px-3 py-3.5 text-left">
                            Connection Name
                          </th>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left  sm:pl-6">
                            Integration
                          </th>

                          <th scope="col" className="px-3 py-3.5 text-left ">
                            Created By
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left ">
                            Created At
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left ">
                            Status
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left "></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border bg-background">
                        {integrations &&
                          integrations.installed &&
                          integrations.installed.length > 0 &&
                          addLogoToInstalled({
                            available: integrations.available,
                            installed: integrations.installed,
                          })
                            .filter(
                              (integration) =>
                                integration.integration.display_name
                                  .toLowerCase()
                                  .includes(queryData?.key?.toLowerCase()) ||
                                integration.name.toLowerCase().includes(queryData?.key?.toLowerCase())
                            )
                            .map((integration, index) => (
                              <tr
                                key={integration.id}
                                className={`text-paragraph ${index % 2 !== 0 ? "bg-gray-50" : ""}

                                ${
                                  integration.notifications && integration.notifications.length > 0
                                    ? `${
                                        integration.notifications[0]?.type === "error" &&
                                        "bg-error-100 border-y-error-400"
                                      } ${
                                        integration.notifications[0]?.type === "warning" &&
                                        "bg-warning-100 border-y-warning-400"
                                      } `
                                    : ""
                                }
                                
                                `}
                              >
                                <td className="whitespace-nowrap px-3 py-4">
                                  {integration.notifications && integration.notifications.length > 0 && (
                                    <div className="inline-flex mr-3">
                                      <HoverCard>
                                        <HoverCardTrigger>
                                          {integration.notifications[0]?.type === "warning" && (
                                            <svg
                                              className="inline-flex"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M11.9998 9.00023V13.0002M11.9998 17.0002H12.0098M10.6151 3.89195L2.39019 18.0986C1.93398 18.8866 1.70588 19.2806 1.73959 19.6039C1.769 19.886 1.91677 20.1423 2.14613 20.309C2.40908 20.5002 2.86435 20.5002 3.77487 20.5002H20.2246C21.1352 20.5002 21.5904 20.5002 21.8534 20.309C22.0827 20.1423 22.2305 19.886 22.2599 19.6039C22.2936 19.2806 22.0655 18.8866 21.6093 18.0986L13.3844 3.89195C12.9299 3.10679 12.7026 2.71421 12.4061 2.58235C12.1474 2.46734 11.8521 2.46734 11.5935 2.58235C11.2969 2.71421 11.0696 3.10679 10.6151 3.89195Z"
                                                stroke="#F79009"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          )}
                                          {integration.notifications[0]?.type === "error" && (
                                            <svg
                                              className="inline-flex"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                stroke="#D92D20"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          )}
                                        </HoverCardTrigger>
                                        <HoverCardContent
                                          side="top"
                                          className={`${
                                            integration.notifications[0]?.type === "error" && "bg-error-500"
                                          } ${
                                            integration.notifications[0]?.type === "warning" &&
                                            "bg-warning-500"
                                          } text-white border-none shadow-none`}
                                        >
                                          <HoverCardArrow
                                            className={`${
                                              integration.notifications[0]?.type === "error" &&
                                              "tick__bg-red-600"
                                            } ${
                                              integration.notifications[0]?.type === "warning" &&
                                              "tick__bg-warning-600"
                                            } `}
                                          />
                                          <div className="whitespace-pre-wrap text-center">
                                            <Text size="xs" className="text-white">
                                              {integration.notifications[0]?.title}
                                            </Text>
                                            <Text size="xs" className="font-light mt-1 text-white">
                                              {integration.notifications[0]?.message}
                                            </Text>
                                          </div>
                                        </HoverCardContent>
                                      </HoverCard>
                                    </div>
                                  )}
                                  {integration.name}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6">
                                  <div className="inline-flex items-center">
                                    <span
                                      className="svg__32 inline-block rounded-[--radius] p-1 bg-white -ml-1"
                                      dangerouslySetInnerHTML={{
                                        __html: integration.logo,
                                      }}
                                    />
                                    <span className="ml-3">
                                      {integration && integration?.integration?.display_name}
                                    </span>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 ">
                                  <div className="flex items-center">
                                    <div className="h-9 w-9 flex-shrink-0">
                                      <Image
                                        nobaseurl
                                        className="h-9 w-9 rounded-full"
                                        src={integration?.created_by.profile_photo_url}
                                        alt=""
                                      />
                                    </div>
                                    <div className="ml-4">
                                      <div className="font-medium text-foreground">
                                        {integration?.created_by.name}
                                      </div>
                                      <div className="mt-0">{integration?.created_by.email}</div>
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 ">
                                  <PrintDate date={integration.created_at} />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 ">
                                  <div className="inline-flex items-center">
                                    <Switch
                                      checked={integration.status === "Active" ? true : false}
                                      onCheckedChange={(checked) => {
                                        handleEnableDisableConfig(checked, integration.id);
                                      }}
                                      onClick={() => {
                                        captureEvent("SET_IntegrationsInstalled_StatusBtn", { state: integration.status });
                                        }}
                                    />
                                    <span className="ml-3">
                                      {integration.status === "Active" ? "Enabled" : "Disabled"}
                                    </span>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 ">
                                  {integration?.integration.key === "salesforce" ? (
                                    <> 
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Re-Configure Integration"
                                      onClick={() => {
                                        navigate(`salesforce/${integration?.id}`);
                                      }}
                                      variant="ghost"
                                    >
                                      <Image
                                        src="/images/icons/untitledui/edit.svg"
                                        width="16"
                                        height="16"
                                        className="inline-block"
                                      />
                                    </Button>
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Re-authentication"
                                      onClick={() => { 
                                        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/workspace-settings/integrations/salesforce/reauthorize/${integration?.id}`;
                                      }}
                                      variant="ghost"
                                    >
                                      <Image src="/images/icons/untitledui/re-auth.svg" 
                                      className="w-4 h-4 inline-block max-w-min" />
                                    </Button>
                                    </>
                                  ) : (
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Re-Configure Integration"
                                      onClick={() => {
                                        openModal();
                                        setIntegrationFormEditMode(true);
                                        setCurrentIntegrationKey(integration?.integration.key);
                                        setCurrentIntegrationLogo(integration.logo);
                                        setCurrentIntegrationEditName(integration.name);
                                        setCurrentIntegrationId(integration.id);
                                        captureEvent("SET_IntegrationsInstalled_ReconfigureIntegrationBtn");
                                      }}
                                      variant="ghost"
                                    >
                                      <Image
                                        src="/images/icons/untitledui/edit.svg"
                                        width="16"
                                        height="16"
                                        className="inline-block"
                                      />
                                    </Button>
                                  )}

                                  <Button
                                    data-tooltip-id="in_table_actions"
                                    data-tooltip-content="Remove Integration"
                                    disabled={
                                      deleteIntegrationFn.isLoading && currentIntegrationId === integration.id
                                    }
                                    onClick={() => {
                                      setCurrentIntegrationId(integration.id);
                                      setOpenConfirmModal(true);
                                      setOpenConfirmModalType("delete");
                                      captureEvent("SET_IntegrationsInstalled_DeleteBtn");
                                    }}
                                    variant="ghost"
                                  >
                                    <Image
                                      src="/images/icons/untitledui/trash.svg"
                                      width="16"
                                      height="16"
                                      className="inline-block"
                                    />
                                    {deleteIntegrationFn.isLoading &&
                                      currentIntegrationId === integration.id && (
                                        <div className="loader_circle ml-2"></div>
                                      )}
                                  </Button>
                                  <Tooltip id="in_table_actions" />
                                </td>
                              </tr>
                            ))}
                        {integrations &&
                          integrations.installed &&
                          integrations.installed.length > 0 &&
                          addLogoToInstalled({
                            available: integrations.available,
                            installed: integrations.installed,
                          }).filter(
                            (integration) =>
                              integration.integration.display_name
                                .toLowerCase()
                                .includes(queryData?.key?.toLowerCase()) ||
                              integration.name.toLowerCase().includes(queryData?.key?.toLowerCase())
                          ).length === 0 && (
                            <tr>
                              <td colSpan={12}>
                                <div className="p-10">
                                  <NoData
                                    heading={"No Integration found!"}
                                    description={"Please modify search"}
                                    filterMode={true}
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <GenericModal isOpen={isOpen} closeModal={closeModal} drawer={true}>
        <InstallIntegration
          closeModal={closeModal}
          integrationKey={currentIntegrationKey}
          logo={currentIntegrationLogo}
          edit={integrationFormEditMode}
          formData={{
            name: currentIntegrationEditName,
            configId: currentIntegrationId,
          }}
        />
      </GenericModal>
      <GenericModal
        isOpen={isOpenSalesforce}
        closeModal={closeModalSalesforce}
        drawer={true}
        drawerWidth="max-w-[700px] min-w-[700px]"
      >
        <SalesforceDrawer closeModal={closeModalSalesforce} />
      </GenericModal>
      <GenericModal
        isOpen={isOpenManage}
        closeModal={closeModalMange}
        drawer={true}
        drawerWidth="max-w-[700px] min-w-[700px]"
      >
        <div className="p-6">
          {currentIntegration && (
            <>
              <span
                className="svg__32 inline-block border border-border rounded-[--radius] p-1 bg-white"
                dangerouslySetInnerHTML={{
                  __html: currentIntegration?.logo,
                }}
              />
              <h3 className="text-[18px] block font-semibold mb-2 mt-4">
                {currentIntegration?.display_name}
              </h3>
              <p className="text-[14px] text-paragraph leading-relaxed ">{currentIntegration?.description}</p>
              <div className="-mx-6">
                <div className="border-b border-border my-5"></div>
              </div>
              <h3 className="text-[16px] block font-semibold mb-4 mt-4">Supported features</h3>

              {currentIntegration.key !== "salesforce" && currentIntegration.features ? (
                <div className="space-y-4">
                  {Object.entries(currentIntegration.features)
                    .sort(([, isEnabledA], [, isEnabledB]) => isEnabledB - isEnabledA)
                    .map(([feature, isEnabled], index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        {isEnabled ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                              fill="#7F56D9"
                            />
                            <path
                              d="M7.5 12L10.5 15L16.5 9"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                              stroke="#475467"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <span style={{ marginLeft: "8px" }}>{feature}</span>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="space-y-4">
                  {salesforceFeatures.map((feature, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <Image
                        src="/images/icons/untitledui/check-purple.svg"
                        width="20"
                        className="inline-block no-invert"
                      />
                      <span style={{ marginLeft: "8px" }}>{feature}</span>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          <div className="-mx-6">
            <div className="border-b border-border my-5"></div>
          </div>
          <h3 className="text-[16px] block font-semibold mb-4 mt-4">Connections</h3>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border border-border rounded-[--radius]">
                  <table className="min-w-full divide-y divide-border">
                    <thead className="bg-background">
                      <tr>
                        <th scope="col" className="px-3 py-3.5 text-left ">
                          Name
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-left ">
                          Status
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left "></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-border bg-background">
                      {integrations &&
                        integrations.installed &&
                        integrations.installed.length > 0 &&
                        addLogoToInstalled({
                          available: integrations.available,
                          installed: integrations.installed,
                        })
                          .filter(
                            (integration) =>
                              integration.integration.display_name
                                .toLowerCase()
                                .includes(currentIntegration?.display_name?.toLowerCase()) ||
                              integration.name
                                .toLowerCase()
                                .includes(currentIntegration?.display_name?.toLowerCase())
                          )
                          .map((integration, index) => (
                            <tr
                              key={integration.id}
                              className={`text-paragraph ${index % 2 !== 0 ? "bg-gray-50" : ""}

                              ${
                                integration.notifications && integration.notifications.length > 0
                                  ? `${
                                      integration.notifications[0]?.type === "error" &&
                                      "bg-error-100 border-y-error-400"
                                    } ${
                                      integration.notifications[0]?.type === "warning" &&
                                      "bg-warning-100 border-y-warning-400"
                                    } `
                                  : ""
                              }
                              
                              `}
                            >
                              <td className="whitespace-nowrap px-3 py-4">
                                {integration.notifications && integration.notifications.length > 0 && (
                                  <div className="inline-flex mr-3">
                                    <HoverCard>
                                      <HoverCardTrigger>
                                        {integration.notifications[0]?.type === "warning" && (
                                          <svg
                                            className="inline-flex"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M11.9998 9.00023V13.0002M11.9998 17.0002H12.0098M10.6151 3.89195L2.39019 18.0986C1.93398 18.8866 1.70588 19.2806 1.73959 19.6039C1.769 19.886 1.91677 20.1423 2.14613 20.309C2.40908 20.5002 2.86435 20.5002 3.77487 20.5002H20.2246C21.1352 20.5002 21.5904 20.5002 21.8534 20.309C22.0827 20.1423 22.2305 19.886 22.2599 19.6039C22.2936 19.2806 22.0655 18.8866 21.6093 18.0986L13.3844 3.89195C12.9299 3.10679 12.7026 2.71421 12.4061 2.58235C12.1474 2.46734 11.8521 2.46734 11.5935 2.58235C11.2969 2.71421 11.0696 3.10679 10.6151 3.89195Z"
                                              stroke="#F79009"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                        {integration.notifications[0]?.type === "error" && (
                                          <svg
                                            className="inline-flex"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                              stroke="#D92D20"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </HoverCardTrigger>
                                      <HoverCardContent
                                        side="right"
                                        className={`${
                                          integration.notifications[0]?.type === "error" && "bg-error-500"
                                        } ${
                                          integration.notifications[0]?.type === "warning" && "bg-warning-500"
                                        } text-white border-none shadow-none`}
                                      >
                                        <HoverCardArrow
                                          className={`${
                                            integration.notifications[0]?.type === "error" &&
                                            "tick__bg-red-600"
                                          } ${
                                            integration.notifications[0]?.type === "warning" &&
                                            "tick__bg-warning-600"
                                          } `}
                                        />
                                        <div className="whitespace-pre-wrap text-center">
                                          <Text size="xs" className="text-white">
                                            {integration.notifications[0]?.title}
                                          </Text>
                                          <Text size="xs" className="font-light mt-1 text-white">
                                            {integration.notifications[0]?.message}
                                          </Text>
                                        </div>
                                      </HoverCardContent>
                                    </HoverCard>
                                  </div>
                                )}
                                <span className="inline-block w-[200px] break-words whitespace-normal">{integration.name}</span>
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 ">
                                <div className="inline-flex items-center">
                                  <Switch
                                    checked={integration.status === "Active" ? true : false}
                                    onCheckedChange={(checked) => {
                                      handleEnableDisableConfig(checked, integration.id);
                                    }}
                                    onClick={() => {
                                      captureEvent("SET_ManageIntegration_StatusBtn", { state: integration.status });
                                      }}
                                  />
                                  <span className="ml-3">
                                    {integration.status === "Active" ? "Enabled" : "Disabled"}
                                  </span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 ">
                                <div className="flex justify-end">
                                  {integration?.integration.key === "salesforce" ? (
                                    <>
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Re-Configure Integration"
                                      onClick={() => {
                                        navigate(`salesforce/${integration?.id}`);
                                      }}
                                      variant="ghost"
                                    >
                                      <Image
                                        src="/images/icons/untitledui/edit.svg"
                                        width="16"
                                        height="16"
                                        className="inline-block min-w-[16px] min-h-[16px]"
                                      />
                                    </Button>
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Re-authentication"
                                      onClick={() => { 
                                        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/workspace-settings/integrations/salesforce/reauthorize/${integration?.id}`;
                                      }}
                                      variant="ghost"
                                    >
                                      <Image src="/images/icons/untitledui/re-auth.svg" 
                                      className="w-4 h-4 inline-block max-w-min" />
                                    </Button>
                                    </>
                                  ) : (
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Re-Configure Integration"
                                      onClick={() => {
                                        openModal();
                                        setIntegrationFormEditMode(true);
                                        setCurrentIntegrationKey(integration?.integration.key);
                                        setCurrentIntegrationLogo(integration.logo);
                                        setCurrentIntegrationEditName(integration.name);
                                        setCurrentIntegrationId(integration.id);
                                        captureEvent("SET_ManageIntegration_ReConfigureBtn");
                                      }}
                                      variant="ghost"
                                    >
                                      <Image
                                        src="/images/icons/untitledui/edit.svg"
                                        width="16"
                                        height="16"
                                        className="inline-block min-w-[16px] min-h-[16px]"
                                      />
                                    </Button>
                                  )}
                                  <Button
                                    data-tooltip-id="in_table_actions"
                                    data-tooltip-content="Remove Integration"
                                    disabled={
                                      deleteIntegrationFn.isLoading && currentIntegrationId === integration.id
                                    }
                                    onClick={() => {
                                      setCurrentIntegrationId(integration.id);
                                      setOpenConfirmModal(true);
                                      setOpenConfirmModalType("delete");
                                      captureEvent("SET_ManageIntegration_RemoveIntegrationBtn");
                                    }}
                                    variant="ghost"
                                  >
                                    <Image
                                      src="/images/icons/untitledui/trash.svg"
                                      width="16"
                                      height="16"
                                      className="inline-block min-w-[16px] min-h-[16px]"
                                    />
                                    {deleteIntegrationFn.isLoading &&
                                      currentIntegrationId === integration.id && (
                                        <div className="loader_circle ml-2"></div>
                                      )}
                                  </Button>
                                  <Tooltip id="in_table_actions" />
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="p-6 flex items-center justify-center">
            {currentIntegration.key === "salesforce" ? (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  closeModalMange();
                  openModalSalesforce();
                  captureEvent("SET_ManageIntegration_AddConnection");
                }}
              >
                <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" /> Add connection
              </Button>
            ) : (
              <>
             {currentIntegration.key !== "advanced_phone_verification" && <Button
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                  setIntegrationFormEditMode(false);
                  setCurrentIntegrationKey(currentIntegration.key);
                  setCurrentIntegrationLogo(currentIntegration.logo);
                  captureEvent("SET_ManageIntegration_AddConnection");
                }}
              >
                <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" /> Add connection
              </Button>}
              </>
            )}
          </div>
        </div>
      </GenericModal>

      <ConfirmModal
        openModal={openConfirmModal}
        modalType={openConfirmModalType}
        confirmModalState={confirmModalState}
      />
      <GenericModal isOpen={isOpen2} closeModal={closeModal2} title={"Request Integration"}>
        <RequestIntegration closeModal={closeModal2} />
      </GenericModal>
      <GenericModal isOpen={isOpenPriority} closeModal={closeModalPriority} drawer={true}>
        <EnrichmentPriority closeModal={closeModalPriority} logos={logos} />
      </GenericModal>
    </>
  );
}
