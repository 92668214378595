import React from "react";
import classNames from "classnames";

const Text = React.forwardRef(({ size, className, children, ...rest }, ref) => {
  const tags = ["xl", "lg", "md", "md-base", "sm", "xs"];
  const defaultClasses = {
    h1: "text-7xl text-gray-900 font-semibold",
    h2: "text-6xl text-gray-900 font-semibold",
    h3: "text-5xl text-gray-900 font-semibold",
    h4: "text-4xl text-gray-900 font-semibold",
    h5: "text-3xl text-gray-900 font-semibold",
    h6: "text-2xl text-gray-900 font-semibold",
    xl: "text-xl text-gray-900 font-semibold",
    lg: "text-lg text-gray-900 font-semibold",
    md: "text-base text-gray-900 font-semibold",
    "md-base": "text-base text-gray-600",
    sm: "text-sm text-gray-900 font-semibold",
    xs: "text-xs text-gray-900 font-semibold",
    span: "text-sm text-gray-600 font-normal",
    p: "text-sm text-gray-600 font-normal",
    default: "text-sm text-gray-600 font-normal",
  };

  const Tag =
    size in defaultClasses ? (tags.includes(size) ? "p" : size) : "div";
  const defaultClass = defaultClasses[size] || defaultClasses.default;

  return (
    <Tag ref={ref} className={classNames(defaultClass, className)} {...rest}>
      {children}
    </Tag>
  );
});

export default Text;
