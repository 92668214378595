import React, { useEffect, useState } from "react";
import { Button } from "../../../ui/button";
import { getGroups } from "../../../../apis/workspace/groups";
import { useMutation, useQueryClient } from "react-query";
import Image from "../../../Tags/Image";
import { Trash, Trash2 } from "lucide-react";
import { approveMembershipRequest } from "../../../../apis/workspace/members";
import toast from "react-hot-toast";

export default function ApproveRequest({
  closeModal,
  activeWorkspace,
  selectedMembers,
  removeMember,
}) {
  const queryClient = useQueryClient();
  const [role, setRole] = useState("Member");
  const [group, setGroup] = useState("");
  // check groups
  const [groups, setGroups] = useState([]);

  const getGroupsFn = useMutation("get_groups", getGroups, {
    onSuccess(data, variables, context) {
      setGroups(data.data);
      if (data && data.data && data.data.length === 0) {
        setTimeout(() => {
          const groupFilterEl = document.querySelector(".filter__group");
          if (groupFilterEl) {
            //console.log("groups hide");
            groupFilterEl.style.display = "none";
          }
        }, 100);
      }
    },
  });
  const handleGetGroups = () => {
    getGroupsFn.mutate({
      query: `page=1&per_page=100`,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (activeWorkspace && activeWorkspace.id) {
      handleGetGroups();
    }
  }, [activeWorkspace]);

  useEffect(() => {
    console.log(selectedMembers);
  }, [selectedMembers]);

  const approveMemberFn = useMutation(
    "approve_membershiprequest",
    approveMembershipRequest,
    {
      onSuccess(data, variables, context) {
        toast.success(data?.message);
        queryClient.invalidateQueries("get_membershiprequests");
        closeModal();
      },
    }
  );
  const handleApproveMembers = () => {
    const newarr = selectedMembers.map((member) => member.id);
    const payload = { requestIds: newarr, group_id: group, role: role };
    // console.log(payload);
    approveMemberFn.mutate({
      data: payload,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  return (
    <>
      <div>
        <h2 className="text-[18px] font-semibold leading-7 mb-1">
          Set group and role
        </h2>
        <p className="text-paragraph">
          Select the group and role for the approved users
        </p>
      </div>
      <div className="border-b border-border my-5 -mx-6"></div>
      <div className="my-5 space-y-5">
        {selectedMembers &&
          selectedMembers.length > 0 &&
          selectedMembers.map((member) => (
            <div className="flex justify-between items-center">
              <div className="inline-flex items-center">
                <div className="h-9 w-9 flex-shrink-0">
                  <Image
                    nobaseurl
                    className="h-9 w-9 rounded-full"
                    src={member?.requester?.profile_photo_url}
                    alt=""
                  />
                </div>
                <div className="ml-4">
                  <div className="font-medium ">{member?.requester?.name}</div>
                  <div className="mt-0 text-gray-500">
                    {member?.requester?.email}
                  </div>
                </div>
              </div>
              {selectedMembers.length > 1 && (
                <Button
                  variant="outline"
                  onClick={() => {
                    removeMember(member);
                  }}
                >
                  <Trash2 className="h-4 w-4 text-red-500" />
                </Button>
              )}
            </div>
          ))}
      </div>

      <div className="flex space-x-2">
        {groups && groups.length > 0 && (
          <div className="w-6/12">
            <>
              <label
                htmlFor="group"
                className="block text-sm font-medium leading-6 "
              >
                Select Group
              </label>
              <select
                id="group"
                name="location"
                className="mt-2 h-11 px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
                value={group}
                defaultValue={group}
                onChange={(e) => setGroup(e.target.value)}
              >
                <option value={""}>Select a Group</option>
                {groups.map((group) => (
                  <option value={group.id} key={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
              <p className="text-xs mt-2 text-paragraph">
                All users will be added to the selected group
              </p>
            </>
          </div>
        )}
        <div className="w-6/12">
          <label htmlFor="role" className="block text-sm font-medium leading-6">
            Select Role
          </label>
          <select
            id="role"
            name="location"
            className="mt-2 h-11 px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
            value={role}
            defaultValue={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option>Admin</option>
            <option>Member</option>
            <option>Guest</option>
          </select>

          <p className="text-xs mt-2 text-paragraph">
            All invited users will be given the selected role
          </p>
        </div>
      </div>
      <div className="border-b border-border my-5 -mx-6"></div>
      <div className="mt-5 flex space-x-2">
        <Button
          variant="outline"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          type="button"
          disabled={approveMemberFn.isLoading}
          onClick={handleApproveMembers}
          className="flex-1"
        >
          Add user
          {approveMemberFn.isLoading && (
            <div className="loader_circle ml-2"></div>
          )}
        </Button>
      </div>
    </>
  );
}
