import { deleteMembershipRequest, getMembershipRequests } from "../../../../apis/workspace/members";
import { paginationConfig } from "../../../../axios/constant";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useUpdateQuery from "../../../../hooks/useUpdateQuery";
import { getVariant, isEmptyObject, returnArray } from "../../../../utils/GenericFn";
import Pagination from "../../../Pagination";
import NoData from "../../../NoData";
import TableLoader from "../../../Loaders/TableLoader";
import Image from "../../../Tags/Image";
import { Tooltip } from "react-tooltip";
import { Button } from "../../../ui/button";
import PrintDate from "../../../Tags/PrintDate";
import Filter from "../../../Filter";
import EditColumn from "../../../EditColumn";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import ConfirmModal from "../../../Modals/ConfirmModal";
import toast from "react-hot-toast";
import { Checkbox } from "../../../ui/checkbox";
import GenericModal from "../../../GenericModal";
import ApproveRequest from "../ApproveRequest";
import useModal from "../../../GenericModal/useModal";
import { Badge } from "../../../ui/badge";
import { useCaptureEvent } from "../../../../hooks/useCaptureEvent";
export default function MembershipRequests({ getCount }) {
  const captureEvent = useCaptureEvent();
  const queryClient = useQueryClient();
  const [selectedMembers, setSelectedMembers] = useState([]);

  const { activeWorkspace } = useRoleCheck();
  const [members, setMembers] = useState({});
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  const getMembersFn = useQuery(
    ["get_membershiprequests", filterQuery],
    () => {
      return getMembershipRequests({
        query: filterQuery,
        options: {
          headers: {
            "X-Workspace-Id": activeWorkspace?.id,
          },
        },
      });
    },
    {
      enabled: !!activeWorkspace,
      refetchOnWindowFocus: false,
      manual: true,
      onSuccess: (data) => {
        setMembers(data);
        getCount(data?.data?.length);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
  const handleGetMembers = () => {
    getMembersFn.refetch();
  };
  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetMembers();
    }
  }, [activeWorkspace, filterQuery]);
  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    //console.log(page);
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetRole = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => {
      if (a.key === "requester") return -1;
      if (b.key === "requester") return 1;
      return a.order - b.order;
    });
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // handle delete member
  const [currentMemberId, setCurrentMemberId] = useState("");
  const [personIds, setPersonIds] = useState([]);

  const deleteMemberFn = useMutation("delete_membershiprequest", deleteMembershipRequest, {
    onSuccess(data, variables, context) {
      toast.success(data?.message);
      handleGetMembers();
      queryClient.invalidateQueries("get_membershiprequests");
      setSelectedMembers([]);
    },
  });
  const handleDeleteMember = () => {
    deleteMemberFn.mutate({
      data: { requestIds: personIds },
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  // handle open confirm
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteMember();
    }
  };

  // handle approve modal
  const { isOpen, openModal, closeModal } = useModal(false);

  const toggleMember = (member, single = false) => {
    if (single) {
      let arr = [];
      arr.push(member);
      setSelectedMembers(arr);
    } else {
      setSelectedMembers((prevMembers) => {
        const exists = prevMembers.find((m) => m.id === member.id);
        if (exists) {
          // Remove member
          return prevMembers.filter((m) => m.id !== member.id);
        } else {
          // Add member
          return [...prevMembers, member];
        }
      });
    }
  };
  const pushPersonIds = () => {
    const newarr = selectedMembers.map((member) => member.id);
    setPersonIds(newarr);
  };
  return (
    <div>
      <div>
        {members && members.data && (members.data.length > 0 || !isEmptyObject(filters)) && (
          <div className="mt-7 flex justify-between items-center min-h-[40px]">
            <div className="space-x-3 inline-flex items-center">
              {members && members.filterMetadata && (
                <>
                  <span>Filters:</span>
                  <Filter filterMetadata={members.filterMetadata} getFiltersObject={getFiltersObject} />
                </>
              )}
            </div>
            <div className="space-x-3">
              {selectedMembers.length > 1 ? (
                <>
                  <div className="space-x-2 flex items-center w-full justify-end">
                    <Button
                      className="space-x-2"
                      onClick={() => {
                        openModal();
                        captureEvent("SET_MembersRequests_ApproveBtn");
                      }}
                    >
                      <svg
                        width="16"
                        height="11"
                        viewBox="0 0 16 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6666 1L5.49992 10.1667L1.33325 6"
                          stroke="currentColor"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span>Approve</span>
                    </Button>
                    <Button
                      className="space-x-2"
                      disabled={deleteMemberFn.isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenConfirmModal(true);
                        pushPersonIds();
                        setOpenConfirmModalType("delete");
                        captureEvent("SET_MembersRequests_DeleteBtn");
                      }}
                      variant="outline"
                    >
                      <Image
                        src="/images/icons/untitledui/trash.svg"
                        width="16"
                        height="16"
                        className="inline-block"
                      />
                      <span>Remove</span>
                      {deleteMemberFn.isLoading && <div className="loader_circle ml-2"></div>}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {members.columnMetadata && (
                    <EditColumn columnMetadata={members.columnMetadata} getColumns={getColumns} />
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div>
          {members && members.data && members.data.length > 0 && getMembersFn.isSuccess && (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-border rounded-[--radius]">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="bg-background">
                        <tr>
                          {returnArray(sortedVisibleColumns).map((column, index) => (
                            <th
                              key={index}
                              scope="col"
                              className={`px-3 py-3.5 text-left text-sm font-normal   ${
                                column.sortable ? "cursor-pointer" : ""
                              }`}
                              onClick={() => (column.sortable ? handleSetRole(column.key) : void 0)}
                            >
                              <span className={"inline-flex items-center"}>
                                {/* {column.key === "requester" && (
                                      <Checkbox
                                        className="mr-2"
                                        defaultChecked="indeterminate"
                                      />
                                    )} */}
                                {column.display_name}
                                {column.sortable && (
                                  <>
                                    {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                      <Image
                                        src="/images/icons/dual-arrow.svg"
                                        width="7"
                                        height="7"
                                        className="inline-block ml-1"
                                      />
                                    ) : (
                                      <>
                                        {sort === column.key && (
                                          <ArrowDownIcon width={12} className="inline-block ml-1" />
                                        )}
                                        {sort === `-${column.key}` && (
                                          <ArrowUpIcon width={12} className="inline-block ml-1" />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </span>
                            </th>
                          ))}
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold "></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border bg-background">
                        {members &&
                          returnArray(members.data).map((person, index) => (
                            <tr key={person.id} className={index % 2 !== 0 ? "bg-smoke" : ""}>
                              {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                                const colKey =
                                  columns &&
                                  Object.keys(columns).find((key) => columns[key].order === column.order);

                                if (colKey === "requester") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      <div className="flex items-center">
                                        <Checkbox
                                          className="mr-2"
                                          onCheckedChange={() => {
                                            toggleMember(person);
                                          }}
                                          checked={
                                            selectedMembers.find((m) => m.id === person.id) ? true : false
                                          }
                                          disabled={person?.status !== 'Requested' ? true : false}
                                        />
                                        <div className="h-9 w-9 flex-shrink-0">
                                          <Image
                                            nobaseurl
                                            className="h-9 w-9 rounded-full"
                                            src={person?.requester?.profile_photo_url}
                                            alt=""
                                          />
                                        </div>
                                        <div className="ml-4">
                                          <div className="font-medium ">{person?.requester?.name}</div>
                                          <div className="mt-0 text-gray-500">{person?.requester?.email}</div>
                                        </div>
                                      </div>
                                    </td>
                                  );
                                }
                                if (colKey === "approver") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      {person?.approver?.name ? (
                                        <div className="flex items-center">
                                          <div className="h-9 w-9 flex-shrink-0">
                                            <Image
                                              nobaseurl
                                              className="h-9 w-9 rounded-full"
                                              src={person?.approver?.profile_photo_url}
                                              alt=""
                                            />
                                          </div>
                                          <div className="ml-4">
                                            <div className="font-medium ">{person?.approver?.name}</div>
                                            <div className="mt-0 text-gray-500">
                                              {person?.approver?.email}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  );
                                }

                                if (colKey === "status") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      <Badge variant={getVariant(person?.status)} className={"capitalize"}>
                                        {person.status}
                                      </Badge>
                                    </td>
                                  );
                                }

                                if (colKey === "created_at") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      <PrintDate date={person.created_at} />
                                    </td>
                                  );
                                }
                                if (colKey === "reviewed_at") {
                                  return (
                                    <td
                                      key={colIndex}
                                      className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                    >
                                      <PrintDate date={person.reviewed_at} />
                                    </td>
                                  );
                                }

                                return (
                                  <td
                                    key={colIndex}
                                    className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                  >
                                    {person[colKey] ? person[colKey] : "-"}
                                  </td>
                                );
                              })}
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                {person?.status === "Requested" && (
                                  <div className="space-x-2 flex items-center w-full justify-end">
                                    <Button
                                      variant="outline"
                                      className="space-x-2"
                                      onClick={() => {
                                        toggleMember(person, true);
                                        openModal();
                                        captureEvent("SET_MembersRequests_ApproveBtn");
                                      }}
                                      disabled={selectedMembers.length > 1}
                                    >
                                      <svg
                                        width="16"
                                        height="11"
                                        viewBox="0 0 16 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14.6666 1L5.49992 10.1667L1.33325 6"
                                          stroke="currentColor"
                                          stroke-width="1.66667"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      <span>Approve</span>
                                    </Button>
                                    <Button
                                      data-tooltip-id="in_table_actions"
                                      data-tooltip-content="Delete Request"
                                      disabled={
                                        (deleteMemberFn.isLoading && currentMemberId === person.id) ||
                                        selectedMembers.length > 1
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentMemberId(person.id);
                                        setPersonIds([person.id]);
                                        setOpenConfirmModal(true);
                                        setOpenConfirmModalType("delete");
                                        captureEvent("SET_MembersRequests_DeleteBtn");
                                      }}
                                      variant="ghost"
                                    >
                                      <Image
                                        src="/images/icons/untitledui/trash.svg"
                                        width="16"
                                        height="16"
                                        className="inline-block"
                                      />
                                      {deleteMemberFn.isLoading && currentMemberId === person.id && (
                                        <div className="loader_circle ml-2"></div>
                                      )}
                                    </Button>
                                  </div>
                                )}
                                <Tooltip id="in_table_actions" />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {getMembersFn.isLoading && <TableLoader />}

          {getMembersFn.isSuccess &&
            members &&
            members.data &&
            members.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No Member found!"} />}
          {getMembersFn.isSuccess &&
            members &&
            members.data &&
            members.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No Member found!"} filterMode={true} />
              </div>
            )}
          <div className="flex items-center justify-between  rounded-[--radius] py-3">
            <div className="flex-1">
              {members && members.pagination && members.pagination.total > 0 && (
                <Pagination data={members.pagination} getPageNumber={getPageNumber} />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        openModal={openConfirmModal}
        modalType={openConfirmModalType}
        confirmModalState={confirmModalState}
      />
      <GenericModal
        isOpen={isOpen}
        closeModal={() => {
          setSelectedMembers([]);
          closeModal();
        }}
      >
        <ApproveRequest
          closeModal={() => {
            setSelectedMembers([]);
            closeModal();
          }}
          activeWorkspace={activeWorkspace}
          selectedMembers={selectedMembers}
          removeMember={toggleMember}
        />
      </GenericModal>
    </div>
  );
}
